// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
// RXJS
import { filter, first } from 'rxjs/operators';

const CRID_LOAD = 'CRID_PERMISSIONS';
@Injectable({
    providedIn: 'root',
})
export class PermissionsEntityService extends EntityCollectionServiceBase<any> {
    private _correlationIndex = 0;

    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Permissions', serviceElementsFactory);

        this.loadAll();
    }

    /******************/
    /**    Actions    */
    /******************/
    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }
}
