// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NGRX
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
// RXJS
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
// Core
import { FirestoreService } from '@core/services';
import { AppState } from '@core/reducers';
import { QueryFSModel } from '@core/_base/crud';
import { AuthUserModel, getUser } from '@core/auth';
// Store
import { QuoteLineModel, QuoteModel, QuoteStatus } from './quote.model';
import { FS_PATH_QUOTES, FS_PATH_QUOTE_LINES } from '@store/firestore-collections';
// Lodash
import { cloneDeep, identity, isNil, omitBy, pickBy } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class QuotesDataService extends DefaultDataService<any> {
    user: AuthUserModel;

    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private store: Store<AppState>
    ) {
        super('Quotes', http, httpUrlGenerator);

        this._getConnectedUser();
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getAll(): Observable<any[]> {
        return this._getData();
    }

    getById(quoteId: string): Observable<QuoteModel> {
        const pathQuote = `${FS_PATH_QUOTES}/${quoteId}`;

        return this.firestoreService.doc$(pathQuote, null).pipe(
            switchMap((quote: QuoteModel) => {
                const pathQuoteLines = `${pathQuote}/${FS_PATH_QUOTE_LINES}`;
                return this.firestoreService.collection$(pathQuoteLines, null).pipe(map((quoteLines) => new QuoteModel({ ...quote, quoteLines: quoteLines })));
            }),
        );
    }

    getWithQuery(queryParams: QueryParams | string): Observable<any[]> {
        const queryArray = this.firestoreService.getQueryFSModel(queryParams);
        return this._getData(queryArray);
    }

    add(quote: QuoteModel): Observable<any> {
        // Clone object to modify it before save
        const _quote = cloneDeep(quote);

        // Delete quote lines to save them in sub collection
        const quoteLines = quote.quoteLines;
        delete _quote.quoteLines;

        // Add quote and add pre quote lines
        return this.firestoreService.updateAt(FS_PATH_QUOTES, _quote).pipe(
            switchMap((doc) => {
                const quoteId = doc.id;
                return combineLatest([
                    of(doc),
                    quoteLines.map((quoteLine) => {
                        return this.addOrUpdateQuoteLine(quoteId, quoteLine);
                    }),
                ]);
            }),
            // Return object with id to save it in store
            map((res) => {
                return { id: res[0].id, ...quote };
            }),
        );
    }

    update(quote: Update<QuoteModel>): Observable<any> {
        // Clone object to modify it before save
        let _quote = cloneDeep(quote.changes);

        // Clean object (delete null and undefined property)
        _quote = omitBy(_quote, isNil);

        // Delete quote lines
        delete _quote.quoteLines;

        // Update quote
        return this.firestoreService.updateAt(`${FS_PATH_QUOTES}/${quote.id}`, _quote);
    }

    delete(quoteId: string): Observable<any> {
        return this.firestoreService.delete(`${FS_PATH_QUOTES}/${quoteId}`);
    }

    /** Actions on sub collection quoteLines */
    addOrUpdateQuoteLine(quoteId: string, quoteLine: QuoteLineModel): Observable<any> {
        // Clean object (delete null and undefined property)
        const _quoteLine = pickBy(quoteLine, identity);

        const pathQuoteLines = `${FS_PATH_QUOTES}/${quoteId}/${FS_PATH_QUOTE_LINES}/${quoteLine.product.id}`;
        return this.firestoreService.updateAt(pathQuoteLines, _quoteLine);
    }

    updateQuoteLineQuantity(quoteId: string, quoteLine: QuoteLineModel) {
        const pathQuoteLines = `${FS_PATH_QUOTES}/${quoteId}/${FS_PATH_QUOTE_LINES}/${quoteLine.id}`;
        return this.firestoreService.updateAt(`${pathQuoteLines}`, { quantity: quoteLine.quantity, total: quoteLine.total });
    }

    deleteQuoteLine(quoteId: string, quoteLineId: string): Observable<any> {
        const path = `${FS_PATH_QUOTES}/${quoteId}/${FS_PATH_QUOTE_LINES}/${quoteLineId}`;
        return this.firestoreService.delete(path);
    }

    getQuoteLines(quoteId: string): Observable<QuoteLineModel[]> {
        const pathQuoteLines = `${FS_PATH_QUOTES}/${quoteId}/${FS_PATH_QUOTE_LINES}`;
        return this.firestoreService.collectionSnapShot(pathQuoteLines, null);
    }

    /************************/
    /*   Private functions  */
    /************************/
    _getConnectedUser(): void {
        this.store
            .select(getUser)
            .pipe(filter((user) => user != undefined))
            .subscribe((user) => (this.user = user));
    }

    _getData(query: QueryFSModel[] = []): Observable<any> {
        const queryQuote = this._getQueryQuote();

        // Add more query parameters if exists
        queryQuote.push(...query);

        // Query quote
        return this.firestoreService.collection$(FS_PATH_QUOTES, undefined, queryQuote).pipe(
            switchMap((quotes: QuoteModel[]) => {
                if (quotes.length === 0) return of([]);

                // Query quote lines
                return combineLatest(
                    quotes.map((quote) => {
                        return this.getQuoteLines(quote.id).pipe(map((quoteLines) => new QuoteModel({ ...quote, quoteLines: quoteLines })));
                    }),
                );
            }),
        );
    }

    _getQueryQuote(): QueryFSModel[] {
        if (this.user.roles?.includes('supplier')) {
            return [new QueryFSModel('supplier.id', '==', this.user.companyId), new QueryFSModel('status', '>', QuoteStatus.QuoteCart)];
        } else {
            return [new QueryFSModel('customer.companyId', '==', this.user.companyId), new QueryFSModel('status', '>=', QuoteStatus.QuoteCart)];
        }
    }
}
