// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
// Material
import { MatDialog } from '@angular/material/dialog';
// RXJS
import { BehaviorSubject } from 'rxjs';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
// Store
import { ProductsEntityService, SupplierModel, SuppliersEntityService } from '@store/index';
// Feature
import { SuppliersFilterComponentService } from './suppliers-filter.service';
import { SupplierEditComponent } from '@features/suppliers/components/supplier-edit/supplier-edit.component';
// Translate
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'suppliers-filter',
    templateUrl: './suppliers-filter.component.html',
    styleUrls: ['suppliers-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SuppliersFilterComponent extends UnsubscribeOnDestroy implements OnInit {
    collapseSuppliers = false;
    suppliers: any[];

    // Selection
    allSelected: boolean;
    selection = new SelectionModel<any>(true /* multiple */);
    changeSelectionSubject: BehaviorSubject<null> = new BehaviorSubject(null);
    changeSelection$ = this.changeSelectionSubject.asObservable();

    //prettier-ignore
    constructor(
        private dialog: MatDialog,
        private productsService: ProductsEntityService,
        private suppliersFilterComponentService: SuppliersFilterComponentService,
        private suppliersService: SuppliersEntityService,
        private translateService: TranslateService
    ) {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        // On selection change
        this.subs.sink = this.selection.changed.subscribe(() => {
            // Emit suppliers selected to refresh products list
            this.suppliersFilterComponentService.setSuppliers(this.selection.selected);
        });

        // Get suppliers from products list
        this.subs.sink = this.productsService.selectSupplierOfProducts().subscribe((suppliers) => {
            this.suppliers = suppliers;

            if (this.selection.selected.length === 0) {
                // By default select all
                this.allSelected = false;
                this.onSelectAll();
            }
        });
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    editSupplier(supplier: SupplierModel) {
        this.dialog.open(SupplierEditComponent, {
            data: { supplierId: supplier.id },
            width: '600px',
        });
    }

    // async uploadLogo(event: any, supplier: any) {
    //     const file = event.addedFiles[0];

    //     // Prepare data
    //     const _supplier = new SupplierModel();
    //     _supplier.id = supplier.id;
    //     _supplier.logo = file;

    //     // Update Logo in Storage and get file url
    //     this.suppliersServices.update(_supplier);
    // }

    // removeLogo(supplier: any) {
    //     // Remove logo from storage
    //     this.suppliersServices.deleteFileInStorage(supplier.logo);
    // }

    /***************/
    /*  SELECTION  */
    /***************/
    /** Select or unselect all items */
    checkAll() {
        const nbItems = this.suppliers?.length;
        if (nbItems > 0) {
            const nbItemsSelected = this.selection.selected.length;
            this.allSelected = nbItems === nbItemsSelected;
        }
    }

    isSelected(item: string) {
        return this.selection.isSelected(item);
    }

    onSelectAll() {
        this.allSelected = !this.allSelected;
        this.allSelected ? this.selection.select(...this.suppliers.map((s) => s.name)) : this.selection.deselect(...this.suppliers.map((s) => s.name));
    }

    /** Display or hide item and check to select or unselect the group */
    onSelectItem(item: string) {
        if (this.isSelected(item)) this.selection.deselect(item);
        else this.selection.select(item);

        this.checkAll();
    }

    /***********/
    /**   UI   */
    /***********/
    getSupplierName(supplier) {
        return supplier.name !== null ? supplier.name : this.translateService.instant('PRODUCTS.WITHOUT_SUPPLIER');
    }
}
