// Firestore private collections
export const FS_PATH_EMAILS = '_emails';
export const FS_PATH_FEEDBACK = '_feedback';

// Firestore collections
export const FS_PATH_CATALOGS = 'catalogs';
export const FS_PATH_CATEGORIES = 'categories';
export const FS_PATH_CHAT = 'chatRooms';
export const FS_PATH_COMPANIES = 'companies';
export const FS_PATH_CONTACTS = 'contacts';
export const FS_PATH_CUSTOMERS = 'customers';
export const FS_PATH_CUSTOMERS_TENANT = 'customersTenant';
export const FS_PATH_DISCOUNTS = 'discounts';
export const FS_PATH_FAVORITES = 'favorites';
export const FS_PATH_INQUIERIES = 'inquieries';
export const FS_PATH_INVITATIONS = 'invitations';
export const FS_PATH_MESSAGES = 'messages';
export const FS_PATH_NOTIFICATIONS = 'notifications';
export const FS_PATH_ORDERS = 'orders';
export const FS_PATH_ORDER_LINES = 'orderLines';
export const FS_PATH_PERMISSIONS = 'permissions';
export const FS_PATH_PARAMS = '_params/pgoG4A4BZt48sRsNC1Ec';
export const FS_PATH_PRODUCTS = 'products';
export const FS_PATH_QUOTES = 'quotes';
export const FS_PATH_QUOTE_LINES = 'quoteLines';
export const FS_PATH_REQUESTS = 'requests';
export const FS_PATH_REQUESTS_LINES = 'requestsLines';
export const FS_PATH_ROLES = 'roles';
export const FS_PATH_SELLERS = 'sellers';
export const FS_PATH_SUPPLIERS = 'suppliers';
export const FS_PATH_SUPPLIERS_TENANT = 'suppliersTenant';
export const FS_PATH_UNIVERSES = 'universes';
export const FS_PATH_USERS = 'users';

// Tenants name
export const FS_TENANT_CUSTOMERS = 'customers';
export const FS_TENANT_SELLERS = 'sellers';
export const FS_TENANT_SUPPLIERS = 'suppliers';
export const FS_TENANT_USERS = 'users';
