// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// Material
import { MatDialog } from '@angular/material/dialog';
// RXJS
import { Observable } from 'rxjs';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
// Store
import { ProductsEntityService, SuppliersEntityService } from '@store/index';
// Translate
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'select-suppliers',
    templateUrl: './select-suppliers.component.html',
    styleUrls: ['select-suppliers.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SelectSuppliersComponent extends UnsubscribeOnDestroy implements OnInit {
    suppliers$: Observable<any[]>;

    //prettier-ignore
    constructor(
        private dialog: MatDialog,
        private productsService: ProductsEntityService,
        private suppliersService: SuppliersEntityService,
        private translateService: TranslateService)
    {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.getSuppliers();
    }

    /***************/
    /*   ACTIONS   */
    /***************/

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    getSuppliers(): void {
        // this.suppliers$ = this.suppliersService.getEntities();
    }

    /***********/
    /**   UI   */
    /***********/
}
