import { BaseModel } from '@core/_base/crud';

import { AddressModel } from '@store/_models/address.model';
import { CategoryModel } from '@store/categories/category.model';
import { ContactModel } from '@store/_models/contact.model';
import { OpeningTime } from '@store/_models/opening-time.model';
import { ProductModel } from '@store/products/product.model';

import { pickBy } from 'lodash';

export class SaleTerms {
    carriageFree?: number;
    clickAndCollect?: boolean;
    delivery?: boolean;
    minimumOrder?: number;
    shippingCost?: number;
    saleTermsFile: File | string;

    constructor(obj?: any) {
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }
    }
}

export class SupplierModel extends BaseModel {
    // Database Field
    accountingContact: ContactModel;
    address?: AddressModel;
    advContact: ContactModel;
    catalogId: string; // Fill in customer tenant when customer have access to a custom catalog
    commercialContact: ContactModel;
    description?: string;
    distance: number;
    ecoFriendly?: boolean; // True if supplier offer some products eco friendly
    id?: string;
    inactive: boolean;
    isPremium: boolean; // True if supplier subscribe premium plan
    linked?: boolean; // True if there is a link with a supplier and customer
    logo?: any;
    madeInFrance?: boolean; // True if supplier offer some products made in france
    name: string;
    nbOrders: number;
    onlyOnQuote?: boolean; // True if supplier has no catalog, only contact for quote
    openingTime: OpeningTime[];
    orderContact?: ContactModel; // Use to define which contact receive order notification => for the moment it's the first contact in supplier tenant
    photos?: string[];
    saleTerms?: SaleTerms;
    siret?: string;
    standBy: boolean; // True if supplier has not yet filled his catalog => display "Soon available" message
    tags?: string[] = [];
    turnover: number;
    typesenseApiKey?: string;

    // Field not saved in database
    catalogLoaded: boolean;
    categories?: CategoryModel[];
    contact?: ContactModel;
    products?: ProductModel[];

    constructor(obj?: any) {
        super();
        this.inactive = false;

        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);

            if (obj.address) {
                this.address = new AddressModel(obj.address);
            }

            if (obj.saleTerms) {
                this.saleTerms = new SaleTerms(obj.saleTerms);
            }

            if (obj.contact) {
                this.contact = new ContactModel(obj.contact);
            }
        }
    }

    /** Return object with only needed properties */
    getObjectToAddInCart(): any {
        return {
            contact: this.orderContact || null,
            address: this.address,
            id: this.id,
            logo: this.logo || null,
            name: this.name,
            saleTerms: this.saleTerms || null,
        };
    }

    getObjectToAddInQuotesRequests(): any {
        return {
            contact: this.orderContact || null,
            address: this.address,
            id: this.id,
            logo: this.logo || null,
            name: this.name,
            saleTerms: this.saleTerms || null,
        };
    }

    /** Return object with only available search properties */
    getObjectToSearch(): any {
        return {
            name: this.name,
            displayName: this.contact?.displayName,
            email: this.contact?.email,
            tags: this.tags.join(' '),
        };
    }

    getUrl(): string {
        return this.name.trim().toLowerCase().replace(/ /g, '-');
    }
}
