import { TranslateService } from '@ngx-translate/core';
import { MessageType } from './layout-utils.service';
// Angular
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
// RxJS
import { from, Observable, throwError } from 'rxjs';
import { tap, catchError, map, switchMap } from 'rxjs/operators';
// Environment
import { environment } from '@env/environment';
// Core
import { LayoutUtilsService } from './layout-utils.service';
import { AuthService } from '@core/auth/store/_services/auth.service';
import { LogService } from '@core/services/log.service';

@Injectable()
export class InterceptService implements HttpInterceptor {
    //prettier-ignore
    constructor(
        private authService: AuthService,
        private layoutUtilsService: LayoutUtilsService,
        private translateService: TranslateService,
        private logService: LogService) {}

    // intercept request and add token
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const token$ = from(this.authService.getIdToken());
        return token$.pipe(
            map((token) => {
                // console.log(token);
                return !!token
                    ? request.clone({
                          setHeaders: {
                              'Content-Type': 'application/json; charset=utf-8',
                              Authorization: 'Bearer ' + token,
                          },
                      })
                    : request;
            }),
            switchMap((authReq) => {
                return next.handle(authReq).pipe(
                    //retry(1),
                    tap((event) => {
                        if (event instanceof HttpResponse && !environment.production && !event.url.includes('localhost')) {
                        }
                    }),
                    catchError((error: HttpErrorResponse) => {
                        let errorMessage = '';
                        if (error.error instanceof ErrorEvent) {
                            // client-side error
                            errorMessage = `Error: ${error.error.message}`;
                        } else {
                            // server-side error
                            errorMessage = `Error Code: ${error.status}\nMessage: ${error.error?.message || error.message}`;
                        }
                        console.error(errorMessage);
                        const message = this.translateService.instant('GENERAL.LOAD_DATA_ERROR');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 5000, true, false);

                        const _error = { status: error.status, message: error.error?.message || error.message };
                        this.logService.logErrorsInFS(_error);
                        return throwError(_error);
                    }),
                );
            }),
        );
    }
}
