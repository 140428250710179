import { pickBy } from 'lodash';

export class ContactModel {
    id?: string;
    firstname?: string;
    lastname?: string;
    displayName?: string;
    email?: string;
    phone?: string;

    constructor(obj?: any) {
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }
    }

    getDisplayName(): string {
        return this.displayName || `${this.firstname} ${this.lastname}`;
    }
}
