// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RXJS
import { filter, first, map, switchMap } from 'rxjs/operators';
import { Observable, from, combineLatest } from 'rxjs';
// Firebase
import { AngularFireAuth } from '@angular/fire/auth/';
import firebase from 'firebase/app';
import UserCredential = firebase.auth.UserCredential;
// Core
import { FirestoreService } from '@core/services';
import { AuthUserModel } from './../_models/auth.model';
// Store
import { ParamsService } from '@store/_services/params.service';
//Environment
import { environment } from '@env/environment';

// API PATH
const API_PATH = `${environment.apiBaseUrl}/users`;
// const API_PATH = `http://localhost:5001/stoick-dev/europe-west1/usersApi-users`;

// Firebase path for "users" collection
const FS_PATH = 'users';

@Injectable()
export class AuthService {
    private _tenantId$: Observable<string | null>;

    //prettier-ignore
    constructor(
        public afAuth: AngularFireAuth,
        private firestoreService: FirestoreService,
        private http: HttpClient,
        private paramsService: ParamsService,
    ) {
        this._tenantId$ = this.paramsService.getTenantId().pipe(filter((tenantId) => tenantId !== undefined));
    }

    createAccount(userInformations: any): Observable<any> {
        const params = {
            data: userInformations,
        };
        const path = `${API_PATH}/createAccount`;
        return this.http.post(path, JSON.stringify(params));
    }

    deleteAccount(): Observable<any> {
        return from(this.afAuth.currentUser).pipe(map((u) => u.delete()));
    }

    async doLoginWithCredentials(credentials: any): Promise<UserCredential> {
        const tenantId = await this._tenantId$.pipe(first()).toPromise();
        firebase.auth().tenantId = tenantId;

        return this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password);
    }

    getAuthState(): Observable<firebase.User> {
        // Check auth state for the good tenant
        return combineLatest([this.afAuth.authState, this._tenantId$]).pipe(
            map(([authState, tenantId]) => {
                if (authState?.tenantId !== tenantId) {
                    this.logout();
                    return null;
                }

                return authState;
            }),
        );
    }

    getFireErrorMessage(errorCode: string): string {
        if (!this.isFireErrorMessage(errorCode)) return 'GENERAL.ERROR';

        console.debug(errorCode);

        /* Format firebase auth code to translate */
        let message = 'AUTH.FIRE_ERROR.';
        message += errorCode.substring(5).replace(/-/g, '_').toLocaleUpperCase();
        return message;
    }

    async getIdToken(): Promise<string> {
        return (await this.afAuth.currentUser)?.getIdToken(true);
    }

    // getToken$(): Observable<firebase.auth.IdTokenResult> {
    //     return this.afAuth.idTokenResult;
    // }

    // getUser(): Observable<firebase.User> {
    //     // firebase.auth().tenantId = 'keepsafe-xd8wf';
    //     return of(firebase.auth().currentUser);
    // }

    // getUser$(): Observable<firebase.User> {
    //     return from(this.afAuth.currentUser);
    // }

    getUserById(userId: string): Observable<AuthUserModel> {
        const path = `${FS_PATH}/${userId}`;
        return this.firestoreService.docSnapshot(path).pipe(map((user) => new AuthUserModel(user)));
    }

    isFireErrorMessage(errorCode: any): boolean {
        if (errorCode && typeof errorCode === 'string') return errorCode.includes('auth');
        else return false;
    }

    isSignInWithEmailLink(url: string): Observable<boolean> {
        return from(this.afAuth.isSignInWithEmailLink(url));
    }

    logout(): Promise<any> {
        return this.afAuth.signOut();
    }

    registerWithEmail(email: string, tenantName: string): Observable<any> {
        localStorage.setItem('emailForSignIn', email);
        const params = {
            email: email,
            tenantName: tenantName,
        };
        const path = `${API_PATH}/register`;
        return this.http.post(path, JSON.parse(JSON.stringify(params)));
    }

    sendPasswordResetEmail(email: string): Observable<any> {
        return from(this.afAuth.sendPasswordResetEmail(email));
    }

    async signInWithEmailLink(email: string, url: string): Promise<UserCredential> {
        const tenantId = await this._tenantId$.pipe(first()).toPromise();
        firebase.auth().tenantId = tenantId;

        return this.afAuth.signInWithEmailLink(email, url);
    }

    async updateAuthProfile(password: string, displayName?: string): Promise<firebase.User> {
        try {
            const user = await this.afAuth.currentUser;
            await user.getIdToken(true);
            await user.updateProfile({ displayName: displayName || user.displayName });
            await user.updatePassword(password);
            return Promise.resolve(user);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    updateLastSignin(userId: string): void {
        this.firestoreService.updateAt(`${FS_PATH}/${userId}`, { lastSignin: this.firestoreService.getFirestoreTimestamp() });
    }

    updatePassword(oldPassword: string, newPassword: string): Observable<any> {
        return from(this.afAuth.currentUser).pipe(
            // Refresh authentification
            switchMap((user) => {
                const credentials = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);
                return from(user.reauthenticateWithCredential(credentials));
            }),
            // Change password
            switchMap(() => {
                return from(this.afAuth.currentUser).pipe(map((u) => u.updatePassword(newPassword)));
            }),
        );
    }
}
