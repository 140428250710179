// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Core
import { getUser } from '@core/auth';
import { AppState } from '@core/reducers';
import { FirestoreService } from '@core/services';
//NGRX
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
// Store
import { FS_PATH_NOTIFICATIONS, FS_PATH_USERS } from '@store/firestore-collections';
import { NotificationModel } from './notification.model';
// RXJS
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NotificationsDataService extends DefaultDataService<any> {
    private _pathNotifications: string;

    // prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private store: Store<AppState>
    ) {
        super('Notifications,', http, httpUrlGenerator);

        this._setPathNotifications();
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    add(notification: NotificationModel) {
        return this.firestoreService.updateAt(this._pathNotifications, notification);
    }
    getAll(): Observable<any> {
        return this.firestoreService.collection$(this._pathNotifications);
    }

    update(notification: Update<NotificationModel>): Observable<any> {
        return this.firestoreService.updateAt(`${this._pathNotifications}/${notification.id}`, notification.changes);
    }

    delete(notificationId: string): Observable<any> {
        return this.firestoreService.delete(`${this._pathNotifications}/${notificationId}`);
    }

    /************************/
    /*   Private functions  */
    /************************/
    _setPathNotifications(): void {
        this.store
            .select(getUser)
            .pipe(filter((user) => user != undefined))
            .subscribe((user) => (this._pathNotifications = `${FS_PATH_USERS}/${user.id}/${FS_PATH_NOTIFICATIONS}`));
    }
}
