// Angular
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// NGRX
import { EntityAction, EntityOp } from '@ngrx/data';
// RXJS
import { debounceTime, delay, filter, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
// Core
import { LayoutUtilsService, MessageType, TypesUtilsService } from '@core/_base/crud';
import { ExcelService, FileService, UnsubscribeOnDestroy } from '@core/services';
// Store
import { CatalogsEntityService, CatalogModel, ProductDiscount } from '@store/index';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Theme
import { TitlePageService } from '@theme/layout/header/title-page/title-page.service';
// Typesense
import { COLLECTION_PRODUCTS, TypesenseClient, TypesenseHitsProductsListEditComponent, TypesenseRefreshComponent, TypesenseSortComponent } from '@shared/components/typesense';
// Feature
import { CatalogSearchProductComponent } from '../catalog-search-product/catalog-search-product.component';
// Lodash
import { debounce } from 'lodash';
// Shared
import { CatalogImportDialogComponent } from '@shared/components/business/catalog-import/catalog-import-dialog.component';
import { WaitDialogComponent } from '@shared/components/general/wait-dialog/wait-dialog.component';

@Component({
    selector: 'catalog-edit',
    templateUrl: './catalog-edit.component.html',
    styleUrls: ['./catalog-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ExcelService],
})
export class CatalogEditComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @ViewChild(TypesenseHitsProductsListEditComponent) productsListComponent: TypesenseHitsProductsListEditComponent;
    @ViewChild(TypesenseRefreshComponent) typesenseRefresh: TypesenseRefreshComponent;
    @ViewChild(TypesenseSortComponent) typesenseSort: TypesenseSortComponent;

    @Input() customerId: string;

    @Output() backToCustomer = new EventEmitter();

    // Typesense
    typesenseClient: TypesenseClient;

    catalog: CatalogModel;
    oldDiscount: number;
    isEdit = false;
    itemsSort = [];
    loading = false;
    loadingDelete = false;
    productsDiscounts$: Observable<ProductDiscount[]>;

    //prettier-ignore
    constructor(
        private activatedRoute: ActivatedRoute,
        private catalogsService: CatalogsEntityService,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private excelService: ExcelService,
        private fileService: FileService,
        private layoutUtilsService: LayoutUtilsService,
        private router: Router,
        private translateService: TranslateService,
        private titlePageService: TitlePageService,
        private typesUtilService: TypesUtilsService,
    ) {
        super();
        this.catalog = new CatalogModel();

        this.titlePageService.showBackButton(true);

        this.initSort();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.customerId ? this.getCustomerCatalog() : this.getCatalogFromResolver();

        this.initTypesense();

        this.onErrors();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        this.titlePageService.showBackButton(false);
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    addProduct(): void {
        const typesenseRepositoryApiKey = this.activatedRoute.snapshot.data.supplier.typesenseRepositoryApiKey;
        const dialogRef = this.dialog.open(CatalogSearchProductComponent, {
            data: {
                catalog: this.catalog,
                typesenseRepositoryApiKey: typesenseRepositoryApiKey,
            },
            width: '90%',
            height: '90%',
        });

        dialogRef.afterClosed().subscribe((res) => {
            this.catalog = new CatalogModel(res.catalog);
            setTimeout(() => this.setCatalogFilter(), 500);
            this.isEdit = res.isEdit;
        });
    }

    debouceCatalogDiscountChange = debounce((discount: string) => this.catalogDiscountChange(this.typesUtilService.getNumber(discount)), 1000);

    catalogDiscountChange(discount: number) {
        const title = this.translateService.instant('CATALOGS.EDIT.UPDATE_CATALOG_DISCOUNT');
        const description = `${this.translateService.instant('CATALOGS.EDIT.UPDATE_CATALOG_DISCOUNT_CONFIRM_1')} ${discount}${this.translateService.instant('CATALOGS.EDIT.UPDATE_CATALOG_DISCOUNT_CONFIRM_2')}\n\n${this.translateService.instant('CATALOGS.EDIT.UPDATE_CATALOG_DISCOUNT_CONFIRM_3')}`;
        const dialogRef = this.layoutUtilsService.confirm(title, description);

        this.subs.sink = dialogRef.componentInstance.cancel.pipe(first()).subscribe(() => {
            this.catalog = new CatalogModel({ ...this.catalog, discount: this.oldDiscount });
            this.cdr.markForCheck();
        });

        this.subs.sink = dialogRef.componentInstance.confirm.pipe(first()).subscribe(() => {
            this.oldDiscount = this.catalog.discount;
            this.catalog = new CatalogModel({ ...this.catalog, discount });
            this.catalogsService
                .updateCatalogDiscount(this.catalog)
                .pipe(first())
                .subscribe(() => {
                    dialogRef.close();
                    setTimeout(() => this.typesenseRefresh.refresh(), 500);
                });
        });
    }

    dataChange(): void {
        this.isEdit = true;
    }

    deleteSelectedProducts() {
        this.loadingDelete = true;
        this.catalogsService
            .deleteManyProducts(this.catalog, this.productsListComponent.dataTable.selection.selected)
            .pipe(delay(1000))
            .subscribe((catalog) => {
                this.loadingDelete = false;
                this.catalog = new CatalogModel(catalog);
                this.typesenseRefresh.refresh();
            });
    }

    downloadTemplate(): void {
        const file = 'assets/files/template_catalog.xlsx';
        this.fileService.downloadFile(file, 'Stoick_catalogue_import.xlsx');
    }

    async exportProducts(): Promise<void> {
        const dialogRef = this.openWaitDialog();

        try {
            const jsonArray = [];
            const data = await this.catalogsService.getProductsWithReference(this.catalog.id).pipe(first()).toPromise();

            data.forEach((item) => {
                const jsonObject: any = {
                    'Référence interne': item.reference,
                    Désignation: item.name,
                    'Prix public': item.price,
                    '% Remise': item.discount,
                    'Prix remisé': item.priceDiscount,
                };
                jsonArray.push(jsonObject);
            });

            await this.excelService.exportAsExcelFileFromTemplate(jsonArray, `Catalogue_${this.catalog.name}`, 'assets/files/template_catalog.xlsx');

            dialogRef.close();
        } catch (error) {
            console.error(error);
            dialogRef.close();
        }
    }

    handlerBackToCustomer(): void {
        this.backToCustomer.emit();
    }

    importProducts(): void {
        const dialogRef = this.dialog.open(CatalogImportDialogComponent, {
            width: '600px',
            data: {
                catalog: this.catalog,
            },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((catalog) => {
            this.catalog = new CatalogModel(catalog);
            setTimeout(() => this.setCatalogFilter(), 1000);
            this.isEdit = true;
        });
    }

    onHitsChange(productsId: string[]) {
        this.productsDiscounts$ = this.catalogsService.getProductsById(this.catalog.id, productsId);
    }

    onProductDiscountChange(productDiscount: ProductDiscount) {
        this.catalogsService.updateProduct(this.catalog.id, productDiscount).pipe(first()).subscribe();
    }

    save(): void {
        this.loading = true;

        const catalog = this.prepareData();

        // if (!this.catalog.nbProducts) {
        //     const dialogRef = this.layoutUtilsService.confirm('CATALOGS.EDIT.EMPTY_PRODUCT_TITLE', 'CATALOGS.EDIT.EMPTY_PRODUCT_DESC', null, false, null, null, true);
        //     dialogRef.componentInstance.confirm.pipe(first()).subscribe(() => dialogRef.close());
        //     this.loading = false;
        //     return;
        // }

        this.catalog.id ? this.catalogsService.update(catalog) : this.catalogsService.add(catalog);

        // Wait for response
        this.subs.sink = this.catalogsService.entityActions$
            .pipe(
                filter((ea: EntityAction) => ea.payload.entityOp === EntityOp.SAVE_ADD_ONE_SUCCESS || ea.payload.entityOp === EntityOp.SAVE_UPDATE_ONE_SUCCESS),
                debounceTime(500),
            )
            .subscribe(() => {
                this.isEdit = false;
                this.loading = false;
                const _message = this.translateService.instant('CATALOGS.EDIT.SAVE_SUCCESS');
                this.layoutUtilsService.showActionNotification(_message, MessageType.Success, 5000, true, false);
                if (this.customerId) {
                    this.handlerBackToCustomer();
                } else {
                    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
                }
            });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/

    getCatalogFromResolver() {
        const catalog = this.activatedRoute.snapshot.data.catalog;
        this.oldDiscount = catalog?.discount || 0;
        this.catalog = new CatalogModel(catalog);
    }

    getCustomerCatalog() {
        this.subs.sink = this.catalogsService.loadByCustomerId(this.customerId).subscribe((catalog) => {
            this.catalog = catalog;
            this.setCatalogFilter();
        });
    }

    hasUnsavedData(): boolean {
        return this.productsListComponent.isEdit || this.isEdit;
    }

    initTypesense(): void {
        const typesenseApiKey = this.activatedRoute.snapshot.data.supplier.typesenseApiKey;
        if (typesenseApiKey) {
            this.typesenseClient = new TypesenseClient(COLLECTION_PRODUCTS, typesenseApiKey, null, 0);
            this.setCatalogFilter();
            setTimeout(() => this.typesenseSort.sort(`${COLLECTION_PRODUCTS}/sort/referenceSupplier:asc`), 500);
        }
    }

    initSort(): void {
        this.itemsSort = [
            { label: this.translateService.instant('TYPESENSE.SORT_NAME_ASC'), value: `${COLLECTION_PRODUCTS}/sort/name:asc` },
            { label: this.translateService.instant('TYPESENSE.SORT_NAME_DESC'), value: `${COLLECTION_PRODUCTS}/sort/name:desc` },
            { label: this.translateService.instant('TYPESENSE.SORT_PRICE_ASC'), value: `${COLLECTION_PRODUCTS}/sort/price:asc` },
            { label: this.translateService.instant('TYPESENSE.SORT_PRICE_DESC'), value: `${COLLECTION_PRODUCTS}/sort/price:desc` },
            { label: this.translateService.instant('TYPESENSE.SORT_REF_ASC'), value: `${COLLECTION_PRODUCTS}/sort/referenceSupplier:asc` },
            { label: this.translateService.instant('TYPESENSE.SORT_REF_DESC'), value: `${COLLECTION_PRODUCTS}/sort/referenceSupplier:desc` },
        ];
    }

    onErrors(): void {
        this.subs.sink = this.catalogsService.errors$
            .pipe(
                filter((ea: EntityAction) => ea.payload.entityOp === EntityOp.SAVE_ADD_ONE_ERROR || ea.payload.entityOp === EntityOp.SAVE_UPDATE_ONE_ERROR),
                debounceTime(500),
            )
            .subscribe(() => {
                this.isEdit = false;
                this.loading = false;
                this.cdr.detectChanges();
            });
    }

    openWaitDialog(): MatDialogRef<WaitDialogComponent> {
        return this.dialog.open(WaitDialogComponent, {
            data: {
                message: 'GENERAL.WAIT_EXPORT',
                image: 'fas fa-file-export',
            },
            width: '400px',
            // disableClose: true,
        });
    }

    prepareData(): CatalogModel {
        const _catalog = new CatalogModel();
        if (this.catalog.id) _catalog.id = this.catalog.id;

        _catalog.name = this.catalog.name;
        _catalog.description = this.catalog.description;
        _catalog.discount = +this.catalog.discount || 0;
        _catalog.nbProducts = this.productsListComponent.state.hits.length || 0;

        return _catalog;
    }

    setCatalogFilter(): void {
        this.typesenseClient.removeFilterBy('catalogId');
        this.typesenseClient.addFilterBy('catalogId', '=', [this.catalog.id]);
        this.cdr.markForCheck();
    }
}
