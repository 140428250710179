// Angular
import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
// Store
import { DaysArray, Day, OpeningTime } from '@store/index';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
// Lodash
import { remove } from 'lodash';

type FormGroupParams = {
    monday: FormArray;
    tuesday: FormArray;
    wednesday: FormArray;
    thursday: FormArray;
    friday: FormArray;
    saturday: FormArray;
    sunday: FormArray;
};

@Component({
    selector: 'opening-time',
    templateUrl: './opening-time.component.html',
    styleUrls: ['./opening-time.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpeningTimeComponent extends UnsubscribeOnDestroy implements OnDestroy {
    @Input() displayForm = false;

    @Input() set openingTime(value: OpeningTime[]) {
        if (value) {
            this._openingTime = value.map((el) => new OpeningTime(el));

            if (this.displayForm) {
                this.initDaySelected();
                this.setFormValue();
            }
        }
    }

    @Output() dataChange = new EventEmitter();

    _openingTime: OpeningTime[] = [];

    days = [...DaysArray];
    daysSelected: Day[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
    sameTime = true;
    sameTimeForm: FormGroup;
    showMoreTime = false;
    weekForm: FormGroup;
    weekFormGroupParams: FormGroupParams;

    //prettier-ignore
    constructor(private fb: FormBuilder) {
        super();

        this.initSameTimeForm();
        this.initWeekForm();
        this.listenForDataChange();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    select(day) {
        this.isSelected(day) ? remove(this.daysSelected, (el) => el === day) : this.daysSelected.push(day);
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    createWeekFormControl() {
        this.days.forEach((day) => {
            this.weekFormGroupParams = { ...this.weekFormGroupParams, [day]: this.fb.array([null, null, null, null]) };
        });
    }

    getHours(controls: any): any[] {
        const hours = [];
        for (let i = 0; i < 4; i++) {
            if (controls[i].value !== null && controls[i].value !== '') {
                hours[i] = controls[i].value.padStart(4, '0');
            } else {
                hours[i] = null;
            }
        }
        return hours;
    }

    initSameTimeForm(): void {
        this.sameTimeForm = this.fb.group([null, null, null, null]);
    }

    initDaySelected(): void {
        this.daysSelected = [];
        this._openingTime.map((ot) => this.daysSelected.push(ot.day));
    }

    initWeekForm(): void {
        this.createWeekFormControl();
        this.weekForm = this.fb.group(this.weekFormGroupParams);
    }

    isSameTime(): boolean {
        const tmpArray = this._openingTime.map((day) => day.hours.join('_'));
        return tmpArray.every((el) => el === tmpArray[0]);
    }

    isShowMoreTime(): boolean {
        return this._openingTime.filter((day) => day.isTwoTimeSlots())?.length > 0;
    }

    listenForDataChange() {
        this.subs.sink = this.sameTimeForm.valueChanges.subscribe(() => this.dataChange.emit(true));
        this.subs.sink = this.weekForm.valueChanges.subscribe(() => this.dataChange.emit(true));
    }

    prepareData(): OpeningTime[] {
        const openingTime: OpeningTime[] = [];
        if (this.sameTime) {
            this.days.filter((day) => this.daysSelected.includes(day)).map((day) => openingTime.push(new OpeningTime({ day: day, hours: this.getHours(this.sameTimeForm.controls) })));
        } else {
            this.days.filter((day) => this.daysSelected.includes(day)).map((day) => openingTime.push(new OpeningTime({ day: day, hours: this.getHours(this.weekFormGroupParams[day].controls) })));
        }

        return openingTime.map((time) => Object.assign({}, time));
    }

    setFormValue() {
        this.sameTime = this.isSameTime();
        this.showMoreTime = this.isShowMoreTime();

        if (this.sameTime) {
            this.setSameTimeFormHours(this._openingTime[0].hours);
        } else {
            this.setWeekFormHours();
        }
    }

    setSameTimeFormHours(hours: string[] = [null, null, null, null]) {
        this.sameTimeForm = this.fb.group(hours);
    }

    setWeekFormHours(): void {
        const days = Object.keys(this.weekForm?.value);
        days.map((day) => {
            const hours = this._openingTime.find((el) => el.day === day)?.hours || [null, null, null, null];
            this.weekForm.controls[day].setValue(hours);
        });
    }

    /*************/
    /*    UI     */
    /*************/
    getShortName(day: string): string {
        return `GENERAL.WEEKDAYS.${day.toUpperCase()}_SHORT`;
    }

    isOpeningTime(): boolean {
        return this._openingTime?.length > 0 && this._openingTime?.filter((el) => el.isOpen()).length > 0;
    }

    isSelected(day): boolean {
        return this.daysSelected.includes(day);
    }
}
