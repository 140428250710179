// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NGRX
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
// RXJS
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
// Core
import { FirestoreService } from '@core/services';
import { AppState } from '@core/reducers';
import { QueryFSModel } from '@core/_base/crud';
import { AuthUserModel, getUser } from '@core/auth';
// Store
import { OrderLineModel, OrderModel, OrderStatus } from './order.model';
import { FS_PATH_ORDERS, FS_PATH_ORDER_LINES } from '@store/firestore-collections';
// Lodash
import { cloneDeep, identity, isNil, omitBy, pickBy } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class OrdersDataService extends DefaultDataService<any> {
    user: AuthUserModel;

    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private store: Store<AppState>
    ) {
        super('Orders', http, httpUrlGenerator);

        this._getConnectedUser();
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getAll(): Observable<any[]> {
        return this._getData();
    }

    getById(orderId: string): Observable<OrderModel> {
        const pathOrder = `${FS_PATH_ORDERS}/${orderId}`;

        return this.firestoreService.doc$(pathOrder, null).pipe(
            switchMap((order: OrderModel) => {
                const pathOrderLines = `${pathOrder}/${FS_PATH_ORDER_LINES}`;
                return this.firestoreService.collection$(pathOrderLines, null).pipe(map((orderLines) => new OrderModel({ ...order, orderLines: orderLines })));
            }),
        );
    }

    getWithQuery(queryParams: QueryParams | string): Observable<any[]> {
        const queryArray = this.firestoreService.getQueryFSModel(queryParams);
        return this._getData(queryArray);
    }

    add(order: OrderModel): Observable<any> {
        // Clone object to modify it before save
        const _order = cloneDeep(order);

        // Delete order lines to save them in sub collection
        const orderLines = order.orderLines;
        delete _order.orderLines;

        // Add order and add pre order lines
        return this.firestoreService.updateAt(FS_PATH_ORDERS, _order).pipe(
            switchMap((doc) => {
                const orderId = doc.id;
                return combineLatest([
                    of(doc),
                    orderLines.map((orderLine) => {
                        return this.addOrUpdateOrderLine(orderId, orderLine);
                    }),
                ]);
            }),
            // Return object with id to save it in store
            map((res) => {
                return { id: res[0].id, ...order };
            }),
        );
    }

    async addUserDisplayNameToComment(orderId: string, userDisplayName: string): Promise<void> {
        const path = `${FS_PATH_ORDERS}/${orderId}`;
        const order = (await this.firestoreService.docSnapshot(path).pipe(first()).toPromise()) as OrderModel;

        let comment = '';
        if (!order.commentCustomer) {
            comment = userDisplayName;
        } else if (order.commentCustomer?.includes(userDisplayName) === false) {
            comment = `${order.commentCustomer}\n${userDisplayName}`;
        } else {
            comment = order.commentCustomer;
        }
        return this.firestoreService.updateAt(`${FS_PATH_ORDERS}/${order.id}`, { commentCustomer: comment }).pipe(first()).toPromise();
    }

    update(order: Update<OrderModel>): Observable<any> {
        // Clone object to modify it before save
        let _order = cloneDeep(order.changes);

        // Clean object (delete null and undefined property)
        _order = omitBy(_order, isNil);

        // Delete order lines
        delete _order.orderLines;

        // Update order
        return this.firestoreService.updateAt(`${FS_PATH_ORDERS}/${order.id}`, _order);
    }

    delete(orderId: string): Observable<any> {
        return this.firestoreService.delete(`${FS_PATH_ORDERS}/${orderId}`);
    }

    /** Actions on sub collection orderLines */
    addOrUpdateOrderLine(orderId: string, orderLine: OrderLineModel): Observable<any> {
        // Clean object (delete null and undefined property)
        const _orderLine = pickBy(orderLine, identity);

        const path = `${FS_PATH_ORDERS}/${orderId}/${FS_PATH_ORDER_LINES}/${orderLine.product.id}`;
        return this.firestoreService.updateAt(path, _orderLine);
    }

    updateOrderLineQuantity(orderId: string, orderLine: OrderLineModel) {
        const path = `${FS_PATH_ORDERS}/${orderId}/${FS_PATH_ORDER_LINES}/${orderLine.id}`;
        return this.firestoreService.updateAt(`${path}`, { quantity: orderLine.quantity, total: orderLine.total });
    }

    deleteOrderLine(orderId: string, orderLineId: string): Observable<any> {
        const path = `${FS_PATH_ORDERS}/${orderId}/${FS_PATH_ORDER_LINES}/${orderLineId}`;
        return this.firestoreService.delete(path);
    }

    getOrderLines(orderId: string): Observable<OrderLineModel[]> {
        const path = `${FS_PATH_ORDERS}/${orderId}/${FS_PATH_ORDER_LINES}`;
        return this.firestoreService.collectionSnapShot(path, null);
    }

    /************************/
    /*   Private functions  */
    /************************/
    _getConnectedUser(): void {
        this.store
            .select(getUser)
            .pipe(filter((user) => user != undefined))
            .subscribe((user) => (this.user = user));
    }

    _getData(query: QueryFSModel[] = []): Observable<any> {
        const queryOrder = this._getQueryOrder();

        // Add more query parameters if exists
        queryOrder.push(...query);

        // Query order
        return this.firestoreService.collection$(FS_PATH_ORDERS, undefined, queryOrder).pipe(
            switchMap((orders: OrderModel[]) => {
                if (orders.length === 0) return of([]);

                // Query order lines
                return combineLatest(
                    orders.map((order) => {
                        return this.getOrderLines(order.id).pipe(map((orderLines) => new OrderModel({ ...order, orderLines: orderLines })));
                    }),
                );
            }),
        );
    }

    _getQueryOrder(): QueryFSModel[] {
        if (this.user.roles?.includes('supplier')) {
            return [new QueryFSModel('supplier.id', '==', this.user.companyId), new QueryFSModel('status', '>', OrderStatus.Cart), new QueryFSModel('status', '!=', OrderStatus.ConsultationCart)];
        } else {
            return [new QueryFSModel('customer.companyId', '==', this.user.companyId), new QueryFSModel('status', '>=', OrderStatus.Cart)];
        }
    }
}
