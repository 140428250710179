// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NGRX
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Update } from '@ngrx/entity';
// RXJS
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
// Store
import { CatalogModel, ProductDiscount } from './catalog.model';
import { FS_PATH_CATALOGS, FS_PATH_PRODUCTS, FS_TENANT_SUPPLIERS } from '@store/firestore-collections';
import { ProductModel } from '../products/product.model';
// Core
import { FirestoreService } from '@core/services';
// Lodash
import { cloneDeep, isNil, omitBy } from 'lodash';
// Environment
import { environment } from '@env/environment';

// API PATH
const API_PATH_CATALOGS = `${environment.apiBaseUrl}/catalogs`;
// const API_PATH_CATALOGS = 'http://localhost:5001/stoick-dev/europe-west1/catalogsApi-catalogs';
@Injectable({
    providedIn: 'root',
})
export class CatalogsDataService extends DefaultDataService<CatalogModel> {
    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        http: HttpClient, httpUrlGenerator:
        HttpUrlGenerator)
    {
        super('Catalogs', http, httpUrlGenerator);
    }

    /*********************/
    /*        API        */
    /*********************/

    /** To import product from Excel File in Catalog */
    import(catalogId: string, data: any): Observable<any> {
        const path = `${API_PATH_CATALOGS}/import`;
        const params = {
            data: data,
            catalogId: catalogId,
        };
        return this.http.post(path, params);
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getAll(): Observable<CatalogModel[]> {
        return this.firestoreService.collection$(FS_PATH_CATALOGS, FS_TENANT_SUPPLIERS).pipe(map((res) => res.map((r) => new CatalogModel(r))));
    }

    getById(catalogId: string): Observable<CatalogModel> {
        const path = `${FS_PATH_CATALOGS}/${catalogId}`;
        return this.firestoreService.doc$(path, null).pipe(map((res) => new CatalogModel(res)));
    }

    getWithQuery(queryParams: QueryParams | string): Observable<CatalogModel[]> {
        const queryArray = this.firestoreService.getQueryFSModel(queryParams);
        return this.firestoreService.collection$(FS_PATH_CATALOGS, FS_TENANT_SUPPLIERS, queryArray);
    }

    add(catalog: CatalogModel): Observable<CatalogModel> {
        // Clone object to modify it before save
        let _catalog: any = cloneDeep(catalog);

        // Clean object (delete null and undefined property)
        _catalog = omitBy(_catalog, isNil);

        return this.firestoreService.updateAt(FS_PATH_CATALOGS, _catalog, FS_TENANT_SUPPLIERS).pipe(map((res) => new CatalogModel({ id: res.id, ...catalog })));
    }

    delete(catalogId: string): Observable<string> {
        const path = `${FS_PATH_CATALOGS}/${catalogId}`;
        return this.firestoreService.delete(path, FS_TENANT_SUPPLIERS).pipe(map(() => catalogId));
    }

    /** Delete one product in many catalogs */
    deleteMany(catalogIds: string[], product: ProductDiscount): Observable<any> {
        // Delete product in each catalog
        const batch = this.firestoreService.getBatch();
        catalogIds.forEach((catalogId) => {
            const pathProduct = `${FS_PATH_CATALOGS}/${catalogId}/${FS_PATH_PRODUCTS}/${product.id}`;
            const docRef = this.firestoreService.getDocRef(pathProduct, FS_TENANT_SUPPLIERS);
            batch.delete(docRef);
        });

        return from(batch.commit());
    }

    update(catalog: Update<CatalogModel>): Observable<CatalogModel> {
        // Clone object to modify it before save
        let _catalog = cloneDeep(catalog.changes);

        // Clean object (delete null and undefined property)
        _catalog = omitBy(_catalog, isNil);

        return this.firestoreService.updateAt(`${FS_PATH_CATALOGS}/${catalog.id}`, _catalog, FS_TENANT_SUPPLIERS);
    }

    /** Update one product in many catalogs */
    updateMany(catalogIds: string[], discounts: any[], product: ProductDiscount): Observable<any> {
        const _product = {
            id: product.id,
            discount: 0,
        };

        // Add product in each catalog
        const batch = this.firestoreService.getBatch();
        catalogIds.forEach((catalogId) => {
            const pathProducts = `${FS_PATH_CATALOGS}/${catalogId}/${FS_PATH_PRODUCTS}/${product.id}`;
            const docRef = this.firestoreService.getDocRef(pathProducts, FS_TENANT_SUPPLIERS);

            //Add product discount for this catalog
            _product.discount = discounts.find((el) => el.id === catalogId)?.amount || 0;

            batch.set(docRef, _product, { merge: true });
        });

        return from(batch.commit());
    }

    updateCatalogDiscount(catalog: CatalogModel): Observable<void> {
        const productDiscounts$ = this.getProducts(catalog.id);
        return productDiscounts$.pipe(
            switchMap((productDiscounts) => {
                return combineLatest(
                    productDiscounts.map((p) => {
                        // Load product for each productDiscount to get product price
                        return this.getProduct(p.id).pipe(
                            map((product) => {
                                p.updateProductDiscount(product.price, catalog.discount);
                                return p;
                            }),
                        );
                    }),
                );
            }),
            switchMap((productDiscounts) => this.addOrUpdateProducts(catalog.id, productDiscounts)),
        );
    }

    /** Actions on sub collection products */
    addOrUpdateProducts(catalogId: string, products: ProductDiscount[]): Observable<any> {
        const batchArray = [];
        let batch = this.firestoreService.getBatch();
        batchArray.push(batch);
        let nbRecords = 0;

        products.forEach((product) => {
            // Clean object (delete null and undefined property)
            const _product = omitBy(product, isNil);

            const pathProduct = `${FS_PATH_CATALOGS}/${catalogId}/${FS_PATH_PRODUCTS}/${product.id}`;
            const docRef = this.firestoreService.getDocRef(pathProduct, FS_TENANT_SUPPLIERS);
            batch.set(docRef, _product, { merge: true });

            nbRecords++;
            const newBatch = this.firestoreService.checkIfNeedToChangeBatch(nbRecords);
            if (newBatch) {
                batch = newBatch;
                batchArray.push(batch);
                nbRecords = 0;
            }
        });

        const promises = batchArray.map((batch) => batch.commit());
        return from(Promise.all(promises));
    }

    deleteManyProducts(catalogId: string, products: ProductDiscount[]): Observable<any> {
        const batchArray = [];
        let batch = this.firestoreService.getBatch();
        batchArray.push(batch);
        let nbRecords = 0;

        const products$ = products ? of(products) : this.getProducts(catalogId);

        return products$.pipe(
            map((products) => {
                products.forEach((product) => {
                    const pathProduct = `${FS_PATH_CATALOGS}/${catalogId}/${FS_PATH_PRODUCTS}/${product.id}`;
                    const docRef = this.firestoreService.getDocRef(pathProduct, FS_TENANT_SUPPLIERS);
                    batch.delete(docRef);

                    nbRecords++;
                    const newBatch = this.firestoreService.checkIfNeedToChangeBatch(nbRecords);
                    if (newBatch) {
                        batch = newBatch;
                        batchArray.push(batch);
                        nbRecords = 0;
                    }
                });
            }),
            switchMap(() => {
                const promises = batchArray.map((batch) => batch.commit());
                return from(Promise.all(promises));
            }),
        );
    }

    /** Actions on products */
    getProduct(productId): Observable<ProductModel> {
        const pathProduct = `${FS_PATH_PRODUCTS}/${productId}`;
        return this.firestoreService.docSnapshot(pathProduct, FS_TENANT_SUPPLIERS).pipe(map((res) => new ProductModel(res)));
    }

    getProducts(catalogId: string): Observable<ProductDiscount[]> {
        const pathProducts = `${FS_PATH_CATALOGS}/${catalogId}/${FS_PATH_PRODUCTS}`;
        return this.firestoreService.collectionSnapShot(pathProducts, FS_TENANT_SUPPLIERS).pipe(map((res) => res.map((r) => new ProductDiscount(r))));
    }

    getProductsById(catalogId: string, productsId: string[]): Observable<ProductDiscount[]> {
        return combineLatest(
            productsId.map((id) => {
                const pathProducts = `${FS_PATH_CATALOGS}/${catalogId}/${FS_PATH_PRODUCTS}/${id}`;
                return this.firestoreService.docSnapshot(pathProducts, FS_TENANT_SUPPLIERS).pipe(map((res) => new ProductDiscount(res)));
            }),
        );
    }

    deleteProduct(catalogId: string, productId: string): Observable<string> {
        const path = `${FS_PATH_CATALOGS}/${catalogId}/${FS_PATH_PRODUCTS}/${productId}`;
        return this.firestoreService.delete(path, FS_TENANT_SUPPLIERS).pipe(map(() => catalogId));
    }

    updateProduct(catalogId: string, productDiscount: ProductDiscount): Observable<any> {
        const path = `${FS_PATH_CATALOGS}/${catalogId}/${FS_PATH_PRODUCTS}/${productDiscount.id}`;
        return this.firestoreService.updateAt(path, productDiscount, FS_TENANT_SUPPLIERS);
    }
}
