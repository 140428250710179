// Angular
import { Component, EventEmitter, Inject, Output } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'delete-dialog',
    templateUrl: './delete-dialog.component.html',
})
export class DeleteDialogComponent {
    @Output() delete = new EventEmitter();

    labelNoButton: string;
    labelYesButton: string;
    loading = false;
    waitDescription: string;

    // prettier-ignore
    constructor(
        public dialogRef: MatDialogRef<DeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.labelNoButton = this.data.labelNoButton ||  "GENERAL.CANCEL";
        this.labelYesButton = this.data.labelYesButton ||  "GENERAL.DELETE";
        this.waitDescription = this.data.waitDescription ||  "GENERAL.WAIT_DELETE";
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    onNoClick(): void {
        this.dialogRef.close();
    }

    onYesClick(): void {
        this.loading = true;
        this.delete.emit(true);
    }
}
