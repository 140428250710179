// Angular
import { Injectable } from '@angular/core';
// RXJS
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class TypesensePaginationService {
    private reset$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    reset(): void {
        this.reset$.next(true);
    }

    isReset(): Observable<boolean> {
        return this.reset$;
    }
}
