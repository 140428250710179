// Angular
import { Component, OnInit } from '@angular/core';
// RXJS
import { Observable } from 'rxjs';
// Store
import { CartService } from '@store/index';

@Component({
    selector: 'cart-topbar',
    templateUrl: './cart-topbar.component.html',
    styleUrls: ['./cart-topbar.component.scss'],
})
export class CartTopBarComponent implements OnInit {
    cart$: Observable<number>;

    constructor(private cartService: CartService) {}

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.cart$ = this.cartService.selectCartNbItems();
    }

    /*************/
    /*    UI     */
    /*************/
    isActive() {
        return window.location.pathname.includes('/cart');
    }
}
