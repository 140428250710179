// Angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatDialog } from '@angular/material/dialog';
// NGRX
import { Store } from '@ngrx/store';
import { EntityAction, EntityOp } from '@ngrx/data';
// RXJS
import { Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
// Core
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
import { MixpanelService, ToolsService, UnsubscribeOnDestroy, UrlService } from '@core/services';
import { AppState } from '@core/reducers';
import { isAuthSuccess, isDemo, isStoick } from '@core/auth';
// Store
import { CartService, FavoritesEntityService, ParamsService, ProductModel, ProductsEntityService, QuotesEntityService, SupplierModel, SuppliersEntityService } from '@store/index';
// Shared
import { ProductDetailComponent } from '@shared/components/business/product-detail/product-detail.component';
import { SelectQuantityComponent } from '@shared/components/business/select-quantity/select-quantity.component';
import { TypesensePaginationService } from '@shared/components/typesense/pagination/pagination.service';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Lodash
import { isNil, omitBy } from 'lodash';
//Typesense
import { COLLECTION_PRODUCTS, TypesenseClient } from '@shared/components/typesense/typesense-client';
// Theme
import { HeaderActionButtonsService, TitlePageService } from '@theme/index';
import { AddStockInformationsComponent } from '../add-stock-informations/add-stock-informations.component';

const BACK_URL = '/mp/public/suppliers';
@Component({
    selector: 'store-supplier',
    templateUrl: './store-supplier.component.html',
    styleUrls: ['./store-supplier.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class StoreSupplierComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    // Typesense
    typesenseClient: TypesenseClient;

    // Services
    private cartService: CartService;
    private favoritesService: FavoritesEntityService;
    private quotesService: QuotesEntityService;

    // Other
    canAddToInternalCatalog = false;
    catalogId: string;
    displayAllProducts = false;
    displayOnlyFavorites = false;
    favorites = [];
    isDemoUser = false;
    isUserLogged = false;
    loading$: Observable<boolean>;
    selectedProducts: ProductModel[] = [];
    supplier: SupplierModel;

    //prettier-ignore
    constructor(
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog,
        private inject: Injector,
        private headerActionButtonsService: HeaderActionButtonsService,
        private layoutUtilsService: LayoutUtilsService,
        private mixpanelService: MixpanelService,
        private paramsService: ParamsService,
        private productsService: ProductsEntityService,
        private router: Router,
        private suppliersService: SuppliersEntityService,
        private store: Store<AppState>,
        private titlePageService: TitlePageService,
        private toolsService: ToolsService,
        private translateService: TranslateService,
        private typesensePaginationService: TypesensePaginationService,
        private urlService: UrlService,
    ) {
        super();

        this.checkIsUserLogged();
        this.checkIsDemoUser();
        this.checkIsStoickUser();

        // Only work in constructor method : https://stackoverflow.com/questions/54891110/router-getcurrentnavigation-always-returns-null
        this.getSupplier();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.loadServices();

        this.checkStoickOrWhiteLabelApp();

        this.loading$ = this.suppliersService.loading$;
    }

    ngOnDestroy(): void {
        this.resetTitlePage();
        this.subs.unsubscribe();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    addToInternalCatalog(product: ProductModel) {
        this.selectedProducts = this.toolsService.toggleObjectInArray(this.selectedProducts, product, 'id');
        this.headerActionButtonsService.setTitleButton(`Enregistrer : ${this.selectedProducts.length} sélectionné(s)`);
        this.cdr.markForCheck();
    }

    filterFavorites() {
        this.displayOnlyFavorites = !this.displayOnlyFavorites;
        this.displayOnlyFavorites ? this.setFavoritesFilter() : this.resetFavoritesFilter();
        this.mixpanelService.track('Typesense - Filter on favorites');
    }

    filterAllProducts() {
        this.displayAllProducts = !this.displayAllProducts;
        this.displayAllProducts ? this.resetCatalogFilter() : this.setCatalogFilter();
        this.typesensePaginationService.reset();
    }

    openAddStockInformations(product: ProductModel): void {
        this.dialog.open(AddStockInformationsComponent, {
            data: { product: product },
            width: '600px',
        });
    }

    selectQuantity(product: ProductModel): void {
        const dialogRef = this.dialog.open(SelectQuantityComponent, {
            data: { product: product },
            width: '500px',
        });

        this.subs.sink = dialogRef
            .afterClosed()
            .pipe(first())
            .subscribe((res) => {
                if (!res) return;
                product.quotation ? this.addItemToQuoteRequest(product, res.quantity, res.size) : this.addItemToCart(product, res.quantity, res.size);
            });
    }

    viewProductDetail(product: ProductModel): void {
        const dialogRef = this.dialog.open(ProductDetailComponent, {
            data: {
                product: product,
                canBuy: this.canBuy(),
            },
            width: '90%',
            maxWidth: '1100px',
            height: '80%',
        });

        this.subs.sink = dialogRef
            .afterClosed()
            .pipe(first())
            .subscribe((res) => {
                if (!res) return;
                product.quotation ? this.addItemToQuoteRequest(product, res.quantity, res.size) : this.addItemToCart(product, res.quantity, res.size);
            });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    addItemToCart(product: ProductModel, quantity: number, size: string): void {
        const _product = this.prepareData(product, size);
        this.subs.sink = this.cartService
            .addToCart(_product, quantity)
            .pipe(first())
            .subscribe(() => {
                const _message = this.translateService.instant('CART.ITEM_ADDED_TO_CART');
                this.layoutUtilsService.showActionNotification(_message, null, 2000, true, false);
            });
    }

    addItemToQuoteRequest(product: ProductModel, quantity: number, size: string): void {
        const _product = this.prepareData(product, size);
        this.subs.sink = this.quotesService
            .addToQuote(_product, quantity)
            .pipe(first())
            .subscribe(() => {
                const _message = this.translateService.instant('QUOTES.ADDED_TO_QUOTES_REQUEST');
                this.layoutUtilsService.showActionNotification(_message, null, 2000, true, false);
            });
    }

    checkIsDemoUser(): void {
        this.subs.sink = this.store.select(isDemo).subscribe((isDemo) => (this.isDemoUser = isDemo));
    }

    checkIsStoickUser() {
        this.subs.sink = this.store
            .select(isStoick)
            .pipe(filter((isStoick) => isStoick === true))
            .subscribe(() => {
                this.listenHeaderActionButtonsClick();
            });
    }

    checkIsUserLogged(): void {
        this.subs.sink = this.store.select(isAuthSuccess).subscribe((isAuthSuccess) => (this.isUserLogged = isAuthSuccess));
    }

    checkStoickOrWhiteLabelApp() {
        this.paramsService.isStoickApp() ? this.getStoreStoick() : this.getStoreWhiteLabel();
    }

    getAndInitFavoritesFilter(supplierId?: string): void {
        const favorites$ = supplierId ? this.favoritesService.selectFavoritesBySupplierId(supplierId) : this.favoritesService.selectFavorites();
        this.subs.sink = favorites$.subscribe((favorites) => {
            this.favorites = [...favorites];
            if (favorites.length > 0 && this.displayOnlyFavorites) {
                this.setFavoritesFilter();
            } else {
                this.displayOnlyFavorites = false;
                this.resetFavoritesFilter();
            }
        });
    }

    getStoreStoick(): void {
        if (this.supplier?.id) {
            this.titlePageService.setTitlePage(`${this.translateService.instant('CATALOGS.CATALOG_OF')} ${this.supplier.name}`);
            this.titlePageService.showBackButton(true);
            this.urlService.setBackUrl(BACK_URL);

            this.initTypesense(this.supplier.typesenseApiKey);

            if (this.isUserLogged && this.isStoreUrl()) {
                this.catalogId = this.supplier.catalogId || null;
                this.displayAllProducts = this.catalogId == null;
                this.displayAllProducts ? this.resetCatalogFilter() : this.setCatalogFilter();

                // Use to keep only product with discount when there is catalog
                this.typesenseClient.setGroup();
                this.getAndInitFavoritesFilter(this.supplier.id);
            }
        } else {
            this.router.navigateByUrl(BACK_URL);
        }
    }

    getStoreWhiteLabel(): void {
        const supplierConfig$ = this.paramsService.getSupplierConfig();

        // Auth catalog
        if (this.isUserLogged) {
            supplierConfig$.pipe(switchMap((config) => this.suppliersService.selectEntityById(config.supplierId))).subscribe((supplier) => {
                if (supplier.id) {
                    this.initTypesense(supplier.typesenseApiKey);

                    this.catalogId = supplier.catalogId || null;
                    this.resetCatalogFilter();
                    // Use to keep only product with discount when there is catalog
                    this.typesenseClient.setGroup();
                    this.getAndInitFavoritesFilter(supplier.id);
                }
            });
        }
        // Public catalog
        else {
            supplierConfig$.subscribe((supplierConfig) => {
                this.titlePageService.setTitlePage(`${this.translateService.instant('WHITELABEL.WELCOME_IN_STORE')} ${supplierConfig.supplierName}`);
                this.initTypesense(supplierConfig.typesenseApiKey);
            });
        }
    }

    getSupplier(): void {
        this.supplier = this.activatedRoute.snapshot.data.supplier;
    }

    initTypesense(typesenseApiKey: string | null): void {
        this.typesenseClient = new TypesenseClient(COLLECTION_PRODUCTS, typesenseApiKey);

        this.typesenseClient.addFilterBy('catalogId', '=', ['mp']);

        const sort = [
            { label: this.translateService.instant('TYPESENSE.SORT_RELEVANCE'), value: 'products' },
            { label: this.translateService.instant('TYPESENSE.SORT_PRICE_ASC'), value: 'products/sort/price:asc' },
            { label: this.translateService.instant('TYPESENSE.SORT_PRICE_DESC'), value: 'products/sort/price:desc' },
        ];

        this.typesenseClient.setSort(sort);
    }

    isStoreUrl() {
        return window.location.pathname.includes('stores/');
    }

    listenHeaderActionButtonsClick() {
        this.subs.sink = this.headerActionButtonsService.onClick.subscribe(() => {
            if (this.selectedProducts?.length == 0) {
                this.selectedProducts = [];
                this.canAddToInternalCatalog = true;
                this.headerActionButtonsService.setTitleButton(`Enregistrer : ${this.selectedProducts.length} sélectionné(s)`);
                this.headerActionButtonsService.setShowCancelButton(true);
            } else {
                this.saveToInternalCatalog();
            }
            this.cdr.markForCheck();
        });

        this.subs.sink = this.headerActionButtonsService.onCancel.subscribe(() => this.resetActionButtons());
    }

    loadServices() {
        if (this.isUserLogged) {
            this.cartService = this.inject.get(CartService);
            this.favoritesService = this.inject.get(FavoritesEntityService);
            this.quotesService = this.inject.get(QuotesEntityService);
        }
    }

    prepareData(product: ProductModel, size: string): ProductModel {
        const _product = new ProductModel();
        _product.ecotax = product.ecotax;
        _product.id = !size ? product.productId : `${product.productId}-${size}`;
        _product.name = product.name;
        _product.maker = product.maker;
        _product.minimumPurchase = product.minimumPurchase;
        _product.photo = product.photo;
        _product.price = product.price;
        _product.priceDiscount = product.priceDiscount;
        _product.provider = product.provider;
        _product.referenceMaker = product.referenceMaker;
        _product.referenceProvider = product.referenceProvider;
        _product.referenceSupplier = product.referenceSupplier;
        _product.size = size;
        _product.taxes = product.taxes;
        _product.supplierCompanyId = product.supplierCompanyId;

        if (product.packaging > 1) {
            _product.name = `${this.translateService.instant('BUSINESS.PACKAGING')} ${product.packaging} - ${_product.name}`;
        }

        return <ProductModel>omitBy(_product, isNil);
    }

    resetActionButtons() {
        this.canAddToInternalCatalog = false;
        this.selectedProducts = [];
        this.headerActionButtonsService.setLoading(false);
        this.headerActionButtonsService.setTitleButton('INTERNAL_CATALOG.ADD');
        this.headerActionButtonsService.setShowCancelButton(false);
        this.cdr.markForCheck();
    }

    resetCatalogFilter() {
        this.typesenseClient.removeFilterBy('catalogId');
        this.typesenseClient.addFilterBy('catalogId', '=', ['mp', this.catalogId]);
    }

    resetFavoritesFilter() {
        this.typesenseClient.removeFilterBy('productId');
    }

    resetTitlePage() {
        this.titlePageService.showBackButton(false);
        this.titlePageService.setImage(null);
        this.titlePageService.setTitlePage(null);
    }

    saveToInternalCatalog() {
        this.headerActionButtonsService.setLoading(true);

        this.productsService.saveProductInInternalCatalog(this.selectedProducts);

        this.subs.sink = this.productsService.entityActions$
            .pipe(
                filter((ea: EntityAction) => ea.payload.entityOp === EntityOp.SAVE_UPDATE_MANY_SUCCESS),
                first(),
            )
            .subscribe(() => {
                const message = this.translateService.instant('INTERNAL_CATALOG.SAVE_SUCCESS');
                this.layoutUtilsService.showActionNotification(message, MessageType.Success, 3000, true, false);
                this.resetActionButtons();
            });

        // this.productsService
        //     .saveProductInInternalCatalog(this.selectedProducts)
        //     .pipe(first())
        //     .subscribe(() => {
        //         const message = this.translateService.instant('INTERNAL_CATALOG.SAVE_SUCCESS');
        //         this.layoutUtilsService.showActionNotification(message, MessageType.Success, 3000, true, false);
        //         this.resetActionButtons();
        //     });
    }

    setCatalogFilter() {
        this.typesenseClient.removeFilterBy('catalogId');
        this.typesenseClient.addFilterBy('catalogId', '=', this.catalogId);
    }

    setFavoritesFilter() {
        this.typesenseClient.addFilterBy('productId', '=', this.favorites);
    }

    /*******************/
    /*       UI        */
    /*******************/
    canBuy(): boolean {
        return !this.isDemoUser && this.isUserLogged;
    }

    getSelectedProductsId(): string[] {
        return this.selectedProducts?.map((p) => p.productId);
    }
}
