// Angular
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Angular Material
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'confirm-email',
    templateUrl: './confirm-email.component.html',
})
export class ConfirmEmailComponent implements OnInit {
    emailForm: FormGroup;

    constructor(private dialogRef: MatDialogRef<ConfirmEmailComponent>, private fb: FormBuilder) {}

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.emailForm = this.fb.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
        });
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    close() {
        this.dialogRef.close();
    }

    submit() {
        const email = this.emailForm.controls.email.value.trim().toLocaleLowerCase();
        this.dialogRef.close(email);
    }
}
