// Angular
import { Component, Inject, forwardRef, Optional, EventEmitter, Output } from '@angular/core';
// Angular instantsearch
import { NgAisIndex, TypedBaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
// Instantsearch
import { connectStats } from 'instantsearch.js/es/connectors';
import { StatsConnectorParams, StatsWidgetDescription, StatsRenderState } from 'instantsearch.js/es/connectors/stats/connectStats';

@Component({
    selector: 'typesense-stats',
    templateUrl: 'stats.component.html',
})
export class TypesenseStatsComponent extends TypedBaseWidget<StatsWidgetDescription, StatsConnectorParams> {
    @Output() handleGetStats = new EventEmitter();

    public state: StatsRenderState = {
        nbHits: 0,
        nbPages: 0,
        page: 0,
        processingTimeMS: 0,
        query: '',
        areHitsSorted: false,
    };

    //prettier-ignore
    constructor(
        @Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
    ) {
        super('Stats');
        this.createWidget(connectStats, {});
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    get templateContext(): any {
        return { state: this.state };
    }
}
