import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

@Injectable()
export class ExcelService {
    static toExportFileName(excelFileName: string): string {
        return `${excelFileName}_${moment().format('YYYY_MM_DD')}.xlsx`;
    }

    async exportAsExcelFile(json: any[], excelFileName: string): Promise<void> {
        const workbook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(json);
        XLSX.utils.book_append_sheet(workbook, workSheet);
        XLSX.writeFile(workbook, ExcelService.toExportFileName(excelFileName));
    }

    async exportAsExcelFileFromTemplate(json: any[], excelFileName: string, templateName: string): Promise<void> {
        const buffer = await fetch(templateName);
        const arrayBuffer = await buffer.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });

        // get first sheet
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];

        // modify value from A5
        XLSX.utils.sheet_add_json(worksheet, json, { skipHeader: true, origin: 'A5' });

        XLSX.writeFile(workbook, ExcelService.toExportFileName(excelFileName));
    }

    importExcelFile(file: Blob, lineHeader?: string[], nbLineToRemove = 0): Promise<any> {
        return new Promise((resolve) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                const data = new Uint8Array(<any>fileReader.result);
                const arr = [];
                for (let i = 0; i < data.length; ++i) arr[i] = String.fromCharCode(data[i]);
                const bstr = arr.join('');
                const workbook = XLSX.read(bstr, { type: 'binary' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const excelData = XLSX.utils.sheet_to_json(worksheet, { header: lineHeader, raw: true, defval: null });

                if (nbLineToRemove > 0) excelData.splice(0, nbLineToRemove);

                // Trim all string value
                excelData.map((object) => {
                    Object.keys(object)
                        .filter((el) => typeof object[el] === 'string')
                        .map((el) => {
                            object[el] = object[el] === '' ? null : object[el].trim();
                        });
                });

                resolve(excelData);
            };

            fileReader.readAsArrayBuffer(file);
        });
    }
}
