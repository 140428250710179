// Angular
import { Component } from '@angular/core';
import { Input } from '@angular/core';
// Instantsearch
import { HierarchicalMenuRenderState, HierarchicalMenuItem } from 'instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu';

@Component({
    selector: 'typesense-hierarchical-menu-item',
    templateUrl: 'hierarchical-menu-item.component.html',
    styleUrls: ['hierarchical-menu-item.component.scss'],
})
export class TypesenseHierarchicalMenuItemComponent {
    @Input() refine: HierarchicalMenuRenderState['refine'];
    @Input() createURL: HierarchicalMenuRenderState['createURL'];
    @Input() item: HierarchicalMenuItem;

    /***************/
    /*   ACTIONS   */
    /***************/
    handleClick(event: MouseEvent, item: HierarchicalMenuItem): void {
        event.preventDefault();
        event.stopPropagation();

        this.refine(item.value);
    }

    /**********************/
    /*          UI        */
    /**********************/
    getItemClass(item: any): string {
        let className = '';

        if (this.isSelected(item)) {
            className = `${className} selected`;
        }

        if (this.isParent(item)) {
            className = `${className} parent`;
        }

        return className;
    }

    isArray(potentialArray: any): boolean {
        return Array.isArray(potentialArray);
    }

    isChild(item: any): boolean {
        // https://github.com/typesense/typesense-instantsearch-adapter#widget-specific-instructions
        // for a nested hierarchy of field.lvl0 > field.lvl1 > field.lvl2
        return item.value.includes(' > ');
    }

    isParent(item: any): boolean {
        return this.isArray(item.data) && item.data.length > 0;
    }

    isSelected(item: any): boolean {
        return item.isRefined;
    }
}
