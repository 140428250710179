// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
// Angular Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RXJS
import { Observable } from 'rxjs';
import { debounceTime, filter, first } from 'rxjs/operators';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
// Store
import { CategoriesEntityService, CategoryModel } from '@store/index';

@Component({
    selector: 'category-add',
    templateUrl: './category-add.component.html',
})
export class CategoryAddComponent extends UnsubscribeOnDestroy implements OnInit {
    categories$: Observable<CategoryModel[]>;
    categorySelected: string;
    inputForm: FormControl;
    loading: boolean;

    // prettier-ignore
    constructor(
        private categoriesService: CategoriesEntityService,
        private dialogRef: MatDialogRef<CategoryAddComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super();

        this.inputForm = new FormControl('', Validators.required);
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.categories$ = this.categoriesService.selectParentCategories().pipe(first());

        // Listen for error
        this.subs.sink = this.categoriesService.errors$.pipe(debounceTime(500)).subscribe(() => (this.loading = false));
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    close(category?: CategoryModel) {
        this.dialogRef.close({ category: category });
    }

    // Prepare object to save it in Firestore
    prepareData() {
        const _category = new CategoryModel();
        _category.name = this.inputForm.value?.trim();
        _category.parent = this.categorySelected || null;

        return _category;
    }

    save() {
        this.loading = true;

        const category = this.prepareData();

        // Add category in Firestore and wait for category created
        this.categoriesService
            .add(category)
            .pipe(
                filter((category) => category.id),
                debounceTime(500),
                first(),
            )
            .subscribe(async (category) => {
                this.loading = false;
                const _category = await this.categoriesService.selectCategoryFlatNameById(category.id).pipe(first()).toPromise();
                this.close(_category);
            });
    }

    /***************/
    /*     UI      */
    /***************/
    hasError() {
        return this.inputForm?.invalid && (this.inputForm?.touched || this.inputForm?.dirty);
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
}
