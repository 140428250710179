// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, Output } from '@angular/core';
// Core
import { AuthNotice, AuthNoticeService } from '@core/auth';
import { UnsubscribeOnDestroy } from '@core/services';

@Component({
    selector: 'kt-auth-notice',
    templateUrl: './auth-notice.component.html',
})
export class AuthNoticeComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @Output() type: string;
    @Output() text: string;

    constructor(public authNoticeService: AuthNoticeService, private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this.subs.sink = this.authNoticeService.onNoticeChanged$.subscribe((notice: AuthNotice) => {
            notice = Object.assign({}, { message: '', type: '' }, notice);
            this.text = notice.message;
            this.type = notice.type;
            this.cdr.markForCheck();
        });
    }
}
