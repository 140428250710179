// Angular
import { Injectable } from '@angular/core';

// RXJS
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SuppliersFilterComponentService {
    private suppliers$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

    constructor() {
        this.suppliers$.next([]);
    }

    getSuppliers(): Observable<any[]> {
        return this.suppliers$.asObservable();
    }

    setSuppliers(suppliersSelected: any[]) {
        this.suppliers$.next(suppliersSelected);
    }
}
