// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RXJS
import { filter } from 'rxjs/operators';
// NGRX
import { Store } from '@ngrx/store';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Core
import { AuthNoticeService, PasswordManagementActions, getChangePasswordStatus } from '@core/auth';
import { AppState } from '@core/reducers';
import { UnsubscribeOnDestroy } from '@core/services';
import { AuthService } from '@core/auth/store/_services/auth.service';

@Component({
    selector: 'kt-forgot-password',
    templateUrl: './forgot-password.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    forgotPasswordForm: FormGroup;
    loading = false;

    //prettier-ignore
    constructor(
        private authService: AuthService, 
        public authNoticeService: AuthNoticeService, 
        private fb: FormBuilder, 
        private store: Store<AppState>, 
        private translateService: TranslateService) 
    {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.initRegistrationForm();
    }

    ngOnDestroy(): void {
        this.loading = false;
        this.authNoticeService.setNotice(null);
    }

    initRegistrationForm(): void {
        this.forgotPasswordForm = this.fb.group({
            email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(320)])],
        });
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    submit(): void {
        const controls = this.forgotPasswordForm.controls;
        /** check form */
        if (this.forgotPasswordForm.invalid) {
            Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
            return;
        }

        this.loading = true;

        // Get form data
        const payload = {
            email: controls.email.value,
        };

        // Call action to change password
        this.store.dispatch(new PasswordManagementActions.ResetPasswordRequest(payload));

        // Get result of action
        this.subs.sink = this.store
            .select(getChangePasswordStatus)
            .pipe(filter((status) => !status.loading))
            .subscribe((status) => {
                if (status.success) {
                    const message = this.translateService.instant('AUTH.FORGOT.SUCCESS');
                    this.authNoticeService.setNotice(message, 'success');
                } else {
                    const message = this.translateService.instant(this.authService.getFireErrorMessage(status.error.code));
                    this.authNoticeService.setNotice(message, 'danger');
                }
                this.loading = false;
            });
    }
}
