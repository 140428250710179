// Angular
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// Store
import { ProductModel, SuppliersEntityService } from '@store/index';
// RXJS
import { first } from 'rxjs/operators';
// Core
import { MixpanelService, UrlService } from '@core/services';

@Component({
    selector: 'product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductDetailComponent {
    canBuy = true;
    product: ProductModel;

    // prettier-ignore
    constructor(
        private dialogRef: MatDialogRef<ProductDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private mixpanelService: MixpanelService,
        private router: Router,
        private suppliersService: SuppliersEntityService,
        private urlService: UrlService,
    ) {
        this.product = new ProductModel(this.data.product);
        this.canBuy = this.data.canBuy;

        this.getSupplier();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/

    /*************/
    /*  ACTIONS  */
    /*************/
    addItemToCart(): void {
        this.dialogRef.close({ product: this.product });
    }

    close() {
        this.dialogRef.close();
    }

    register() {
        this.close();
        const baseUrl = this.urlService.getBaseUrl();
        this.router.navigateByUrl(`${baseUrl}/auth/register`);
        this.mixpanelService.track('Register from product');
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    getSupplier(): void {
        this.suppliersService
            .selectEntityById(this.product.supplierCompanyId)
            .pipe(first())
            .subscribe((supplier) => (this.product.supplier = supplier));
    }

    /*************/
    /*    UI     */
    /*************/
    getSupplierLogoOrName(): string {
        if (this.product.supplier?.logo) {
            return `<img src="${this.product.supplier?.logo}" class="max-h-50px max-w-100px">`;
        } else {
            return `<p>${this.product.supplier?.name}</p>`;
        }
    }

    isDelay() {
        return this.product.delay > 0;
    }
}
