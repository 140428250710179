// Angular
import { Injectable } from '@angular/core';
// Leaftlet
import { LatLng } from 'leaflet';

@Injectable({
    providedIn: 'root',
})
export class ToolsService {
    calculateDistance(locationFrom: LatLng, locationTo: LatLng): number {
        const _locationFrom = new LatLng(locationFrom.lat, locationFrom.lng);
        const _locationTo = new LatLng(locationTo.lat, locationTo.lng);

        return Math.round(_locationFrom.distanceTo(_locationTo) / 1000);
    }

    // Compare two objects
    deepEqual(object1, object2): boolean {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = this.isObject(val1) && this.isObject(val2);
            if ((areObjects && !this.deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
                return false;
            }
        }
        return true;
    }

    isObject(object): boolean {
        return object != null && typeof object === 'object';
    }

    toggleElementInArray(arr: any[], element: any) {
        return arr.some((el) => el == element) ? arr.filter((el) => el !== element) : [...arr, element];
    }

    toggleObjectInArray(arr: any[], object: any, key: string) {
        return arr.some((el) => el[key] == object[key]) ? arr.filter((el) => el[key] !== object[key]) : [...arr, object];
    }
}
