/* eslint-disable @angular-eslint/no-output-native */
// Angular
import { Component, Input, Output, EventEmitter, Inject, forwardRef, ViewChild, ElementRef, Optional, AfterViewInit, OnDestroy } from '@angular/core';
// Angular instantsearch
import { NgAisIndex, TypedBaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
// Instantsearch
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { SearchBoxConnectorParams, SearchBoxWidgetDescription, SearchBoxRenderState } from 'instantsearch.js/es/connectors/search-box/connectSearchBox';
//Typesense
import { noop } from '../utils';
import { TypesenseClearRefinementsService } from '../clear-refinements/clear-refinements.service';
// RXJS
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
// Core
import { MixpanelService } from '@core/services';

@Component({
    selector: 'typesense-search',
    templateUrl: 'search.component.html',
    styleUrls: ['search.component.scss'],
})
export class TypesenseSearchComponent extends TypedBaseWidget<SearchBoxWidgetDescription, SearchBoxConnectorParams> implements AfterViewInit, OnDestroy {
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    @Input() public searchAsYouType = true;
    @Input() public searchValue: string;

    // Output events
    @Output() submit = new EventEmitter();
    @Output() reset = new EventEmitter();
    @Output() change = new EventEmitter();

    public state: SearchBoxRenderState = {
        query: '',
        refine: noop,
        clear: noop,
        isSearchStalled: false,
    };

    private subs: Subscription;

    //prettier-ignore
    constructor(
        @Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
        private mixpanelService: MixpanelService,
        private clearRefinementService: TypesenseClearRefinementsService,
    ) {
        super('SearchBox');
        this.createWidget(connectSearchBox, {});
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngAfterViewInit(): void {
        this.onSearchChange();

        this.onSearchInputFill();
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    clear(): void {
        this.searchInput.nativeElement.value = '';
        this.reset.emit('');
        this.state.refine('');
    }

    handleSubmit(event: Event): void {
        this.submit.emit(event);
        event.preventDefault();

        if (!this.searchAsYouType) {
            this.search(this.searchInput.nativeElement.value);
        }
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    onSearchChange(): void {
        this.subs = fromEvent(this.searchInput?.nativeElement, 'keyup')
            .pipe(
                filter(Boolean),
                debounceTime(1000),
                distinctUntilChanged(),
                tap(() => {
                    if (this.searchAsYouType) {
                        this.search(this.searchInput.nativeElement.value);
                    }
                }),
            )
            .subscribe();
    }

    onSearchInputFill(): void {
        if (this.searchValue) {
            this.searchInput.nativeElement.value = this.searchValue;
            setTimeout(() => this.search(this.searchValue), 0);
        }
    }

    search(value: string): void {
        this.clearRefinementService.clear();
        const searchValue = value.toString().toLowerCase();
        this.state.refine(searchValue);
        this.mixpanelService.track('Typesense - Search', { value: searchValue });
    }

    /************/
    /*    UI    */
    /************/
    isValue(): boolean {
        return this.searchInput.nativeElement.value !== '';
    }
}
