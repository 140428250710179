// Angular
import { Component, OnInit, EventEmitter, Output, Input, ViewEncapsulation, Optional, OnDestroy } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
// Angular Material
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { MatDialogRef } from '@angular/material/dialog';
// RXJS
import { Observable } from 'rxjs';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
// Store
import { CategoriesEntityService, CategoryModel, CategoryModelFlat, ProductModel } from '@store/index';
// Lodash
import { sortBy } from 'lodash';

@Component({
    selector: 'categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CategoriesComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @Input() edit = false;

    @Output() dragEnd: EventEmitter<ProductModel[]> = new EventEmitter();
    @Output() closeCategories = new EventEmitter();

    /** Feature properties */
    categories$: Observable<CategoryModel[]>;
    hasItems = false;
    loading$: Observable<boolean>;

    /** Mat tree properties */
    dataSource: MatTreeFlatDataSource<any, any>;
    flatNodeMap = new Map<CategoryModelFlat, CategoryModel>();
    nestedNodeMap = new Map<CategoryModel, CategoryModelFlat>();
    nodeIdToExpand: string;
    selectedParent: CategoryModelFlat | null = null;
    treeControl: FlatTreeControl<CategoryModelFlat>;
    treeFlattener: MatTreeFlattener<CategoryModel, CategoryModelFlat>;

    /* Drag and drop */
    dragNode: any;
    dragNodeExpandOverWaitTimeMs = 300;
    dragNodeExpandOverNode: any;
    dragNodeExpandOverTime: number;
    dragNodeExpandOverArea: string;

    //prettier-ignore
    constructor(
        private categoriesService: CategoriesEntityService,
        @Optional() private dialogRef: MatDialogRef<CategoriesComponent>
    ) {
        super();

        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<CategoryModelFlat>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        if (this.dialogRef) {
            this.edit = true;
        }
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.listenLoading();

        this.getCategories();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /***********************/
    /** MAT TREE FUNCTIONS */
    /***********************/
    getLevel = (node: CategoryModelFlat): number => node.level;
    isExpandable = (node: CategoryModelFlat): boolean => node.expandable;
    getChildren = (node: CategoryModel): CategoryModel[] => node.children;
    hasChild = (_: number, _nodeData: CategoryModelFlat): boolean => _nodeData.expandable;
    hasNoContent = (_: number, _nodeData: CategoryModelFlat): boolean => !_nodeData.name;

    /** Display input to add new sub category */
    addNewItem(node: CategoryModelFlat): void {
        const newNode = new CategoryModel();
        newNode.parent = node.id;
        this.dataSource.data.find((n) => n.id === node.id).children.push(newNode);
        this.dataSource.data = this.dataSource.data;
        setTimeout(() => this.treeControl.expand(node), 0);
    }

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: CategoryModel, level: number): CategoryModelFlat => {
        const existingNode = this.nestedNodeMap.get(node);

        const flatNode = existingNode && existingNode.name === node.name ? existingNode : new CategoryModelFlat();
        flatNode.id = node.id;
        flatNode.hasProducts = node.hasProducts;
        flatNode.name = node.name;
        flatNode.level = level;
        flatNode.expandable = node.children?.length > 0;
        flatNode.parent = node.parent;

        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);

        return flatNode;
    };

    getParentNode(node: CategoryModelFlat): CategoryModelFlat | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    getNodeById(id: string): CategoryModelFlat {
        return this.treeControl.dataNodes.find((node) => node.id === id);
    }

    expandAll(): void {
        this.treeControl.dataNodes.forEach((node) => this.treeControl.expand(node));
    }

    /****************/
    /**   ACTIONS   */
    /****************/
    /** Select the category so we can insert the new item. */
    addNewCategory(input: any): void {
        if (input.value === '') return;

        const newCategory = { name: input.value };
        this.categoriesService.add(newCategory);
        input.value = null;
        this.edit = true;
    }

    addNewSubCategory(node: CategoryModelFlat, name: string): void {
        const newSubCategory = { ...this.flatNodeMap.get(node), name: name };
        this.categoriesService.add(newSubCategory);
        this.nodeIdToExpand = node.parent;
    }

    close() {
        this.edit ? this.dialogRef.close() : this.closeCategories.emit(true);
    }

    delete(node: CategoryModelFlat): void {
        if (this.canDelete(node)) {
            this.categoriesService.delete(node.id);
            this.nodeIdToExpand = node.parent;
        }
    }

    prepareProductToUpdate(ids: string[], category: CategoryModelFlat): ProductModel[] {
        const products = [];
        ids.forEach((id) => {
            const _product = this.prepareDataProduct(id, category);
            products.push(_product);
        });

        return products;
    }

    update(node: CategoryModelFlat, name: string): void {
        if (name === '') return;

        const updateCategory = { id: node.id, name: name, parent: node.parent || null };
        this.categoriesService.update(updateCategory);
        this.nodeIdToExpand = node.parent || node.id;
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    buildCategoriesTree(data: any[]): any[] {
        // Keep only high level category and init them with children array
        let dataTree = data
            .filter((c) => !c.parent)
            .map((c) => {
                return { ...c, children: [] };
            });

        dataTree = sortBy(dataTree, 'name');

        data.forEach((node) => {
            if (node.parent) {
                const parentItem: CategoryModel = dataTree.find((i) => i.id == node.parent);
                if (parentItem) {
                    parentItem.children.push(node);
                }
            }
        });

        // Sort subcategory for each category
        dataTree.forEach((category) => {
            category.children = sortBy(category.children, 'name');
        });

        return dataTree;
    }

    getCategories(): void {
        this.subs.sink = this.categoriesService.getEntities().subscribe((categories) => {
            // Fill datasource
            this.dataSource.data = this.buildCategoriesTree(categories);

            this.expandAll();

            // Expand node if saved
            if (this.nodeIdToExpand) {
                this.treeControl.expand(this.getNodeById(this.nodeIdToExpand));
            }
        });
    }

    listenLoading(): void {
        this.loading$ = this.categoriesService.loading$;
    }

    prepareDataProduct(productId: string, category: CategoryModelFlat): ProductModel {
        const _product = new ProductModel();
        _product.id = productId;
        _product.categoryId = category.id;

        return _product;
    }

    /************/
    /**   UI    */
    /************/
    canDelete(node: CategoryModelFlat): boolean {
        return !node.hasProducts && !node.expandable;
    }

    deleteEmptyNode(): void {
        this.dataSource.data = this.dataSource.data.map((n) => {
            return { ...n, edit: false, children: n.children?.filter((c) => c.id) };
        });
    }

    disableEditNode(): void {
        this.flatNodeMap.forEach((value: any, key: any) => {
            if (key.edit) {
                key.edit = false;
            }
        });
    }

    editTree(): void {
        this.edit = !this.edit;

        if (!this.edit) {
            this.deleteEmptyNode();
            this.disableEditNode();
        }
    }

    /**********************/
    /*    DRAG AND DROP   */
    /**********************/
    handleDragOver(event: any, node: CategoryModelFlat): void {
        event.preventDefault();

        // Handle node expand
        if (node === this.dragNodeExpandOverNode) {
            if (!this.treeControl.isExpanded(node)) {
                if (new Date().getTime() - this.dragNodeExpandOverTime > this.dragNodeExpandOverWaitTimeMs) {
                    this.treeControl.expand(node);
                }
            }
        } else {
            this.dragNodeExpandOverNode = node;
            this.dragNodeExpandOverTime = new Date().getTime();
        }

        // Handle drag area
        const percentageY = event.offsetY / event.target.clientHeight;
        if (percentageY > 0.25 && percentageY < 0.75) {
            this.dragNodeExpandOverArea = 'center';
        } else {
            this.dragNodeExpandOverArea = null;
        }
    }

    handleDrop(event: any, node: CategoryModelFlat): void {
        event.preventDefault();
        const products = JSON.parse(event.dataTransfer.getData('dragProducts'));
        const productsToUpdate = products instanceof Array ? this.prepareProductToUpdate(products, node) : this.prepareProductToUpdate([products], node);

        this.dragNodeExpandOverNode = null;
        this.dragNodeExpandOverTime = 0;
        this.dragEnd.emit(productsToUpdate);
    }
}
