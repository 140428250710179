// Core
import { BaseModel } from '@core/_base/crud';
// Store
import { CategoryModel } from '@store/categories/category.model';
import { SupplierModel } from '@store/suppliers/supplier.model';
import { SizeType } from '@store/_services/params.service';
// Lodash
import { isNil, omitBy, pickBy } from 'lodash';

const TVA_20 = 20;

export enum ManagementMode {
    'Click & Collect' = 0,
    'D-Stock' = 1,
    'Services' = 2,
}

export enum StockStatus {
    'OK' = 0,
    'Alert' = 1,
    'OutOfStock' = 2,
}

export interface ManagerModel {
    id: string;
    displayName: string;
    selected: boolean;
}

export class ProductModel extends BaseModel {
    // Database field
    category?: CategoryModel;
    categoryId?: string;
    categoryName?: string;
    description?: string;
    delay?: number;
    discount?: number;
    ean?: string;
    ecoFriendly?: boolean;
    ecotax?: number;
    favorite: boolean; // Use in customer catalog
    hideInMarketplace: boolean;
    id?: string;
    isCustomizable: boolean;
    isManageInStock: boolean; // True if customer manage the stock of the product
    linked?: boolean; // True if it's a product from a supplier catalog | False : if it's created by the customer
    madeInFrance?: boolean;
    maker: string;
    managementMode?: number;
    manager!: ManagerModel;
    minimumPurchase?: number;
    name: string;
    needs?: number; // Sum of requests in statuts between "New" and "Ready"
    ordered?: number; // Sum of orders in status between "Cart" and "Delivered"
    packaging?: number;
    photo?: string[];
    price?: number;
    priceDiscount?: number;
    productId?: string; // Product id save in Typesense
    provider?: string; // Exemple Dompro for NQB
    quotation: boolean; // Product on quote
    reference?: string;
    referenceMaker?: string;
    referenceProvider?: string;
    referenceSupplier?: string;
    size?: string;
    sizes?: string[];
    sizeType?: SizeType;
    step?: number;
    stock?: number;
    supplier!: SupplierModel; // ! is used to avoid error if property is not initialized
    taxes?: number;
    technicalSheet?: File | string;
    universe?: string;
    worksCouncil?: boolean;

    // Typesense model
    supplierName?: string;
    supplierCompanyId?: string;

    //Field not saved in database
    catalogId: string;

    //Calculate field
    futureStock?: number;
    statusActualStock?: StockStatus;
    statusFutureStock?: StockStatus;

    constructor(obj?: any) {
        super();

        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);

            // Calculate field
            if (obj.managementMode >= 0) {
                this.futureStock = this._calculateFutureStock();
                this.statusActualStock = this._getStatusActualStock();
                this.statusFutureStock = this._getStatusFutureStock();
            }

            // Set categories with Typensense info
            if (obj['categoryName.lvl0']) {
                this.category = new CategoryModel();
                this.category.nameCategory = obj['categoryName.lvl0'];
            }

            if (obj['categoryName.lvl1']) {
                const categories = obj['categoryName.lvl1'].split(' > ');
                this.category.nameSubcategory = categories[1];
            }
        }
    }

    /***********************/
    /*   CLASS  FUNCTIONS  */
    /***********************/

    /** Refresh object */
    clear(): void {
        this.id = null;
        this.categoryId = null;
        this.description = null;
        this.discount = null;
        this.ean = null;
        this.managementMode = null;
        this.manager = null;
        this.minimumPurchase = null;
        this.name = null;
        this.needs = null;
        this.ordered = null;
        this.packaging = null;
        this.photo = null;
        this.price = null;
        this.priceDiscount = null;
        this.reference = null;
        this.referenceMaker = null;
        this.referenceSupplier = null;
        this.step = null;
        this.stock = null;
        this.supplier = null;
    }

    calculateDiscount(catalogDiscount?: number, productDiscount?: number): void {
        let newPrice = this.price;
        if (catalogDiscount) newPrice = newPrice * (1 - catalogDiscount / 100);

        if (productDiscount) newPrice = newPrice * (1 - productDiscount / 100);

        this.priceDiscount = newPrice;
    }

    /** Delete undefined property */
    clean(): void {
        for (const prop in this) {
            if (this[prop] === undefined) {
                delete this[prop];
            }
        }
    }

    getEcotax() {
        return this.ecotax || 0;
    }

    getObjectToCart(size?: string): ProductModel {
        const _product = new ProductModel();
        _product.ecotax = this.ecotax;
        _product.id = this.id;
        _product.name = this.name;
        _product.maker = this.maker;
        _product.minimumPurchase = this.minimumPurchase;
        _product.photo = this.photo;
        _product.price = this.price;
        _product.priceDiscount = this.priceDiscount;
        _product.provider = this.provider;
        _product.referenceMaker = this.referenceMaker;
        _product.referenceProvider = this.referenceProvider;
        _product.referenceSupplier = this.referenceSupplier;
        _product.size = size ?? this.size;
        _product.taxes = this.taxes;
        _product.supplierCompanyId = this.supplierCompanyId;

        if (this.packaging > 1) {
            _product.name = `Lot de ${this.packaging} - ${_product.name}`;
        }

        return <ProductModel>omitBy(_product, isNil);
    }

    /** Return object with only available search properties */
    getObjectToSearch(): any {
        const obj = {
            ean: this.ean,
            manager: this.manager?.displayName,
            managementMode: ManagementMode[this.managementMode],
            maker: this.maker,
            name: this.name,
            packaging: this.packaging,
            price: this.price,
            reference: this.reference,
            referenceMaker: this.referenceMaker,
            referenceSupplier: this.referenceSupplier,
            supplier: this.supplier?.name,
        };
        return obj;
    }

    getObjectToSaveInInternalCatalog(): ProductModel {
        return new ProductModel({
            categoryId: this.categoryId,
            categoryName: this.categoryName,
            description: this.description,
            delay: this.delay,
            ean: this.ean,
            ecoFriendly: this.ecoFriendly,
            ecotax: this.ecotax,
            id: this.productId,
            madeInFrance: this.madeInFrance,
            maker: this.maker,
            name: this.name,
            packaging: this.packaging,
            photo: this.photo,
            price: this.price,
            priceDiscount: this.priceDiscount,
            referenceMaker: this.referenceMaker,
            referenceSupplier: this.referenceSupplier,
            sizes: this.sizes,
            supplierCompanyId: this.supplierCompanyId,
            supplierName: this.supplierName,
            taxes: this.taxes,
            universe: this.universe,
        });
    }

    getPrice(): number | null {
        if (this.price === null || this.price === undefined) return null;

        return (this.priceDiscount || this.price) + this.getEcotax();
    }

    getPublicPrice(): number | null {
        if (!this.price) return null;

        return this.price + this.getEcotax();
    }

    getAmountTVA(): number {
        const tva = (this.priceDiscount || this.price) * (this.taxes / 100) + this.getEcotax() * (TVA_20 / 100);
        return Math.round(tva * 1000) / 1000;
    }

    /*************************/
    /*  PRIVATE FUNCTIONS  */
    /*************************/
    private _calculateFutureStock(): number {
        if (this.stock == null && this.ordered == null && this.needs == null) return null;

        return (this.stock || 0) + (this.ordered || 0) - (this.needs || 0);
    }

    private _getStatusActualStock(): StockStatus {
        if (this.stock == null && this.ordered == null && this.needs == null) return null;

        if (this.stock <= 0) return StockStatus.OutOfStock;

        if (this.step >= 0 && this.stock < this.step) return StockStatus.Alert;

        return StockStatus.OK;
    }

    private _getStatusFutureStock(): StockStatus {
        if (this.stock == null && this.ordered == null && this.needs == null) return null;

        if (this.futureStock <= 0) return StockStatus.OutOfStock;

        if (this.step >= 0 && this.futureStock < this.step) return StockStatus.Alert;

        return StockStatus.OK;
    }
}
