import { OpeningTime } from '@store/_models/opening-time.model';
import { BaseModel } from '@core/_base/crud';
import { pickBy } from 'lodash';

import { ContactModel } from '..';
import { AddressModel } from '..';

export type PaymentChoice = 'transfer' | 'check' | 'exchange';
export type BillingMethod = 'order' | 'monthly';

export class CustomerTenantModel extends BaseModel {
    billingAddress?: AddressModel[];
    billingContact: ContactModel;
    billingMethod?: BillingMethod;
    deliveryAddress?: AddressModel[];
    id?: string;
    openingTime?: OpeningTime[];
    paymentChoice?: PaymentChoice;

    constructor(obj?: any) {
        super();

        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }
    }
}
