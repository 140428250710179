import { Observable } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RXJS
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TypesenseSearchService {
    private search$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor() {
        this.reset();
    }

    getSearchValue$(): Observable<string> {
        return this.search$.asObservable();
    }

    setSearchValue$(searchValue: string): void {
        this.search$.next(searchValue);
    }

    reset(): void {
        this.search$.next('');
    }
}
