// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RXJS
import { filter, first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
// NGRX
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
// Core
import { FirestoreService } from '@core/services';
import { AuthUserModel, getUser } from '@core/auth';
import { QueryFSModel } from '@core/_base/crud';
import { AppState } from '@core/reducers';
// Store
import { FS_PATH_INVITATIONS } from '@store/firestore-collections';
import { InvitationModel } from './invitation.model';

@Injectable({
    providedIn: 'root',
})
export class InvitationsDataService extends DefaultDataService<InvitationModel> {
    user: AuthUserModel;

    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private store: Store<AppState>
    ) {
        super('Invitations', http, httpUrlGenerator);

        this._getConnectedUser();
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getAll(): Observable<InvitationModel[]> {
        const query = this._getQueryInvitations();
        return this.firestoreService.collection$(FS_PATH_INVITATIONS, null, query).pipe(map((res) => res.map((r) => new InvitationModel(r))));
    }

    update(invitation: Update<InvitationModel>): Observable<any> {
        const _invitation = invitation.changes;
        const path = `${FS_PATH_INVITATIONS}/${invitation.id}`;
        return this.firestoreService.updateAt(path, _invitation).pipe(map(() => _invitation));
    }

    delete(id: string): Observable<string> {
        const path = `${FS_PATH_INVITATIONS}/${id}`;
        return this.firestoreService.delete(path).pipe(map(() => id));
    }

    /************************/
    /*   Private functions  */
    /************************/
    _getConnectedUser(): void {
        this.store
            .select(getUser)
            .pipe(
                filter((user) => user != undefined),
                first(),
            )
            .subscribe((user) => (this.user = user));
    }

    _getQueryInvitations(): QueryFSModel[] {
        const queryArray = [new QueryFSModel('inactive', '!=', true)];
        if (this.user.roles?.includes('supplier')) {
            queryArray.push(new QueryFSModel('supplier.companyId', '==', this.user.companyId));
        } else {
            queryArray.push(new QueryFSModel('customer.id', '==', this.user.id));
        }
        return queryArray;
    }
}
