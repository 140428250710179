// Angular
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouteReuseStrategy } from '@angular/router';
// Angular Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Env
import { environment } from '@env/environment';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// NGRX
import { EntityDataModule } from '@ngrx/data';
import { entityConfig } from './store/entity-metadata';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// State
import { metaReducers, reducers } from '@core/reducers';
// Copmponents
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';
import { LayoutModule } from '@theme/layout/layout.module';
// Shared components
import { SharedModule } from '@shared/shared.module';
// Theme
import { ThemeModule } from '@theme/theme.module';
// Layout Services
import { KtDialogService, LayoutConfigService, LayoutRefService, MenuAsideService, MenuConfigService, MenuHorizontalService, PageConfigService, SplashScreenService, SubheaderService } from './core/_base/layout';
// Auth
import { AuthModule } from './core/auth/auth.module';
import { AuthService } from '@core/auth';
// CRUD
import { LayoutUtilsService, TypesUtilsService } from '@core/_base/crud';
// Config
import { LayoutConfig } from '@core/_config/layout.config';
// Core
import { CustomRouteReuseStrategy, MixpanelService } from '@core/services';
import { ChangePasswordComponent } from '@core/auth/components/change-password/change-password.component';
// Highlight JS
import { HIGHLIGHT_OPTIONS, HighlightLanguage } from 'ngx-highlightjs';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as scss from 'highlight.js/lib/languages/scss';
import * as xml from 'highlight.js/lib/languages/xml';
import * as json from 'highlight.js/lib/languages/json';
//Firebase
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAnalyticsModule, APP_VERSION, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
// Store
import { AppStoreModule } from '@store/app-store.module';
import { ParamsService, UniversesService } from './store';
// Ngx Datepicker
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// Ngx Mask
import { NgxMaskModule } from 'ngx-mask';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// Locale
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
// Typesense
import { NgAisModule } from 'angular-instantsearch';

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
    swipeEasing: true,
    minScrollbarLength: 40,
    maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
    // initialize app by loading default demo layout config
    return () => {
        return appConfig.loadConfigs(new LayoutConfig().configs);
    };
}

export function hljsLanguages(): HighlightLanguage[] {
    return [
        { name: 'typescript', func: typescript },
        { name: 'scss', func: scss },
        { name: 'xml', func: xml },
        { name: 'json', func: json },
    ];
}

// Enable / Disable Redux Dev tools in terms of environment
export const StoreDevModules = !environment.production ? StoreDevtoolsModule.instrument() : [];

//prettier-ignore
@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		// Firebase
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireAnalyticsModule,
		AngularFireAuthModule,
		AngularFirestoreModule,
		AngularFireDatabaseModule,
		AngularFireFunctionsModule,
		AngularFireStorageModule,

		AppRoutingModule,
		AppStoreModule,
		AuthModule.forRoot(),
		BrowserAnimationsModule,
		BrowserModule,
		CoreModule,
		EffectsModule.forRoot([]),
		EntityDataModule.forRoot(entityConfig),
		// GtmModule,
		InlineSVGModule.forRoot(),
		LayoutModule,
		NgAisModule.forRoot(),
		NgxDaterangepickerMd.forRoot(),
		NgxPermissionsModule.forRoot(),
		NgxMaskModule.forRoot(),
		OverlayModule,
		SharedModule,
		StoreModule.forRoot(reducers, { metaReducers }),
		StoreDevModules,
		StoreRouterConnectingModule.forRoot(),
		TranslateModule.forRoot(),
		ThemeModule,
	],
	exports: [
		ChangePasswordComponent,
	],
	providers: [
		AuthService,
		KtDialogService,
		LayoutConfigService,
		LayoutRefService,
		LayoutUtilsService,
		MenuAsideService,
		MenuConfigService,
		MenuHorizontalService,
		MixpanelService,
		PageConfigService,
		ParamsService,
		ScreenTrackingService,
		SplashScreenService,
		SubheaderService,
		TypesUtilsService,
		UserTrackingService,
		{
			provide: RouteReuseStrategy,
			useClass: CustomRouteReuseStrategy,
		},
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService, MixpanelService, UniversesService], multi: true
		},
		{
			provide: APP_VERSION,
			useValue: environment.version,
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: { languages: hljsLanguages }
		},
		// AngularFireFunctions
		{
			provide: REGION,
			useValue: 'europe-west1',
		},
		// Locale
		{
			provide: LOCALE_ID,
			useValue: "fr-FR"
		},
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }
