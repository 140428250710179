// Angular
import { Component, Inject, forwardRef, Optional, Input, OnInit, OnDestroy } from '@angular/core';
// Angular instantsearch
import { NgAisIndex, TypedBaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
// Instantsearch
import { SortByConnectorParams, SortByItem, SortByRenderState, SortByWidgetDescription } from 'instantsearch.js/es/connectors/sort-by/connectSortBy';
import { connectSortBy } from 'instantsearch.js/es/connectors';
// Typesense
import { noop } from '../utils';
import { TypesenseSortService } from './sort.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'typesense-sort',
    templateUrl: 'sort.component.html',
})
export class TypesenseSortComponent extends TypedBaseWidget<SortByWidgetDescription, SortByConnectorParams> implements OnInit, OnDestroy {
    @Input() public items: SortByItem[];
    @Input() public transformItems?: <U extends SortByItem>(items: SortByItem[]) => U[];

    subs: Subscription;

    public state: SortByRenderState = {
        currentRefinement: null,
        options: [],
        refine: noop,
        hasNoResults: false,
    };

    //prettier-ignore
    constructor(
        @Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
        private sortService: TypesenseSortService,
    ) {
        super('SortBy');
        
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.createWidget(connectSortBy, {
            items: this.items,
            transformItems: this.transformItems,
        });

        super.ngOnInit();

        this.listenOnSortChange();
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    selectChange(event: any): void {
        this.state.refine(event.target.value);
    }

    sort(value: string): void {
        this.state.refine(value);
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    listenOnSortChange(): void {
        this.subs = this.sortService.getSortValue$().subscribe((label) => {
            if (label != '') {
                const sort = this.items.find((el) => el.label == label);
                if (sort) {
                    setTimeout(() => this.state.refine(sort.value), 0);
                }
            }
        });
    }
}
