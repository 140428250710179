// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// Core
import { AuthActions } from '@core/auth/store/_actions';
import { getAuthError, isAuthSuccess, AuthState } from '@core/auth/store/_selectors/auth.selectors';
import { AuthService } from '@core/auth';
import { MixpanelService, UnsubscribeOnDestroy, UrlService } from '@core/services';
import { AuthNoticeService } from '@core/auth/store/auth-notice/auth-notice.service';
import { LayoutUtilsService } from '@core/_base/crud';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Feature
import { ConfirmEmailComponent } from '../confirm-email/confirm-email.component';
// Store
import { ParamsService } from '@store/index';

@Component({
    selector: 'kt-login',
    templateUrl: './login.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LoginComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    displayForm = true;
    emailForSignIn: string;
    error$: Observable<{ code: string }>;
    loginForm: FormGroup;
    loading = false;
    passwordType = 'password';
    signWithEmail = false;
    tenantId$: Observable<any>;
    user: Observable<any>;

    private returnUrl: any;

    // prettier-ignore
    constructor(
        private activatedRoute: ActivatedRoute,
        public authNoticeService: AuthNoticeService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private fb: FormBuilder,
        private layoutUtilsService: LayoutUtilsService,
        private mixpanelService: MixpanelService,
        private paramsService: ParamsService,
        private router: Router,
        private store: Store<AuthState>,
        private translateService: TranslateService,
        private urlService: UrlService)
    {
        super();

        this.getReturnUrl();

        this.tenantId$ = this.paramsService.getTenantId();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    async ngOnInit(): Promise<void> {
        this.initLoginForm();

        this.onSuccessLogin();

        this.onErrorLogin();

        this.onSignInWithEmailLink();
    }

    ngOnDestroy(): void {
        this.authNoticeService.setNotice(null);
        this.loading = false;
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    signIn(): void {
        this.loading = true;

        this.subs.sink = this.tenantId$.subscribe((tenantId) => {
            const credentials = {
                email: this.loginForm.controls.email.value,
                password: this.loginForm.controls.password.value,
                tenantId: tenantId,
            };

            this.store.dispatch(new AuthActions.CredentialsLogin(credentials));
        });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    getEmail(): Promise<string> {
        const _title = this.translateService.instant('AUTH.GENERAL.CONFIRM_EMAIL');

        const dialogRef = this.layoutUtilsService.confirm(_title, null, null, false, ConfirmEmailComponent);
        return dialogRef.componentInstance.confirm
            .pipe(
                first(),
                tap(() => dialogRef.close()),
            )
            .toPromise();
    }

    getReturnUrl(): void {
        this.returnUrl = this.activatedRoute.snapshot.params.returnUrl || this.urlService.getBaseUrl('') || '/';
    }

    initLoginForm(): void {
        this.loginForm = this.fb.group({
            email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(320)])],
            password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(100)])],
        });
    }

    onSignInWithEmailLink(): void {
        this.authService
            .isSignInWithEmailLink(this.router.url)
            .pipe(filter((res) => res === true))
            .subscribe(async () => {
                try {
                    // If ever another user was connected to the workstation before
                    this.authService.logout();

                    this.signWithEmail = true;
                    this.displayForm = false;
                    this.cdr.markForCheck();
                    this.emailForSignIn = window.localStorage.getItem('emailForSignIn') || this.activatedRoute.snapshot.queryParams.email || null;
                    const urlLink = this.router.url;

                    if (!this.emailForSignIn) {
                        this.emailForSignIn = await this.getEmail();
                    }

                    await this.authService.signInWithEmailLink(this.emailForSignIn, urlLink);

                    localStorage.removeItem('emailForSignIn');
                } catch (error) {
                    console.error(error);
                    this.displayForm = true;

                    // Re send sign in email link if it's invalid
                    if (error.code === 'auth/argument-error' || 'auth/invalid-action-code' || 'auth/auth/expired-action-code') {
                        this.renewRegister();
                    }

                    const message = this.translateService.instant(this.authService.getFireErrorMessage(error.code));
                    this.authNoticeService.setNotice(message, 'danger');
                    this.cdr.markForCheck();
                    this.authService.logout();
                }
            });
    }

    onSuccessLogin(): void {
        this.subs.sink = this.store
            .pipe(
                select(isAuthSuccess),
                filter((isAuthSuccess) => isAuthSuccess),
            )
            .subscribe(() => this.router.navigateByUrl(this.returnUrl));
    }

    onErrorLogin(): void {
        this.error$ = this.store.pipe(select(getAuthError));
        this.subs.sink = this.error$.subscribe((error) => {
            if (error) {
                const message = this.translateService.instant(this.authService.getFireErrorMessage(error.code));
                this.authNoticeService.setNotice(message, 'danger');
                this.loading = false;
                this.mixpanelService.track('Login error', { errorCode: error.code, errorMessage: message });
            }
        });
    }

    renewRegister(): void {
        localStorage.setItem('emailForSignIn', this.emailForSignIn);
        this.store.dispatch(new AuthActions.SendRegisterLink({ email: this.emailForSignIn, tenantName: this.paramsService.getTenantName() }));
        this.mixpanelService.track('Register - New link', { email: this.emailForSignIn });
    }

    /****************/
    /*      UI      */
    /****************/
}
