// Angular
import { Injectable } from '@angular/core';
//NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
// RXJS
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
// Model
import { NotificationModel, NotificationType } from './notification.model';
// Moment
import moment from 'moment';
// Lodash
import _, { orderBy } from 'lodash';

const CRID_LOAD = 'CRID_NOTIFICATIONS';
@Injectable({
    providedIn: 'root',
})
export class NotificationsEntityService extends EntityCollectionServiceBase<any> {
    private _correlationIndex = 0;

    // prettier-ignore
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('Notifications', serviceElementsFactory);

        this.loadAll();
    }

    /******************/
    /**    Getter     */
    /******************/
    getEntities(): Observable<NotificationModel[]> {
        return this.entities$.pipe(map((notifs) => notifs.map((n) => new NotificationModel(n))));
    }

    /******************/
    /**    Actions    */
    /******************/
    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    /******************/
    /**   Selectors   */
    /******************/
    selectIsNotifications(): Observable<boolean> {
        return this.getEntities().pipe(map((notifs) => notifs.length > 0));
    }

    selectNotifications(): Observable<any> {
        return this.getEntities().pipe(
            map((notifs) => orderBy(notifs, '_createdDate', 'desc')),
            map((notifs) => {
                return _(notifs)
                    .groupBy('day')
                    .map((data, day) => ({ day, data }))
                    .value();
            }),
            map((notifs) => notifs.sort((n1: any, n2: any) => moment(n2.day).diff(moment(n1.day)))),
        );
    }

    selectNotSeenNotifications(): Observable<NotificationModel[]> {
        return this.getEntities().pipe(
            map((notifs) =>
                orderBy(
                    notifs.filter((notif) => !notif.seen),
                    '_createdDate',
                    'desc',
                ),
            ),
        );
    }

    selectNbNewMessages(): Observable<number> {
        return this.getEntities().pipe(
            map((notifs) => notifs.filter((notif) => notif.type === NotificationType.Message && !notif.seen)),
            map((notifs) => notifs.length),
        );
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }
}
