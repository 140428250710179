import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    private storedRoutes = new Map<string, DetachedRouteHandle>();

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.routeConfig.data && route.routeConfig.data.reuse;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.storedRoutes.set(this.getUrlKey(route), handle);
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        this.clearStoredRoute();
        return !!route.routeConfig && !!this.storedRoutes.get(this.getUrlKey(route));
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return this.storedRoutes.get(this.getUrlKey(route));
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }

    getUrlKey(route: ActivatedRouteSnapshot): string {
        return route.routeConfig?.data?.featureName || null;
    }

    clearStoredRoute() {
        if (this.storedRoutes.size > 0) {
            const key = this.storedRoutes.keys().next().value;
            if (window.location.pathname.includes(key) === false) {
                this.storedRoutes.clear();
            }
        }
    }
}
