// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Angular Material
import { MatDialogRef } from '@angular/material/dialog';
// RXJS
import { filter } from 'rxjs/operators';
// NGRX
import { Store } from '@ngrx/store';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Core
import { PasswordManagementActions } from '@core/auth';
import { AppState } from '@core/reducers';
import { getChangePasswordStatus } from '@core/auth/store/_selectors/auth.selectors';
import { UnsubscribeOnDestroy } from '@core/services';
import { AuthService } from '@core/auth/store/_services/auth.service';
import { AuthNoticeService } from '@core/auth/store/auth-notice/auth-notice.service';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    changePasswordForm: FormGroup;
    loading = false;
    passwordType = 'password';
    //prettier-ignore
    constructor(
        private authService: AuthService, 
        public authNoticeService: AuthNoticeService, 
        private dialogRef: MatDialogRef<ChangePasswordComponent>, 
        private fb: FormBuilder, 
        private translateService: TranslateService, 
        private store: Store<AppState>) 
    {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.initRegistrationForm();
    }

    ngOnDestroy(): void {
        this.loading = false;
        this.subs.unsubscribe();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    close(): void {
        this.dialogRef.close();
    }

    submit(): void {
        const controls = this.changePasswordForm.controls;
        /** check form */
        if (this.changePasswordForm.invalid) {
            Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
            return;
        }

        this.loading = true;

        // Get form data
        const payload = {
            oldPassword: controls.oldPassword.value,
            newPassword: controls.newPassword.value,
        };

        // Call action to change password
        this.store.dispatch(new PasswordManagementActions.ChangePasswordRequest(payload));

        // Get result of action
        this.subs.sink = this.store
            .select(getChangePasswordStatus)
            .pipe(filter((status) => !status.loading))
            .subscribe((status) => {
                if (status.success) {
                    const message = this.translateService.instant('AUTH.CHANGE_PASSWORD.SUCCESS');
                    this.authNoticeService.setNotice(message, 'success');
                    setTimeout(() => this.close(), 3000);
                } else {
                    const message = this.translateService.instant(this.authService.getFireErrorMessage(status.error.code));
                    this.authNoticeService.setNotice(message, 'danger');
                }
                this.loading = false;
            });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    initRegistrationForm(): void {
        this.changePasswordForm = this.fb.group({
            newPassword: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
            oldPassword: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
        });
    }
}
