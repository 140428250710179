// Angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Material
import { MatDialog } from '@angular/material/dialog';
// RXJS
import { filter } from 'rxjs/operators';
// Core
import { LayoutConfigService, MenuAsideService, MenuOptions, OffcanvasOptions } from '@core/_base/layout';
// Theme
import { HtmlClassService } from '../html-class.service';
import { HelpComponent } from '../content/help/help.component';
// Environment
import { environment } from '@env/environment';

import * as objectPath from 'object-path';

@Component({
    selector: 'kt-aside-left',
    templateUrl: './aside-left.component.html',
    styleUrls: ['./aside-left.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideLeftComponent implements OnInit {
    private offcanvas: any;

    @ViewChild('asideMenuOffcanvas', { static: true }) asideMenuOffcanvas: ElementRef;
    @ViewChild('asideMenu', { static: true }) asideMenu: ElementRef;

    asideClasses = '';
    currentRouteUrl = '';
    insideTm: any;
    outsideTm: any;
    version: string;

    menuCanvasOptions: OffcanvasOptions = {
        baseClass: 'aside',
        overlay: true,
        closeBy: 'kt_aside_close_btn',
        toggleBy: {
            target: 'kt_aside_mobile_toggle',
            state: 'mobile-toggle-active',
        },
    };

    menuOptions: MenuOptions = {
        // submenu setup
        submenu: {
            desktop: {
                // by default the menu mode set to accordion in desktop mode
                default: 'dropdown',
            },
            tablet: 'accordion', // menu set to accordion in tablet mode
            mobile: 'accordion', // menu set to accordion in mobile mode
        },

        // accordion setup
        accordion: {
            expandAll: false, // allow having multiple expanded accordions in the menu
        },
    };

    //prettier-ignore
    constructor(
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        public htmlClassService: HtmlClassService,
        public layoutConfigService: LayoutConfigService,
        public menuAsideService: MenuAsideService,
        private router: Router,
        private render: Renderer2,
    ) { }

    ngOnInit(): void {
        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
            this.mobileMenuClose();
            this.cdr.markForCheck();
        });

        const config = this.layoutConfigService.getConfig();

        if (objectPath.get(config, 'aside.menu.dropdown')) {
            this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown', '1');
            // tslint:disable-next-line:max-line-length
            this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown-timeout', objectPath.get(config, 'aside.menu.submenu.dropdown.hover-timeout'));
        }

        this.asideClasses = this.htmlClassService.getClasses('aside', true).toString();
        setTimeout(() => {
            this.offcanvas = new KTOffcanvas(this.asideMenuOffcanvas.nativeElement, this.menuCanvasOptions);
        });

        //Get version
        this.version = environment.version;
    }

    /**
     * Check Menu is active
     * @param item: any
     */
    isMenuItemIsActive(item: any): boolean {
        if (item.submenu) {
            return this.isMenuRootItemIsActive(item);
        }

        if (!item.page) {
            return false;
        }

        return this.currentRouteUrl.indexOf(item.page) !== -1;
    }

    /**
     * Check Menu Root Item is active
     * @param item: any
     */
    isMenuRootItemIsActive(item: any): boolean {
        let result = false;

        for (const subItem of item.submenu) {
            result = this.isMenuItemIsActive(subItem);
            if (result) {
                return true;
            }
        }

        return false;
    }

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseEnter(): void {
        // check if the left aside menu is fixed
        if (document.body.classList.contains('aside-fixed')) {
            if (this.outsideTm) {
                clearTimeout(this.outsideTm);
                this.outsideTm = null;
            }

            this.insideTm = setTimeout(() => {
                // if the left aside menu is minimized
                if (document.body.classList.contains('aside-minimize') && KTUtil.isInResponsiveRange('desktop')) {
                    // show the left aside menu
                    this.render.removeClass(document.body, 'aside-minimize');
                    this.render.addClass(document.body, 'aside-minimize-hover');
                }
            }, 50);
        }
    }

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseLeave(): void {
        if (document.body.classList.contains('aside-fixed')) {
            if (this.insideTm) {
                clearTimeout(this.insideTm);
                this.insideTm = null;
            }

            this.outsideTm = setTimeout(() => {
                // if the left aside menu is expand
                if (document.body.classList.contains('aside-minimize-hover') && KTUtil.isInResponsiveRange('desktop')) {
                    // hide back the left aside menu
                    this.render.removeClass(document.body, 'aside-minimize-hover');
                    this.render.addClass(document.body, 'aside-minimize');
                }
            }, 100);
        }
    }

    /**
     * Returns Submenu CSS Class Name
     * @param item: any
     */
    getItemCssClasses(item: any): string {
        let classes = 'menu-item';

        if (objectPath.get(item, 'submenu')) {
            classes += ' menu-item-submenu';
        }

        if (!item.submenu && this.isMenuItemIsActive(item)) {
            classes += ' menu-item-active menu-item-here';
        }

        if (item.submenu && this.isMenuItemIsActive(item)) {
            classes += ' menu-item-open menu-item-here';
        }

        // custom class for menu item
        const customClass = objectPath.get(item, 'custom-class');
        if (customClass) {
            classes += ' ' + customClass;
        }

        if (objectPath.get(item, 'icon-only')) {
            classes += ' menu-item-icon-only';
        }

        return classes;
    }

    getItemAttrSubmenuToggle(item: any): string {
        let toggle = 'hover';
        if (objectPath.get(item, 'toggle') === 'click') {
            toggle = 'click';
        } else if (objectPath.get(item, 'submenu.type') === 'tabs') {
            toggle = 'tabs';
        } else {
            // submenu toggle default to 'hover'
        }

        return toggle;
    }

    mobileMenuClose(): void {
        if (KTUtil.isBreakpointDown('lg') && this.offcanvas) {
            // Tablet and mobile mode
            this.offcanvas.hide(); // Hide offcanvas after general link click
        }
    }

    openHelp() {
        this.dialog.open(HelpComponent, {
            disableClose: false,
            width: '550px',
        });
    }
}
