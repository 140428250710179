// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// Material
import { MatDialogRef } from '@angular/material/dialog';
// Store
import { ProductsEntityService } from '@store/index';
// RXJS
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'edit-all-suppliers',
    templateUrl: './edit-all-suppliers.component.html',
    styleUrls: ['./edit-all-suppliers.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EditAllSuppliersComponent implements OnInit {
    logo: File = null;
    form: FormGroup;
    loading: boolean;
    suppliers$: Observable<any>;

    constructor(private dialogRef: MatDialogRef<EditAllSuppliersComponent>, private productsService: ProductsEntityService, private fb: FormBuilder) {}

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        // Init form
        this.suppliers$ = this.productsService.selectSupplierOfProducts().pipe(filter((s) => s.id !== null));
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    submit() {
        const supplier = {
            name: this.form.controls.name.value.trim(),
            logo: this.logo,
        };

        this.dialogRef.close(supplier);
    }

    uploadLogo(event: any) {
        this.logo = event.addedFiles[0];
    }

    removePhoto() {
        this.logo = null;
    }

    close() {
        this.dialogRef.close();
    }
}
