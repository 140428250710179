// Angular
import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
//RXJS
import { catchError, exhaustMap, map, switchMap, tap, mapTo, first } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
// NRGX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
// Firebase
import firebase from 'firebase/app';
import 'firebase/auth';
// FireAuth
import * as userActions from '../_actions/auth.actions';
// Core
import { FirestoreService, StorageService } from '@core/services';
import { AuthService } from '../_services/auth.service';
import { AuthActions } from '../_actions';
// Store
import { CompanyEntityService } from '@store/company/company-entity.service';
import { RolesEntityService } from '@store/roles/roles-entity.service';
import { PermissionsEntityService } from '@store/permissions/permissions-entity.service';
import { FS_PATH_COMPANIES } from '@store/firestore-collections';

const PROVIDERS_MAP = {};
PROVIDERS_MAP[firebase.auth.FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD] = 'facebook';
PROVIDERS_MAP[firebase.auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD] = 'google';
PROVIDERS_MAP[firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD] = 'password';
PROVIDERS_MAP[firebase.auth.PhoneAuthProvider.PHONE_SIGN_IN_METHOD] = 'phone';

@Injectable()
export class AuthEffects {
    @Effect()
    getUser: Observable<Action> = this.actions$.pipe(
        ofType<userActions.GetUser>(userActions.AuthActionTypes.GetUser),
        map((action: userActions.GetUser) => action.payload),
        // Check if user if authenticated
        switchMap(() => this.authService.getAuthState()),
        switchMap((user) => {
            if (user) {
                // Load roles and permissions
                this.permissionsService.loadAll();
                this.rolesService.loadAll();

                // Save user id in local storage
                localStorage.setItem('userId', user.uid);
                // Get user information from Firestore
                return this.authService.getUserById(user.uid).pipe(
                    map((userAuth) => {
                        if (userAuth) {
                            if (userAuth.profileCompleted) {
                                // Set the path to query data according to the user role
                                const isSupplier = userAuth.roles?.includes('supplier');
                                this.firestoreService.initAllTenantsPath(userAuth.companyId, userAuth.id, isSupplier);

                                // Set storage folder path
                                this.storageService.setFolderPath(FS_PATH_COMPANIES, userAuth.companyId);

                                // Get company of the user
                                this.companyService.loadById(userAuth.companyId).pipe(first()).subscribe();

                                // Redirect
                                this.redirect(isSupplier);
                            }

                            // Update lastSignin field
                            this.authService.updateLastSignin(userAuth.id);

                            return new userActions.Authenticated({ user: userAuth });
                        } else {
                            return new userActions.NotAuthenticated();
                        }
                    }),
                    catchError((error) => {
                        console.error(error);
                        this.authService.logout();
                        return of(new userActions.AuthError(error));
                    }),
                );
            } else return of(new userActions.NotAuthenticated());
        }),
    );

    @Effect()
    loginWithCredentials: Observable<Action> = this.actions$.pipe(
        ofType(userActions.AuthActionTypes.CredentialsLogin),
        map((action: userActions.CredentialsLogin) => action.payload),
        exhaustMap((credentials) => {
            return from(this.authService.doLoginWithCredentials(credentials)).pipe(
                map((p) => {
                    // successful login
                    localStorage.setItem('userId', p.user.uid);
                    return new userActions.GetUser();
                }),
                catchError((error) => of(new userActions.AuthError(error))),
            );
        }),
    );

    @Effect()
    sendRegisterLink: Observable<Action> = this.actions$.pipe(
        ofType(userActions.AuthActionTypes.SendRegisterLink),
        map((action: userActions.SendRegisterLink) => action.payload),
        exhaustMap((payload) => {
            return this.authService.registerWithEmail(payload.email, payload.tenantName).pipe(
                mapTo(new AuthActions.RegisterLinkSuccess()),
                catchError((err) => {
                    console.error(err);
                    return of(new AuthActions.RegisterLinkError(err));
                }),
            );
        }),
    );

    @Effect()
    logout: Observable<Action> = this.actions$.pipe(
        ofType(userActions.AuthActionTypes.Logout),
        map((action: userActions.Logout) => action.payload),
        exhaustMap(() => {
            this.firestoreService.close();
            return from(this.authService.logout());
        }),
        map(() => {
            this.router.navigateByUrl(`${this.getBaseUrl()}/auth/login`);
            window.location.reload();
            return new userActions.NotAuthenticated();
        }),
    );

    @Effect()
    onDeleteNotVerifiedAccount$: Observable<any> = this.actions$.pipe(
        ofType<userActions.DeleteAccount>(userActions.AuthActionTypes.DeleteAccount),
        switchMap(() => {
            return this.authService.deleteAccount().pipe(
                map(() => new userActions.DeleteAccountSuccess()),
                catchError((error) => of(new userActions.DeleteAccountError(error))),
            );
        }),
    );

    @Effect({ dispatch: false })
    refreshToken$ = this.actions$.pipe(
        ofType(userActions.AuthActionTypes.RefreshToken),
        tap(() => this.authService.getIdToken()),
    );

    @Effect({ dispatch: false })
    notAuthenticated = this.actions$.pipe(
        ofType(userActions.AuthActionTypes.NotAuthenticated),
        tap(() => {
            localStorage.removeItem('userId');
            // const search = window.location.search || '';
            // this.router.navigateByUrl('/auth/login' + search);
        }),
    );

    private returnUrl: string;

    //prettier-ignore
    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private companyService: CompanyEntityService,
        private firestoreService: FirestoreService,
        private permissionsService: PermissionsEntityService,
        private rolesService: RolesEntityService,
        private router: Router,
        private storageService: StorageService)
    {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.returnUrl = event.url || '/';
            }
        });
    }

    private redirect(isSupplier: boolean) {
        // Case first connexion after use is completed is profile
        if (this.returnUrl.includes('welcome')) {
            this.returnUrl = isSupplier ? 'products' : `${this.getBaseUrl()}/home`;
        }

        this.router.navigateByUrl(this.returnUrl);
    }

    // Use to define is base url is "mp" or a white label
    private getBaseUrl() {
        const urlSegments = this.router.url.split('/');
        return urlSegments.length > 1 ? urlSegments[1] : null;
    }
}
