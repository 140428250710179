// Angular
import { Component, Input, Inject, forwardRef, Optional, OnInit } from '@angular/core';
// Angular instantsearch
import { NgAisIndex, TypedBaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
// Instantsearch
import { connectHitsPerPage } from 'instantsearch.js/es/connectors';
import { HitsPerPageConnectorParams, HitsPerPageWidgetDescription, HitsPerPageRenderState } from 'instantsearch.js/es/connectors/hits-per-page/connectHitsPerPage';
// Typesense
import { noop } from '../utils';

@Component({
    selector: 'typesense-hits-per-page',
    templateUrl: 'hits-per-page.component.html',
})
export class TypesenseHitsPerPageComponent extends TypedBaseWidget<HitsPerPageWidgetDescription, HitsPerPageConnectorParams> implements OnInit {
    @Input() public transformItems?: HitsPerPageConnectorParams['transformItems'];

    items: HitsPerPageConnectorParams['items'] = [
        { value: 25, label: '25', default: true },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
    ];

    public state: HitsPerPageRenderState = {
        items: [],
        refine: noop,
        hasNoResults: true,
    };

    //prettier-ignore
    constructor(
        @Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
    ) {
        super('HitsPerPage');
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.createWidget(connectHitsPerPage, {
            items: this.items,
            transformItems: this.transformItems,
        });
        super.ngOnInit();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    selectChange(value: number): void {
        this.state.refine(value);
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    get isHidden(): boolean {
        return this.state.items.length === 0 && this.autoHideContainer;
    }
}
