// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
// RXJS
import { Observable } from 'rxjs';
// Theme
import { HeaderActionButtonsService } from './header-action-buttons.service';

@Component({
    selector: 'header-action-buttons',
    templateUrl: './header-action-buttons.component.html',
})
export class HeaderActionButtonsComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    loading = false;
    showButtons$: Observable<boolean>;
    showCancelButton$: Observable<boolean>;
    title: string;

    // prettier-ignore
    constructor(
        private cdr: ChangeDetectorRef,
        private headerActionButtonsService: HeaderActionButtonsService,
    ) {
        super();
        this.showButtons$ = this.headerActionButtonsService.showButtons$;
        this.showCancelButton$ = this.headerActionButtonsService.showCancelButton$;
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.listenToSetTitlePage();
        this.listenLoading();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    onClick(): void {
        this.headerActionButtonsService.onClick.emit(null);
    }

    onCancel(): void {
        this.headerActionButtonsService.onCancel.emit(null);
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    listenLoading() {
        this.subs.sink = this.headerActionButtonsService.loading$.subscribe((loading) => (this.loading = loading));
    }

    listenToSetTitlePage(): void {
        this.subs.sink = this.headerActionButtonsService.titleButton$.subscribe((title) => {
            this.setTitleButton(title || '');
        });
    }

    setTitleButton(title: string): void {
        this.title = title;
        setTimeout(() => this.cdr.markForCheck(), 100);
    }
}
