// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Material
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Core
import { CoreModule } from '@core/core.module';
// CRUD
import { InterceptService } from '../_base/crud';
// Shared
import { SharedModule } from '@shared/shared.module';
// Theme
import { ThemeModule } from '@theme/theme.module';
// Feature
import { AccountFormComponent } from './components/welcome-form/accountForm/account-form.component';
import { AuthComponent } from './auth.component';
import { AuthNoticeComponent } from './components/auth-notice/auth-notice.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CompanyFormComponent } from './components/welcome-form/company-form/company-form.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { InactiveAccountComponent } from './components/inactive-account/inactive-account.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { TermsSaleFormComponent } from './components/welcome-form/terms-sales-form/terms-sales-form.component';
import { WelcomeFormComponent } from './components/welcome-form/welcome-form.component';
// Store Auth
import { AuthEffects, AuthGuard, AuthService, AuthWhiteLabelGuard, CanActivateWelcome, CanActivateInactive } from '.';
import { CustomEmailHandlerEffects } from './store/_effects/custom-email-handler.effects';
import { ReAuthenticationEffects } from './store/_effects/re-authentication.effects';
import { PasswordManagementEffects } from './store/_effects/password-management.effects';
import { reducers } from './store/_selectors/auth.selectors';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full',
            },
            {
                path: 'login',
                component: LoginComponent,
                data: { returnUrl: window.location.pathname },
            },
            {
                path: 'inactive',
                canActivate: [CanActivateInactive],
                component: InactiveAccountComponent,
            },
            {
                path: 'register',
                component: RegisterComponent,
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path: 'welcome',
                canActivate: [CanActivateWelcome],
                component: WelcomeFormComponent,
            },
        ],
    },
];

//prettier-ignore
@NgModule({
    imports: [
        CoreModule,
        RouterModule.forChild(routes),
        SharedModule,
        StoreModule.forFeature('auth', reducers),
        EffectsModule.forFeature([
            AuthEffects,
            CustomEmailHandlerEffects,
            PasswordManagementEffects,
            ReAuthenticationEffects
        ]),
        ThemeModule
    ],
    providers: [
        InterceptService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true,
        },
        // Dialog
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                disableClose: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '500px',
            },
        },
    ],
    exports: [
        AuthComponent,
        AuthNoticeComponent,
        ChangePasswordComponent,
        ConfirmEmailComponent,
        WelcomeFormComponent
    ],
    declarations: [
        AccountFormComponent,
        AuthComponent,
        AuthNoticeComponent,
        ChangePasswordComponent,
        CompanyFormComponent,
        ConfirmEmailComponent,
        ForgotPasswordComponent,
        InactiveAccountComponent,
        LoginComponent,
        RegisterComponent,
        TermsSaleFormComponent,
        WelcomeFormComponent
    ],
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                AuthService,
                AuthGuard,
                AuthWhiteLabelGuard,
                CanActivateInactive,
                CanActivateWelcome
            ],
        };
    }
}
