// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
// RXJS
import { Observable, combineLatest, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
// Store
import { CatalogsDataService } from './catalogs-data.service';
import { CatalogModel, ProductDiscount } from './catalog.model';
import { ProductsDataService } from '@store/products/products-data.service';
// Lodash
import { orderBy } from 'lodash';
// Moment
import moment from 'moment';

const CRID_LOAD = 'CRID_CATALOGS';
@Injectable({
    providedIn: 'root',
})
export class CatalogsEntityService extends EntityCollectionServiceBase<CatalogModel> {
    private _correlationIndex = 0;

    //prettier-ignore
    constructor(
        private catalogsDataService: CatalogsDataService,
        private productsDataService: ProductsDataService,
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('Catalogs', serviceElementsFactory);

        this.loadAll();
    }

    /******************/
    /**    Getter     */
    /******************/
    getEntities(): Observable<CatalogModel[]> {
        return this.entities$.pipe(map((catalogs) => catalogs.map((c) => new CatalogModel(c))));
    }

    /******************/
    /**    Actions    */
    /******************/
    addProduct(catalog: CatalogModel, product: ProductDiscount): Observable<CatalogModel> {
        if (catalog.id) {
            catalog.nbProducts++;
            return this.update(catalog).pipe(switchMap(() => this.catalogsDataService.addOrUpdateProducts(catalog.id, [product])));
        } else {
            catalog.name = catalog.name || `Catalogue du ${moment().format('DD MMMM yyyy')}`;
            catalog.nbProducts = 1;

            return this.add(catalog).pipe(
                switchMap((catalogCreated) => combineLatest([of(catalogCreated), this.catalogsDataService.addOrUpdateProducts(catalogCreated.id, [product])])),
                map(([catalogCreated]) => catalogCreated),
            );
        }
    }

    deleteCatalog(catalog: CatalogModel): Observable<string | number> {
        return this.catalogsDataService.deleteManyProducts(catalog.id, catalog.products).pipe(switchMap(() => this.delete(catalog.id)));
    }

    deleteMany(catalogIds: string[], product: ProductDiscount): Observable<void> {
        return this.catalogsDataService.deleteMany(catalogIds, product);
    }

    deleteManyProducts(catalog: CatalogModel, productDiscounts: ProductDiscount[]): Observable<CatalogModel> {
        return this.catalogsDataService.deleteManyProducts(catalog.id, productDiscounts).pipe(
            switchMap(() => {
                return this.update({ ...catalog, nbProducts: catalog.nbProducts - productDiscounts.length });
            }),
        );
    }

    duplicate(catalog: CatalogModel): Observable<any> {
        const _catalog = new CatalogModel();
        _catalog.name = catalog.name + ' - Copie';
        _catalog.description = catalog.description;
        _catalog.discount = catalog.discount;

        const products$ = this.getProducts(catalog.id);
        return products$.pipe(
            switchMap((products) => combineLatest([this.add(new CatalogModel({ ..._catalog, nbProducts: products.length })), of(products)])),
            switchMap(([catalog, products]) => this.catalogsDataService.addOrUpdateProducts(catalog.id, products)),
        );
    }

    getProducts(catalogId: string): Observable<ProductDiscount[]> {
        return this.catalogsDataService.getProducts(catalogId);
    }

    getProductsWithReference(catalogId: string): Observable<any[]> {
        const productDiscount$ = this.catalogsDataService.getProducts(catalogId);
        const products$ = this.productsDataService.getAllSnapshot();
        return combineLatest([productDiscount$, products$]).pipe(
            map(([productDiscounts, products]) => {
                return productDiscounts.map((productDiscount) => {
                    const product = products.find((p) => p.id == productDiscount.id);
                    return {
                        reference: product.reference,
                        name: product.name,
                        price: product.price,
                        discount: productDiscount.canEditDiscount ? productDiscount.discount : null,
                        priceDiscount: productDiscount.canEditPrice ? productDiscount.priceDiscount : null,
                    };
                });
            }),
        );
    }

    getProductsById(catalogId: string, productsId: string[]): Observable<ProductDiscount[]> {
        return this.catalogsDataService.getProductsById(catalogId, productsId);
    }

    import(catalog: CatalogModel, data: any): Observable<CatalogModel> {
        if (catalog.id) {
            return this.catalogsDataService.import(catalog.id, data).pipe(map(() => catalog));
        } else {
            const catalog = new CatalogModel();
            catalog.name = catalog.name || `Catalogue du ${moment().format('DD MMMM yyyy')}`;

            return this.add(catalog).pipe(switchMap((catalogCreated) => this.catalogsDataService.import(catalogCreated.id, data).pipe(map(() => catalogCreated))));
        }
    }

    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    loadByCustomerId(customerId: string): Observable<CatalogModel> {
        const query: any = { query1: { field: 'customUser', operator: '==', value: customerId } };
        return this.getWithQuery(query, { correlationId: this.getCorrelationId('loadByCustomerId') }).pipe(map((res) => (res.length > 0 ? new CatalogModel(res[0]) : null)));
    }

    loadById(id: string): Observable<CatalogModel> {
        return this.getByKey(id, { correlationId: this.getCorrelationId('loadById') });
    }

    updateCatalogDiscount(catalog: CatalogModel) {
        return this.catalogsDataService.updateCatalogDiscount(catalog).pipe(switchMap(() => this.update(catalog)));
    }

    updateMany(catalogIds: string[], discounts: any[], product: ProductDiscount): Observable<void> {
        return this.catalogsDataService.updateMany(catalogIds, discounts, product);
    }

    updateProduct(catalogId: string, productDiscount: ProductDiscount) {
        return this.catalogsDataService.updateProduct(catalogId, productDiscount);
    }

    /******************/
    /**   Selectors   */
    /******************/
    selectCatalogsName(): Observable<CatalogModel[]> {
        return this.getEntities().pipe(
            map((catalogs) => catalogs.map((c) => new CatalogModel({ id: c.id, name: c.name, description: c.description }))),
            map((catalogs) => orderBy(catalogs, 'name')),
        );
    }

    selectCatalogsNameForCustomerCard(customerId: string): Observable<CatalogModel[]> {
        const customCatalog$ = this.selectCustomCatalogByCustomerId(customerId);
        const catalogsWithoutCustom$ = this.selectCatalogsNameNoCustom();

        return combineLatest([customCatalog$, catalogsWithoutCustom$]).pipe(
            map(([catalog, catalogs]) => [catalog, ...catalogs]),
            map((catalogs) => catalogs.filter((c) => c !== undefined)),
            map((catalogs) => catalogs.map((c) => new CatalogModel({ id: c.id, name: c.name, description: c.description }))),
            map((catalogs) => orderBy(catalogs, 'name')),
        );
    }

    selectCatalogsNameNoCustom(): Observable<CatalogModel[]> {
        return this.entities$.pipe(
            map((catalogs) => catalogs.filter((c) => !c.customUser)),
            map((catalogs) => catalogs.map((c) => new CatalogModel({ id: c.id, name: c.name, description: c.description }))),
            map((catalogs) => orderBy(catalogs, 'name')),
        );
    }

    selectCatalogs(): Observable<CatalogModel[]> {
        return this.getEntities().pipe(map((catalogs) => orderBy(catalogs, 'name')));
    }

    selectCustomCatalogs(): Observable<CatalogModel[]> {
        return this.getEntities().pipe(
            map((catalogs) => catalogs.filter((c) => c.customUser)),
            map((catalogs) => orderBy(catalogs, 'name')),
        );
    }

    selectCustomCatalogByCustomerId(customerId: string): Observable<CatalogModel> {
        return this.entities$.pipe(map((catalogs) => catalogs.find((catalog) => catalog.customUser === customerId)));
    }

    selectCatalogWithProducts(catalogId: string): Observable<CatalogModel> {
        return this.getProducts(catalogId).pipe(switchMap(() => this.selectEntityById(catalogId)));
    }

    selectEntityById(catalogId: string): Observable<CatalogModel> {
        return this.entityMap$.pipe(map((entities) => entities[catalogId]));
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }
}
