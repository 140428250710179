// NGRX
import { EntityMetadataMap, EntityDataModuleConfig } from '@ngrx/data';

export const entityMetadata: EntityMetadataMap = {
    Catalogs: {},
    Categories: {},
    ChatRooms: {},
    Company: {},
    Customers: {},
    Favorites: {},
    Invitations: {},
    Notifications: {},
    Orders: {},
    Permissions: {},
    Products: {},
    Quotes: {},
    Requests: {},
    Roles: {},
    Sellers: {},
    Suppliers: {},
    Users: {},
};

const pluralNames = {};

export const entityConfig: EntityDataModuleConfig = {
    entityMetadata,
    pluralNames,
};
