import { BaseModel } from '@core/_base/crud';

export class PermissionModel extends BaseModel {
    id: string;
    name: string;
    translateCode: string;
    level: number;
    parentId: string;
    isSelected: boolean;
    _children: PermissionModel[];

    constructor(obj: any) {
        if (obj) {
            super();
            this.id = obj.id;
            this.name = obj.name;
        }
    }

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.translateCode = '';
        this.level = 1;
        this.parentId = undefined;
        this.isSelected = false;
        this._children = [];
    }
}
