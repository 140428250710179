// Angular
import { Component, ViewEncapsulation, ChangeDetectorRef, Input, OnInit, Inject, Optional, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RXJS
import { switchMap, tap, filter } from 'rxjs/operators';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
// Store
import { SuppliersEntityService, SupplierModel, InvitationsEntityService, NotificationModel, InvitationModel } from '@store/index';

@Component({
    selector: 'accept-invitation',
    templateUrl: './accept-invitation.component.html',
    styleUrls: ['./accept-invitation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AcceptInvitationComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @Input() supplier: SupplierModel;

    @Output() handleClose = new EventEmitter();

    actionSuccess = false;
    notification: NotificationModel;
    loading = false;

    //prettier-ignore
    constructor(
        private cdr: ChangeDetectorRef,
        @Optional() private dialogRef: MatDialogRef<AcceptInvitationComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private invitationsService: InvitationsEntityService,
        private router: Router,
        private suppliersService: SuppliersEntityService,
    ) {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.notification = this.data.notification;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    acceptInvitation(isAccepted: boolean) {
        this.loading = true;

        const _invitation = new InvitationModel({ id: this.notification.actionId, accepted: isAccepted, inactive: true });
        this.invitationsService.update(_invitation);

        if (isAccepted) {
            this.checkIfSupplierIsAdded();
        } else {
            setTimeout(() => this.close(), 1000);
        }
    }

    close() {
        this.dialogRef?.close({ closed: true });
        this.handleClose.emit();
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    checkIfSupplierIsAdded() {
        const invitationId = this.notification?.actionId;
        this.subs.sink = this.invitationsService
            .selectEntityById(invitationId)
            .pipe(
                filter((invitation) => invitation.id !== undefined),
                switchMap((invitation: InvitationModel) => {
                    return this.suppliersService.selectEntityById(invitation.supplier.companyId).pipe(
                        filter((supplier) => supplier.id !== undefined),
                        tap(() => {
                            this.actionSuccess = true;
                            this.loading = false;
                            this.cdr.markForCheck();

                            setTimeout(() => {
                                this.router.navigateByUrl(`/mp/stores`);
                                this.close();
                            }, 4000);
                        }),
                    );
                }),
            )
            .subscribe();
    }

    /*******************/
    /*       UI        */
    /*******************/
}
