// Angular
import { EventEmitter, Inject, Input, Component, forwardRef, Optional, Output, ChangeDetectorRef, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy } from '@angular/core';
// Material
import { MatSort } from '@angular/material/sort';
// Angular Instantsearch
import { NgAisIndex, TypedBaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
// Instantsearch
import { connectHitsWithInsights } from 'instantsearch.js/es/connectors';
import { HitsConnectorParams, HitsWidgetDescription, HitsRenderState } from 'instantsearch.js/es/connectors/hits/connectHits';
// Store
import { ProductModel } from '@store/index';
// Core
import { CustomDataTable } from '@core/_base/crud';
// RXJS
import { BehaviorSubject, Subscription } from 'rxjs';
// Typesense
import { COLLECTION_REPOSITORY } from '../typesense-client';
import { TypesenseSortService } from '../sort/sort.service';
import { ScrollableDirective } from '@core/_base/layout';

@Component({
    selector: 'typesense-hits-products-list',
    templateUrl: 'hits-products-list.component.html',
    styleUrls: ['hits-products-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TypesenseHitsProductsListComponent extends TypedBaseWidget<HitsWidgetDescription, HitsConnectorParams> implements OnInit, OnDestroy {
    @ViewChild(ScrollableDirective) scrollDirective: ScrollableDirective;

    @Input() displayActionButton = true;
    @Input() displaySelection = true;

    @Input() escapeHTML?: HitsConnectorParams['escapeHTML'];
    @Input() transformItems?: HitsConnectorParams['transformItems'];

    @Input() set allowDragAndDrop(value: boolean) {
        this._allowDragAndDrop = value;
        this.loadColumns();
    }

    @Input() set loading(value: boolean) {
        this.loading$.next(value);
    }

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.listenOnSortChange(ms);
    }

    @Output() deleteProducts = new EventEmitter();
    @Output() dragItem = new EventEmitter();
    @Output() clickProduct = new EventEmitter<ProductModel>();
    @Output() selectAll = new EventEmitter();
    @Output() selectProduct = new EventEmitter<ProductModel>();

    // Datatable
    @ViewChild('tableLoader') set tableLoader(tableLoader: ElementRef) {
        this.dataTable.tableLoader = tableLoader;
    }
    public columnDefinitions: any[] = [];
    public dataTable: CustomDataTable;
    data$ = new BehaviorSubject([]);

    _allowDragAndDrop = false;
    itemsSort = [];
    hasItems = false;
    loading$ = new BehaviorSubject(false);
    nbDragItems = 0;
    subs: Subscription;

    public state: HitsRenderState = {
        hits: [],
        results: undefined,
        bindEvent: undefined,
        sendEvent: undefined,
    };

    //prettier-ignore
    constructor(
        @Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
        private cdr: ChangeDetectorRef,
        private sortService: TypesenseSortService,
    )
    {
        super('Hits');

        this.initSort();

    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.createWidget(connectHitsWithInsights, {
            escapeHTML: this.escapeHTML,
            transformItems: this.transformItems,
        });

        super.ngOnInit();

        this.loadColumns();

        this.initDataTable();
    }

    ngOnDestroy(): void {
        this.sortService.reset();
        this.subs?.unsubscribe();
    }

    /***************/
    /*  DATATABLE  */
    /***************/
    getDisplayedColumns(): string[] {
        return this.columnDefinitions.filter((cd) => !cd.hide).map((cd) => cd.def);
    }

    initDataTable(): void {
        this.dataTable = new CustomDataTable(this.data$, null, this.loading$, null);
        this.dataTable.setPageSize(100);
    }

    loadColumns(): void {
        // prettier-ignore
        this.columnDefinitions = [
            { def: 'id', hide: !this._allowDragAndDrop },
            { def: 'select', hide: !this.displaySelection },
            { def: 'photo' },
            { def: 'reference' },
            { def: 'name' },
            { def: 'price' },
        ];
    }

    listenOnSortChange(matSort: MatSort) {
        const defaultSort = 'reference_asc';
        if (matSort) {
            this.subs = matSort.sortChange.subscribe((sort) => {
                const _sort = sort ? `${sort.active}_${sort.direction}` : defaultSort;
                this.sortService.setsortValue$(_sort);
            });
        } else {
            this.sortService.setsortValue$(defaultSort);
        }
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    drag(event: any, item: ProductModel): boolean {
        this.dragItem.emit(true);

        const selection: ProductModel[] = this.dataTable.selection.selected;
        if (selection.length > 0) {
            this.nbDragItems = selection.length;
            event.dataTransfer.setData('dragProducts', JSON.stringify(selection.map((el) => el.id)));
        } else {
            this.nbDragItems = 1;
            event.dataTransfer.setData('dragProducts', JSON.stringify(item.id));
        }

        // Change ghost image
        const ghostElement = document.getElementById('drag-message');
        ghostElement.style.opacity = '1';
        event.dataTransfer.setDragImage(ghostElement, -10, -10);

        return true;
    }

    dragEnd(event: DragEvent) {
        if (event.dataTransfer.dropEffect !== 'none') {
            this.dataTable.clearSelection();
        }
    }

    handlerDeleteProduct(event: any, item: ProductModel): void {
        event.stopPropagation();
        this.deleteProducts.emit(item);
    }

    handlerDeleteSelectedProducts(event: any): void {
        event.stopPropagation();
        this.deleteProducts.emit(this.dataTable.getSelectedProducts());
    }

    handlerClickProduct(item: ProductModel): void {
        this.clickProduct.emit(item);
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    initSort() {
        this.itemsSort = [
            { label: 'name_asc', value: `${COLLECTION_REPOSITORY}/sort/name:asc` },
            { label: 'name_desc', value: `${COLLECTION_REPOSITORY}/sort/name:desc` },
            { label: 'price_asc', value: `${COLLECTION_REPOSITORY}/sort/price:asc` },
            { label: 'price_desc', value: `${COLLECTION_REPOSITORY}/sort/price:desc` },
            { label: 'reference_asc', value: `${COLLECTION_REPOSITORY}/sort/referenceSupplier:asc` },
            { label: 'reference_desc', value: `${COLLECTION_REPOSITORY}/sort/referenceSupplier:desc` },
        ];
    }

    updateState = (state: HitsRenderState, isFirstRendering: boolean): void => {
        if (isFirstRendering) return;
        this.state = state;
        this.data$.next(state.hits);
        this.hasItems = state.hits.length > 0;
        this.dataTable.checkAllSelected();
        this.cdr.markForCheck();
    };

    /*******************/
    /*    SELECTION    */
    /*******************/
    isAllSelected(): boolean {
        return this.dataTable.isAllSelected;
    }

    isItemsSelected(): boolean {
        return this.dataTable?.nbItemsSelected() > 0;
    }

    isSelected(item: ProductModel) {
        return this.dataTable.selection.isSelected(item);
    }

    selectRow(item: ProductModel) {
        this.dataTable.selectItem(item);
        this.selectProduct.emit(item);
    }

    selectAllDisplayed() {
        this.dataTable.masterToggle();
        this.selectAll.emit(this.dataTable.selection);
    }

    /*******************/
    /*       UI        */
    /*******************/
}
