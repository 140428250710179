import { BaseModel } from '@core/_base/crud';
import { pickBy } from 'lodash';

export class UserModel extends BaseModel {
    id: string;
    firstname: string;
    lastname: string;
    displayName: string;
    email: string;
    phone: string;
    companyId: string;
    companyName: string;
    isAdmin?: string;
    roles: string[];
    dataAccess: string[];

    constructor(obj?: any) {
        super();
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }
    }

    getActivesRoles(): string[] {
        return this.roles?.filter((el) => el !== 'inactive') || [];
    }

    hasAdminRole(): boolean {
        return this.roles?.includes('admin');
    }
}
