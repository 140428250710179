// Angular
import { NgModule } from '@angular/core';
// Inline SVG
import { InlineSVGModule } from 'ng-inline-svg';
// Dropzone
import { NgxDropzoneModule } from 'ngx-dropzone';
// Mask module
import { NgxMaskModule } from 'ngx-mask';
// Perfect
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Loading bar
import { LoadingBarModule } from '@ngx-loading-bar/core';
// Infinite scroll
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
//Timepicker
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

//prettier-ignore
@NgModule({
    exports: [
        //Inline SVG
        InlineSVGModule,

        // NGX Module
        NgxDropzoneModule,
		NgxMaterialTimepickerModule,
        NgxMaskModule,

        // Other
        InfiniteScrollModule,
        LoadingBarModule,
        PerfectScrollbarModule,
    ],
})
export class NgxModule {}
