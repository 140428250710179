import { pickBy } from 'lodash';
import { BaseModel } from '@core/_base/crud';
import { ProductModel } from '@store/products/product.model';
import { SupplierModel } from '@store/suppliers/supplier.model';
import moment from 'moment';

const TVA_5_5 = 5.5;
const TVA_10 = 10;
const TVA_20 = 20;

export enum QuoteStatus {
    QuoteCart = 0,
    New = 1,
    View = 2,
    Proposal = 3,
    Accepted = 4,
    AcceptedAndCart = 41,
    Refused = 5,
    Canceled = 6,
    Archived = 7,
    NewProposal = 8,
}

export class QuoteModel extends BaseModel {
    id: string;
    amountTotalHT: number;
    clickAndCollect: boolean;
    commentCustomer: string;
    commentSupplier: string;
    customer: {
        id: string;
        displayName: string;
        email: string;
        phone?: string;
        companyId: string;
        companyName: string;
        companyAddress: string;
        siret: string;
        tenantName?: string;
    };
    deliveryAsap: boolean;
    deliveryAddress: string;
    deliveryDate: Date | string;
    deliveryPlanned: Date | string;
    nbProducts: number;
    quoteLines: QuoteLineModel[] = [];
    shippingCost?: number;
    status: number;
    supplier: SupplierModel;
    validateDate: Date;

    //Calculate field
    tva5_5? = 0;
    tva10? = 0;
    tva20? = 0;

    constructor(obj?: any) {
        super();

        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);

            if (obj.deliveryDate) {
                this.deliveryDate = obj.deliveryDate instanceof Date ? obj.deliveryDate : obj.deliveryDate.toDate();
            }

            if (obj.deliveryPlanned) {
                this.deliveryPlanned = obj.deliveryPlanned instanceof Date ? obj.deliveryPlanned : obj.deliveryPlanned.toDate();
            }

            if (obj.supplier) {
                this.supplier = new SupplierModel(obj.supplier);
            }

            if (obj.quoteLines) {
                this.quoteLines = obj.quoteLines.map((ql) => new QuoteLineModel(ql));
                this.calculateTVA();
                this.setShippingCost();
            }
        }
    }

    /***********************/
    /*   CLASS  FUNCTIONS  */
    /***********************/
    calculateAndSetAmountTotal(): void {
        this.amountTotalHT = 0;
        this.nbProducts = 0;

        this.quoteLines.map((quoteLine) => {
            this.nbProducts++;
            quoteLine.total = quoteLine.quantity * quoteLine.product.getPrice();
            this.amountTotalHT += quoteLine.total;
        });

        this.amountTotalHT = Math.round(this.amountTotalHT * 100) / 100;
    }

    calculateTVA() {
        this.tva5_5 = 0;
        this.tva10 = 0;
        this.tva20 = 0;

        this.quoteLines.map((ol) => {
            if (ol.product.taxes === TVA_5_5) {
                this.tva5_5 += ol.product?.getAmountTVA() * ol.quantity;
            }
            if (ol.product.taxes === TVA_10) {
                this.tva10 += ol.product?.getAmountTVA() * ol.quantity;
            }
            if (ol.product.taxes === TVA_20) {
                this.tva20 += ol.product?.getAmountTVA() * ol.quantity;
            }
        });
    }

    getFormatQuoteDate(): string {
        return moment(this._createdDate).format('DD MMM YYYY à HH:mm');
    }

    getFormatDeliveryDate(): string {
        return moment(this.deliveryDate).format('DD MMM YYYY');
    }

    getFormatOrderId() {
        const id = this.id.toUpperCase();
        return [id.slice(0, 6), id.slice(6, 12), id.slice(12)].join('-');
    }

    /** Return object with only available search properties */
    getObjectToSearch(): any {
        const obj = {
            customerName: this.customer?.displayName,
            customerCompany: this.customer?.companyName,
            status: this.status,
        };
        return obj;
    }

    getTotalHT(): number {
        const total = this.quoteLines.reduce((p, ol) => (p += ol.quantity * ol.product.getPrice()), 0);
        return Math.round(total * 100) / 100;
    }

    getTotalTTC(): number {
        const total = this.getTotalHT() + this.tva5_5 + this.tva10 + this.tva20;
        return Math.round(total * 100) / 100;
    }

    getTotalTTCToPay(): number {
        return this.isShippingCost() ? this.getTotalTTC() + this.supplier?.saleTerms?.shippingCost : this.getTotalTTC();
    }

    getTVA(rate: string): number {
        switch (rate) {
            case '5.5':
                return this.tva5_5;
            case '10':
                return this.tva10;
            case '20':
                return this.tva20;
            default:
                return 0;
        }
    }

    getFormatQuoteId() {
        const id = this.id.toUpperCase();
        return [id.slice(0, 6), id.slice(6, 12), id.slice(12)].join('-');
    }

    isShippingCost(): boolean {
        return this.getTotalHT() <= this.supplier?.saleTerms?.carriageFree && !this.clickAndCollect;
    }

    setShippingCost(): void {
        this.shippingCost = this.isShippingCost() ? this.supplier.saleTerms.shippingCost : 0;
    }
}

export class QuoteLineModel {
    id?: string;
    priceQuote: number;
    product: ProductModel;
    quantity: number;
    total: number;

    constructor(obj: any) {
        if (obj) {
            this.id = obj.product.id;

            this.product = new ProductModel();
            this.product.ean = obj.product.ean || null;
            this.product.ecotax = obj.product.ecotax || 0;
            this.product.id = obj.product.id;
            this.product.maker = obj.product.maker || null;
            this.product.name = obj.product.name;
            this.product.photo = [...obj.product.photo];
            this.product.price = obj.product.price;
            this.product.priceDiscount = obj.product.priceDiscount || 0;
            this.product.provider = obj.product.provider || null;
            this.product.referenceMaker = obj.product.referenceMaker || null;
            this.product.referenceProvider = obj.product.referenceProvider || null;
            this.product.referenceSupplier = obj.product.reference || obj.product.referenceSupplier;
            this.product.size = obj.product.size || null;
            this.product.taxes = obj.product.taxes;

            this.quantity = +obj.quantity;
            this.priceQuote = this.product.price + this.product.ecotax;
            this.total = this.calculateTotal();
        }
    }

    calculateTotal() {
        return Math.round(this.quantity * this.product.getPrice() * 100) / 100;
    }
}
