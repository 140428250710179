// Angular
import { Component } from '@angular/core';
// Core
import { AuthActions } from '@core/auth/store/_actions';
import { AppState } from '@core/reducers';
// NGRX
import { Store } from '@ngrx/store';

@Component({
    selector: 'inactive-account',
    templateUrl: './inactive-account.component.html',
})
export class InactiveAccountComponent {
    constructor(private store: Store<AppState>) {}

    /***************/
    /*   ACTIONS   */
    /***************/
    logout() {
        this.store.dispatch(new AuthActions.Logout());
    }
}
