// Angular
import { Injectable } from '@angular/core';
// RXJS
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class TitlePageService {
    private _backButtonRoute$: BehaviorSubject<string> = new BehaviorSubject<any>(false);
    private _image$: BehaviorSubject<string | null> = new BehaviorSubject<any>(null);
    private _titlePage$: BehaviorSubject<string | null> = new BehaviorSubject<any>(null);
    private _showBackButton$: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    getBackButtonRoute(): Observable<string> {
        return this._backButtonRoute$;
    }

    getImage(): Observable<string | null> {
        return this._image$;
    }

    getShowBackButton(): Observable<boolean> {
        return this._showBackButton$;
    }

    getTitlePage(): Observable<string | null> {
        return this._titlePage$;
    }

    setBackButtonRoute(url: string) {
        this._backButtonRoute$.next(url);
    }

    setImage(url: string) {
        this._image$.next(url);
    }

    setTitlePage(title: string) {
        this._titlePage$.next(title);
    }

    showBackButton(show: boolean) {
        this._showBackButton$.next(show);
    }
}
