// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RXJS
import { filter } from 'rxjs/operators';
// NGRX
import { Store } from '@ngrx/store';
// Core
import { AuthService, AuthActions } from '@core/auth';
import { MixpanelService, UnsubscribeOnDestroy } from '@core/services';
import { AppState } from '@core/reducers';
import { getAuthStatus } from '@core/auth/store/_selectors/auth.selectors';
import { AuthNoticeService } from '@core/auth/store/auth-notice/auth-notice.service';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { ParamsService } from '@store/index';

@Component({
    selector: 'kt-register',
    templateUrl: './register.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    emailSend = false;
    error = false;
    loading = false;
    registerForm: FormGroup;
    termsOfServices: string;

    //prettier-ignore
    constructor(
        private authService: AuthService,
        public authNoticeService: AuthNoticeService,
        private fb: FormBuilder,
        private mixpanelService: MixpanelService,
        private paramsService: ParamsService,
        private store: Store<AppState>,
        private translateService: TranslateService) {
        super();


    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.termsOfServices = this.paramsService.getParams()?.termsOfServiceUrl;

        this.initRegistrationForm();
    }

    ngOnDestroy(): void {
        this.loading = false;
        this.authNoticeService.setNotice(null);
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    goBack(): void {
        window.history.back();
    }

    submit(): void {
        const controls = this.registerForm.controls;
        // Check form
        if (this.registerForm.invalid) {
            Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
            return;
        }

        this.loading = true;

        // Get form data
        const payload = {
            email: controls.email.value,
            tenantName: this.paramsService.getTenantName(),
        };

        // Call action to send register link
        this.store.dispatch(new AuthActions.SendRegisterLink(payload));

        // Get result of action
        this.subs.sink = this.store
            .select(getAuthStatus)
            .pipe(filter((status) => !status.loading))
            .subscribe((status) => {
                if (status.success) {
                    const message = this.translateService.instant('AUTH.REGISTER.EMAIL_SEND');
                    this.authNoticeService.setNotice(message, 'success');
                    localStorage.setItem('emailForSignIn', payload.email);
                    this.mixpanelService.track('Register', { email: payload.email });
                } else {
                    const message = this.translateService.instant(this.authService.getFireErrorMessage(status.error?.code));
                    this.authNoticeService.setNotice(message, 'danger');
                }
                this.loading = false;
            });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    initRegistrationForm(): void {
        this.registerForm = this.fb.group({
            email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(320)])],
            agree: ['', Validators.requiredTrue],
        });
    }
}
