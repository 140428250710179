// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
// RXJS
import { Observable } from 'rxjs';
import { map, tap, filter, first } from 'rxjs/operators';
// Store
import { CompanyDataService } from './company-data.service';
// Model
import { CompanyModel, CompanyScoringModel } from './company.model';
import { CustomerTenantModel } from '../_models/customer-tenant.model';
// Leaflet
import { LatLng } from 'leaflet';

const CRID_LOAD = 'CRID_COMPANY';
@Injectable({
    providedIn: 'root',
})
export class CompanyEntityService extends EntityCollectionServiceBase<CompanyModel> {
    private _correlationIndex = 0;

    //prettier-ignore
    constructor(
        private companyDataService: CompanyDataService,
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('Company', serviceElementsFactory);
    }

    /******************/
    /**    Actions    */
    /******************/
    getCompanyBySiret(siret: string): Observable<CompanyModel> {
        return this.companyDataService.getCompanyBySiret(siret).pipe(map((company) => new CompanyModel(company)));
    }

    getCompanyLocation(company: CompanyModel): Promise<LatLng> {
        return this.companyDataService.getCompanyLocation(company).pipe(first()).toPromise();
    }

    getCompanyScoring(siret: string): Observable<CompanyScoringModel> {
        return this.companyDataService.getCompanyScoring(siret);
    }

    getCustomerTenantInformations(companyId: string) {
        return this.companyDataService.getCustomersTenant(companyId);
    }

    loadById(id: string): Observable<any> {
        return this.getByKey(id, { correlationId: this.getCorrelationId('loadById') }).pipe(tap(() => this.setLoaded(true)));
    }

    updateCustomersTenantInformations(customerTenant: CustomerTenantModel): Observable<any> {
        return this.companyDataService.updateCustomersTenant(customerTenant);
    }

    uploadLogo(companyId: string, logo: File): Promise<any> {
        return this.companyDataService.uploadLogo(companyId, logo).pipe().toPromise();
    }

    /******************/
    /**   Selectors   */
    /******************/
    getCompany(): Observable<CompanyModel> {
        return this.entities$.pipe(map((entities) => new CompanyModel(entities[0])));
    }

    selectCompanyLocation(): Observable<LatLng> {
        return this.getCompany().pipe(
            filter((company) => company.id !== undefined),
            map((company) => company.address.location),
        );
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }
}
