/** DEV */
export const environment = {
    production: false,
    version: 'DEV - v1.4.3',
    apiBaseUrl: 'https://api-dev.stoick.io',
    firebaseConfig: {
        apiKey: 'AIzaSyAckZPALKPZUU8LxVKCNhZJ9T53haddNAk',
        authDomain: 'stoick-dev.firebaseapp.com',
        databaseURL: 'https://stoick-dev.firebaseio.com',
        projectId: 'stoick-dev',
        storageBucket: 'stoick-dev.appspot.com',
        messagingSenderId: '101354749953',
        appId: '1:101354749953:web:b56513eac1947e07064000',
        measurementId: 'G-JD3EZETGY7',
    },
    mixPanelToken: 'ca694fcfe55cff1eea5b04afff111d46',
    typesenseConfig: {
        apiKey: 'stoick@2020',
        host: 'typesense-dev.stoick.io',
        port: '',
        protocol: 'https',
    },
    gtmId: '',
};
