// Angular
import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'filter-inactive',
    templateUrl: './filter-inactive.component.html',
})
export class FilterInactiveComponent {
    @Input() label: string;

    @Output() filterInactive = new EventEmitter();

    isFilterInactive = false;

    onFilterInactive() {
        this.isFilterInactive = !this.isFilterInactive;
        this.filterInactive.next(this.isFilterInactive);
    }
}
