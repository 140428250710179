// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns only first letter of string
 */
@Pipe({
    name: 'capitalizeFirstLetter',
})
export class CapitalizeFirstLetterPipe implements PipeTransform {
    /**
     * Transform
     *
     * @param value: any
     * @param args: any
     */
    transform(value: string): string {
        const first = value.substring(0, 1).toUpperCase();
        return first + value.substring(1).toLowerCase();
    }
}
