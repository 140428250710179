// NGRX
import { createSelector } from '@ngrx/store';
import { EntitySelectorsFactory } from '@ngrx/data';
// Lodash
import { each, find, some } from 'lodash';
// Core store
import { getAuthState } from './auth.selectors';
// Store
import { RoleModel } from '@store/roles/role.model';
import { PermissionModel } from '@store/permissions/permission.model';

export const selectAuthState = (state) => state.auth;

export const rolesSelectors = new EntitySelectorsFactory().create<RoleModel>('Roles');
export const permissionsSelectors = new EntitySelectorsFactory().create<PermissionModel>('Permissions');

export const currentUserRoleIds = createSelector(getAuthState, (state) => {
    if (!state?.auth.loggedIn) {
        return null;
    }
    return state.auth.user.roles;
});

export const currentUserPermissionsIds = createSelector(currentUserRoleIds, rolesSelectors.selectEntities, (userRoleIds: string[], allRoles: RoleModel[]) => {
    const result = getPermissionsIdsFrom(userRoleIds, allRoles);
    return result;
});

export const isAdmin = createSelector(currentUserRoleIds, (userRoleIds: string[]) => {
    return userRoleIds?.includes('admin');
});

export const isBuyer = createSelector(currentUserRoleIds, (userRoleIds: string[]) => {
    return userRoleIds?.includes('buyer');
});

export const isCustomer = createSelector(currentUserRoleIds, (userRoleIds: string[]) => {
    return userRoleIds?.includes('customer') || userRoleIds?.includes('customerWhiteLabel') || userRoleIds?.includes('customerWithoutMP');
});

export const isCustomerWithoutMP = createSelector(currentUserRoleIds, (userRoleIds: string[]) => {
    return userRoleIds?.includes('customerWithoutMP');
});

export const isCustomerWhiteLabel = createSelector(currentUserRoleIds, (userRoleIds: string[]) => {
    return userRoleIds?.includes('customerWhiteLabel');
});

export const isDemo = createSelector(currentUserRoleIds, (userRoleIds: string[]) => {
    return userRoleIds?.includes('demo');
});

export const isPremium = createSelector(currentUserRoleIds, (userRoleIds: string[]) => {
    return userRoleIds?.includes('premium');
});

export const isStoick = createSelector(currentUserRoleIds, (userRoleIds: string[]) => {
    return userRoleIds?.includes('stoick');
});

export const isSupplier = createSelector(currentUserRoleIds, (userRoleIds: string[]) => {
    return userRoleIds?.includes('supplier');
});

export const isUser = createSelector(currentUserRoleIds, (userRoleIds: string[]) => {
    return userRoleIds?.includes('user');
});

export const checkHasUserPermission = (permissionId: string) =>
    createSelector(currentUserPermissionsIds, (ids: string[]) => {
        return ids.some((id) => id === permissionId);
    });

export const currentUserPermissions = createSelector(
    currentUserPermissionsIds,
    permissionsSelectors.selectEntities,
    //selectAllPermissions,
    (permissionIds: string[], allPermissions: PermissionModel[]) => {
        const result: PermissionModel[] = [];
        each(permissionIds, (id) => {
            const userPermission = find(allPermissions, (elem) => elem.id === id);
            if (userPermission) {
                result.push(userPermission);
            }
        });
        return result;
    },
);

function getPermissionsIdsFrom(userRolesIds: string[] = [], allRoles: RoleModel[] = []): string[] {
    const userRoles: RoleModel[] = [];
    each(userRolesIds, (_id: string) => {
        const userRole = find(allRoles, (_role: RoleModel) => _role.id === _id);
        if (userRole) {
            userRoles.push(userRole);
        }
    });

    const result: string[] = [];
    each(userRoles, (_role: RoleModel) => {
        each(_role.permissions, (id) => {
            if (!some(result, (_id) => _id === id)) {
                result.push(id);
            }
        });
    });
    return result;
}
