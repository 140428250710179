/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */

// Angular
import { Component, ChangeDetectionStrategy, forwardRef, Inject, Optional, OnInit, ChangeDetectorRef } from '@angular/core';
// Angular Instantsearch
import { TypedBaseWidget, NgAisInstantSearch, NgAisIndex } from 'angular-instantsearch';
import { Connector } from 'instantsearch.js/es/types';

type RefreshWidgetDescription = {
    $$type: 'custom.refresh';
    renderState: {
        refresh(): void;
    };
};
type RefreshConnectorParams = {};

type RefreshConnector = Connector<RefreshWidgetDescription, RefreshConnectorParams>;

const connectRefresh: RefreshConnector = (renderFn, unmountFn) => (widgetParams) => ({
    $$type: 'custom.refresh',
    init: function ({ instantSearchInstance }) {
        renderFn(
            {
                refresh() {},
                widgetParams,
                instantSearchInstance,
            },
            true,
        );
    },
    render({ instantSearchInstance }) {
        const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);
        renderFn(
            {
                refresh,
                widgetParams,
                instantSearchInstance,
            },
            false,
        );
    },
    dispose() {
        if (unmountFn) {
            unmountFn();
        }
    },
});
@Component({
    selector: 'typesense-refresh',
    templateUrl: './refresh.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypesenseRefreshComponent extends TypedBaseWidget<RefreshWidgetDescription, RefreshConnectorParams> implements OnInit {
    loading = false;
    state: RefreshWidgetDescription['renderState'] = { refresh(): void {} };

    // Rendering options
    constructor(
        private cdr: ChangeDetectorRef,
        @Inject(forwardRef(() => NgAisIndex))
        @Optional()
        public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch))
        public instantSearchInstance: NgAisInstantSearch,
    ) {
        super('Refresh');
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit() {
        this.createWidget(connectRefresh, {});
        super.ngOnInit();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    refresh(): void {
        this.loading = true;
        this.cdr.markForCheck();

        this.state.refresh();

        setTimeout(() => {
            this.loading = false;
            this.cdr.markForCheck();
        }, 500);
    }
}
