// Angular
import { Injectable } from '@angular/core';
// Core
import { AuthUserModel } from '@core/auth';
import { FirestoreService } from '@core/services';
// Store
import { FS_PATH_ORDERS, FS_PATH_QUOTES, FS_PATH_USERS } from '../firestore-collections';
import { OrderModel } from '../orders/order.model';
import { QuoteModel } from '../quotes/quote.model';
// RXJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    constructor(private firestoreService: FirestoreService) {}

    getUsers(): Observable<AuthUserModel[]> {
        return this.firestoreService.collection$(FS_PATH_USERS).pipe(map((users) => users.map((user: AuthUserModel) => new AuthUserModel(user))));
    }

    getOrders(): Observable<OrderModel[]> {
        return this.firestoreService.collection$(FS_PATH_ORDERS).pipe(map((orders) => orders.map((order: OrderModel) => new OrderModel(order))));
    }

    getQuotes(): Observable<QuoteModel[]> {
        return this.firestoreService.collection$(FS_PATH_QUOTES).pipe(map((quotes) => quotes.map((quote: QuoteModel) => new QuoteModel(quote))));
    }
}
