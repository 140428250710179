// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
// RXJS
import { BehaviorSubject, Observable } from 'rxjs';
// AngularFire
import { AngularFireAnalytics } from '@angular/fire/analytics';
// Environment
import { environment } from '@env/environment';

//eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;
@Injectable({
    providedIn: 'root',
})
export class UrlService {
    private _backUrl: string;
    private _currentUrl: string;
    private _currentUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _previousUrl: string;
    private _previousUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    //prettier-ignore
    constructor(
        private analytics: AngularFireAnalytics,
        private router: Router
    ) {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                // To be sure to stay on whiteLabel if navigation url is "/"
                if (sessionStorage.getItem('isWhiteLabel') && event.url === '/') {
                    const homeUrl = `${this.getBaseUrl()}/home`;
                    this.router.navigateByUrl(homeUrl);
                }
            }

            if (event instanceof NavigationEnd) {
                this._setPreviousUrl();
                this._setCurrentUrl(event.url);
                this.analytics.setCurrentScreen(event.url);

                if (environment.production) {
                    this.registerGoogleTagManegerPageEvent(event.urlAfterRedirects);
                }
            }
        });
    }

    getCurrentUrl(): string {
        return this._currentUrl;
    }

    getCurrentUrl$(): Observable<string> {
        return this._currentUrl$.asObservable();
    }

    getPreviousUrl$(): Observable<string> {
        return this._previousUrl$.asObservable();
    }

    private _setCurrentUrl(url: string) {
        this._currentUrl = url;
        this._currentUrl$.next(this._currentUrl);
    }

    private _setPreviousUrl() {
        this._previousUrl = this._currentUrl;
        this._previousUrl$.next(this._previousUrl);
    }

    getBaseUrl(url?: string) {
        const urlSegments = (url || this._currentUrl)?.split('/');
        return urlSegments?.length > 1 ? urlSegments[1] : null;
    }

    goBack() {
        if (this._previousUrl) {
            this.router.navigateByUrl(this._previousUrl);
        } else {
            if (this._backUrl) {
                this.router.navigateByUrl(this._backUrl);
                this.setBackUrl(null);
            } else {
                window.history.back();
            }
        }
    }

    setBackUrl(backUrl?: string) {
        this._backUrl = backUrl;
    }

    registerGoogleTagManegerPageEvent(url): void {
        gtag('config', environment.gtmId, {
            page_path: url,
        });
    }
}
