// Angular
import { Component, OnDestroy, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Store
import { ContactModel } from '@store/index';
// Core
import { UnsubscribeOnDestroy } from '@core/services';

@Component({
    selector: 'contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent extends UnsubscribeOnDestroy implements OnDestroy {
    @Input() set contact(value: ContactModel) {
        this._contact = value;
        if (this._contact) {
            this.setFormValue();
        }
    }
    @Input() set disabled(value: boolean) {
        if (value) {
            this.setFormDisabled();
        }
    }

    @Input() typeView: 'row' | 'column' = 'column';

    @Output() dataChange = new EventEmitter();

    _contact: ContactModel;
    form: FormGroup;

    //prettier-ignore
    constructor(
        private fb: FormBuilder
    )
    {
        super();

        this.initForm();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    initForm(): void {
        this.form = this.fb.group({
            firstname: null,
            email: [null, Validators.compose([Validators.email, Validators.minLength(3), Validators.maxLength(320)])],
            lastname: null,
            phone: [null, Validators.minLength(10)],
        });

        this.subs.sink = this.form.valueChanges.subscribe(() => this.dataChange.emit(true));
    }

    isInvalid(): boolean {
        return this.form.invalid;
    }

    prepareData(): ContactModel {
        const controls = this.form.controls;
        const _contact = new ContactModel({ id: this._contact?.id });

        _contact.firstname = controls.firstname.value?.trim() || null;
        _contact.email = controls.email.value?.trim() || null;
        _contact.lastname = controls.lastname.value?.trim() || null;
        _contact.phone = controls.phone.value || null;

        return _contact;
    }

    setFormDisabled(): void {
        this.form.controls.firstname.disable();
        this.form.controls.lastname.disable();
        this.form.controls.email.disable();
    }

    setFormValue(): void {
        this.form.controls.firstname.setValue(this._contact.firstname);
        this.form.controls.lastname.setValue(this._contact.lastname);
        this.form.controls.email.setValue(this._contact.email);
        this.form.controls.phone.setValue(this._contact.phone);

        // Set dataChange on false when data are initialized
        this.dataChange.emit(false);
    }
}
