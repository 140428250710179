// Angular
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
// Store
import { Universe, UniversesService } from '@store/index';
// RXJS
import { fromEvent } from 'rxjs';

@Component({
    selector: 'store-universes',
    templateUrl: './store-universes.component.html',
    styleUrls: ['./store-universes.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StoreUniversesComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    @Output() handleSearch = new EventEmitter();
    @Output() handleSelectUniverse = new EventEmitter();

    universes: Universe[];

    //prettier-ignore
    constructor(
        private cdr: ChangeDetectorRef, 
        private universesService: UniversesService,
    ) {
      super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.getUniverses();

        this.onSearchInputChange();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*****************/
    /*    ACTIONS    */
    /*****************/
    clear(): void {
        this.searchInput.nativeElement.value = '';
    }

    search(): void {
        this.handleSearch.emit(this.searchInput.nativeElement.value);
    }

    selectUniverse(universeId: string) {
        this.handleSelectUniverse.emit(universeId);
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    getUniverses(): void {
        this.universes = this.universesService.getUniversesActive();
    }

    onSearchInputChange(): void {
        this.subs.sink = fromEvent(this.searchInput.nativeElement, 'keyup').subscribe(() => this.cdr.markForCheck());
    }

    /************/
    /*    UI    */
    /************/
    isValue(): boolean {
        return this.searchInput.nativeElement.value !== '';
    }
}
