// Angular
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
// RXJS
import { first } from 'rxjs/operators';
// Core
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
import { UnsubscribeOnDestroy } from '@core/services';
// Store
import { CompanyEntityService, CompanyModel } from '@store/index';
// Translate
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'company-form',
    templateUrl: './company-form.component.html',
})
export class CompanyFormComponent extends UnsubscribeOnDestroy {
    @Input() form: FormGroup;

    @Output() accountExpired = new EventEmitter();
    @Output() selectLogo = new EventEmitter();

    company: CompanyModel = new CompanyModel();
    disabledLogo: boolean;
    filesUpload: File[] = [];
    loading = false;
    logo: string = null;

    //prettier-ignore
    constructor(
        private cdr: ChangeDetectorRef,
        private companyService: CompanyEntityService,
        private layoutUtilsService: LayoutUtilsService,
        private translateService: TranslateService)
    {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/

    /***************/
    /*   ACTIONS   */
    /***************/
    loadCompany(): void {
        const siretInput = this.form.controls.siret;

        if (siretInput.value.length === 14) {
            this.loading = true;
            siretInput.disable();
            this.companyService
                .getCompanyBySiret(siretInput.value)
                .pipe(first())
                .subscribe(
                    (company) => {
                        this.setCompany(company);

                        // For now, block register if the company already exist
                        if (this.company.id) {
                            this.disableControls();
                            const message = this.translateService.instant('AUTH.WELCOME.COMPANY_EXIST');
                            this.layoutUtilsService.showActionNotification(message, null, 5000, true);
                        }
                        siretInput.enable();
                        this.loading = false;
                        this.cdr.detectChanges();
                    },
                    (error) => {
                        if (error === 401) {
                            const message = this.translateService.instant('AUTH.REGISTER.ACCOUNT_EXPIRED');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 5000, true, false);
                            this.accountExpired.emit();
                        } else {
                            const message = this.translateService.instant('GENERAL.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 5000, true, false);
                        }
                        siretInput.enable();
                        this.loading = false;
                        this.cdr.detectChanges();
                    },
                );
        }
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    disableControls(): void {
        for (const control in this.form.controls) {
            if (control != 'siret') this.form.controls[control].disable();
        }
    }

    enableControls(): void {
        for (const control in this.form.controls) {
            this.form.controls[control].enable();
        }
        this.disabledLogo = false;
    }

    isEmpty(obj: CompanyModel): boolean {
        return !Object.keys(obj).length;
    }

    uploadPhoto(event): void {
        this.filesUpload[0] = event.addedFiles[0];
        this.selectLogo.emit(this.filesUpload[0]);
        setTimeout(() => this.cdr.detectChanges(), 150);
    }

    removePhoto(): void {
        this.filesUpload = [];
        this.selectLogo.emit(null);
    }

    resetForm(): void {
        this.company = new CompanyModel();

        const controls = this.form.controls;
        controls.siret.setValue(null);
        controls.id.setValue(null);
        controls.name.setValue(null);
        controls.address1.setValue(null);
        controls.address2.setValue(null);
        controls.postalCode.setValue(null);
        controls.city.setValue(null);
    }

    setCompany(company: CompanyModel): void {
        if (this.isEmpty(company)) return;

        this.company = company;

        const controls = this.form.controls;
        controls.id.setValue(company.id || null);
        controls.name.setValue(company.name);
        controls.address1.setValue(company.address.address1);
        controls.address2.setValue(company.address.address2);
        controls.postalCode.setValue(company.address.postalCode);
        controls.city.setValue(company.address.city);
    }

    /*************/
    /*    UI     */
    /*************/
    isValue(): boolean {
        return this.form.controls.siret.value !== '';
    }

    clear(): void {
        this.resetForm();
        this.enableControls();
    }
}
