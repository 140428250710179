// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NGRX
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
// RXJS
import { combineLatest, from, Observable, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
// Core
import { FirestoreService, StorageService } from '@core/services';
import { isCustomer } from '@core/auth';
import { AppState } from '@core/reducers';
// Store
import { ProductModel } from './product.model';
import { FS_PATH_PRODUCTS, FS_TENANT_CUSTOMERS, FS_TENANT_SUPPLIERS } from '@store/firestore-collections';
// Environment
import { environment } from '@env/environment';

// API PATH
const API_PATH_PRODUCTS = `${environment.apiBaseUrl}/products`;
const API_PATH_REPOSITORY = `${environment.apiBaseUrl}/repository`;

// Storage
const STORAGE_FOLDER_PRODUCTS = 'products';

@Injectable({
    providedIn: 'root',
})
export class ProductsDataService extends DefaultDataService<any> {
    tenantPath: string;

    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private storageService: StorageService,
        private store: Store<AppState>
    ) {
        super('Products', http, httpUrlGenerator);

        this._setTenantPath();
    }

    /*********************/
    /*        API        */
    /*********************/

    /** To import category and product from Excel File */
    import(data: any): Observable<any> {
        const path = `${API_PATH_PRODUCTS}/import`;
        const params = {
            data: data,
        };
        return this.http.post(path, params);
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getAll(): Observable<ProductModel[]> {
        return this.firestoreService.collection$(FS_PATH_PRODUCTS, this.tenantPath).pipe(map((products) => products.map((p) => new ProductModel(p))));
    }

    getAllSnapshot(): Observable<ProductModel[]> {
        return this.firestoreService.collectionSnapShot(FS_PATH_PRODUCTS, this.tenantPath).pipe(map((products) => products.map((p) => new ProductModel(p))));
    }

    getById(productId: string): Observable<ProductModel> {
        const pathProduct = `${FS_PATH_PRODUCTS}/${productId}`;
        return this.firestoreService.doc$(pathProduct, this.tenantPath).pipe(map((product) => new ProductModel(product)));
    }

    productsExist(): Observable<boolean> {
        return this.firestoreService.collectionExist(FS_PATH_PRODUCTS, this.tenantPath);
    }

    add(product: ProductModel): Observable<any> {
        const path = `${FS_PATH_PRODUCTS}`;
        const _product = new ProductModel(product);
        const product$ = this._saveInFirestore(_product, path);

        return product$.pipe(switchMap((product) => this._indexInTypesenseRepository(product)));
    }

    delete(id: string): Observable<number | string> {
        const path = `${FS_PATH_PRODUCTS}/${id}`;
        const deleteTypesense$ = this._deleteInTypesenseRepository(id);
        return deleteTypesense$.pipe(switchMap(() => this.firestoreService.delete(path, this.tenantPath))).pipe(map(() => id));
    }

    deleteMany(productsId: string[], isInternalCatalog = false): Observable<any> {
        const batch = this.firestoreService.getBatch();

        productsId.forEach((productId) => {
            const pathProduct = `${FS_PATH_PRODUCTS}/${productId}`;
            const docRef = this.firestoreService.getDocRef(pathProduct, this.tenantPath);
            batch.delete(docRef);
        });

        // Don't update Typesense repository if it's in internal catalog
        if (isInternalCatalog) {
            return from(batch.commit());
        } else {
            return combineLatest([from(batch.commit()), this._deleteManyInTypesenseRepository(productsId)]);
        }
    }

    update(product: Update<ProductModel>): Observable<ProductModel> {
        const path = `${FS_PATH_PRODUCTS}/${product.id}`;
        const _product = new ProductModel(product.changes);

        const product$ = this._saveInFirestore(_product, path);

        return product$.pipe(switchMap((product) => this._indexInTypesenseRepository(product, true)));
    }

    updateMany(products: ProductModel[], isInternalCatalog = false): Observable<any> {
        const batch = this.firestoreService.getBatch();

        products.forEach((product) => {
            const pathProducts = `${FS_PATH_PRODUCTS}/${product.id}`;
            const docRef = this.firestoreService.getDocRef(pathProducts, this.tenantPath);
            const _product = this.firestoreService.setUserInfo(JSON.parse(JSON.stringify(product)), true);
            batch.set(docRef, _product, { merge: true });
        });

        // Don't update Typesense repository if it's in internal catalog
        if (isInternalCatalog) {
            return from(batch.commit());
        } else {
            return combineLatest([from(batch.commit()), this._indexManyInTypesenseRepository(products)]);
        }
    }

    updatePhotoInProductsList(file: File, product: ProductModel) {
        const path = `${FS_PATH_PRODUCTS}/${product.id}`;
        return this.saveProductsFiles(file, product.reference).pipe(
            switchMap((urlPhoto) => {
                const _product = new ProductModel();
                if (!_product.photo) {
                    _product.photo = [];
                }
                _product.photo.push(urlPhoto);
                return this.firestoreService.updateAt(path, _product, this.tenantPath);
            }),
            switchMap((product) => this._indexInTypesenseRepository(product, true)),
        );
    }

    /*********************/
    /*      STORAGE      */
    /*********************/
    deleteFileInStorage(url: string): Promise<any> {
        return this.storageService.deleteFile(url);
    }

    saveProductsFiles(file: File, filename: string): Observable<string> {
        const date = Date.now();
        const name = `${filename}_${file.name}_${date}`;
        return this.storageService.uploadFile(file, name, STORAGE_FOLDER_PRODUCTS, true);
    }

    /*********************/
    /*      TYPESENSE    */
    /*********************/
    _deleteInTypesenseRepository(productId: string): Observable<any> {
        const path = `${API_PATH_REPOSITORY}/${productId}`;
        return this.http.delete(path);
    }

    _deleteManyInTypesenseRepository(productsId: string[]): Observable<any> {
        const path = `${API_PATH_REPOSITORY}/deleteMany`;
        return this.http.post(path, { productsId });
    }

    _indexInTypesenseRepository(product: ProductModel, update = false): Observable<ProductModel> {
        return this.http.post(API_PATH_REPOSITORY, { product, update }).pipe(map(() => product));
    }

    _indexManyInTypesenseRepository(products: ProductModel[], update = false): Observable<ProductModel[]> {
        const path = `${API_PATH_REPOSITORY}/indexMany`;
        return this.http.post(path, { products, update }).pipe(map(() => products));
    }

    /************************/
    /*  PRIVATE FUNCTIONS   */
    /************************/
    _saveInFirestore(item: ProductModel, path: string): Observable<any> {
        let technicalSheet$: Observable<string | null> = of(null);
        if (item.technicalSheet instanceof File) {
            technicalSheet$ = this.saveProductsFiles(item.technicalSheet, item.reference);
        }

        return technicalSheet$.pipe(
            switchMap((technicalSheet) => {
                if (technicalSheet) item.technicalSheet = technicalSheet;

                return this.firestoreService.updateAt(path, item, this.tenantPath).pipe(map((doc) => new ProductModel({ id: doc.id, ...doc })));
            }),
        );
    }

    _setTenantPath(): void {
        this.store
            .select(isCustomer)
            .pipe(
                filter((res) => res !== undefined && res !== null),
                first(),
            )
            .subscribe((isCustomer) => {
                this.tenantPath = isCustomer ? FS_TENANT_CUSTOMERS : FS_TENANT_SUPPLIERS;
            });
    }
}
