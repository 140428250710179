// France
export const locale = {
    lang: 'fr',
    data: {
        GENERAL: {
            ACCOUNT: 'Mon compte',
            ACTIVE: 'Actif',
            ADD: 'Ajouter',
            ADD_SELECTION: 'Ajouter la sélection',
            ADDRESS: 'Adresse',
            BACK: 'Retour',
            CANCEL: 'Annuler',
            COMMENT: 'Commentaire',
            CONFIRM: 'Confirmer',
            CONGRATULATIONS: 'Félicitations',
            COPY: 'Copie',
            CREATE: 'Créer',
            CREATED_BY: 'Créé par',
            DAYS: 'Jours',
            DATA_UNSAVED: 'Modifications non enregistrées',
            DATA_UNSAVED_DESCRIPTION: "Certaines de vos modifications n'ont pas été enregistrées. \n\n Etes vous sûr de vouloir quitter la page ?",
            DATE: 'Date',
            DESCRIPTION: 'Description',
            DELETE: 'Supprimer',
            DELETE_ERROR: 'Une erreur est survenue lors de la suppression',
            DELETE_SELECTION: 'Supprimer la sélection',
            DISPLAY_ALL: 'Afficher tout',
            DUPLICATE: 'Dupliquer',
            EDIT: 'Editer',
            EMAIL: 'E-mail',
            ERASE: 'Effacer',
            ERROR: 'Une erreur est survenue. Veuillez réessayer ou contactez le support.',
            EXIT: 'Sortir',
            EXPORT: 'Exporter',
            EXPORT_PDF: 'Exporter en PDF',
            FILTER_BY: 'Filtrer par',
            FILTERS: 'Filtres',
            HELLO: 'Bonjour',
            HELP: 'Aide',
            ID: 'ID',
            INACTIVE: 'Inactif',
            INFORMATIONS: 'Informations',
            IMPORT_IN_PROGRESS: 'Import en cours ...',
            LANGUAGE: 'Langue',
            LOAD_DATA_ERROR: 'Une erreur est survenue lors du chargement des données. Essayez de recharger la page ou contactez le support si le problème persiste.',
            MORE_ACTIONS: 'Autres actions',
            MOVE_TO_CATEGORY: 'Déplacer dans une catégorie',
            NAME: 'Nom',
            NEXT: 'Suivant',
            NO: 'Non',
            NO_DATA: 'Aucune donnée à afficher',
            NO_RESULT: 'Aucun résultat',
            NONE: 'Aucun',
            NOT_SPECIFIED: 'Non renseigné',
            NUMERO: 'Numéro',
            NUMERO_SHORT: 'N°',
            OR: 'ou',
            PREVIOUS: 'Précédent',
            REFRESH_DATA: 'Rafraichir les données',
            REFUSE: 'Refuser',
            REGISTERED_SINCE: 'Inscrit depuis',
            SAVE: 'Enregistrer',
            SAVE_AND_EXIT: 'Enregistrer et quitter',
            SAVE_AND_NEW: 'Enregistrer et nouveau',
            SAVE_ERROR: "Une erreur est survenue lors de l'enregistrement. Veuillez réessayer ou contactez le support.",
            SAVE_FORGOTTEN: 'Vous devez enregistrer vos nouvelles informations',
            SAVE_SUCCESS: 'Vos nouvelles informations ont été enregistrées avec succés',
            SEARCH: 'Rechercher',
            SEARCH_CUSTOMER: 'Rechercher un client',
            SEARCH_NO_RECORD: 'Aucun résultat ne correspond à votre recherche',
            SEE: 'Voir',
            SEND: 'Envoyer',
            SUBTOTAL: 'Sous-total',
            TAGS: 'Tags',
            TO_COME_UP: 'A venir',
            TO_LOG_IN: 'Se connecter',
            TOTAL: 'Total',
            UNAUTHORIZED: "Vous n'êtes pas authorisé(e) à effectuer cette action",
            UPDATE: 'Modifier',
            UPLOAD_FILE: 'Sélectionner un fichier ou déposer la ici. \n\n Formats acceptés (jpeg, png, pdf).',
            UPLOAD_IMG: 'Sélectionner une image ou déposer la ici. \n\n Formats acceptés (jpeg, png).',
            UPLOAD_PDF: 'Sélectionner un fichier ou déposer le ici. \n\n Format accepté (pdf).',
            VALIDATE: 'Valider',
            VERSION: 'Version',
            WAIT_CANCEL: 'Annulation en cours ...',
            WAIT_DELETE: 'Suppression en cours ...',
            WAIT_EXPORT: 'Export en cours ...',
            WAIT_IN_PROGRESS: 'Traitement en cours ...',
            WAIT_LOADING: 'Chargement en cours ...',
            WAIT_SAVE: 'Enregistrement en cours ...',
            WAIT_SENDING: 'Envoi en cours ...',
            WELCOME: 'Bienvenue',
            YES: 'Oui',
            WEEKDAYS: {
                MONDAY: 'Lundi',
                MONDAY_SHORT: 'Lun',
                TUESDAY: 'Mardi',
                TUESDAY_SHORT: 'Mar',
                WEDNESDAY: 'Mercredi',
                WEDNESDAY_SHORT: 'Mer',
                THURSDAY: 'Jeudi',
                THURSDAY_SHORT: 'Jeu',
                FRIDAY: 'Vendredi',
                FRIDAY_SHORT: 'Ven',
                SATURDAY: 'Samedi',
                SATURDAY_SHORT: 'Sam',
                SUNDAY: 'Dimanche',
                SUNDAY_SHORT: 'Dim',
            },
        },
        AUTH: {
            GENERAL: {
                ACCEPT_PART_1: "J'accepte",
                ACCEPT_PART_2: "les conditions générales d'utilisation",
                ACCOUNT_TO_VALIDATE: 'Inscription à valider',
                BACK_BUTTON: 'Retour',
                CHANGE_PASSWORD_BUTTON: 'Changer le mot de passe',
                CONFIRM_EMAIL: 'Merci de confirmer votre adresse email',
                DELETE_ACCOUNT: "Supprimer l'inscription",
                FORGOT_BUTTON: 'Mot de passe oublié',
                LOGIN_IN_PROGRESS: 'Connexion en cours',
                NO_ACCOUNT: 'Pas encore de compte ?',
                REGISTER: 'Créer un compte',
                SIGNIN_BUTTON: 'Connexion',
                SIGNOUT_BUTTON: 'Déconnexion',
                SUBMIT_BUTTON: 'Soumettre',
                VALIDATE_ACCOUNT: "Valider l'inscription",
            },
            CHANGE_PASSWORD: {
                NEW_PASSWORD: 'Nouveau mot de passe',
                OLD_PASSWORD: 'Mot de passe actuel',
                SUCCESS: 'Votre mot de passe a été modifié avec succés',
                TITLE: 'Changement de mot de passe',
            },
            FIRE_ERROR: {
                ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL: "Votre compte existe déjà. Essayez de vous connecter avec un autre fournisseur d'accès (Facebook, Google ou mail).",
                ARGUMENT_ERROR: "Le lien de connexion n'est plus valide. \n Un nouveau lien vient de vous être envoyé.",
                CANCELLED_POPUP_REQUEST: 'La connexion a été annulée.',
                EMAIL_NOT_VERIFIED: 'Merci de valider votre compte pour vous connecter.',
                EMAIL_ALREADY_EXISTS: "Un compte existe déjà avec l'email renseigné",
                ERROR: 'Une erreur est survenue lors de votre connexion. Veuillez réessayer ou contacter le support',
                EMAIL_ALREADY_IN_USE: "Un compte existe déjà avec l'email renseigné",
                EXPIRED_ACTION_CODE: 'Le lien de connexion a expiré. \n Un nouveau lien vient de vous être envoyé.',
                INVALID_ACTION_CODE: "Le lien de connexion n'est plus valide. \n Un nouveau lien vient de vous être envoyé.",
                INVALID_EMAIL: 'Veuillez saisir une adresse mail valide.',
                NETWORK_REQUEST_FAILED: "Une erreur réseau s'est produite. Veuillez réessayer ou recharger la page.",
                PASSWORD_ERROR: "Le mot de passe doit être composé d'au moins six caractères et d'une combinaison de chiffres et de lettres.",
                TOO_MANY_REQUESTS: 'Trop de tentatives de connexion',
                USER_DISABLED: 'Le compte a été désactivé. Veuillez contacter le support',
                USER_NOT_FOUND: "L'adresse mail renseignée n'existe pas.",
                WRONG_PASSWORD: "Le mot de passe saisi n'est pas correct. Veuillez réessayer.",
            },
            FORGOT: {
                TITLE: 'Mot de passe oublié ?',
                RESET_PASSWORD: 'Réinitialiser le mot de passe',
                SUCCESS: 'Un email vous attend dans votre boite mail pour réinitialiser votre mot de passe',
            },
            INPUT: {
                ADDRESS: 'Adresse',
                ADDRESS2: "Complément d'adresse",
                BILLING_ADDRESS: 'Adresse de facturation',
                BILLS_MONTHLY_GROUPED: 'Regroupement des factures mensuel',
                CGV: 'Conditions générales de vente',
                CITY: 'Ville',
                COMPANY: 'Entreprise',
                COMPANY_NAME: "Nom de l'entreprise",
                DESCRIPTION: 'Description',
                DELIVERY_ADDRESS: 'Adresse de livraison',
                EMAIL: 'Adresse mail',
                EMAIL_DESC: 'Saisissez votre email',
                FIRSTNAME: 'Prénom',
                FUNCTION: 'Fonction',
                KBIS: 'KBIS',
                LASTNAME: 'Nom',
                LOGO: 'Logo',
                PASSWORD: 'Mot de passe',
                PASSWORD_DESC: 'Saisissez votre mot de passe',
                PAYMENT_CHOICE: 'Mode de paiement',
                PHONE: 'Téléphone',
                POSTAL_CODE: 'Code postal',
                SIRET: 'Numéro de SIRET',
                SIRET_SHORT: 'SIRET',
                TAGS: 'Tags',
                TAGS_ADD: 'Ajouter un tag',
            },
            REGISTER: {
                ACCOUNT_EXPIRED: "Votre compte temporaire a expiré, veuillez vous reconnecter via l'email que vous allez recevoir.",
                CREATE: 'Créer mon compte',
                CREATE_SUCCESS: 'Votre compte a bien été créé',
                EMAIL_SEND: 'Un email de connexion vous attend dans votre boite mail !',
                ERROR_PASSWORD_UPDATE: "La mise à jour de votre mot de passe a échoué car votre compte temporaire a expiré. Veuillez vous reconnecter via l'email que vous allez recevoir.",
                PASSWORD_UPDATED: 'Mot de passe mis à jour',
                TITLE: "Tout d'abord, nous avons besoin de votre adresse e-mail. Celle-ci vous permettra d’accéder à votre compte.",
                VALIDATE_PROGRESS: "Votre compte est en attente de validation par l'administrateur de votre entreprise.",
            },
            VALIDATION: {
                EMAIL_INVALID: "L'adresse mail n'est pas valide",
                INVALID_FIELD: "Le champ n'est pas valide",
                MAX_LENGTH_FIELD: 'Longueur maximum :',
                MIN_LENGTH_FIELD: 'Longueur minimum :',
                PASSWORD_LENGTH: 'Le mot de passe doit avoir au moins 6 caractères',
                PHONE_LENGTH: 'Le numéro de téléphone doit être composé de 10 chiffres',
                REQUIRED_FIELD: 'Champ requis',
                SIRET_LENGTH: 'Le numéro SIRET doit être composé de 14 chiffres',
                USER_ALREADY_EXIST: "L'utilisateur existe déjà",
            },
            WELCOME: {
                ACCOUNT: 'Compte',
                ACCOUNT_CUSTOMER: 'Compte ACHETEUR',
                ACCOUNT_CUSTOMER_DESC: 'Achetez vos articles à côté de chez vous',
                ACCOUNT_SUPPLIER: 'Compte FOURNISSEUR',
                ACCOUNT_SUPPLIER_DESC: 'Vendez vos articles sur notre plateforme',
                COMPANY: 'Entreprise',
                COMPANY_EXIST: 'Votre entreprise existe déjà dans notre application.\n\nCliquez sur "Suivant" pour finaliser votre inscription.',
                COMPLETE: 'Terminer',
                COMPLETE_LABEL: 'Terminer votre inscription',
                FIND_SIRET: 'Trouver le SIRET de votre entreprise',
                PROFIL: 'Votre profil',
                SUBTITLE: 'Merci de valider les informations ci-dessous pour terminer la création de votre compte',
                TERMS_OF_SALES: 'Conditions de vente',
                TITLE: 'Bienvenue sur Stoick',
                YOUR_ACCOUNT: 'Votre compte',
                YOUR_COMPANY: 'Votre entreprise',
            },
        },
        BUSINESS: {
            ADD_SIZE: 'Gérer les tailles',
            ADD_TO_FAVORITES: 'Ajouter aux favoris',
            ALERTE: 'Alerte',
            ASAP: 'Au plus tôt',
            AVERAGE_DELIVERY_TIME: 'Délai moyen de livraison',
            BRAND: 'Marques',
            CATEGORIES: 'Catégories',
            CARRIAGE_FREE: 'Franco de port',
            CARRIAGE_FREE_TOOLTIP: 'Montant au delà duquel les frais de livraison sont offerts',
            CHECK: 'Chèque',
            CLOTHES: 'Vêtement',
            CLOSED: 'Fermé',
            COMPANY: 'Entreprise',
            CONTACT: 'Contact',
            CUSTOMER: 'Client',
            CUSTOMER_WHITELABEL: 'Client site Internet',
            DATE_DESIRED: 'Souhaitée le',
            DATE_PLANNED: 'Prévue le',
            DELAY: 'Délai',
            DELAY_PLACEHOLDER: 'Délai (en jours)',
            DELETE_FROM_FAVORITES: 'Supprimer des favoris',
            DESIRED_QUANTITY: 'Quantité souhaitée',
            DESIRED_ANNUAL_QUANTITY: 'Quantité annuelle souhaitée',
            GLOVES: 'Gants',
            ECO_FRIENDLY: 'Ecoresponsable',
            ECO_FRIENDLY_MANY: 'Ecoresponsables',
            ECOTAX: 'Dont écotaxes',
            EXCHANGE: 'Lettre de Change Relevé',
            FAVORITES: 'Favoris',
            FEEDBACK_COMMENT_PLACEHOLDER: 'Une remarque, une suggestion, un fournisseur que vous voudriez retrouver sur Stoick ? Dites-nous tout !',
            FEEDBACK_FIRST_PURCHASE: 'Votre avis sur ce premier achat sur Stoick ?',
            IN_STOCK: 'En stock',
            IS_CUSTOMIZABLE: 'Personnalisable',
            ITEM: 'Article',
            ITEMS: 'Articles',
            MADE_IN_FRANCE: 'Fabriqué en France',
            MADE_IN_FRANCE_MANY: 'Fabriqués en France',
            MAKER: 'Fabricant',
            MANAGE_STOCK: 'Gérer le stock',
            MINIMUM_ORDER: 'Minimum de commande',
            MINIMUM_PURCHASE: "Minimum d'achat",
            MONTHLY: 'Mensuel',
            NORMAL: 'Normal',
            NEED_YOUR_EMAIL: 'Un email pour vous contacter ? 🙂',
            OPENING_TIME: "Horaires d'ouverture",
            OPENING_TIME_NOT_SPECIFIED: 'Non renseignées',
            OPENING_TIME_SAME: 'Horaires identiques tous les jours',
            OPINION_TITLE: 'Votre avis sur cette première commande ?',
            ORDER: 'À la commande',
            OUT_OF_STOCK: 'Rupture',
            PACKAGING: 'Lot de',
            PERSON_IN_CHARGE: 'Responsable',
            POINT: 'Point',
            PRODUCT_ECO_FRIENDLY: 'Produit écoresponsable',
            PRICE_FREE: 'Gratuit',
            PUBLIC_PRICE: 'Prix public',
            QUANTITY: 'Quantité',
            QUOTATION: 'Sur devis',
            REF_INTERNAL: 'Référence interne',
            REFERENCE: 'Référence',
            SALES_REVENUE: "Chiffres d'affaires",
            SELECT_SIZE: 'Sélectionnez votre taille',
            STATISTICS: 'Statistiques',
            SHIPPING_COST: 'Frais de livraison forfaitaire',
            SHOES: 'Chaussures',
            SIZE: 'Taille',
            SIZES: 'Tailles',
            SIZE_EU: 'Tailles EU',
            SIZE_MANAGEMENT: 'Gestion des tailles',
            SIZE_MANAGEMENT_INFO: 'Sélectionner une seule taille ou cliquez sur la taille minimum puis la taille maximum.',
            SIZE_UNIVERSAL: 'Tailles universelles',
            STEP: "Seuil d'alerte",
            STOCK: 'Stock actuel',
            STOCK_STATUS: 'Etat du stock',
            STORE: 'Boutique',
            SUPPLIER: 'Fournisseur',
            SUPPLIERS: 'Fournisseurs',
            TAXES: 'Taux TVA',
            TOTAL_TTC_ORDER: 'Total TTC de la commande',
            TOTAL_TTC_TO_PAY: 'Total TTC à payer',
            TRANSFER: 'Virement',
            UPDATE_TERMS_OF_SALES: 'Modifiez vos conditions de vente',
            UNIT_PRICE: 'Prix unitaire',
            USERS: 'Utilisateurs',
            WANT_TO_BUY_TODAY: "Bonjour, de quoi avez-vous besoin aujourd'hui ?",
            WISHED_ON: 'Souhaitée le',
            WORKS_COUNCIL: "Comité d'entreprise",
        },
        CART: {
            ADD: 'Ajouter',
            ADD_TO_CART: 'Ajouter au panier',
            ITEM_ADDED_TO_CART: 'Article ajouté au panier',
            ITEMS_ADDED_TO_CART: 'Articles ajoutés au panier',
            DELETE_CART: 'Supprimer le panier',
            DELETE_CART_CONFIRMATION: 'Confirmez-vous la suppression du panier ?',
            DELETE_PRODUCT: "Supprimer l'article du panier",
            MISS_SUPPLIER: "L'article doit être rattaché à un fournisseur pour l'ajouter au panier",
            NEXT_SUPPLIER: 'Fournisseur suivant',
            PREVIOUS_SUPPLIER: 'Fournisseur précédent',
            TITLE: 'Panier',
        },
        CATALOGS: {
            ADD_NEW: 'Nouveau catalogue',
            ADD_PRODUCT: 'Ajouter un article',
            CANT_DELETE_TITLE: 'Suppression impossible',
            CANT_DELETE_DESC: 'Vous ne pouvez pas supprimer ce catalogue pour le moment car il est actuellement attribué à un ou plusieurs clients.',
            CATALOG: 'Catalogue',
            CATALOG_ASSIGN: 'Catalogue attribué',
            CATALOG_CUSTOM_DESCRIPTION: 'Catalogue personnalisé pour ',
            CATALOG_OF: 'Catalogue de',
            CREATE_LINK: 'Créer un nouveau catalogue',
            CREATE_CUSTOM_CATALOG: 'Créer un catalogue personnalisé',
            CREATE_CUSTOM_CATALOG_TOOLTIP: 'Vous permez de créer un catalogue personnalisé dédié à ce client',
            CUSTOM: 'Personnalisé',
            DELETE_ONE_TITLE: "Suppression d'un catalogue",
            DELETE_ONE_DESCRIPTION: 'Confirmez-vous la suppression du catalogue ?',
            DISCOUNT: 'Remise catalogue',
            DUPLICATE_SUCCESS: 'Votre catalogue a été dupliqué avec succés',
            EDIT_CUSTOM_CATALOG: 'Modifier le catalogue personnalisé',
            LABEL_DISCOUNT: 'Remise',
            LABEL_NAME: 'Nom du catalogue',
            NB_PRODUCTS: "Nombre d'articles",
            NO_DISCOUNT: 'Aucune',
            NO_RECORD: "Aucun catalogue n'a été créé pour le moment",
            NO_RECORD_FOUND: 'Aucun catalogue ne correspond à votre recherche',
            SELECT_ALL: 'Sélectionner tous les articles',
            SELECT_ALL_IN_CATEGORY: 'Sélectionner tous les articles affichés de la catégorie',
            SELECT_ITEM: "Sélectionner l'article",
            SELECT_ONLY_CUSTOM: 'La sélection est possible uniquement pour les catalogues personnalisés par client',
            TITLE: 'Catalogues',
            EDIT: {
                EMPTY_PRODUCT_TITLE: 'Aucun article dans le catalogue',
                EMPTY_PRODUCT_DESC: 'Vous devez sélectionner au moins un produit pour enregistrer votre catalogue.',
                PRODUCT_ADDED: 'Votre article a bien été ajouté au catalogue',
                SAVE_SUCCESS: 'Votre catalogue a été enregistré avec succés',
                TITLE_NEW: "Création d'un nouveau catalogue",
                TITLE_UPDATE: 'Modification du catalogue',
                UPDATE_CATALOG_DISCOUNT: 'Mise à jour de la remise globale',
                UPDATE_CATALOG_DISCOUNT_CONFIRM_1: "Confirmer l'application d'une remise de",
                UPDATE_CATALOG_DISCOUNT_CONFIRM_2: "% à l'ensemble des articles du catalogue ?",
                UPDATE_CATALOG_DISCOUNT_CONFIRM_3: 'Les articles pour lesquels un prix remisé a été déjà saisi ne seront pas impactés par la remise globale',
            },
        },
        CHAT: {
            ONLINE: 'En ligne',
            NO_RECORD: "Vous n'avez aucun message pour le moment",
            NO_RECORD_FOUND: 'Aucune personne ne correspond à votre recherche',
            MAX_FILE_SIZE: 'Votre fichier est trop volumineux (max 2Mo)',
            SEND_FILE: 'Envoyer un fichier',
            SEND_LABEL: 'Ecrivez votre message...',
            VIEW_CUSTOMER: 'Voir la fiche Client',
            VIEW_SUPPLIER: 'Voir la fiche Fournisseur',
            WRONG_TYPE: "Ce type de fichier n'est pas autorisé (autorisé: jpg, png, gif, svg, pdf)",
        },
        CUSTOMERS: {
            ACTIVE: 'Actif',
            AVERAGE_CART: 'Panier moyen',
            BILLING_CHOICE: 'Mode de facturation',
            BILLING_CONTACT: 'Contact comptable',
            BILLING_CONTACT_TITLE: 'Coordonnées du contact comptable (si différent de vous)',
            CATALOG: 'Catalogue',
            COMPANY: 'Entreprise',
            CREATION_DATE: 'Date de création',
            DELETE_INVITATION_DESCRIPTION: "Confirmez-vous la suppression de l'invitation ? Celui-ci n'aura plus accès à votre catalogue d'articles.",
            DELETE_INVITATION_TITLE: "Suppression de l'invitation",
            DELETE_ONE_DESCRIPTION: "Confirmez-vous la suppression du client ? Celui-ci n'aura plus accès à votre catalogue d'articles.",
            DELETE_ONE_TITLE: "Suppression d'un client",
            EMAIL: 'Email',
            END_DATE: "Date de fin d'abonnement",
            FILTER_ON_INACTIVE: 'Voir les clients inactifs',
            INACTIVE: 'Inactif',
            LAST_ORDER: 'Dernière commande',
            LATE_PAYEMENT: 'retard(s) de paiement signalé(s)',
            LOADING_SCORING: 'Chargement du scoring',
            MARKETPLACE_PRICE: 'Prix marketplace',
            NAME: 'Nom',
            NB_ORDERS: 'Nb commandes',
            NEW_CUSTOMER: 'Ajouter un client',
            NO_ACCOUNT: 'En attente création du compte',
            NO_CATALOG: 'Aucun (prix grand public)',
            NO_LATE_PAYEMENT: 'Aucun retard de paiement signalé',
            NO_OPEN_PROCEDURE: 'Aucune procédure ouverte',
            NO_RECORD: "Aucun client n'a accés à votre catalogue pour le moment",
            NO_RECORD_FOUND: 'Aucun client ne correspond à votre recherche',
            NO_SCORING: 'Aucun scoring pour cette entreprise',
            NO_SCORING_NOTE: 'Non notée',
            OPEN_PROCEDURE: 'Procédure en cours',
            OPEN_HOURS: "Horaires d'ouverture (pour les livraisons)",
            PAYMENT_CHOICE: 'Mode de paiement',
            PAYMENT_TITLE: 'Paiement et mode de facturation',
            PHONE: 'Téléphone',
            STATUS: 'Statut',
            TITLE: 'Clients',
            TOKENS_FREE: 'Abonnements disponibles',
            TURNOVER: "Chiffre d'affaires",
            WAIT_ACCOUNT: 'En attente activation',
            ADD: {
                CATALOG_SELECTED: 'Catalogue sélectionné',
                COMPLETE_SUBTITLE: "Merci de valider les informations ci-dessous pour terminer l'ajout de votre client",
                COMPLETE_TITLE: "Terminer l'ajout de votre client",
                CUSTOMER_FOUND: 'Le client est déjà inscrit sur Stoick',
                CUSTOMER_INFORMATIONS: 'Coordonnées de votre client',
                ERROR_CATALOG_NEXT: 'Merci de sélectionner un catalogue avant de continuer.',
                ERROR_CUSTOMER_NEXT: 'Merci de sélectionner un client avant de continuer.',
                FINALIZE: "Finalisez l'ajout de votre client",
                FIND_CUSTOMER: 'Trouvez votre client',
                INVITATION_ON_STOICK: 'Votre client sera invité sur Stoick',
                INVITATION_ON_WEBSITE: 'Votre client sera invité sur votre site Internet',
                INVITATION_PENDING: 'Invitation en attente',
                INVITE_ON: 'Inviter votre client sur',
                NO_RESULT: "Votre client n'utilise pas encore l'application. Il va recevoir une invitation par mail.\n\nMerci de renseigner son prénom et nom.",
                SEARCH_CUSTOMER: 'Rechercher votre client',
                SELECT_CATALOG: 'Sélectionnez un catalogue',
                SEND_INVITATION: 'Envoyer une invitation',
                SEND_INVITATION_SUCCESS: "L'invitation a bien été envoyée à votre client",
                STOICK_PLATFORM: 'La plateforme Stoick',
                TITLE: 'Ajouter un nouveau client',
                USER_EXIST: "L'entreprise du contact sélectionné fait déjà parti de vos clients.",
                YOUR_CUSTOMER: 'Votre client',
                YOUR_WEBSITE: 'Votre site Internet',
            },
            BILLING_INFO: {
                SUB_TITLE_1: 'Afin que nos fournisseurs vous assurent un meilleur service, nous avons besoin de quelques informations complémentaires.',
                SUB_TITLE_2: "Promis on ne le vous demandera qu'une fois. 🙂",
                TITLE: 'Merci pour cette première commande ! 🙏',
            },
            DISCOUNTS: {
                BACK_TO_CUSTOMER_CARD: 'Retour à la fiche client',
                CHANGE_CATALOG_TITLE: 'Changement de catalogue',
                CHANGE_CATALOG_DESCRIPTION: 'Confirmez-vous le changement de catalogue pour ce client ?',
                CHANGE_CATALOG_WAIT: 'Changement de catalogue en cours...',
                CUSTOM_CREATE: 'Personnaliser le catalogue',
                CUSTOM_EXIST_TITLE: 'Catalogue personnalisé existant',
                CUSTOM_EXIST_DESCRIPTION: 'Confirmez-vous la sélection du catalogue personalisé existant ?',
                CUSTOM_EXIST_WAIT: 'Sélection du catalogue en cours...',
                CUSTOM_TITLE: "Création d'un catalogue personnalisé",
                CUSTOM_DESCRIPTION: "La création d'un catalogue personnalisé vous permet de sélectionner des articles et de définir des taux de remise pour ce client . \n\nConfirmez-vous la création de ce nouveau catalogue ?",
                CUSTOM_WAIT: 'Préparation du catalogue en cours...',
                DISABLE_SELECTION: 'Afficher les articles du catalogue',
                ENABLE_SELECTION: 'Afficher tous les articles',
                GLOBAL_LABEL: 'Remise catalogue',
                SAVE_SUCCESS: 'Les modifications ont été enregistrées avec succés',
                TITLE: 'Gestion des remises',
            },
        },
        DATATABLE: {
            ERROR_LOAD_DATA: 'Une erreur est survenue lors du chargement des données.',
            ITEMS_BY_PAGE: 'Elements par page',
            LABEL_FIRST: 'Première page',
            LABEL_LAST: 'Dernière page',
            LABEL_NEXT: 'Page suivante',
            LABEL_PAGINATOR: 'Items par page',
            LABEL_PREVIOUS: 'Page précédente',
            NO_RECORD: 'Aucun enregistrement trouvé',
            COLUMNS: {
                ACTIONS: 'Actions',
                AMOUNT_TOTAL: 'Montant total',
                COMPANY: 'Entreprise',
                CONTACT: 'Contact',
                CREATED_DATE: 'Date de création',
                DESCRIPTION: 'Description',
                EMAIL: 'Email',
                LAST_SIGNIN: 'Dernière connexion',
                NAME: 'Nom',
                NB_ORDERS: 'Nombre de commandes',
                NB_PRODUCTS: 'Nombre de produits',
                ROLES: 'Rôles',
                STATUS: 'Statut',
                TURNOVER: "Chiffres d'affaires",
                UPDATED_DATE: 'Date de mise à jour',
                VALIDATE_DATE: 'Date de validation',
            },
        },
        EDIT_SUPPLIER: {
            NAME_SUPPLIER: 'Nom du fournisseur',
        },
        EMAIL_DIALOG: {
            BUTTON: 'Je veux en savoir plus',
            MESSAGE_SUCCESS: 'On vous recontacte rapidement pour tout vous expliquer.',
            TEXT: 'Les prix affichés sont les prix publics.\n\nVous souhaitez bénéficier de prix négociés ?\n\nLaissez-nous votre email et on vous contacte pour vous en dire plus.',
        },
        EMPTY: {
            CANT_DISPLAY_CATALOG: "Un problème est survenue lors de l'affichage du catalogue. \n\n Veuillez réessayer ou signaler le problème au support",
            CART: 'Votre panier est vide',
            CATALOGS: 'Aucun catalogue créé pour le moment',
            CATALOGS_DESC: 'Cette fonctionnalité vous permet de créer des catalogues avec des prix personnalisés pour vos clients',
            CUSTOMERS: "Vous n'avez aucun client pour le moment",
            FEED: 'Aucune actualité pour le moment',
            MESSAGES: 'Aucun message pour le moment',
            NEW_ORDER_CUSTOMER: 'Aucune commande en cours pour le moment',
            NEW_ORDER_SUPPLIER: 'Aucune nouvelle commande pour le moment',
            NO_ITEMS_FOUND: 'Aucun article ne correspond à votre recherche',
            ORDERS_CUSTOMER: "Vous n'avez passé aucune commande pour le moment",
            ORDERS_SUPPLIER: "Vous n'avez reçu aucune commande pour le moment",
            PRODUCTS: "Votre référentiel d'articles est vide",
            QUOTES_CART: "Vous n'avez aucune demande de devis à valider",
            QUOTES_CUSTOMER: "Vous n'avez fait aucune demande de devis pour le moment",
            QUOTES_SUPPLIER: "Vous n'avez reçu aucune demande de devis pour le moment",
            REQUESTS: 'Aucune nouvelle demande pour le moment',
            SUPPLIERS: "Vous n'avez aucun fournisseur privilégié pour le moment",
            STOCK: 'Vos stocks sont à jour',
        },
        HOME: {
            ALERT: {
                NO_RECORD: 'Aucune alerte actuellement',
                PRODUCTS_ON_ALERT: 'Articles en alerte',
                PRODUCTS_OUT_OF_STOCK: 'Articles en rupture de stock',
                TITLE: 'Alerte stock',
            },
            EXCEPTIONAL_REQUESTS: {
                NO_RECORD: 'Aucune demande exceptionnelle actuellement',
                TITLE: 'DEMANDES EXCEPTIONNELLES',
            },
            FEED: {
                TITLE: 'Actualité',
            },
            INTERNAL_REQUESTS: {
                TITLE: 'Nouvelles demandes',
            },
            ORDERS: {
                TITLE_CUSTOMER: 'Commandes en cours',
                TITLE_SUPPLIER: 'Nouvelles commandes',
            },
            WELCOME: {
                BTN_FIND_ITEM: 'Je passe ma première commande',
                BTN_SEE_ALL_PRODUCTS: 'Je découvre tous les articles',
                BTN_SUPPLIERS: 'Je découvre les fournisseurs près de chez moi',
            },
        },
        HELP: {
            CATALOGS: "Cet écran vous permet de créer plusieurs catalogues adaptés à vos clients. Lorsque vos articles sont créés, la création d'un catalogue vous permet d'afficher ou non des articles et de définir des remises.",
            I_UNDERSTAND: "J'ai compris",
        },
        INTERNAL_CATALOG: {
            ADD: 'Ajouter au catalogue interne',
            SAVE_SUCCESS: 'Article(s) ajouté(s) au catalogue interne',
        },
        INVITATIONS: {
            ACCEPT: "Accepter l'invitation",
            ACCEPT_SUCCESS_1: 'Vous avez désormais accés à la boutique en ligne de votre fournisseur.',
            ACCEPT_SUCCESS_2: 'On vous emmène la découvrir tout de suite ',
            CANCEL: "Refuser l'invitation",
            MESSAGE: "Si vous ne connaissez pas cette personne, vous pouvez refuser l'invitation.",
            TITLE: "Invitation d'un fournisseur",
        },
        MENU: {
            ADMIN: 'Admin',
            BUY: 'Acheter',
            CART: 'Panier',
            CATALOGS: 'Catalogues',
            CHAT: 'Messages',
            CUSTOMERS: 'Clients',
            FOLLOW_STOCK: 'Suivi stock',
            INTERNAL: 'Interne',
            INTERNAL_CATALOG: 'Catalogue interne',
            ITEMS: 'Référentiel articles',
            HOME: 'Accueil',
            QUOTES_CART: 'Demandes de devis',
            SUPPLIERS: 'Fournisseurs',
            STORES: 'Boutiques',
            TRACKING_ORDERS: 'Suivi des commandes',
            TRACKING_QUOTES: 'Suivi des devis',
            TRACKING_REQUESTS: 'Suivi des demandes',
            USERS_MANAGEMENT: 'Utilisateurs',
        },
        ORDERS: {
            AMOUNT: 'Montant total',
            COMMENT_CUSTOMER: 'Commentaire client',
            COMMENT_SUPPLIER: 'Commentaire fournisseur',
            COMPANY: 'Entreprise du client',
            CUSTOMER: 'Nom du client',
            DELIVERY_ADDRESS: 'Adresse de livraison',
            DELIVERY_ASAP: 'Au plus tôt',
            DELIVERY_DATE: 'Livraison souhaitée le',
            DELIVERY_PLANNED: 'Livraison prévue le',
            FUTURE_STOCK: 'Stock futur',
            NB_PRODUCTS: "Nombre d'articles",
            NEW_ORDER: 'Je passe ma première commande',
            NO_RECORD: "Vous n'avez passé aucune commande pour le moment",
            NO_RECORD_FOUND: 'Aucune commande ne correspond à votre recherche',
            NUMERO: 'N° commande',
            ORDER_DATE: 'Date de la commande',
            ORDERS_IN_PROGRESS: 'Commandes en cours',
            REQUESTS_IN_PROGRESS: 'Demandes en cours',
            STATUT_0: 'Panier',
            STATUT_1: 'Nouvelle commande',
            STATUT_2: 'Prise en compte',
            STATUT_3: 'Validée',
            STATUT_4: 'Expédiée',
            STATUT_5: 'Réceptionnée',
            STATUT_6: 'Refusée',
            STATUT_7: 'Annulée',
            STATUT_8: 'Disponible',
            STATUT_10: 'Archivée',
            STATUT_20: 'Consultation panier',
            STATUT_21: 'Consultation',
            TOTAL: 'Total',
            TITLE: 'Commandes',
            TRACK_DELIVERY: 'Suivre',
            CONSULTATION: {
                DETAILS: 'Détails de la consultation',
                SEND_BTN: 'Envoyer la consultation au fournisseur',
                SEND_SUCCESS: 'Votre consultation a été envoyée au fournisseur',
                TITLE: 'Consultation',
                TOOLTIP: 'Permet de consulter votre fournisseur sur des volumes annuels pour chaque article',
                VALIDATE: 'Valider la consultation',
                VALIDATE_TITLE: 'Validation de votre consultation',
                WARNING_INFO: 'Attention de bien saisir des quantités annuelles pour votre consultation',
            },
            EDIT: {
                ARCHIVE: 'Archiver',
                AVAILABLE: 'Disponible',
                WILL_BE_AVAILABLE: 'Disponible le',
                CANCEL_DESCRIPTION: 'Etes vous sûr de vouloir annuler la commande ?',
                CANCEL_TOOLTIP: 'Annuler la commande',
                CANCEL_TITLE: 'Annulation de la commande',
                CHOOSE_DATE: 'Choisir une date',
                CLICK_AND_COLLECT: 'Click & Collect',
                CLICK_AND_COLLECT_LABEL: 'Je récupère ma commande',
                MARK_AS_READ_TOOLTIP: 'Indiquez à votre client que vous avez pris connaissance de sa commande',
                RECEIVED: 'Reçue',
                SEND_ORDER: 'Envoyer la commande au fournisseur',
                SEND_ORDER_SUCCESS: 'Votre commande a été envoyée au fournisseur',
                SHIPMENT_TITLE: 'Expédition de la commande',
                VALIDATE: 'Valider la commande',
                VALIDATE_TITLE: 'Validation de la commande',
                VALIDATE_SUCCESS: 'La commande est validée',
                TITLE: 'Détail de la commande',
                TRACKING_LINK: 'Lien de tracking',
            },
            NEW: {
                ADD_TO_INTERNAL_CATALOG: 'Ajouter à votre catalogue interne',
                ADD_TO_INTERNAL_CATALOG_SUCCESS: "L'article a été ajouté à votre catalogue",
                ADD_TO_STOCK: 'Gérer le stock de cet article',
                DELETE_FROM_FAVORITES: 'Retirer des favoris',
                FILTER_ON_FAVORITE_FALSE: 'Ne plus filtrer sur les favoris',
                FILTER_ON_FAVORITE_TRUE: 'Filtrer sur les favoris',
                LINKED: 'Cet article est déjà géré en stock',
                STRETCH_SEARCH: 'Elargir la recherche à tous les fournisseurs',
                SUPPLIERS_FAVORITE: 'Fournisseurs favoris',
                TITLE: 'Nouvelle commande',
                VIEW_ALL_SUPPLIERS: 'Voir tous les fournisseurs',
                VIEW_ONLY_FAVORITES: 'Voir uniquement mes fournisseurs favoris',
            },
        },
        PRODUCTS: {
            CATEGORIE: 'Catégorie',
            DESC: 'Description',
            DETAILS: "Détails de l'article",
            DISCOUNT: 'Remise',
            DISCOUNT_ITEMS: 'Remise article',
            DISPLAY: 'Afficher / Masquer',
            DISPLAY_IN_MARKETPLACE: 'Visible dans la marketplace',
            DISPLAYED_PRICE: 'Prix affiché',
            EAN: 'EAN / Code barre',
            ECOTAX: 'Ecotaxes',
            HIDE_IN_MARKETPLACE: 'Masqué dans la marketplace',
            MAIN_PHOTO: 'Photo principale',
            MANAGEMENT_MODE: "Gestion de l'article",
            NAME: 'Désignation',
            PHOTO: 'Photo',
            PHOTOS: 'Photos (5 maximum)',
            PRICE: 'Prix',
            PRICE_DISCOUNT: 'Prix remisé',
            PRICE_FROM: 'À partir de',
            REF: 'Référence',
            REF_MAKER: 'Référence fabricant',
            REF_SUPPLIER: 'Référence fournisseur',
            SEE_TECHNICAL_SHEET: 'Voir la fiche technique',
            SELECT_ALL_SUPPLIERS_TOOLTIP: 'Sélectionner tous les fournisseurs',
            SELECT_MAIN_PHOTO: 'Sélectionner comme photo principale',
            SELL_BY: 'Vendu par',
            SUPPLIER: 'Fournisseur',
            WITHOUT_SUPPLIER: 'Sans fournisseur',
            CATEGORY: {
                ADD_SUBCATEGORY: 'Ajouter une sous catégorie',
                ADD_TO_SUBCATEGORY: 'Inclure dans la catégorie',
                CANT_DELETE: 'Suppression impossible tant que la catégorie contient des articles ou des sous catégories',
                CLICK_TO_EDIT: 'Cliquez pour éditer',
                MANAGE_CATEGORIES: 'Gérer les catégories',
                MOVE_PRODUCTS: 'Déplacer les produits directement dans les catégories ci-dessous.',
                MOVE_PRODUCTS_TOOLTIP: "Gérer l'organisation des produits",
                NEW_CATEGORY: 'Nouvelle catégorie',
                NEW_DESC: 'Saisissez le nom',
                SELECT_ALL_TOOLTIP: 'Sélectionner toutes les catégories',
                TITLE: 'Catégories',
                NEW_SUBCATEGORY: 'Nouvelle sous catégorie',
                UPDATE_SUCCESS_ONE: 'Article déplacé dans la catégorie ',
                UPDATE_SUCCESS_MANY: 'Articles déplacés dans la catégorie ',
                UNCLASSIFIED: ' Non classé',
                UPDATE_LABEL: 'Modifier le libellé',
            },
            EDIT: {
                ADD_DETAILS_DESC: "Entrez les détails de l'article",
                ADD_SUPPLIER: 'Ajouter un fournisseur',
                ADD_TO_CATALOG: 'Ajouter au catalogue',
                DISCOUNTS_ITEM_INFO: "Attention la modification d'une remise article au niveau du catalogue écrasera les remises personnalisées pour les clients étant rattachés à ce catalogue",
                MAKER_INFORMATIONS: 'Informations fabriquant',
                MANAGEMENT_MODE: "Mode de gestion de l'article",
                MORE_INFO: 'Informations complémentaires',
                NEW: "Création d'un nouvel article",
                PRICE_LINKED: "Vous ne pouvez pas modifier le prix car l'article est lié au catalogue de votre fournisseur.",
                SAVE_SUCCESS: "L'article a été enregistré avec succés.",
                STOCK_MANAGEMENT: 'Gestion du stock',
                SUPPLIER_INFO: 'Informations fournisseur',
                TECHNICAL_SHEET: 'Fiche technique',
                UPDATE: "Mise à jour de l'article",
                UPDATE_CATALOG_DESC: "Ajouter l'article et gérer les remises dans vos catalogues",
                UPDATE_CATALOG_TITLE: 'Mise à jour des catalogues',
                UPDATE_DISCOUNTS_DESC: 'Personnalisez la remise pour vos clients',
                UPDATE_DISCOUNTS_TITLE: 'Remise client',
                UPLOAD_PHOTO: 'Sélectionner une image ou déposer la ici. \n\n Fichiers acceptés (jpeg, png).',
                UPLOAD_PHOTO_ERROR: 'Erreur lors du chargement du fichier',
                UPLOAD_PHOTO_MAX: 'Vous avez atteint le maximum de fichiers. \n\n Veuillez supprimer un fichier pour en enregistrer un nouveau',
            },
            EMPTY: {
                CREATE: "Créer le référentiel via l'interface",
                DOWNLOAD_TPL: 'Télécharger le template',
                IMPORT: 'Importer votre référentiel via un fichier Excel',
            },
            IMPORT: {
                CHECK_FILE_PROGRESS: 'Vérification du fichier en cours',
                CHECK_FILE_SUCCESS: 'Vérification du fichier réussie',
                CREATE_ITEMS_PROGRESS: 'Import des articles en cours',
                CREATE_ITEMS_PROGRESS_WARNING: "L'import peut prendre quelques minutes si le fichier est volumineux",
                CREATE_ITEMS_SUCCESS: 'Import des articles réussi',
                ERROR: "Une erreur est survenue lors de l'import. Veuillez réessayer ou contacter le support si l'erreur persiste.",
                ERROR_FILE: "Erreur(s) lors de l'import",
                LINE: 'Ligne',
                NOT_NUMBER_MINIMUM_PURCHASE: 'Le champ "Minimum d\'achat" doit être un nombre',
                NOT_NUMBER_PACKAGING: 'Le champ "Conditionnement" doit être un nombre',
                NOT_NUMBER_PRICE: 'Le champ "Prix" doit être un nombre',
                NOT_NUMBER_ECOTAX: 'Le champ "Ecotaxe" doit être un nombre',
                NOT_NUMBER_STEP: 'Le champ "Seuil d\'alerte stock" doit être un nombre',
                REQUIRED_MISSING_MANAGEMENT: 'Le champ "Mode de gestion" est obligatoire',
                REQUIRED_MISSING_NAME: 'Le champ "Désignation" est obligatoire',
                REQUIRED_MISSING_PRICE: 'Le champ "Prix" est obligatoire',
                REQUIRED_MISSING_REFERENCE: 'Le champ "Référence" est obligatoire',
                SUCCESS: "L'import s'est terminé avec succés",
                UPLOAD_FILE: 'Cliquez ici pour sélectionner un fichier ou déposer le ici. \n\n Fichier accepté (xls, xlsx).',
                TITLE: 'Import des articles par fichier Excel',
            },
            LIST: {
                ADD_PHOTO_TOOLTIP: "Ajouter une photo de l'article",
                DELETE_MANY_TITLE: 'Suppression des articles',
                DELETE_MANY_DESCRIPTION: 'Confirmez-vous la suppression des articles sélectionnés ?',
                DELETE_ONE_TITLE: "Suppression d'un article",
                DELETE_ONE_DESCRIPTION: "Confirmez-vous la suppression de l'article ?",
                DOWNLOAD: 'Télécharger template Excel',
                EXPORT: 'Export catalogue',
                GROUP_BY_CATEGORIES: 'Grouper par catégories',
                IMPORT: 'Import catalogue',
                MOVE: 'Déplacer',
                NEW_PRODUCT: 'Nouvel article',
                NO_RECORD: 'Aucun article à afficher',
                NO_RECORD_FOUND: 'Aucun article ne correspond à votre recherche',
                PRODUCT: 'article',
                PRODUCTS: 'articles',
                SELECTED_RECORDS_COUNT: "Nombre d'article(s) sélectionné(s)",
                TITLE: 'Articles',
            },
        },
        PROFILE: {
            ADV_CONTACT: 'A.D.V.',
            ACCOUNTING_CONTACT: 'Comptabilité',
            BILLING_INFORMATIONS: 'Facturation',
            CLICK_AND_COLLECT: 'Click & Collect',
            CLICK_AND_COLLECT_TOOLTIP: 'Les clients peuvent venir récupérer la marchandise',
            COMPANY_INFORMATIONS: 'Entreprise',
            DELIVERY: 'Livraison',
            ENTER_NEW_TAG: 'Saisir un nouveau tag',
            SUPPLIER_PRESENTATION: 'Fiche de présentation',
            USER_INFORMATIONS: 'Informations personnelles',
            TERMS_OF_SALES: 'Conditions de vente',
            CONTACTS: 'Contacts',
            COMMERCIAL_CONTACT: 'Commercial',
        },
        QUOTES: {
            ADD_TO_CART: 'Ajouter les articles au panier',
            ADD_TO_CART_SUCCESS: 'Les articles ont bien été ajoutés au panier',
            ADDED_TO_QUOTES_REQUEST: 'Ajouté à la demande de devis',
            DATE: 'Date de la demande',
            DELETE_QUOTE: 'Supprimer la demande',
            DELETE_QUOTE_CONFIRMATION: 'Confirmez-vous la suppression de la demande ?',
            NEW_QUOTE: 'Je fais ma première demande de devis',
            NO_RECORD: "Vous n'avez fait aucune demande de devis pour le moment",
            NO_RECORD_FOUND: 'Aucune demande de devis ne correspond à votre recherche',
            REQUEST: 'Faire une demande de devis',
            REQUESTS_TITLE: 'Demandes de devis',
            STATUT_0: 'Panier',
            STATUT_1: 'Nouveau devis',
            STATUT_2: 'Pris en compte',
            STATUT_3: 'Proposition',
            STATUT_4: 'Accepté',
            STATUT_41: 'Accepté',
            STATUT_5: 'Refusé',
            STATUT_6: 'Annulé',
            STATUT_7: 'Archivé',
            STATUT_8: 'Nouvelle Proposition',
            TITLE: 'Devis',
            VALIDATE: 'Valider la demande',
            EDIT: {
                ACCEPT: 'Accepter',
                ACCEPT_INFO: "L'acceptation du devis entraînera la création d'une commande",
                ACCEPT_TITLE: 'Accepter la proposition',
                ACCEPT_DESCRIPTION: 'Êtes vous sûr de vouloir accepter la proposition?',
                ACCEPTED_SUCCESS: 'La commande a été créée et envoyée au fournisseur. Retrouvez la dans le suivi des commandes.',
                CANCEL_TOOLTIP: 'Annuler la demande',
                CANCEL_TITLE: 'Annulation de la demande de devis',
                CANCEL_DESCRIPTION: 'Êtes vous sûr de vouloir annuler la demande de devis?',
                MARK_AS_READ_TOOLTIP: 'Indiquez à votre client que vous avez pris connaissance de sa demande',
                NEW_PROPOSAL: 'Nouvelle Proposition',
                PROPOSAL: 'Valider la proposition',
                PROPOSAL_TITLE: 'Proposition de devis',
                ORDER: 'Commander',
                REFUSE: 'Refuser',
                REFUSE_TITLE: 'Refuser la proposition',
                REFUSE_DESCRIPTION: 'Êtes vous sûr de vouloir refuser la proposition?',
                SEND_QUOTE_PROPOSAL: 'Envoyer la proposition au client',
                SEND_QUOTE_PROPOSAL_SUCCESS: 'Votre demande de devis a été envoyée au client',
                SEND_QUOTE_REQUEST: 'Envoyer la demande au fournisseur',
                SEND_QUOTE_REQUEST_SUCCESS: 'Votre proposition a été envoyée au fournisseur',
                TITLE: 'Détails du devis',
                VALIDATE_TITLE: 'Validation de la demande de devis',
            },
        },
        REQUESTS: {
            AMOUNT: 'Montant total',
            APPLICANT: 'Demandeur',
            COMMENT_APPLICANT: 'Commentaire demandeur',
            COMMENT_MANAGER: 'Commentaire gestionnaire',
            DELIVERY_ADDRESS: 'Adresse de livraison',
            DELIVERY_ASAP: 'Au plus tôt',
            DELIVERY_DATE: 'Livraison souhaitée le',
            DELIVERY_PLANNED: 'Livraison prévue le',
            INTERVENTION_DATE: 'Intervention souhaitée le',
            INTERVENTION_PLANNED: 'Intervention prévue le',
            ITEMS: 'Articles',
            MANAGEMENT_MODE: 'Type de demande',
            NB_PRODUCTS: "Nombre d'articles",
            NO_RECORD: "Vous n'avez aucune demande pour le moment",
            NO_RECORD_FOUND: 'Aucune demande ne correspond à votre recherche',
            NUMERO: 'N° demande',
            DATE: 'Date de la demande',
            STATUT_0: 'Panier',
            STATUT_1: 'Nouvelle demande',
            STATUT_2: 'Ajoutée au panier',
            STATUT_3: 'Validée',
            STATUT_4: 'Disponible',
            STATUT_5: 'Récupérée',
            STATUT_6: 'Réalisée',
            STATUT_7: 'Refusée',
            STATUT_8: 'Annulée',
            STATUT_10: 'Archivée',
            TITLE: 'Demandes',
            EDIT: {
                CANCEL_DESCRIPTION: 'Etes vous sûr de vouloir annuler la demande ?',
                CANCEL_TOOLTIP: 'Annuler la demande',
                CANCEL_TITLE: 'Annulation de la demande',
                CHOOSE_DATE: 'Choisir une date',
                DELIVERED: 'Demande récupérée',
                INTERVENTION_DONE: 'Intervention réalisée',
                MARK_AS_READ_TOOLTIP: 'Indiquez à votre demandeur que vous avez pris connaissance de sa demande',
                READY: 'Demande disponible',
                READY_TOOLTIP: "Indiquez à votre demandeur qu'il peut récupérer sa demande",
                VALIDATE: 'Valider la demande',
                VALIDATE_SUCCESS: 'La demande est validée',
                VALIDATE_TITLE: 'Validation de la demande',
                TITLE: 'Détail de la demande',
            },
        },
        ROLES: {
            ADMIN: 'Admin',
            ADMINSTOICK: 'Admin Stoick',
            CUSTOMER: 'Client',
            CUSTOMERWHITELABEL: 'Client MB',
            CUSTOMERWITHOUTMP: 'Client',
            INACTIVE: 'Inactive',
            PREMIUM: 'Premium',
            STOICKWITHOUTCATALOG: 'Inside Sans Catalogue',
            STOICK: 'Inside',
            SUPPLIER: 'Fournisseur',
            USER: 'Utilisateur',
        },
        STORES: {
            ALL_PRODUCTS: 'Tous les articles',
            GOTO_MP: 'Accéder à la plateforme',
            SEE_CATALOGS_PRODUCTS: 'Voir les articles remisés',
            SEE_ALL_PRODUCTS: "Voir toute l'offre",
            SELECT_MP_SUBTITLE: 'Retrouvez ici tous les articles des autres fournisseurs (qui ne sont pas encore privilégiés !).',
            SELECT_MP_TITLE: 'Plateforme Stoick',
            SELECT_SUPPLIERS_SUBTITLE: 'Retrouvez ci-dessous les fournisseurs qui vous ont proposés des catalogues personnalisés avec des tarifs négociés.',
            SELECT_SUPPLIERS_TITLE: 'Vos fournisseurs privilégiés',
        },
        SUPPLIERS: {
            ACTIVE_TOOLTIP: 'Réactiver la connexion à ce fournisseur pour lui passer commande',
            ACTIVE_ONE_TITLE: 'Ajouter le fournisseur',
            ACTIVE_ONE_DESCRIPTION: 'Réactiver la connexion au fournisseur pour lui passer commande. \n\nConfirmer la connexion ?',
            ADD_LOGO: 'Ajouter un logo',
            ADD_NEW: 'Ajouter un fournisseur',
            ADD_SUPPLIER: 'Ajouter le fournisseur',
            ADD_SUPPLIER_IN_PROGRESS: 'Ajout du fournisseur en cours ...',
            ADD_SUPPLIER_SUCCESS: 'Vous pouvez désormais passer commande auprès du fournisseur ',
            ADRESS: 'Adresse',
            ALREADY_SELECTED: 'Fournisseur déjà sélectionné',
            ASK_FOR_QUOTE: 'Demande de renseignements',
            ASK_FOR_QUOTE_SUCCESS: 'Votre demande de renseignements a été envoyée, le fournisseur vous recontacte rapidement.',
            ASK_FOR_QUOTE_TOOLTIP: 'Le fournisseur reçoit un message avec vos coordonnées pour vous recontacter',
            CONTACT: 'Contact',
            CONTACT_COMMERCIAL: 'Contact commercial',
            CONTACT_FIRSTNAME: 'Prénom du contact',
            CONTACT_LASTNAME: 'Nom du contact',
            ECO_FRIENDLY_TOOLTIP: 'Propose des produits écoresponsables',
            EDIT: 'Modifier le fournisseur',
            FILTER_ON_INACTIVE: 'Voir les fournisseurs inactifs',
            FIND: 'Trouver un fournisseur',
            INACTIVE: 'Se déconnecter du fournisseur',
            INACTIVE_ONE_TITLE: 'Déconnexion du fournisseur',
            INACTIVE_ONE_DESCRIPTION: 'Vous ne pourrez plus commander auprès de ce fournisseur. \n\nConfirmer la déconnexion ?',
            LINKED_TOOLTIP: "Indique que vous êtes rattachés avec le fournisseur dans Stoick. \n\nVous pouvez donc échanger et suivre l'avancement de vos commandes directement depuis l'application.",
            LOADING_SUPPLIERS: 'Chargement des fournisseurs',
            MADE_IN_FRANCE_TOOLTIP: 'Propose des produits fabriqués en France',
            NAME: "Nom de l'entreprise",
            NO_RECORD: "Vous n'avez accès à aucun fournisseur pour le moment",
            NO_RECORD_FOUND: 'Aucun fournisseur ne correspond à votre recherche',
            PRODUCTS_OFFER: 'Propose des produits',
            SAVE_SUCCESS: 'Le fournisseur a été enregistré avec succés',
            SEE_CATALOG: 'Voir le catalogue',
            SEE_SALE_TERMS: 'Voir les conditions de vente détaillées',
            SELECT_SUPPLIER: 'Demande de contact',
            SELECT_SUPPLIER_TOOLTIP: 'Le fournisseur peut personnaliser votre offre, contactez le.',
            SELECT_SUPPLIER_SUCCESS: 'Votre demande de contact a été envoyée, le fournisseur vous recontacte rapidement.',
            TITLE: 'Liste des fournisseurs',
            UPDATE: 'Modifier le fournisseur',
        },
        TYPESENSE: {
            CLEAR_FILTERS: 'Effacer les filtres',
            NO_ITEMS_FOUND: 'Aucun article ne correspond à votre recherche',
            SEARCH_IN_PROGRESS: 'Recherche en cours',
            SEARCH_ITEM: 'Rechercher un article',
            SORT_LABEL: 'Trier par',
            SORT_DISTANCE_ASC: 'Distance ↑',
            SORT_DISTANCE_DESC: 'Distance ↓',
            SORT_NAME_ASC: 'Nom ↑',
            SORT_NAME_DESC: 'Nom ↓',
            SORT_PRICE_ASC: 'Prix ↑',
            SORT_PRICE_DESC: 'Prix ↓',
            SORT_REF_ASC: 'Référence ↑',
            SORT_REF_DESC: 'Référence ↓',
            SORT_RELEVANCE: 'Pertinence',
        },
        USERS: {
            ADD_NEW: 'Ajouter un utilisateur',
            ADD_SUCCESS: "L'utilisateur a désormais accès à vos articles. Il va recevoir un mail de connexion.",
            CAN_BUY: 'Peut passer des commandes auprès des fournisseurs',
            CAN_BUY_COLUMN: 'Commandes fournisseurs',
            CAN_BUY_TOOLTIP: "Les demandes d'achat de l'utilisateur sont envoyées directement aux fournisseurs sans validation. \n\nN'est valable que lorsque vous avez accès aux catalogues de vos fournisseurs dans l'application.",
            DELETE_ONE_TITLE: "Supprimer l'utilisateur",
            DELETE_ONE_DESCRIPTION: "Cette opération est irréversible, l'utilisateur n'aura plus accès à l'application.",
            NO_RECORD: "Aucun utilisateur n'a accès à vos articles pour le moment",
            NO_RECORD_FOUND: 'Aucun utilisateur ne correspond à votre recherche',
            RESET_PASSWORD: "Un email a été envoyé à l'utilisateur",
            TITLE: 'Utilisateurs',
        },
        VALIDATION: {
            NUMBER: 'La valeur saisie doit être un nombre',
            REQUIRED_FIELD: 'Champ requis',
        },
        WHITELABEL: {
            WELCOME_IN_STORE: 'Bienvenue dans la boutique',
        },
    },
};
