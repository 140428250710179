// Typesense
import TypesenseInstantSearchAdapter from './instantsearch-adapter/typesense-instantsearch-adapter';
//Environment
import { environment } from '@env/environment';

export type FilterByOperator = '=' | '!=';
export interface FilterByType {
    key: string;
    operator: FilterByOperator;
    value: string | string[];
}

export const COLLECTION_PRODUCTS = 'products';
export const COLLECTION_REPOSITORY = 'repository';

export class TypesenseClient {
    private _config: any;
    private _queryBy: string;
    private _searchClient: any;
    private _sort: any[];
    private _typesenseInstantsearchAdapter: TypesenseInstantSearchAdapter;

    private _searchParameters: {
        aroundLatLng: string;
        aroundRadius: number;
        facetFilters: [];
        filter_by: FilterByType[];
        group_by: string;
    };

    constructor(indexName: string, apiKey?: string, queryBy?: string, cacheSeconds?: number) {
        if (indexName === COLLECTION_PRODUCTS) {
            this._queryBy = 'name, categoryName.lvl0, categoryName.lvl1, description, maker, referenceMaker, referenceSupplier, supplierName';
        } else {
            this._queryBy = 'name, categoryName.lvl0, categoryName.lvl1, maker, referenceMaker, referenceSupplier';
        }

        this.initSearchParameters();
        this.initClient(apiKey, queryBy, cacheSeconds);
        this.setSearchClient();
        this.setConfig(indexName);
    }

    initClient(apiKey?: string, queryBy?: string, cacheSeconds = 60): void {
        this._typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
            server: {
                apiKey: apiKey || environment.typesenseConfig.apiKey,
                nodes: [
                    {
                        host: environment.typesenseConfig.host,
                        port: environment.typesenseConfig.port,
                        protocol: <'http' | 'https'>environment.typesenseConfig.protocol,
                    },
                ],
                cacheSearchResultsForSeconds: cacheSeconds,
            },
            geoLocationField: 'supplierLocation',
            additionalSearchParameters: {
                queryBy: queryBy || this._queryBy,
            },
        });
    }

    initSearchParameters() {
        this._searchParameters = {
            aroundLatLng: null,
            aroundRadius: 0,
            facetFilters: [],
            filter_by: [],
            group_by: null,
        };
    }

    getConfig(): any {
        return this._config;
    }

    getSearchClient(): any {
        return this._searchClient;
    }

    getSearchParameters(): any {
        return this._searchParameters;
    }

    getSort(): any[] {
        return this._sort;
    }

    removeFilterBy(value: string) {
        this._searchParameters.filter_by = this._searchParameters.filter_by.filter((f) => f.key !== value);
    }

    resetFilterBy() {
        this._searchParameters.filter_by = [];
    }

    setConfig(indexName: string): void {
        this._config = {
            indexName: indexName,
            searchClient: this._searchClient,
        };
    }

    addFilterBy(key: string, operator: FilterByOperator, value: string | string[]): void {
        this._searchParameters.filter_by = [...this._searchParameters.filter_by, { key, operator, value }];
    }

    setGroup(): void {
        this._searchParameters.group_by = 'productId';
    }

    // distance in km
    setGeoSearch(location: string, distance: number) {
        if (distance > 0) {
            this._searchParameters.aroundLatLng = location;
            this._searchParameters.aroundRadius = distance * 1000;
        }
    }

    setSearchClient(): void {
        this._searchClient = this._typesenseInstantsearchAdapter.searchClient;
    }

    setSort(sort: any[]): void {
        this._sort = sort;
    }
}
