// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// Services
import { DatePipe, ContentAnimateDirective, CapitalizeFirstLetterPipe, FirstLetterPipe, GetObjectPipe, HeaderDirective, JoinPipe, MenuDirective, OffcanvasDirective, RouterOutletDirective, SafePipe, ScrollTopDirective, StickyDirective, TabClickEventDirective, TimeElapsedPipe, ToggleDirective, AdDirective, ScrollableDirective } from './_base/layout';
import { InputNumberDirective } from './_base/layout/directives/input-number.directive';

//prettier-ignore
@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        // Directives
        AdDirective,
        ContentAnimateDirective,
        HeaderDirective,
        InputNumberDirective,
        MenuDirective,
        OffcanvasDirective,
        RouterOutletDirective,
        ScrollableDirective,
        ScrollTopDirective,
        StickyDirective,
        TabClickEventDirective,
        ToggleDirective,

        // Pipes
        DatePipe,
        CapitalizeFirstLetterPipe,
        FirstLetterPipe,
        JoinPipe,
        GetObjectPipe,
        TimeElapsedPipe,
        SafePipe,
    ],
    exports: [
        // Common modules
        CommonModule,
        FormsModule,
        HttpClientModule,
        NgxPermissionsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,

        // Directives
        AdDirective,
        ContentAnimateDirective,
        HeaderDirective,
        InputNumberDirective,
        MenuDirective,
        OffcanvasDirective,
        RouterOutletDirective,
        ScrollableDirective,
        ScrollTopDirective,
        StickyDirective,
        TabClickEventDirective,
        ToggleDirective,

        // Pipes
        DatePipe,
        CapitalizeFirstLetterPipe,
        FirstLetterPipe,
        JoinPipe,
        GetObjectPipe,
        TimeElapsedPipe,
        SafePipe,
    ],
    providers: [],
})
export class CoreModule {}
