// Angular
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Core
import { UnsubscribeOnDestroy, UrlService } from '@core/services';
import { MenuConfigService } from '@core/_base/layout';
import { AuthUserModel, getUser } from '@core/auth';
import { AppState } from '@core/reducers';
// RXJS
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
// Store
import { Store } from '@ngrx/store';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Object-Path
import * as objectPath from 'object-path';
// Component
import { TitlePageService } from './title-page.service';

@Component({
    selector: 'title-page',
    templateUrl: './title-page.component.html',
})
export class TitlePageComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    asideMenus: any;
    image$: Observable<string>;
    isStoick = false;
    permissionsId: string[] = [];
    showBackButton$: Observable<boolean>;
    title = '';
    url: string;
    user: AuthUserModel;

    //prettier-ignore
    constructor(
        private cdr: ChangeDetectorRef,
        private menuConfigService: MenuConfigService,
        private router: Router,
        private store: Store<AppState>,
        private titlePageService: TitlePageService,
        private translateService: TranslateService,
        private urlService: UrlService,
    ) {
        super();

        this.asideMenus = objectPath.get(this.menuConfigService.getMenus(), 'aside');

        this.image$ = this.titlePageService.getImage();
        this.showBackButton$ = this.titlePageService.getShowBackButton();

        this.listenToBackButtonRoute();
        this.listenToPageChange();
        this.listenToSetTitlePage();

        this.getUser();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        // Set page title on first loading
        this.getPageTitleFromUrl();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    goBack(): void {
        if (this.url) {
            const _url = `${this.urlService.getBaseUrl()}/${this.url}`;
            this.router.navigateByUrl(_url);
        } else {
            this.urlService.goBack();
        }
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    getPageTitleFromUrl(): void {
        if (this.router.url === '/home') {
            this.title = `${this.translateService.instant('GENERAL.HELLO')} ${this.user?.firstname}`;
            this.isStoick = false;
            return;
        }

        this.asideMenus?.items.forEach((item) => {
            if (this.router.url.includes(item.page)) {
                this.setTitlePage(this.translateService.instant(item.translate));
                return;
            }
        });
    }

    getUser() {
        this.store
            .select(getUser)
            .pipe(first())
            .subscribe((user) => (this.user = user));
    }

    listenToBackButtonRoute(): void {
        this.subs.sink = this.titlePageService.getBackButtonRoute().subscribe((url) => (this.url = url));
    }

    listenToPageChange(): void {
        this.subs.sink = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.getPageTitleFromUrl();
            }
        });
    }

    listenToSetTitlePage(): void {
        this.subs.sink = this.titlePageService.getTitlePage().subscribe((title) => {
            this.setTitlePage(title || '');
        });
    }

    setTitlePage(title: string): void {
        this.title = title;
        setTimeout(() => this.cdr.markForCheck(), 100);
    }

    /*************/
    /*    UI     */
    /*************/
    showPublicButtons(): boolean {
        return this.showStoresButton() || this.showSuppliersButton();
    }

    showStoresButton(): boolean {
        return window.location.pathname === '/mp/public/suppliers';
    }

    showSuppliersButton(): boolean {
        return window.location.pathname === '/mp/public/items';
    }
}
