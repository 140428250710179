import { AuthActionTypes } from '@core/auth/store/_actions/auth.actions';
// NGRX
import { routerReducer } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

// tslint:disable-next-line:no-empty-interface
export interface AppState {}

export const reducers: ActionReducerMap<AppState> = { router: routerReducer };

// export const metaReducers: Array<MetaReducer<AppState>> = !environment.production ? [storeFreeze] : [];
export const metaReducers: Array<MetaReducer<AppState>> = [clearState];

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return function (state: AppState, action: Action): AppState {
        if (action.type === AuthActionTypes.NotAuthenticated) {
            state = undefined;
        }
        return reducer(state, action);
    };
}
