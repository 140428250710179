// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NGRX
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
// RXJS
import { Observable } from 'rxjs';
// Store
import { RoleModel } from './role.model';
import { FS_PATH_ROLES } from '@store/firestore-collections';
// Core
import { FirestoreService } from '@core/services';

@Injectable({
    providedIn: 'root',
})
export class RolesDataService extends DefaultDataService<any> {
    constructor(private firestore: FirestoreService, http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
        super('Roles', http, httpUrlGenerator);
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getAll(): Observable<RoleModel[]> {
        return this.firestore.collectionSnapShot(FS_PATH_ROLES);
    }
}
