// Angular
import { Component, EventEmitter, OnInit, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// NGRX
import { Store } from '@ngrx/store';
// Core
import { AppState } from '@core/reducers';
import { isAuthSuccess } from '@core/auth';
import { FirestoreService, UnsubscribeOnDestroy } from '@core/services';
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
// Store
import { FS_PATH_FEEDBACK } from '@store/firestore-collections';
// Firebase
import { firestore } from 'firebase/app';

@Component({
    selector: 'welcome-customer',
    templateUrl: './welcome-customer.component.html',
    styleUrls: ['./welcome-customer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WelcomeCustomerComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @Output() handleClickButton = new EventEmitter();

    isUserLogged = false;

    //prettier-ignore
    constructor(
        private activatedRoute: ActivatedRoute,
        private firestoreService: FirestoreService,
        private layoutUtilsService: LayoutUtilsService,
        private router: Router,
        private store: Store<AppState>
    ) {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.checkIsUserLogged();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    needPayment() {
        this.firestoreService.updateAt(`${FS_PATH_FEEDBACK}/needPayement`, { counter: firestore.FieldValue.increment(1) });
        this.layoutUtilsService.showActionNotification("C'est noté, merci pour votre retour !", MessageType.Success, 3000, true, false);
    }

    viewPage(page: 'catalogs' | 'suppliers') {
        let url = '';
        if (this.isUserLogged) {
            url = page === 'catalogs' ? '../mp/stores' : '../mp/suppliers/search';
        } else {
            url = page === 'catalogs' ? './items' : './suppliers';
        }
        this.router.navigate([url], { relativeTo: this.activatedRoute });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    checkIsUserLogged(): void {
        this.subs.sink = this.store.select(isAuthSuccess).subscribe((isAuthSuccess) => (this.isUserLogged = isAuthSuccess));
    }
}
