// Angular
import { Injectable } from '@angular/core';
// AngularFire
import { AngularFireFunctions } from '@angular/fire/functions';
// Core
import { FirestoreService } from './firestore.service';

const FS_PATH_ERRORS = '_errors';

@Injectable({
    providedIn: 'root',
})
export class LogService {
    //prettier-ignore
    constructor(
        private aff: AngularFireFunctions,
        private firestoreService: FirestoreService,
    ) {

    }

    logError(className: string, functionName: string, error: Error): void {
        const data = {
            function: `${className} - ${functionName}`,
            error: {
                message: error.message,
                name: error.name,
                stack: error.stack,
            },
        };

        // Display in console
        console.group();
        console.warn(`${className} - ${functionName}`);
        console.error(error);
        console.groupEnd();

        // Call cloud function to save log in Google Logging
        this.aff.httpsCallable('logger-logErrors')(data);
    }

    logErrorsInFS(data: any): void {
        this.firestoreService.updateAt(FS_PATH_ERRORS, data);
    }
}
