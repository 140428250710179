// Angular
import { Injectable } from '@angular/core';

// RXJS
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CategoriesComponentService {
    private categories$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

    constructor() {
        this.categories$.next([]);
    }

    getCategories(): Observable<any[]> {
        return this.categories$.asObservable();
    }

    setCategories(categoriesSelected: any[]): void {
        this.categories$.next(categoriesSelected.map((cat) => cat.id));
    }
}
