import { Action } from '@ngrx/store';
import { AuthUserModel } from '../_models/auth.model';

export enum AuthActionTypes {
    Authenticated = '[Auth] Authenticated',
    AuthError = '[Auth] Error',
    CredentialsLogin = '[Auth] Credentials Login Attempt',
    CredentialsReAuthentication = '[Auth] Credentials ReAuthentication attempt',
    DeleteAccount = '[Auth] Delete Account',
    DeleteAccountSuccess = '[Auth] Delete Account Success',
    DeleteAccountError = '[Auth] Delete Account Error',
    GetUser = '[Auth] Get User',
    Logout = '[Auth] Logout',
    NotAuthenticated = '[Auth] Not Authenticated',
    ReAuthenticationError = '[Auth] ReAuthentication Error',
    ReAuthenticationSuccess = '[Auth] ReAuthentication Success',
    RegisterLinkError = '[Auth] Register Link Error',
    RegisterLinkSuccess = '[Auth] Register Link Success',
    RefreshToken = '[Auth] ReFresh Token',
    ResetAuthState = '[Auth] Reset State',
    SendVerificationEmail = '[Auth] Send Verification Email',
    SendRegisterLink = '[Auth] Send Register Link',
    VerificationEmailError = '[Auth] Verification Email Error',
    VerificationEmailSent = '[Auth] Verification Email Sent',
}

export class GetUser implements Action {
    readonly type = AuthActionTypes.GetUser;
    constructor(public payload?: any) {}
}

export class Authenticated implements Action {
    readonly type = AuthActionTypes.Authenticated;
    constructor(public payload: { user: AuthUserModel }) {}
}

export class NotAuthenticated implements Action {
    readonly type = AuthActionTypes.NotAuthenticated;
    constructor(public payload?: any) {}
}

export class AuthError implements Action {
    readonly type = AuthActionTypes.AuthError;
    constructor(public payload?: any) {}
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
    constructor(public payload?: any) {}
}

export class CredentialsLogin implements Action {
    readonly type = AuthActionTypes.CredentialsLogin;
    constructor(public payload?: any) {}
}

export class CredentialsReAuthentication implements Action {
    readonly type = AuthActionTypes.CredentialsReAuthentication;
    constructor(public email: string, public password: string, public tenantId?: string) {}
}

export class ReAuthenticationSuccess implements Action {
    readonly type = AuthActionTypes.ReAuthenticationSuccess;
}

export class ReAuthenticationError implements Action {
    readonly type = AuthActionTypes.ReAuthenticationError;
    constructor(public payload?: any) {}
}

export class SendRegisterLink implements Action {
    readonly type = AuthActionTypes.SendRegisterLink;
    constructor(public payload: { email: string; tenantName: string }) {}
}

export class RegisterLinkSuccess implements Action {
    readonly type = AuthActionTypes.RegisterLinkSuccess;
}

export class RegisterLinkError implements Action {
    readonly type = AuthActionTypes.RegisterLinkError;
    constructor(public payload: { code: string; message: string }) {}
}

export class SendVerificationEmail implements Action {
    readonly type = AuthActionTypes.SendVerificationEmail;
    constructor(public payload: { redirectUrl: string }) {}
}

export class VerificationEmailError implements Action {
    readonly type = AuthActionTypes.VerificationEmailError;
    constructor(public payload: { code: string; message: string }) {}
}

export class VerificationEmailSent implements Action {
    readonly type = AuthActionTypes.VerificationEmailSent;
}

export class DeleteAccount implements Action {
    readonly type = AuthActionTypes.DeleteAccount;
}

export class DeleteAccountSuccess implements Action {
    readonly type = AuthActionTypes.DeleteAccountSuccess;
}

export class DeleteAccountError implements Action {
    readonly type = AuthActionTypes.DeleteAccountError;
    constructor(public payload: { code: string; message: string }) {}
}

export class ResetAuthState implements Action {
    readonly type = AuthActionTypes.ResetAuthState;
}

export class RefreshToken implements Action {
    readonly type = AuthActionTypes.RefreshToken;
}

export type AuthActionsUnion = GetUser | Authenticated | NotAuthenticated | ReAuthenticationSuccess | ReAuthenticationError | CredentialsReAuthentication | CredentialsLogin | AuthError | Logout | SendVerificationEmail | VerificationEmailSent | VerificationEmailError | DeleteAccount | DeleteAccountError | DeleteAccountSuccess | ResetAuthState | RefreshToken | SendRegisterLink | RegisterLinkSuccess | RegisterLinkError;
