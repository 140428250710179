// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NGRX
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// RXJS
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
// Core
import { AuthUserModel, getUser, isUser } from '@core/auth';
import { AppState } from '@core/reducers';
import { FirestoreService } from '@core/services';
import { QueryFSModel } from '@core/_base/crud';
// Store
import { RequestLineModel, RequestModel } from './requests.model';
import { FS_PATH_REQUESTS, FS_PATH_REQUESTS_LINES, FS_TENANT_CUSTOMERS } from '@store/firestore-collections';
// Lodash
import { cloneDeep, identity, isNil, omitBy, pickBy } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class RequestsDataService extends DefaultDataService<any> {
    isUserRole: boolean;
    user: AuthUserModel;

    constructor(private firestoreService: FirestoreService, http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private store: Store<AppState>) {
        super('Requests', http, httpUrlGenerator);

        // Get connected user
        this.store
            .select(getUser)
            .pipe(filter((user) => user != undefined))
            .subscribe((user) => (this.user = user));

        // Check if userRole
        this.store.select(isUser).subscribe((isUser) => (this.isUserRole = isUser));
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getAll(): Observable<any[]> {
        return this._getData();
    }

    getWithQuery(queryParams: QueryParams | string): Observable<any[]> {
        const queryArray = this.firestoreService.getQueryFSModel(queryParams);
        return this._getData(queryArray);
    }

    add(request: RequestModel): Observable<any> {
        // Clone object to modify it before save
        const _request = cloneDeep(request);

        // Delete request lines to save them in sub collection
        const requestLines = request.requestLines;
        delete _request.requestLines;

        // Add request and add pre request lines
        return this.firestoreService.updateAt(FS_PATH_REQUESTS, Object.assign({}, _request), FS_TENANT_CUSTOMERS).pipe(
            switchMap((doc) => {
                const requestId = doc.id;
                return combineLatest([
                    of(doc),
                    requestLines.map((requestLine) => {
                        return this.addOrUpdateRequestLine(requestId, requestLine);
                    }),
                ]);
            }),
            // Return object with id to save it in store
            map((res) => {
                return { id: res[0].id, ...request };
            }),
        );
    }

    delete(requestId: string): Observable<any> {
        return this.firestoreService.delete(`${FS_PATH_REQUESTS}/${requestId}`, FS_TENANT_CUSTOMERS);
    }

    update(request: Update<RequestModel>) {
        // Clone object to modify it before save
        let _request = cloneDeep(request.changes);

        // Clean object (delete null and undefined property)
        _request = omitBy(_request, isNil);

        // Delete request lines
        delete _request.requestLines;

        // Update request
        return this.firestoreService.updateAt(`${FS_PATH_REQUESTS}/${request.id}`, _request, FS_TENANT_CUSTOMERS);
    }

    /** Actions on sub collection requestLines */
    addOrUpdateRequestLine(requestId: string, requestLine: RequestLineModel): Observable<any> {
        // Clean object (delete null and undefined property)
        const _requestLine = pickBy(requestLine, identity);

        const path = `${FS_PATH_REQUESTS}/${requestId}/${FS_PATH_REQUESTS_LINES}`;
        return this.firestoreService.updateAt(`${path}/${requestLine.product.id}`, Object.assign({}, _requestLine), FS_TENANT_CUSTOMERS);
    }

    updateRequestLineQuantity(requestId: string, requestLine: RequestLineModel) {
        const path = `${FS_PATH_REQUESTS}/${requestId}/${FS_PATH_REQUESTS_LINES}/${requestLine.id}`;
        return this.firestoreService.updateAt(`${path}`, { quantity: requestLine.quantity, total: requestLine.total }, FS_TENANT_CUSTOMERS);
    }

    deleteRequestLine(requestId: string, requestLineId: string) {
        const path = `${FS_PATH_REQUESTS}/${requestId}/${FS_PATH_REQUESTS_LINES}/${requestLineId}`;
        return this.firestoreService.delete(path, FS_TENANT_CUSTOMERS);
    }

    getRequestLines(requestId: string): Observable<RequestLineModel[]> {
        const path = `${FS_PATH_REQUESTS}/${requestId}/${FS_PATH_REQUESTS_LINES}`;
        return this.firestoreService.collectionSnapShot(path, FS_TENANT_CUSTOMERS);
    }

    /************************/
    /*   Private functions  */
    /************************/
    _getData(query: QueryFSModel[] = []) {
        // Query on customer id if user has "user" role
        const queryArray = this.isUserRole ? [new QueryFSModel('user.id', '==', this.user.id)] : [];

        // Add more query parameters if exists
        queryArray.push(...query);

        // Get data
        return this.firestoreService.collection$(FS_PATH_REQUESTS, FS_TENANT_CUSTOMERS, queryArray).pipe(
            switchMap((requests: RequestModel[]) => {
                if (requests.length === 0) return of([]);

                // Query request lines
                return combineLatest(
                    requests.map((request) => {
                        const pathRequestLines = `${FS_PATH_REQUESTS}/${request.id}/${FS_PATH_REQUESTS_LINES}`;
                        return this.firestoreService.collectionSnapShot(pathRequestLines, FS_TENANT_CUSTOMERS).pipe(
                            map((requestLines) => {
                                return { ...request, requestLines: requestLines };
                            }),
                        );
                    }),
                );
            }),
        );
    }
}
