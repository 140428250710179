import { BaseModel } from '@core/_base/crud';
import { pickBy } from 'lodash';

export class InvitationModel extends BaseModel {
    accepted?: boolean;
    id: string;
    inactive: boolean;
    catalog: {
        id: string;
        name: string;
        discount: number;
    };
    customer: {
        companyId?: string;
        companyName: string;
        displayName: string;
        email: string;
        id: string;
        tenantName?: string;
    };
    supplier: {
        companyId: string;
        companyName: string;
        email: string;
        displayName: string;
        id: string;
    };

    constructor(obj?: any) {
        super();
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }
    }
}
