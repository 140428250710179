import { Observable } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RXJS
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TypesenseClearRefinementsService {
    private clear$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    clear(): void {
        this.clear$.next(true);
    }

    isClear(): Observable<boolean> {
        return this.clear$;
    }
}
