// Angular
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RXJS
import { filter, first } from 'rxjs/operators';
// NGRX
import { Store } from '@ngrx/store';
// Core
import { AuthService, AuthActions } from '@core/auth';
import { MixpanelService, UnsubscribeOnDestroy } from '@core/services';
import { LayoutUtilsService, MessageType, TypesUtilsService } from '@core/_base/crud';
import { AuthState } from '@core/auth/store/_selectors/auth.selectors';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { AddressModel, CompanyModel, ParamsService } from '@store/index';
// Firebase
import firebase from 'firebase/app';

@Component({
    selector: 'welcome-form',
    styleUrls: ['./welcome-form.component.scss'],
    templateUrl: './welcome-form.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class WelcomeFormComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('wizard', { static: true }) el: ElementRef;

    accountForm: FormGroup;
    companyForm: FormGroup;
    loading = false;
    logo: File = null;
    user: firebase.User;
    userInformations: any = {};
    wizard: any;

    //prettier-ignore
    constructor(
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private fb: FormBuilder,
        private layoutUtilsService: LayoutUtilsService,
        private mixpanelService: MixpanelService,
        private paramsService: ParamsService,
        private store: Store<AuthState>,
        private translateService: TranslateService,
        private utilsService: TypesUtilsService) {
        super();

    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.initAccountForm();
        this.initCompanyForm();
        this.checkIfWhiteLabel();

        this.getUserIdAndEmail();
    }

    ngAfterViewInit(): void {
        // Initialize form wizard
        this.wizard = new KTWizard(this.el.nativeElement, {
            startStep: 1,
        });

        // Change event
        this.wizard.on('change', (wizard) => {
            const step = wizard.currentStep;
            if (step === 1) {
                this.mixpanelService.track('Register - Complete account form', { email: this.user.email });
            }
            if (step === 2) {
                this.prepareUserInformations();
                this.mixpanelService.track('Register - Complete company form', { email: this.user.email });
            }
            // To refresh button css
            setTimeout(() => this.cdr.detectChanges(), 0);
        });
    }

    ngOnDestroy(): void {
        this.loading = false;
        this.subs.unsubscribe();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    async submit(): Promise<void> {
        try {
            this.loading = true;
            this.cdr.detectChanges();

            // If logo => upload it
            if (this.logo) {
                this.userInformations.company.logo = await this.getBase64(this.logo);
            }

            // Create user in Firestore
            await this.authService.createAccount(this.userInformations).pipe(first()).toPromise();

            //If Success
            const message = this.translateService.instant('AUTH.REGISTER.CREATE_SUCCESS');
            this.layoutUtilsService.showActionNotification(message, MessageType.Success, 5000, true, false);
            this.store.dispatch(new AuthActions.GetUser());

            this.loading = false;
            this.mixpanelService.track('Register - Complete', { email: this.user.email });
        } catch (error) {
            console.error(error);
            const message = this.translateService.instant('GENERAL.ERROR');
            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 5000, true, false);
            this.loading = false;
            this.cdr.markForCheck();
        }
    }

    renewRegister(): void {
        this.store.dispatch(new AuthActions.SendRegisterLink({ email: this.user.email, tenantName: this.paramsService.getTenantName() }));
        this.logout();
    }

    logout(): void {
        this.store.dispatch(new AuthActions.Logout());
    }

    getBase64(file): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    getUserIdAndEmail(): void {
        this.subs.sink = this.authService
            .getAuthState()
            .pipe(filter((user) => user !== null && user !== undefined))
            .subscribe((user) => {
                this.user = user;
                this.accountForm.controls.email.setValue(user.email);
                this.accountForm.controls.email.disable();
            });
    }

    initAccountForm(): void {
        this.accountForm = this.fb.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            isCustomer: [null],
            email: [''],
            phone: ['', Validators.minLength(10)],
            password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(50)])],
        });
    }

    initCompanyForm(): void {
        this.companyForm = this.fb.group({
            id: [null],
            siret: ['', Validators.compose([Validators.minLength(14), Validators.required])],
            name: ['', Validators.required],
            logo: [null],
            address1: ['', Validators.required],
            address2: [''],
            postalCode: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]{5}')])],
            city: ['', Validators.required],
        });
    }

    checkIfWhiteLabel(): void {
        this.subs.sink = this.paramsService
            .getSupplierConfig()
            .pipe(
                filter((config) => config !== null),
                first(),
            )
            .subscribe((config) => (this.userInformations.tenantName = config.id));
    }

    async prepareUserInformations(): Promise<any> {
        const accountForm = this.accountForm.controls;
        const companyForm = this.companyForm.controls;

        // Get account form informations
        const _user = {
            id: this.user.uid,
            email: this.user.email,
            firstname: this.utilsService.capitalizeWords(accountForm.firstname.value.trim()),
            lastname: this.utilsService.capitalizeWords(accountForm.lastname.value.trim()),
            displayName: null,
            profileCompleted: true,
            phone: accountForm.phone.value,
            isCustomer: accountForm.isCustomer.value,
        };
        _user.displayName = `${_user.firstname} ${_user.lastname}`;

        // Get company form informations
        const _company = new CompanyModel();
        _company.id = companyForm.id.value;
        _company.siret = companyForm.siret.value;
        _company.name = this.utilsService.capitalizeWords(companyForm.name.value.trim());

        const _address = new AddressModel();
        _address.address1 = companyForm.address1.value.trim();
        _address.address2 = companyForm.address2.value?.trim();
        _address.postalCode = companyForm.postalCode.value;
        _address.city = this.utilsService.capitalizeWords(companyForm.city.value.trim());
        _company.address = _address;

        // Create object to send to backend
        this.userInformations.user = _user;
        this.userInformations.company = _company;
    }

    setLogo(file: File): void {
        this.logo = file;
    }

    /*************/
    /*    UI     */
    /*************/
    isValid(): boolean {
        // Not valid if company already exist => change to validate subscription by admin
        return (this.wizard?.currentStep === 1 && this.accountForm.valid) || (this.wizard?.currentStep === 2 && this.companyForm.valid);
    }
}
