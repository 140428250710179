// Angular
import { Component, HostBinding, OnInit, Input, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
// RxJS
import { filter } from 'rxjs/operators';
// Translate
import { TranslationService } from '@core/_base/layout';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

interface LanguageFlag {
    lang: string;
    name: string;
    flag: string;
    label: string;
    active?: boolean;
}

@Component({
    selector: 'kt-language-selector',
    templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit {
    // Public properties
    @HostBinding('class') classes = '';
    @Input() iconType: '' | 'brand';
    @ViewChild('dropDown', { static: true }) dropDown: NgbDropdown;

    language: LanguageFlag;
    languages: LanguageFlag[] = [
        // {
        //     lang: 'en',
        //     name: 'Anglais',
        //     flag: './assets/media/flags/260-united-kingdom.svg',
        //     label: 'EN',
        // },
        {
            lang: 'fr',
            name: 'Français',
            flag: './assets/media/flags/195-france.svg',
            label: 'FR',
        },
    ];

    /**
     * Component constructor
     *
     * @param translationService: TranslationService
     * @param router: Router
     */
    constructor(private translationService: TranslationService, private router: Router) {}

    /**
     * On init
     */
    ngOnInit(): void {
        this.setSelectedLanguage();
        this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
            this.setSelectedLanguage();
        });
    }

    /**
     * Set language
     *
     * @param lang: any
     */
    setLanguage(lang: string): void {
        this.languages.forEach((language: LanguageFlag) => {
            if (language.lang === lang) {
                language.active = true;
                this.language = language;
            } else {
                language.active = false;
            }
        });
        this.translationService.setLanguage(lang);
        this.dropDown.close();
    }

    /**
     * Set selected language
     */
    setSelectedLanguage(): any {
        this.setLanguage(this.translationService.getSelectedLanguage());
    }
}
