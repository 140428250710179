// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RXJS
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
// NGRX
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity';
// Core
import { FirestoreService, StorageService } from '@core/services';
// Store
import { FS_PATH_COMPANIES, FS_PATH_CUSTOMERS_TENANT } from '@store/firestore-collections';
import { CompanyModel, CompanyScoringModel } from './company.model';
import { CustomerTenantModel } from '../_models/customer-tenant.model';
// Environment
import { environment } from '@env/environment';

// API PATH
const API_PATH = `${environment.apiBaseUrl}/companies`;
// const API_PATH = 'http://localhost:5001/stoick-dev/europe-west1/companiesApi-companies';
@Injectable({
    providedIn: 'root',
})
export class CompanyDataService extends DefaultDataService<CompanyModel> {
    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        http: HttpClient, httpUrlGenerator: HttpUrlGenerator,
        private storageService: StorageService
    ) {
        super('Company', http, httpUrlGenerator);
    }

    /*********************/
    /*        API        */
    /*********************/
    getCompanyBySiret(siret: string): Observable<any> {
        const path = `${API_PATH}/siret/${siret}`;
        return this.http.get(path);
    }

    getCompanyScoring(siret: string): Observable<CompanyScoringModel> {
        const path = `${API_PATH}/scoring?siret=${siret}`;
        return this.http.get(path).pipe(map((res) => new CompanyScoringModel(res)));
    }

    getCompanyLocation(company: CompanyModel): Observable<any> {
        const path = `${API_PATH}/location`;
        const params = {
            company: company,
        };
        return this.http.post(path, params);
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getById(companyId: string): Observable<CompanyModel> {
        return this.firestoreService.docSnapshot(`${FS_PATH_COMPANIES}/${companyId}`);
    }

    update(company: Update<CompanyModel>): Observable<CompanyModel> {
        const path = `${FS_PATH_COMPANIES}/${company.id}`;
        const _company = new CompanyModel(company.changes);

        let logo$ = of(_company.logo);
        if (_company.logo instanceof File) {
            logo$ = this.saveLogo(_company.logo);
        }

        return logo$.pipe(
            switchMap((logo) => {
                if (logo !== undefined) _company.logo = logo;
                return this.firestoreService.updateAt(path, _company).pipe(map(() => _company));
            }),
        );
    }

    uploadLogo(companyId: string, logo: File): Observable<void> {
        return this.saveLogo(logo).pipe(
            switchMap((urlLogo) => {
                return this.firestoreService.updateAt(`${FS_PATH_COMPANIES}/${companyId}`, { logo: urlLogo });
            }),
        );
    }

    /****************************/
    /*      Customer tenants    */
    /****************************/
    getCustomersTenant(companyId: string): Observable<CustomerTenantModel> {
        return this.firestoreService.docSnapshot(`${FS_PATH_CUSTOMERS_TENANT}/${companyId}`).pipe(map((res) => new CustomerTenantModel(res)));
    }

    updateCustomersTenant(customerTenant: CustomerTenantModel): Observable<CustomerTenantModel> {
        const path = `${FS_PATH_CUSTOMERS_TENANT}/${customerTenant.id}`;
        return this.firestoreService.updateAt(path, customerTenant).pipe(map(() => customerTenant));
    }

    /*********************/
    /*      STORAGE      */
    /*********************/
    deleteLogo(url: string): Promise<any> {
        return this.storageService.deleteFile(url);
    }

    saveFile(file: File) {
        return this.storageService.uploadFile(file, 'kbis');
    }

    saveLogo(file: File): Observable<string> {
        return this.storageService.uploadFile(file, 'logo');
    }
}
