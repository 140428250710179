import { BaseModel } from '@core/_base/crud';

import { AddressModel } from '@store/_models/address.model';
import { BillingMethod } from '@store/_models/customer-tenant.model';
import { CatalogModel } from '@store/catalogs/catalog.model';
import { CompanyModel } from '@store/company/company.model';
import { ContactModel } from '@store/_models/contact.model';
import { OpeningTime } from '@store/_models/opening-time.model';
import { PaymentChoice } from '@store/_models/customer-tenant.model';

import { pickBy } from 'lodash';
export class CustomerModel extends BaseModel {
    address: AddressModel;
    billingContact: ContactModel;
    billingMethod?: BillingMethod;
    catalogId?: string;
    catalogDiscount?: number;
    catalog?: CatalogModel;
    contact?: ContactModel;
    company?: CompanyModel;
    name: string;
    id?: string;
    inactive = false;
    lastOrder?: string;
    nbOrders: number;
    openingTime?: OpeningTime[];
    paymentChoice?: PaymentChoice;
    siret?: string;
    turnover: number;

    constructor(obj?: any) {
        super();

        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);

            if (obj.address) {
                this.address = new AddressModel(obj.address);
            }

            if (obj.billingContact) {
                this.billingContact = new ContactModel(obj.billingContact);
            }

            if (obj.catalog) {
                this.catalog = new CatalogModel(obj.catalog);
            }

            if (obj.openingTime) {
                this.openingTime = obj.openingTime.map((item) => new OpeningTime(item));
            }
        }
    }

    getAverageCart(): number {
        return this.turnover > 0 ? this.turnover / this.nbOrders : 0;
    }

    getBillingContact(): ContactModel {
        return this.billingContact?.lastname ? this.billingContact : this.contact;
    }

    /** Return object with only available search properties */
    getObjectToSearch(): any {
        return {
            displayName: this.contact.displayName,
            email: this.contact.email,
            phone: this.contact.phone,
            catalogName: this.catalog?.name,
            companyName: this.name,
        };
    }
}
