// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// NGRX
import { EntityAction, EntityOp } from '@ngrx/data';
import { ActionsSubject } from '@ngrx/store';
// RXJS
import { filter } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Core
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
import { AuthService } from '@core/auth';
import { LogService } from '@core/services';

@Injectable()
export class ErrorStoreService {
    //prettier-ignore
    constructor(
        private actions: ActionsSubject,
        private authService: AuthService,
        private layoutUtilsService: LayoutUtilsService,
        private logService: LogService,
        private router: Router,
        private translateService: TranslateService)
    {
        // Filter on error action in store
        this.actions.pipe(filter((action) => action.type.indexOf('error') > -1)).subscribe((error) => this.showErrorNotifications(error));
    }

    /** Show error toast when error occur in NGRX data action */
    showErrorNotifications(error: EntityAction<any> | any): void {
        console.error(error);
        // Don't display store error on login page
        if (this.router.url === '/auth/login') return;

        let message = null;
        // Get error information
        const errorMessage = error.payload.data?.error?.error?.message || null;
        const errorCode = error.payload.data?.error?.error?.status || error.type || null;

        const _error = { code: errorCode, message: errorMessage };
        this.logService.logErrorsInFS(_error);

        if (errorCode === 401) {
            message = this.translateService.instant('GENERAL.UNAUTHORIZED');
            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 5000, true, false);
            return;
        }

        // Check if error is Firebase error
        if (errorMessage && this.authService.isFireErrorMessage(errorMessage)) {
            message = this.translateService.instant(this.authService.getFireErrorMessage(errorMessage));
            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 5000, true, false);
            return;
        }

        // Store error
        switch (error.payload.entityOp || error.type) {
            case EntityOp.SAVE_ADD_ONE_ERROR:
            case EntityOp.SAVE_UPDATE_ONE_ERROR: {
                message = this.translateService.instant('GENERAL.SAVE_ERROR');
                break;
            }
            case EntityOp.SAVE_DELETE_ONE_ERROR: {
                message = this.translateService.instant('GENERAL.DELETE_ERROR');
                break;
            }
            default: {
                message = this.translateService.instant('GENERAL.ERROR');
            }
        }

        if (message) this.layoutUtilsService.showActionNotification(message, MessageType.Error, 5000, true, false);
    }
}
