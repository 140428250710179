import { firestore } from 'firebase/app';
/** Angular */
import { Injectable } from '@angular/core';

@Injectable()
export class TypesUtilsService {
    capitalizeFirstLetter(value: string): string {
        const first = value.substring(0, 1).toUpperCase();
        return first + value.substring(1).toLowerCase();
    }

    /**
     * capitalize the first letter of a string of words
     *
     * @param value: string
     */
    capitalizeWords(value?: string): string | null {
        if (!value) return null;

        return value.toLocaleLowerCase().replace(/(?:^|\s)\S/g, (a: string) => {
            return a.toUpperCase();
        });
    }

    /**
     * Convert number to string and addinng '0' before
     *
     * @param value: number
     */
    padNumber(value: number): string {
        if (this.isNumber(value)) {
            return `0${value}`.slice(-2);
        } else {
            return '';
        }
    }

    /**
     * Checking value type equals to Number
     *
     * @param value: any
     */
    isNumber(value: any): boolean {
        return !isNaN(this.toInteger(value));
    }

    getNumber(value: any): number {
        return typeof value === 'string' ? Number(value.replace(',', '.')) : Number(value);
    }

    /**
     * Covert value to number
     *
     * @param value: any
     */
    toInteger(value: any): number {
        return parseInt(`${value}`, 10);
    }

    /**
     * Convert date to string with 'MM/dd/yyyy' format
     *
     * @param date: Date
     */
    dateFormat(date: Date): string {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        if (date) {
            return `${month}/${day}/${year}`;
        }

        return '';
    }

    /**
     * Convert Date to string with custom format 'MM/dd/yyyy'
     *
     * @param date: any
     */
    dateCustomFormat(date: any): string {
        let stringDate = '';
        if (date) {
            stringDate += this.isNumber(date.month) ? this.padNumber(date.month) + '/' : '';
            stringDate += this.isNumber(date.day) ? this.padNumber(date.day) + '/' : '';

            stringDate += date.year;
        }
        return stringDate;
    }

    /**
     * Convert a number to amount format
     *
     * @param amount: number
     * @param lang: string
     * @param currency: string
     */
    getAmount(amount: number, lang: string, currency: string, digits = 2): string {
        return new Intl.NumberFormat(lang, { style: 'currency', currency: currency, minimumFractionDigits: digits }).format(amount);
    }

    getShortStringAmount(number: number): string | number {
        const SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E'];

        // what tier? (determines SI symbol)
        const tier = (Math.log10(number) / 3) | 0;

        // if zero, we don't need a suffix
        if (tier == 0) return number;

        // get suffix and determine scale
        const suffix = SI_SYMBOL[tier];
        const scale = Math.pow(10, tier * 3);

        // scale the number
        const scaled = number / scale;

        // format number and add suffix
        return scaled.toFixed(1) + suffix;
    }

    getNumberFormat(number: number, lang: string, digits = 2): string {
        return new Intl.NumberFormat(lang, { style: 'decimal', minimumFractionDigits: digits }).format(number);
    }

    /**
     * Convert string to DateFormatter (For Reactive Forms Validators)
     *
     * @param dateInStr: string (format => 'MM/dd/yyyy')
     */
    getDateFormatterFromString(dateInStr: string): any {
        if (dateInStr && dateInStr.length > 0) {
            const dateParts = dateInStr.trim().split('/');
            return [
                {
                    year: this.toInteger(dateParts[2]),
                    month: this.toInteger(dateParts[0]),
                    day: this.toInteger(dateParts[1]),
                },
            ];
        }

        const _date = new Date();
        return [
            {
                year: _date.getFullYear(),
                month: _date.getMonth() + 1,
                day: _date.getDay(),
            },
        ];
    }

    /**
     * Convert string to Date
     *
     * @param dateInStr: string (format => 'MM/dd/yyyy')
     */
    getDateFromString(dateInStr = ''): Date {
        if (dateInStr && dateInStr.length > 0) {
            const dateParts = dateInStr.trim().split('/');
            const year = this.toInteger(dateParts[2]);
            const month = this.toInteger(dateParts[0]);
            const day = this.toInteger(dateParts[1]);
            // tslint:disable-next-line:prefer-const
            const result = new Date();
            result.setDate(day);
            result.setMonth(month - 1);
            result.setFullYear(year);
            return result;
        }

        return new Date();
    }

    /**
     * Convert Date to string with format 'MM/dd/yyyy'
     * @param _date: Date?
     */
    getDateStringFromDate(_date: Date = new Date()): string {
        const month = _date.getMonth() + 1;
        const year = _date.getFullYear();
        const date = _date.getDate();
        return `${month}/${date}/${year}`;
    }

    nestedObjectAssign(a: any, b: any): any {
        if (Object(b) !== b) return b;
        if (Object(a) !== a) a = {};
        if (Object(b) instanceof Date) return b;
        if (Object(b) instanceof firestore.FieldValue) return b;
        if (Array.isArray(b)) return [...b];

        for (const key in b) {
            if (typeof b[key] !== 'function') {
                a[key] = this.nestedObjectAssign(a[key], b[key]);
            }
        }

        return a;
    }

    validateEmail(email: string): boolean {
        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return regexp.test(email);
    }

    validatePhone(phone: string): boolean {
        const regexp = /^((\+)33|0|0033)[1-9](\d{2}){4}$/g;
        return regexp.test(phone);
    }
}
