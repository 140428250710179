export class ParamsModel {
    distanceMax: number;
    distanceMin: number;
    distanceSearch: number;
    sizes: {
        eu: string[];
        gloves: string[];
        point: string[];
        shoes: string[];
        universal: string[];
    };
    termsOfServiceUrl: string;

    constructor(obj: any) {
        this.distanceMax = obj.distanceMax;
        this.distanceMin = obj.distanceMin;
        this.distanceSearch = obj.distanceSearch;
        this.sizes = obj.sizes;
        this.termsOfServiceUrl = obj.termsOfServiceUrl;
    }
}
