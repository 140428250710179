// Angular
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UnsubscribeOnDestroy } from '@core/services';
// RXJS
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
// Feature
import { SearchInputComponentService } from './search-input.service';

@Component({
    selector: 'search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent extends UnsubscribeOnDestroy implements OnInit {
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    // Set background
    @Input() backgroundColor = true;

    @Output() search = new EventEmitter();

    constructor(private searchInputComponentService: SearchInputComponentService) {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.onSearchChange();
    }

    /*****************/
    /*    ACTIONS    */
    /*****************/
    clear(): void {
        this.searchInput.nativeElement.value = '';
        this.search.next('');
        this.searchInputComponentService.setSearchValue$('');
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    onSearchChange(): void {
        this.subs.sink = fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(
                filter(Boolean),
                debounceTime(300),
                distinctUntilChanged(),
                tap(() => {
                    const searchValue = this.searchInput.nativeElement.value.toString().toLowerCase();
                    this.search.next(searchValue);
                    this.searchInputComponentService.setSearchValue$(searchValue);
                }),
            )
            .subscribe();
    }

    /************/
    /*    UI    */
    /************/
    isValue(): boolean {
        return this.searchInput.nativeElement.value !== '';
    }
}
