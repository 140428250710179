// Angular
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
// RXJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// Store
import { ParamsService } from '@store/index';

@Injectable({
    providedIn: 'root',
})
export class WhiteLabelGuard implements CanActivate {
    constructor(private paramsService: ParamsService, private router: Router) {}

    canActivate(): Observable<boolean> | boolean {
        // Check if the first segment to the url correspond to a supplier with a white label => if not redirect to market place
        return this.paramsService.isWhiteLabelExist().pipe(
            tap((isWhiteLabelExist) => {
                if (!isWhiteLabelExist) {
                    this.router.navigateByUrl('mp');
                }
            }),
        );
    }
}
