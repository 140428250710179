// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'edit-custom-supplier',
    templateUrl: './edit-custom-supplier.component.html',
    styleUrls: ['./edit-custom-supplier.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EditCustomSupplierComponent implements OnInit {
    logo: File = null;
    form: FormGroup;
    loading: boolean;

    constructor(private dialogRef: MatDialogRef<EditCustomSupplierComponent>, private fb: FormBuilder) {}

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        // Init form
        this.form = this.fb.group({
            name: ['', Validators.required],
        });
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    submit() {
        const supplier = {
            name: this.form.controls.name.value.trim(),
            logo: this.logo,
        };

        this.dialogRef.close(supplier);
    }

    uploadLogo(event: any) {
        this.logo = event.addedFiles[0];
    }

    removePhoto() {
        this.logo = null;
    }

    close() {
        this.dialogRef.close();
    }
}
