// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
// RXJS
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
// Model
import { FavoriteModel } from './favorites.model';

const CRID_LOAD = 'CRID_FAVORITES';
@Injectable({
    providedIn: 'root',
})
export class FavoritesEntityService extends EntityCollectionServiceBase<FavoriteModel> {
    private _correlationIndex = 0;

    //prettier-ignore
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('Favorites', serviceElementsFactory);
    }

    /******************/
    /**    Getter     */
    /******************/
    getEntities(): Observable<FavoriteModel[]> {
        return this.entities$.pipe(map((favorites) => favorites.map((f) => new FavoriteModel(f))));
    }

    /******************/
    /**    Actions    */
    /******************/
    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    /******************/
    /**   Selectors   */
    /******************/

    selectFavorites(): Observable<string[]> {
        return this.getEntities().pipe(map((favorites) => favorites.map((f) => f.productId)));
    }

    selectFavoritesBySupplierId(supplierId: string): Observable<string[]> {
        return this.getEntities().pipe(
            map((favorites) => favorites.filter((f) => f.supplierId === supplierId)),
            map((favorites) => favorites.map((f) => f.productId)),
        );
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }
}
