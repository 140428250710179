// Angular
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Angular Material
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// Shared
import { ActionNotificationComponent } from '@shared/components/general/action-notification/action-notification.component';
import { DeleteDialogComponent } from '@shared/components/general/delete-dialog/delete-dialog.component';
import { ConfirmDialogComponent } from '@shared/components/general/confirm-dialog/confirm-dialog.component';

export enum MessageType {
    Create,
    Read,
    Update,
    Delete,
    Error,
    Success,
}

@Injectable()
export class LayoutUtilsService {
    /**
     * Service constructor
     *
     * @param snackBar: MatSnackBar
     * @param dialog: MatDialog
     */
    constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

    /**
     * Showing (Mat-Snackbar) Notification
     *
     * @param message: string
     * @param type: MessageType
     * @param duration: number
     * @param showCloseButton: boolean
     * @param showUndoButton: boolean
     * @param undoButtonDuration: number
     * @param verticalPosition: 'top' | 'bottom' = 'top'
     */
    showActionNotification(_message: string, _type: MessageType = MessageType.Create, _duration = 10000, _showCloseButton = true, _showUndoButton = true, _undoButtonDuration = 3000, _verticalPosition: 'top' | 'bottom' = 'bottom') {
        const _data = {
            message: _message,
            snackBar: this.snackBar,
            showCloseButton: _showCloseButton,
            showUndoButton: _showUndoButton,
            undoButtonDuration: _undoButtonDuration,
            verticalPosition: _verticalPosition,
            type: _type,
            action: 'Undo',
        };
        return this.snackBar.openFromComponent(ActionNotificationComponent, {
            duration: _duration,
            data: _data,
            verticalPosition: _verticalPosition,
            panelClass: this.getClass(_type),
        });
    }

    getClass(messageType: MessageType) {
        switch (messageType) {
            case MessageType.Error:
                return 'mat-snackbar-error';

            case MessageType.Success:
                return 'mat-snackbar-success';

            default:
                return null;
        }
    }

    /**
     * Showing Confirmation (Mat-Dialog)
     *
     * @param title: stirng
     * @param description: stirng
     * @param waitDescription: string
     */
    confirm(title = '', description = '', waitDescription = '', displayButtons = true, component: any = null, duration = 500, understandButton = false, labelYesButton?: string, labelNoButton?: string) {
        return this.dialog.open(ConfirmDialogComponent, {
            data: { title, description, waitDescription, displayButtons, understandButton, component, duration, labelYesButton, labelNoButton },
            width: '440px',
        });
    }

    /**
     * Showing Confirmation (Mat-Dialog) before Entity Removing
     *
     * @param title: stirng
     * @param description: stirng
     * @param waitDescription: string
     */
    deleteElement(title = '', description = '', waitDescription = '', labelYesButton?: string, labelNoButton?: string) {
        return this.dialog.open(DeleteDialogComponent, {
            data: { title, description, waitDescription, labelYesButton, labelNoButton },
            width: '440px',
        });
    }

    /** Set focus on first invalid input in form */
    setFocusOnFirstInvalid(form: FormGroup) {
        for (const key of Object.keys(form.controls)) {
            if (form.controls[key].invalid) {
                const invalidControl: any = document.querySelector('[formcontrolname="' + key + '"]');
                invalidControl.focus();
                break;
            }
        }
    }
}
