// Angular
import { Component, Input } from '@angular/core';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { QuoteStatus } from '@store/index';

@Component({
    selector: 'quote-status',
    templateUrl: './quote-status.component.html',
})
export class QuoteStatusComponent {
    @Input() status: number;

    constructor(private translateService: TranslateService) {}

    /***********/
    /**   UI   */
    /***********/
    getStatusCSS() {
        switch (this.status) {
            case QuoteStatus.New:
                return 'label-outline-primary';

            case QuoteStatus.View:
                return 'label-outline-success';

            case QuoteStatus.NewProposal:
            case QuoteStatus.Proposal:
                return 'label-light-primary';

            case QuoteStatus.Accepted:
            case QuoteStatus.AcceptedAndCart:
                return 'label-success';

            case QuoteStatus.Canceled:
                return 'label-light-danger';

            case QuoteStatus.Refused:
                return 'label-danger';

            default:
                return null;
        }
    }

    getStatusLabel(status?: number) {
        return this.translateService.instant('QUOTES.STATUT_' + (status || this.status));
    }
}
