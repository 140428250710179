import { OnDestroy, OnInit } from '@angular/core';
// Angular
import { Component, Inject, forwardRef, Optional } from '@angular/core';
import { Input } from '@angular/core';
// Angular instantsearch
import { NgAisIndex, TypedBaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
// Instantsearch
import { connectPagination } from 'instantsearch.js/es/connectors';
import { PaginationConnectorParams, PaginationWidgetDescription, PaginationRenderState } from 'instantsearch.js/es/connectors/pagination/connectPagination';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
// Typesense
import { noop, parseNumberInput, range } from '../utils';
import { TypesensePaginationService } from './pagination.service';

@Component({
    selector: 'typesense-pagination',
    templateUrl: 'pagination.component.html',
    styleUrls: ['pagination.component.scss'],
})
export class TypesensePaginationComponent extends TypedBaseWidget<PaginationWidgetDescription, PaginationConnectorParams> implements OnInit, OnDestroy {
    // instance options
    @Input() padding: PaginationConnectorParams['padding'] = 3;
    @Input() totalPages?: PaginationConnectorParams['totalPages'];
    // TODO: check if this works, padding and totalPages are most likely strings when passed to the template

    subs: Subscription;

    public state: PaginationRenderState = {
        createURL: () => '#',
        currentRefinement: 0,
        nbHits: 0,
        nbPages: 0,
        refine: noop,
        pages: [],
        canRefine: false,
        isFirstPage: false,
        isLastPage: false,
    };

    //prettier-ignore
    constructor(
        @Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
        private paginationService: TypesensePaginationService,
    )
    {
        super('Pagination')
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.createWidget(connectPagination, {
            totalPages: parseNumberInput(this.totalPages),
        });
        super.ngOnInit();

        this.listenForResetPagination();
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    refine(event: MouseEvent, page: number): void {
        event.stopPropagation();

        event.preventDefault();

        if (page < 0 || page === this.state.currentRefinement || page >= this.state.nbPages) {
            return;
        }

        this.state.refine(page);
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    listenForResetPagination() {
        this.subs = this.paginationService
            .isReset()
            .pipe(filter((isReset) => isReset))
            .subscribe(() => this.state.refine(0));
    }

    get pages(): number[] {
        const { nbPages, currentRefinement } = this.state;

        const pagesArray: number[] = [...Array(nbPages).keys()].map(Number.call, Number);

        const pagesPadding = typeof this.padding === 'string' ? parseInt(this.padding, 10) : this.padding;

        if (pagesPadding && pagesPadding > 0) {
            // should not display pages that does not exists
            if (nbPages < pagesPadding * 2 + 1) {
                return pagesArray;
            }

            const minDelta = currentRefinement - pagesPadding - 1;
            const maxDelta = currentRefinement + pagesPadding + 1;

            if (minDelta < 0) {
                return range({
                    start: 0,
                    end: currentRefinement + pagesPadding + Math.abs(minDelta),
                });
            }

            if (maxDelta > nbPages) {
                return range({
                    start: currentRefinement - pagesPadding - (maxDelta - nbPages),
                    end: nbPages,
                });
            }

            return range({
                start: currentRefinement - pagesPadding,
                end: currentRefinement + pagesPadding + 1,
            });
        }

        return pagesArray;
    }
}
