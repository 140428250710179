// Angular
import { NgModule } from '@angular/core';
// Core
import { CoreModule } from '@core/core.module';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Layout
import { AsideLeftComponent } from './aside/aside-left.component';
import { BaseComponent } from './base/base.component';
import { BrandComponent } from './brand/brand.component';
import { ErrorPageComponent } from './content/error-page/error-page.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import { HeaderPublicComponent } from './header/header-public/header-public.component';
import { HelpComponent } from './content/help/help.component';
import { HtmlClassService } from './html-class.service';
import { MenuHorizontalComponent } from './header/menu-horizontal/menu-horizontal.component';
import { TopbarComponent } from './header/topbar/topbar.component';
import { TitlePageComponent } from './header/title-page/title-page.component';
import { TitlePageService } from './header/title-page/title-page.service';
// Shared
import { SharedModule } from '@shared/shared.module';
// Theme
import { BootstrapModule } from '@theme/bootstrap.module';
import { MaterialModule } from '@theme/material.module';
import { NgxModule } from '@theme/ngx.module';
import { HeaderActionButtonsComponent } from './header/header-action-buttons/header-action-buttons.component';
import { HeaderActionButtonsService } from './header/header-action-buttons/header-action-buttons.service';

//prettier-ignore
@NgModule({
    declarations: [
        BaseComponent,
        FooterComponent,

        // headers
        BrandComponent,
        HeaderComponent,
        HeaderActionButtonsComponent,
        HeaderMobileComponent,
        HeaderPublicComponent,
        TitlePageComponent,

        // topbar components
        TopbarComponent,

        // aside left menu components
        AsideLeftComponent,

        // horizontal menu components
        MenuHorizontalComponent,

        ErrorPageComponent,
        HelpComponent,
    ],
    exports: [
        BaseComponent,
        FooterComponent,

        // headers
        BrandComponent,
        HeaderActionButtonsComponent,
        HeaderComponent,
        HeaderMobileComponent,
        HeaderPublicComponent,

        // topbar components
        TopbarComponent,

        // aside left menu components
        AsideLeftComponent,

        // horizontal menu components
        MenuHorizontalComponent,

        ErrorPageComponent,
        HelpComponent,
    ],
    imports: [
        BootstrapModule,
        CoreModule,
        MaterialModule,
        NgxModule,
        PartialsModule,
        SharedModule
    ],
    providers: [
        HeaderActionButtonsService,
        HtmlClassService,
        TitlePageService
    ],
})
export class LayoutModule {}
