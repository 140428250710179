// Angular
import { Component, Inject, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
// Angular Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
// NGRX
import { EntityAction, EntityOp } from '@ngrx/data';
import { Store } from '@ngrx/store';
// RXJS
import { timer } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';
// Core
import { AppState } from '@core/reducers';
import { isCustomer } from '@core/auth';
// Store
import { OrderModel, OrdersEntityService, OrderStatus } from '@store/index';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
import { AuthNoticeService } from '@core/auth/store/auth-notice/auth-notice.service';
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
// Theme
import { MY_FORMATS } from '@theme/material.module';

@Component({
    selector: 'order-validate',
    templateUrl: './order-validate.component.html',
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class OrderValidateComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @ViewChild('dpFooter', { static: false }) datepickerFooter: ElementRef;
    @ViewChild('dp', { static: false }) datepicker: MatDatepicker<any>;

    comment: string;
    dateSelected: any;
    deliveryAsap: boolean;
    isConsultation = false;
    isCustomer = false;
    isValidateProgress = false;
    loading = false;
    minDateEnd = new Date();
    order: OrderModel;
    tracking: string;

    // prettier-ignore
    constructor(
        public authNoticeService: AuthNoticeService,
        private dialogRef: MatDialogRef<OrderValidateComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private layoutUtilsService: LayoutUtilsService,
        private ordersService: OrdersEntityService,
        private store: Store<AppState>,
        private translateService: TranslateService) {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.checkIfUserIsCustomer();

        this.order = new OrderModel(this.data.order);
        this.isConsultation = this.order.status === OrderStatus.ConsultationCart;

        if (this.isCustomer) {
            this.comment = this.order.commentCustomer;
        }
    }

    ngOnDestroy(): void {
        this.loading = false;
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    close(validate?: boolean): void {
        this.dialogRef.close({ validate: validate || false });
    }

    onDPClosed(): void {
        // When delivery date is selected before user click on validate request
        if (this.isValidateProgress) {
            this.validateOrder();
            this.isValidateProgress = false;
        }
    }

    openDP(): void {
        this.datepicker.open();
        if (this.isCustomer) {
            this.appendFooter();
        }
    }

    resetAsap(): void {
        this.order.deliveryAsap = null;
        this.order.deliveryDate = null;
        this.openDP();
    }

    selectAsap(): void {
        this.order.deliveryAsap = true;
        this.order.deliveryDate = null;
        this.datepicker.close();
    }

    async validateOrder(): Promise<void> {
        // Open automatically the datepicker if delivery date or delivery planned is not selected
        if (this.checkIfNeedToSelectDate()) return;

        this.loading = true;

        // Pause to have a smooth change for the user
        await timer(1000).pipe(take(1)).toPromise();

        const _order = this.prepareData();

        this.ordersService.validate(_order);

        // Wait for response
        this.subs.sink = this.ordersService.entityActions$
            .pipe(
                filter((ea: EntityAction) => ea.payload.entityOp === EntityOp.SAVE_UPDATE_ONE_SUCCESS),
                first(),
            )
            .subscribe(() => {
                this.loading = false;
                this.close(true);

                const message = this.translateService.instant(this.getValidationMessage());
                this.layoutUtilsService.showActionNotification(message, MessageType.Success, 3000, true, false);
            });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    checkIfUserIsCustomer() {
        this.subs.sink = this.store.select(isCustomer).subscribe((isCustomer) => (this.isCustomer = isCustomer));
    }

    checkIfNeedToSelectDate(): boolean {
        if (this.isConsultation) return false;

        if ((this.isCustomer && this.order.deliveryAsap == null && this.order.deliveryDate == null) || (!this.isCustomer && this.order.deliveryPlanned == null)) {
            this.openDP();
            this.isValidateProgress = true;
            return true;
        }
        return false;
    }

    prepareData(): OrderModel {
        const _order = new OrderModel();
        _order.id = this.order.id;
        _order.orderLines = this.order.orderLines;

        // Consultation
        if (this.isConsultation) {
            _order.commentCustomer = this.comment?.trim();
            _order.status = OrderStatus.Consultation;
            _order.validateDate = new Date();
        }
        // Order
        else if (this.order.status === OrderStatus.Cart) {
            _order.supplier = this.order.supplier;
            _order.clickAndCollect = this.order.clickAndCollect;
            _order.deliveryAsap = this.order.deliveryAsap;
            _order.deliveryDate = this.order.deliveryDate;
            _order.commentCustomer = this.comment?.trim();
            _order.status = OrderStatus.New;
            _order.validateDate = new Date();

            _order.calculateAndSetAmountTotal();
            _order.setShippingCost();
        } else {
            _order.deliveryPlanned = this.order.deliveryPlanned;
            _order.commentSupplier = this.comment?.trim();
            _order.status = OrderStatus.Validate;
        }

        return _order;
    }

    switchClickAndCollect(event: any): void {
        this.order.clickAndCollect = event.target.checked;
    }

    /***************/
    /*     UI      */
    /***************/
    appendFooter(): void {
        const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
        matCalendar.appendChild(this.datepickerFooter.nativeElement);
    }

    getValidateLabel(): string {
        if (this.isConsultation) {
            return 'ORDERS.CONSULTATION.SEND_BTN';
        }
        return this.order.status === OrderStatus.Cart ? 'ORDERS.EDIT.SEND_ORDER' : 'GENERAL.VALIDATE';
    }

    getValidationMessage(): string | any {
        switch (this.order.status) {
            case OrderStatus.Cart:
                return 'ORDERS.EDIT.SEND_ORDER_SUCCESS';
            case OrderStatus.ConsultationCart:
                return 'ORDERS.CONSULTATION.SEND_SUCCESS';
            case OrderStatus.New:
                return 'ORDERS.EDIT.VALIDATE_SUCCESS';
            default:
                return 'GENERAL.SAVE_SUCCESS';
        }
    }
}
