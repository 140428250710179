// Angular
import { Component, Input } from '@angular/core';
// Store
import { ManagementMode } from '@store/index';

@Component({
    selector: 'management-mode',
    templateUrl: './management-mode.component.html',
})
export class ManagementModeComponent {
    @Input() managementMode: number;
    @Input() shortDisplay = false;

    /***********/
    /**   UI   */
    /***********/
    getManagementModeIcon(): string {
        switch (Number(this.managementMode)) {
            case ManagementMode['Click & Collect']:
                return 'cart.svg';
            case ManagementMode['D-Stock']:
                return 'stock.svg';
            case ManagementMode.Services:
                return 'support.svg';
            default:
                return null;
        }
    }

    getManagementModeLabel(): string {
        return ManagementMode[this.managementMode];
    }
}
