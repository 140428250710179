// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
// NGRX
import { select, Store } from '@ngrx/store';
// RxJS
import { Observable } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Core
import { LayoutConfigService, MenuConfigService } from '@core/_base/layout';
import { LayoutConfig } from '@core/_config/layout.config';
import { MenuConfig } from '@core/_config/menu.config';
import { AuthState, currentUserPermissions, getUser } from '@core/auth/index';
import { UnsubscribeOnDestroy } from '@core/services';
import { AuthUserModel } from '@core/auth';
// Layout
import { HtmlClassService } from '../html-class.service';
// Ngx permissions
import { NgxPermissionsService } from 'ngx-permissions';
// Store
import { PermissionModel } from '@store/index';
// Loading bar
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
    selector: 'kt-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BaseComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    // Public variables
    asideSelfDisplay: true;
    contentClasses = '';
    contentContainerClasses = '';
    contentExtended: false;
    selfLayout = 'default';
    subheaderDisplay = true;
    user$: Observable<AuthUserModel>;

    // Private properties
    private currentUserPermissions$: Observable<PermissionModel[]>;

    //prettier-ignore
    constructor(
        private htmlClassService: HtmlClassService,
        private layoutConfigService: LayoutConfigService,
        public loader: LoadingBarService,
        private menuConfigService: MenuConfigService,
        private permissionsService: NgxPermissionsService,
        private router: Router,
        private store: Store<AuthState>
    ) {
        super();

        this.getUser();

        this.displayPageProgressBar();

        this.loadRolesWithPermissions();

        // register configs by version
        this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
        this.menuConfigService.loadConfigs(new MenuConfig().configs);

        // setup element classes
        this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

        this.subs.sink = this.layoutConfigService.onConfigUpdated$.subscribe((layoutConfig) => {
            // reset body class based on global and page level layout config, refer to html-class.service.ts
            document.body.className = '';
            this.htmlClassService.setConfig(layoutConfig);
        });
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.initLayoutConfig();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    displayPageProgressBar(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.loader.start();
            }
            if (event instanceof RouteConfigLoadStart) {
                this.loader.increment(35);
            }
            if (event instanceof RouteConfigLoadEnd) {
                this.loader.increment(75);
            }
            if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
                this.loader.complete();
            }
        });
    }

    getUser(): void {
        this.user$ = this.store.select(getUser);
    }

    loadRolesWithPermissions(): void {
        this.currentUserPermissions$ = this.store.pipe(select(currentUserPermissions));
        this.subs.sink = this.currentUserPermissions$.subscribe((res) => {
            if (!res || res.length === 0) {
                return;
            }

            this.permissionsService.flushPermissions();
            res.forEach((pm: PermissionModel) => this.permissionsService.addPermission(pm.name));
        });
    }

    initLayoutConfig(): void {
        const config = this.layoutConfigService.getConfig();
        // Load UI from Layout settings
        this.selfLayout = objectPath.get(config, 'self.layout');
        this.asideSelfDisplay = objectPath.get(config, 'aside.self.display');
        this.subheaderDisplay = objectPath.get(config, 'subheader.display');
        this.contentClasses = this.htmlClassService.getClasses('content', true).toString();
        this.contentContainerClasses = this.htmlClassService.getClasses('content_container', true).toString();
        this.contentExtended = objectPath.get(config, 'content.extended');

        // let the layout type change
        this.subs.sink = this.layoutConfigService.onConfigUpdated$.subscribe((cfg) => {
            setTimeout(() => {
                this.selfLayout = objectPath.get(cfg, 'self.layout');
            });
        });
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    isAuthUrl() {
        return window.location.href.includes('/auth/');
    }
}
