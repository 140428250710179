import { ElementRef } from '@angular/core';
// Angular
import { SelectionModel } from '@angular/cdk/collections';
// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, debounceTime, tap } from 'rxjs/operators';
// NGRX
import { EntityAction, EntityOp } from '@ngrx/data';
// Material
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// Shared
import { TablePaginatorComponent } from '@shared/components/index';
// Unsubscribe observables
import { SubSink } from 'subsink';
// Lodash
import { get, orderBy } from 'lodash';
// Core
import { QueryParamsModel } from './query-models/query-params.model';

export class Group {
    id: string;
    level = 0;
    parent: Group;
    expanded = true;
    totalItems = 0;
    get visible(): boolean {
        return !this.parent || (this.parent.visible && this.parent.expanded);
    }
}

export class CustomDataTable {
    // Unsubscribe observables
    private subs = new SubSink();

    // Datable
    public dataSource: MatTableDataSource<any>;
    public error$: Observable<any>;
    public error = false;
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public refreshDataDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    // Data
    public data$: Observable<any[]>;
    public dataFiltered: any[];
    public hasItems = false;
    public items: any[] = [];
    public nbItems: number;

    // QueryParams
    queryParams = new QueryParamsModel();
    public search$: Observable<string>;
    public paginator: TablePaginatorComponent;
    public sort: MatSort;

    // Selection
    selection = new SelectionModel<any>(true, []);
    isAllSelected = false;

    // Group
    groupedFilteredItems: Group[] = [];
    groupByColumns: any[] = [];
    sortField: string;

    // LoaderPosition
    matTableWrapper: ElementRef;
    tableLoader: ElementRef;

    constructor(data$?: Observable<any>, search$?: Observable<string>, loadingService$?: Observable<boolean>, errorService$?: Observable<any>) {
        this.data$ = data$;

        this.setError(errorService$);
        this.setLoading(loadingService$);
        this.setSearch(search$);

        this.onRefreshDataDisplayed();

        setTimeout(() => this.init(), 0);
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    init(): void {
        this.items = [];
        this.dataSource = new MatTableDataSource([]);

        this.subs.sink = this.data$.subscribe((items: any[]) => {
            this.items = items;
            // When data are grouped
            if (this.isDataGroup()) {
                this.refreshDataDisplayed$.next(true);
                this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
                this.dataSource.filter = performance.now().toString();
            } else {
                this.refreshDataDisplayed$.next(true);
            }
        });
    }

    disconnect(): void {
        this.loading$.complete();
        this.subs.unsubscribe();
    }

    /***************/
    /*    ERROR    */
    /***************/
    setError(errorService$: Observable<any>): void {
        if (!errorService$) return;

        this.error$ = errorService$;

        // Filter on EntityOp action when data are load in the store (Ngrx data)
        this.subs.sink = this.error$.pipe(filter((ea: EntityAction) => (ea.payload ? ea.payload.entityOp === EntityOp.QUERY_ALL_ERROR : null))).subscribe(() => {
            this.error = true;
        });
    }

    /***************/
    /*   LOADING   */
    /***************/
    loading(value: boolean): void {
        this.loading$.next(value);
    }

    setLoading(loadingService$: Observable<any>): void {
        if (!loadingService$) return;

        this.subs.sink = loadingService$.subscribe((isLoading) => {
            this.loading(isLoading);
        });
    }

    /**************/
    /*    DATA    */
    /**************/
    getDisplayedData(): any[] {
        return this.dataFiltered;
    }

    getDisplayedGroupedData(): any[] {
        return this.addGroups(this.dataFiltered);
    }

    /***************/
    /*  SELECTION  */
    /***************/
    checkAllSelected(): void {
        // Get only items, not group
        const items = this.dataSource.data.filter((d) => !d.level);
        this.isAllSelected = this.selection.hasValue() && this.selection.selected.length === items.length;
    }

    clearSelection(): void {
        this.selection.clear();
        this.isAllSelected = false;
    }

    getSelectedProducts(): any[] {
        return this.selection.selected;
    }

    masterToggle(): void {
        // Get only items, not group
        const items = this.dataSource.data.filter((d) => !d.level);

        if (this.selection.selected.length === items.length) {
            this.selection.clear();
        } else {
            items.forEach((row) => this.selection.select(row));
        }

        this.checkAllSelected();
    }

    nbItemsSelected(): number {
        return this.selection.selected.length;
    }

    selectItem(item: any) {
        this.selection.toggle(item);
        this.checkAllSelected();
    }

    selectAll(): void {
        const items = this.dataSource.data.filter((d) => !d.level);
        this.isAllSelected ? this.selection.select(...items) : this.selection.deselect(...items);
        this.isAllSelected = !this.isAllSelected;
    }

    /***************/
    /*    GROUP    */
    /***************/
    addGroups(data: any[]): any[] {
        const rootGroup = new Group();
        rootGroup.expanded = true;
        return this.getSublevel(data, 0, this.groupByColumns, rootGroup);
    }

    customFilterPredicate(data: any | Group): boolean {
        return data instanceof Group ? data.visible : this.getDataRowVisible(data);
    }

    getDataRowVisible(data: any): boolean {
        const groupRows = this.dataSource.data.filter((row) => {
            if (!(row instanceof Group)) {
                return false;
            }
            let match = true;
            this.groupByColumns.forEach((column) => {
                if (row.id === undefined || data[column.id] === undefined || row.id !== data[column.id]) {
                    match = false;
                }
            });
            return match;
        });

        if (groupRows.length === 0) {
            return true;
        }
        const parent = groupRows[0] as Group;
        return parent.visible && parent.expanded;
    }

    getGroup(): Group[] {
        return this.groupedFilteredItems.filter((el) => el instanceof Group);
    }

    getSublevel(data: any[], level: number, groupByColumns: any[], parent: Group): any[] {
        if (level >= groupByColumns.length) {
            return data;
        }

        // Build groups
        let groups = this.uniqueBy(
            data?.map((row) => {
                const result = new Group();
                result.id = row[groupByColumns[0].id];
                result.level = level + 1;
                result.parent = parent;
                for (let i = 0; i <= level; i++) {
                    result[groupByColumns[i].label] = row[groupByColumns[i].label] || ''; // Replace null by '' to order item in first
                }
                return result;
            }),
            JSON.stringify,
        );

        groups = orderBy(groups, this.groupByColumns[level].label, 'asc');

        // Build sub groups
        const currentColumn = groupByColumns[level].id;
        let subGroups = [];
        groups.forEach((group) => {
            const rowsInGroup = data.filter((row) => group.id === row[currentColumn]);
            group.totalItems = rowsInGroup.length;
            let subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);

            // Sort subgroup
            subGroup = orderBy(subGroup, [(item) => this.getValueToSort(item, this.sort?.active)], <any>this.sort?.direction);

            subGroup.unshift(group);
            subGroups = subGroups.concat(subGroup);
        });

        return subGroups;
    }

    groupHeaderClick(row: any): void {
        row.expanded = !row.expanded;
        this.dataSource.filter = performance.now().toString(); // bug here need to fix
    }

    isDataGroup(): boolean {
        return this.groupByColumns.length > 0;
    }

    isGroup(index: any, item: any): boolean {
        return item.level;
    }

    resetGroup(): void {
        this.groupByColumns = [];
        this.paginator.resetPageIndex();
        this.refreshDataDisplayed$.next(true);
    }

    uniqueBy(a: any, key: any): any {
        const seen = {};
        return a?.filter((item) => {
            const k = key(item);
            return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        });
    }

    /*******************/
    /*    PAGINATOR    */
    /*******************/
    paginateData(_items: any[]): any[] {
        const initialPos = this.queryParams.pageIndex * this.queryParams.pageSize;
        const itemsPerPage = this.queryParams.pageSize;

        if (this.isDataGroup()) {
            const itemsWithoutGroup = _items.filter((d) => !d.level);
            const itemsPaginate = itemsWithoutGroup.slice(initialPos, initialPos + itemsPerPage);
            return this.addGroups(itemsPaginate);
        } else {
            return _items.slice(initialPos, initialPos + itemsPerPage);
        }
    }

    setPaginator(paginator: TablePaginatorComponent): void {
        if (!paginator) return;

        this.paginator = paginator;

        this.subs.sink = this.paginator.pageChange$.subscribe(() => {
            this.queryParams.pageIndex = this.paginator.pageIndex;
            this.queryParams.pageSize = this.paginator.pageSize;
            this.scrollToTop();
            this.refreshDataDisplayed$.next(true);
        });
    }

    setPageSize(value: 25 | 50 | 100): void {
        this.queryParams.pageSize = value;
    }

    /*****************/
    /*    SEARCH     */
    /*****************/
    search(items: any[]): any[] {
        return items.filter((item) => Object.values(item).some((val) => val?.toString().toLowerCase().includes(this.queryParams.searchValue)));
    }

    setSearch(search$: Observable<any>): void {
        if (!search$) return;

        this.search$ = search$;

        this.subs.sink = this.search$.subscribe((value) => {
            this.queryParams.searchValue = value.toString().trim().toLowerCase();
            this.scrollToTop();
            this.paginator?.resetPageIndex();
        });
    }

    /***************/
    /*    SORT     */
    /***************/
    getValueToSort(item: any, sortActive: string): string | number {
        const value = get(item, sortActive);
        if (!value) return null;

        return isNaN(Number(value)) ? value.toLowerCase() : value;
    }

    setSort(sort: MatSort): void {
        if (!sort) return;

        this.sort = sort;

        this.queryParams.sortField = this.sort.active;
        this.queryParams.sortDirection = this.sort.direction || 'asc';

        this.subs.sink = this.sort?.sortChange.subscribe(() => {
            this.queryParams.sortField = this.sort.active;
            this.queryParams.sortDirection = this.sort.direction || 'asc';
            this.paginator?.resetPageIndex();
            this.scrollToTop();
        });
    }

    sortData(_items: any[]): any[] {
        if (this.isDataGroup()) {
            return this.addGroups(_items);
        } else {
            return orderBy(_items, this.queryParams.sortField, this.queryParams.sortDirection);
        }
    }

    onRefreshDataDisplayed(): void {
        this.refreshDataDisplayed$
            .pipe(
                filter(() => this.dataSource !== undefined),
                tap(() => {
                    this.loading(true);
                    this.selection.clear();
                    setTimeout(() => this.setLoaderPosition(), 0);
                }),
                debounceTime(300),
                tap(() => {
                    let results = (this.dataFiltered = [...this.items]);
                    this.groupedFilteredItems = this.addGroups(results);

                    if (this.queryParams.searchValue !== '') {
                        results = this.dataFiltered = this.search(results);
                        this.groupedFilteredItems = this.addGroups(results);
                    }

                    // Calculate nbItems after search
                    this.hasItems = results.length > 0;
                    this.nbItems = results.length;

                    results = this.sortData(results);

                    this.dataSource.data = this.paginateData(results);
                }),
                tap(() => {
                    this.loading(false);
                    setTimeout(() => this.setScrollPosition(), 800);
                }),
            )
            .subscribe();
    }

    /***************/
    /*    Loader   */
    /***************/
    setLoaderPosition(): void {
        if (this.tableLoader) {
            const scrollPosition = this.getScrollPosition() + 45; // 45 is header height;
            this.tableLoader.nativeElement.style.top = `${scrollPosition}px`;
        }
    }

    /************************/
    /*    Scroll position   */
    /************************/
    getScrollPosition(): number {
        return this.matTableWrapper?.nativeElement.scrollTop;
    }

    resetScrollPosition(): void {
        localStorage.removeItem('scrollPosition');
    }

    scrollToTop(): void {
        if (!this.matTableWrapper) return;

        this.resetScrollPosition();
        this.matTableWrapper.nativeElement.scrollTop = 0;
    }

    setScrollPosition(): void {
        const scrollPositionSaved = Number(localStorage.getItem('scrollPosition'));

        if (this.matTableWrapper && scrollPositionSaved > 0) {
            this.matTableWrapper.nativeElement.scrollTop = scrollPositionSaved;
        }
    }
}
