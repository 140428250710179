// Angular
import { EventEmitter, Injectable } from '@angular/core';
// RXJS
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class HeaderActionButtonsService {
    private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
    private _showButtons$: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
    private _showCancelButton$: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
    private _titleButton$: BehaviorSubject<string | null> = new BehaviorSubject<any>(null);

    onClick = new EventEmitter();
    onCancel = new EventEmitter();

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    get loading$() {
        return this._loading$.asObservable();
    }

    get showButtons$(): Observable<boolean> {
        return this._showButtons$;
    }

    get showCancelButton$(): Observable<boolean> {
        return this._showCancelButton$;
    }

    get titleButton$(): Observable<string | null> {
        return this._titleButton$;
    }

    setLoading(loading: boolean): void {
        this._loading$.next(loading);
    }

    setTitleButton(title: string) {
        this._titleButton$.next(title);
    }

    setShowButtons(show: boolean) {
        this._showButtons$.next(show);
    }

    setShowCancelButton(show: boolean) {
        this._showCancelButton$.next(show);
    }
}
