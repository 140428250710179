// Angular
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
// Angular Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_DATE_FORMATS } from '@angular/material/core';
// NGRX
import { EntityAction, EntityOp } from '@ngrx/data';
import { Store } from '@ngrx/store';
// RXJS
import { filter, first, take } from 'rxjs/operators';
// Store
import { RequestModel, RequestsEntityService, RequestStatus } from '@store/index';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Core
import { AppState } from '@core/reducers';
import { isUser } from '@core/auth';
import { UnsubscribeOnDestroy } from '@core/services';
import { AuthNoticeService } from '@core/auth/store/auth-notice/auth-notice.service';
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
// Theme
import { MY_FORMATS } from '@theme/material.module';
import { MatDatepicker } from '@angular/material/datepicker';
import { timer } from 'rxjs';

@Component({
    selector: 'request-validate',
    templateUrl: './request-validate.component.html',
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class RequestValidateComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @ViewChild('dpFooter', { static: false }) datepickerFooter: ElementRef;
    @ViewChild('dp', { static: false }) datepicker: MatDatepicker<any>;

    comment: string;
    isUser = false;
    isValidateProgress = false;
    loading = false;
    minDateEnd = new Date();
    request: RequestModel;

    // prettier-ignore
    constructor(
        public authNoticeService: AuthNoticeService, 
        private dialogRef: MatDialogRef<RequestValidateComponent>, 
        @Inject(MAT_DIALOG_DATA) public data: any, 
        private layoutUtilsService: LayoutUtilsService, 
        private requestsService: RequestsEntityService, 
        private store: Store<AppState>,
        private translateService: TranslateService
    ) {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.checkIfIsUser();
        this.request = new RequestModel(this.data.request);
    }

    ngOnDestroy() {
        this.loading = false;
        this.subs.unsubscribe();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    close(validate?: boolean): void {
        this.dialogRef.close({ validate: validate || false });
    }

    onDPClosed(): void {
        // When delivery date is selected before user click on validate request
        if (this.isValidateProgress) {
            this.validateRequest();
            this.isValidateProgress = false;
        }
    }

    openDP(): void {
        this.datepicker.open();
        if (this.isUser) {
            this.appendFooter();
        }
    }

    resetAsap(): void {
        this.request.deliveryAsap = null;
        this.request.deliveryDate = null;
        this.openDP();
    }

    selectAsap(): void {
        this.request.deliveryAsap = true;
        this.request.deliveryDate = null;
        this.datepicker.close();
    }

    async validateRequest(): Promise<void> {
        // Open automatically the datepicker if delivery date or delivery planned is not selected
        if (this.checkIfNeedToSelectDate()) return;

        this.loading = true;

        // Pause to have a smooth change for the user
        await timer(1000).pipe(take(1)).toPromise();

        // Prepare data
        const _request = this.prepareData();

        // Update request
        this.requestsService.update(_request);

        // Wait for response
        this.subs.sink = this.requestsService.entityActions$
            .pipe(
                filter((ea: EntityAction) => ea.payload.entityOp === EntityOp.SAVE_UPDATE_ONE_SUCCESS),
                first(),
            )
            .subscribe(() => {
                this.loading = false;
                this.close(true);

                const _message = this.translateService.instant('REQUESTS.EDIT.VALIDATE_SUCCESS');
                this.layoutUtilsService.showActionNotification(_message, MessageType.Success, 3000, true, false);
            });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/

    checkIfIsUser(): void {
        this.subs.sink = this.store.select(isUser).subscribe((isUser) => (this.isUser = isUser));
    }

    checkIfNeedToSelectDate(): boolean {
        if ((this.isUser && this.request.deliveryAsap == null && this.request.deliveryDate == null) || (!this.isUser && this.request.deliveryPlanned == null)) {
            this.openDP();
            this.isValidateProgress = true;
            return true;
        }
        return false;
    }

    prepareData(): RequestModel {
        const _request = new RequestModel();
        _request.id = this.request.id;
        _request.requestLines = this.request.requestLines;

        if (this.request.status === RequestStatus.Cart) {
            _request.deliveryAsap = this.request.deliveryAsap;
            _request.deliveryDate = this.request.deliveryDate;
            _request.commentUser = this.comment?.trim();
            _request.status = RequestStatus.New;
            _request.validateDate = new Date();

            _request.calculateAndSetAmountTotal();
        } else {
            _request.deliveryPlanned = this.request.deliveryPlanned;
            _request.commentManager = this.comment?.trim();
            _request.status = RequestStatus.Validate;
        }

        return _request;
    }

    /***************/
    /*     UI      */
    /***************/
    appendFooter(): void {
        const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
        matCalendar.appendChild(this.datepickerFooter.nativeElement);
    }
}
