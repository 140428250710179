import { NotifierOptions } from 'angular-notifier';

export const customNotifierOptions: NotifierOptions = {
    position: {
        horizontal: {
            position: 'right',
            distance: 12,
        },
        vertical: {
            position: 'bottom',
            distance: 12,
            gap: 10,
        },
    },
    theme: 'material',
    behaviour: {
        autoHide: 5000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 10,
    },
    animations: {
        enabled: true,
        show: {
            preset: 'slide',
            speed: 500,
            easing: 'ease',
        },
        hide: {
            preset: 'fade',
            speed: 500,
            easing: 'ease',
            offset: 50,
        },
        shift: {
            speed: 500,
            easing: 'ease',
        },
        overlap: 150,
    },
};
