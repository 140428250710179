// Angular
import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
// Angular Material
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
// RxJS
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'chips-multi-select',
    templateUrl: './chips-multi-select.component.html',
    styleUrls: ['chips-multi-select.component.scss'],
})
export class ChipsMultiSelectComponent {
    @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    @Input() items: string[] = [];

    filteredItems: Observable<string[]>;
    itemCtrl = new FormControl();
    itemsSelected: string[] = [];
    removable = true;
    selectable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    showInput = false;
    visible = true;

    constructor() {
        this.filteredItems = this.itemCtrl.valueChanges.pipe(
            startWith(null),
            map((item: string | null) => (item ? this._filter(item) : this.items.slice())),
        );
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            this.itemsSelected.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.itemCtrl.setValue(null);
    }

    remove(item: string): void {
        const index = this.itemsSelected.indexOf(item);

        if (index >= 0) {
            this.itemsSelected.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.itemsSelected.push(event.option.viewValue);
        this.itemInput.nativeElement.value = '';
        this.itemCtrl.setValue(null);
    }

    setShowInput() {
        this.showInput = true;
        this.itemInput.nativeElement.focus();
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.items.filter((item) => item.toLowerCase().indexOf(filterValue) === 0);
    }
}
