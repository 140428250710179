// Angular
import { Inject, Component, forwardRef, Optional, OnInit, OnDestroy } from '@angular/core';
// RXJS
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
// Angular Instantsearch
import { NgAisIndex, TypedBaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
// Instantsearch
import { connectClearRefinements } from 'instantsearch.js/es/connectors';
import { ClearRefinementsConnectorParams, ClearRefinementsWidgetDescription, ClearRefinementsRenderState } from 'instantsearch.js/es/connectors/clear-refinements/connectClearRefinements';
// Typesense
import { noop } from '../utils';
import { TypesenseClearRefinementsService } from './clear-refinements.service';

@Component({
    selector: 'typesense-clear-refinements',
    templateUrl: 'clear-refinements.component.html',
})
export class TypesenseClearRefinementsComponent extends TypedBaseWidget<ClearRefinementsWidgetDescription, ClearRefinementsConnectorParams> implements OnInit, OnDestroy {
    state: ClearRefinementsRenderState = {
        hasRefinements: false,
        canRefine: false,
        refine: noop,
        createURL: () => '#',
    };

    subs: Subscription;

    //prettier-ignore
    constructor(
        @Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
        private clearRefinementService: TypesenseClearRefinementsService,
    )
    {
        super('ClearRefinements');
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.createWidget(connectClearRefinements, {});

        super.ngOnInit();

        this.clearRefinementsOnSearch();
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    clearFilters(): void {
        this.state.refine();
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    clearRefinementsOnSearch() {
        this.subs = this.clearRefinementService
            .isClear()
            .pipe(filter((isClear) => isClear))
            .subscribe(() => (this.state.canRefine ? this.clearFilters() : null));
    }

    /**********************/
    /*          UI        */
    /**********************/
    get isHidden(): boolean {
        return !this.state.canRefine && this.autoHideContainer;
    }
}
