import { pickBy } from 'lodash';
import { BaseModel } from '@core/_base/crud';
import { ProductModel } from '@store/products/product.model';

export enum RequestStatus {
    Cart = 0,
    New = 1,
    AddToCart = 2,
    Validate = 3,
    Ready = 4,
    Delivered = 5,
    Done = 6,
    Refused = 7,
    Canceled = 8,
    Archived = 10,
}

export class RequestModel extends BaseModel {
    id: string;
    amountTotal: number;
    commentUser: string;
    commentManager: string;
    deliveryAsap: boolean;
    deliveryAddress: string;
    deliveryDate: string;
    deliveryPlanned: string;
    nbProducts: number;
    requestLines: RequestLineModel[] = [];
    status: number;
    user: {
        id: string;
        displayName: string;
    };
    validateDate: Date;

    constructor(obj?: any) {
        super();
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);

            if (obj.requestLines) {
                this.requestLines = obj.requestLines.map((rl) => new RequestLineModel(rl));
            }
        }
    }

    calculateAndSetAmountTotal(): void {
        this.amountTotal = 0;
        this.nbProducts = 0;

        this.requestLines.map((requestLine) => {
            this.nbProducts++;
            this.amountTotal += requestLine.total;
        });

        this.amountTotal = Math.round(this.amountTotal * 100) / 100;
    }

    isDeliveryDate(): boolean {
        return this.deliveryAsap != null && this.deliveryDate != null;
    }

    getFormatOrderId() {
        const id = this.id.toUpperCase();
        return [id.slice(0, 6), id.slice(6, 12), id.slice(12)].join('-');
    }

    /** Return object with only available search properties */
    getObjectToSearch(): any {
        const obj = {
            customerName: this.user?.displayName,
            status: this.status,
        };
        return obj;
    }

    getTotalHT(): number {
        const total = this.requestLines.reduce((p, rl) => (p += rl.quantity * rl.product.getPrice()), 0);
        return Math.round(total * 100) / 100;
    }
}

export class RequestLineModel {
    id?: string;
    product: ProductModel;
    quantity: number;
    total: number;

    constructor(obj: any) {
        if (obj) {
            this.id = obj.product.id;

            this.product = new ProductModel();
            this.product.ean = obj.product.ean || null;
            this.product.ecotax = obj.product.ecotax || 0;
            this.product.id = obj.product.id;
            this.product.maker = obj.product.maker || null;
            this.product.name = obj.product.name;
            this.product.photo = [...obj.product.photo];
            this.product.price = obj.product.price;
            this.product.priceDiscount = obj.product.priceDiscount || 0;
            this.product.provider = obj.product.provider || null;
            this.product.referenceMaker = obj.product.referenceMaker || null;
            this.product.referenceProvider = obj.product.referenceProvider || null;
            this.product.referenceSupplier = obj.product.reference || obj.product.referenceSupplier;
            this.product.size = obj.product.size || null;
            this.product.supplierCompanyId = obj.product.supplierCompanyId;
            this.product.taxes = obj.product.taxes;

            this.quantity = +obj.quantity;
            this.total = this.calculateTotal();
        }
    }

    calculateTotal() {
        return Math.round(this.quantity * this.product.getPrice() * 100) / 100;
    }
}
