import { pickBy } from 'lodash';
import { BaseModel } from '@core/_base/crud';
export class CategoryModel extends BaseModel {
    children?: CategoryModel[];
    flatName?: string;
    hasProducts: boolean;
    id?: string;
    isChild?: boolean;
    level?: number;
    name?: string;
    nameCategory?: string;
    nameSubcategory?: string;
    parent?: string;
    universe?: string;

    constructor(obj?: any) {
        super();

        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }
    }
}

/** Flat hierarchy item node with expandable and level information */
export class CategoryModelFlat {
    expandable: boolean;
    hasProducts: boolean;
    id: string;
    level: number;
    name: string;
    parent: string;
}
