// Angular
import { Injectable } from '@angular/core';
// RXJS
import { first, map, filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
// Store
import { ParamsModel } from '@store/_models/params.model';
import { FS_PATH_PARAMS } from '../firestore-collections';
// Core
import { FirestoreService } from '@core/services';

const TVA_DEFAULT = 20;

export enum SizeTypeEnum {
    Eu = 'eu',
    Shoes = 'shoes',
    Gloves = 'gloves',
    Point = 'point',
    Universal = 'universal',
}

export type SizeType = `${SizeTypeEnum}`;

export interface SupplierConfig {
    id: string;
    logo: string;
    supplierId: string;
    supplierName: string;
    tenantId: string;
    typesenseApiKey: string;
}

@Injectable({
    providedIn: 'root',
})
export class ParamsService {
    private _params: ParamsModel;
    private _logo$ = new BehaviorSubject(undefined);
    private _supplierConfig$ = new BehaviorSubject(undefined);
    private _tenantId$ = new BehaviorSubject(undefined);
    private _tenantName: string | null = null;

    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
    ) {
        if (!this._params) {
            this.loadParams();
        }

        this.loadSupplierConfig();
    }

    getLogo(): Observable<string> {
        return this._logo$;
    }

    getParams(): ParamsModel {
        return this._params;
    }

    getSizesFromExcel(sizeType: string, sizeMin: string, sizeMax: string): string[] {
        let sizes = [];

        if (!sizeType) return sizes;

        switch (sizeType) {
            case 'Européenne':
                sizes = [...this._params.sizes.eu];
                break;
            case 'Chaussures':
                sizes = [...this._params.sizes.shoes];
                break;
            case 'Gants':
                sizes = [...this._params.sizes.gloves];
                break;
            case 'Point':
                if (sizeMin == 'T00') sizeMin = '00';
                sizes = [...this._params.sizes.point];
                break;
            case 'Universelle':
                sizes = [...this._params.sizes.universal];
                break;
        }
        const minIndex = sizes.findIndex((v) => v === sizeMin.toString());
        const maxIndex = sizes.findIndex((v) => v === sizeMax.toString());

        return sizes.slice(minIndex, maxIndex + 1);
    }

    getSizesLabel(sizeType: string): string | null {
        switch (sizeType) {
            case SizeTypeEnum.Eu:
                return 'Européenne';
            case SizeTypeEnum.Shoes:
                return 'Chaussures';
            case SizeTypeEnum.Gloves:
                return 'Gants';
            case SizeTypeEnum.Point:
                return 'Point';
            case SizeTypeEnum.Universal:
                return 'Universelle';
            default:
                return null;
        }
    }

    getSizesTypeFromExcel(sizeType: string): SizeType | null {
        switch (sizeType) {
            case 'Européenne':
                return SizeTypeEnum.Eu;
            case 'Chaussures':
                return SizeTypeEnum.Shoes;
            case 'Gants':
                return SizeTypeEnum.Gloves;
            case 'Point':
                return SizeTypeEnum.Point;
            case 'Universelle':
                return SizeTypeEnum.Universal;
            default:
                return null;
        }
    }

    getSupplierConfig(): Observable<SupplierConfig> {
        return this._supplierConfig$;
    }

    getTenantId(): Observable<string> {
        return this._tenantId$;
    }

    getTenantName(): string {
        return this._tenantName;
    }

    getTVADefault(): number {
        return TVA_DEFAULT;
    }

    isStoickApp(): boolean {
        return window.location.pathname.includes('/mp');
    }

    // Check if the white label exist for the url supplier
    isWhiteLabelExist(): Observable<boolean> {
        return this._supplierConfig$.pipe(
            filter((config) => config !== undefined),
            map((config) => config?.supplierName !== undefined),
        );
    }

    loadParams(): void {
        this.firestoreService
            .docSnapshot(FS_PATH_PARAMS)
            .pipe(first())
            .subscribe((res) => (this._params = new ParamsModel(res)));
    }

    // TODO => refacto
    loadSupplierConfig(): void {
        const supplierUrl = window.location.pathname.split('/')[1];
        this._tenantName = supplierUrl || null;
        if (!supplierUrl) {
            this._logo$.next(null);
            this._supplierConfig$.next(null);
            this._tenantId$.next(null);
            sessionStorage.removeItem('isWhiteLabel');
            return;
        }

        const path = `${FS_PATH_PARAMS}/suppliersConfig/${supplierUrl}`;
        this.firestoreService.docSnapshot(path).subscribe((config: SupplierConfig) => {
            if (config.supplierName) {
                this._logo$.next(config.logo);
                this._supplierConfig$.next(config);
                this._tenantId$.next(config.tenantId);
                sessionStorage.setItem('isWhiteLabel', 'true');
            } else {
                this._logo$.next(null);
                this._supplierConfig$.next(null);
                this._tenantId$.next(null);
                sessionStorage.removeItem('isWhiteLabel');
            }
        });
    }
}
