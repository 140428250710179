// Angular
import { Injectable, Injector } from '@angular/core';
// NGRX
import { Store } from '@ngrx/store';
import { EntityAction } from '@ngrx/data';
// RXJS
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
// Store
import { RequestsEntityService } from '@store/requests/requests-entity.service';
import { OrdersEntityService } from '@store/orders/orders-entity.service';
import { ProductModel } from '@store/products/product.model';
import { RequestLineModel, RequestModel } from '@store/requests/requests.model';
import { OrderLineModel, OrderModel } from '@store/orders/order.model';
// Core
import { isUser } from '@core/auth';
import { AppState } from '@core/reducers';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    isUserRole: boolean;
    service: RequestsEntityService | OrdersEntityService;

    //prettier-ignore
    constructor(
        private inject: Injector, 
        private store: Store<AppState>
    ) {
        this.store
            .select(isUser)
            .pipe(first())
            .subscribe((isUser) => {
                this.isUserRole = isUser;
                this.service = isUser ? <RequestsEntityService>this.inject.get(RequestsEntityService) : <OrdersEntityService>this.inject.get(OrdersEntityService);

                this.loadCart();
            });
    }

    addToCart(product: ProductModel, quantity: number): Observable<any> {
        return this.service.addToCart(product, quantity);
    }

    deleteCart(item: OrderModel | RequestModel): Observable<any | null> {
        if (this.service instanceof OrdersEntityService) {
            return this.service.deleteOrder(<OrderModel>item);
        } else {
            return this.service.deleteRequest(<RequestModel>item);
        }
    }

    getEntityActions(): Observable<EntityAction<any>> {
        return this.service.entityActions$;
    }

    getErrors(): Observable<EntityAction<any>> {
        return this.service.errors$;
    }

    getLoading(): Observable<boolean> {
        return this.service.loading$;
    }

    isRequestService(): boolean {
        return this.service instanceof RequestsEntityService;
    }

    loadCart(): void {
        this.service.loadCart().pipe(first()).subscribe();
    }

    removeFromCart(product: ProductModel, item: OrderModel | RequestModel): void {
        if (this.service instanceof OrdersEntityService) {
            this.service.removeFromCart(<OrderModel>item, product);
        } else {
            this.service.removeFromCart(<RequestModel>item, product);
        }
    }

    selectCart(): Observable<OrderModel[]> | Observable<RequestModel[]> {
        return this.service.selectCart();
    }

    selectCartNbItems(): Observable<number> {
        return this.service.selectCartNbItems();
    }

    updateSublineQuantity(item: OrderModel | RequestModel, subLine: OrderLineModel | RequestLineModel) {
        if (this.service instanceof OrdersEntityService) {
            return this.service.updateOrderLineQuantity(<OrderModel>item, subLine);
        } else {
            return this.service.updateRequestLineQuantity(<RequestModel>item, subLine);
        }
    }

    validate(items: RequestModel | OrderModel): void {
        this.isUserRole ? (<RequestsEntityService>this.service).validate(<RequestModel>items) : (<OrdersEntityService>this.service).validate(<OrderModel>items);
    }
}
