// Angular
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'range-selector',
    templateUrl: './range-selector.component.html',
    styleUrls: ['./range-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RangeSelectorComponent implements OnInit {
    @Input() params: string[];
    @Input() name: string;
    @Input() savedMinAndMaxRange: string[] | null;

    @Output() rangeSelected = new EventEmitter();

    filledIndexes: number[] = [];
    selectedIndexes: number[] = [];
    selectedRange: string[];

    ngOnInit(): void {
        if (this.savedMinAndMaxRange) {
            this.setSelectedIndexes(this.savedMinAndMaxRange);
        }
    }
    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    getRange(): void {
        this.selectedRange = [];
        this.params.forEach((size: string, index: number) => {
            this.filledIndexes.forEach((indexfilled: number) => {
                if (index === indexfilled) {
                    this.selectedRange.push(size);
                }
            });
        });
        this.rangeSelected.emit(this.selectedRange);
    }

    reset(): void {
        this.filledIndexes = [];
        this.selectedIndexes = [];
        this.selectedRange = [];
    }

    selectMinAndMaxRange(i: number): void {
        if (this.selectedIndexes.length < 2) {
            this.selectedIndexes.push(i);
            this.setRange();
        } else if (this.selectedIndexes.length === 2) {
            this.selectedIndexes = [];
            this.filledIndexes = [];
            this.selectedIndexes.push(i);
            this.setRange();
        }
        this.getRange();
    }

    setRange(): void {
        if (this.selectedIndexes.length === 2) {
            this.filledIndexes = [];
            this.selectedIndexes.sort((a, b) => a - b);

            const min = this.selectedIndexes[0];
            const max = this.selectedIndexes[1];

            this.params.forEach((_: string, index: number) => {
                if (index >= min && index <= max) {
                    this.filledIndexes.push(index);
                }
            });
        } else if (this.selectedIndexes.length === 1) {
            this.filledIndexes.push(this.selectedIndexes[0]);
        }
    }

    setSelectedIndexes(minAndMaxRange: string[]) {
        minAndMaxRange.forEach((range: string) => {
            const index = this.params.findIndex((value: string) => value === range);
            this.selectedIndexes.push(index);
        });

        this.setRange();
    }

    /***************/
    /*     UI      */
    /***************/

    selectedIndex(i: number): boolean {
        return this.filledIndexes.includes(i);
    }
}
