// Angular
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// Core
import { getUser } from '@core/auth';
import { AppState } from '@core/reducers';
// NGRX
import { Store } from '@ngrx/store';
// Store
import { SupplierModel, SuppliersEntityService } from '@store/index';
// RXJS
import { combineLatest, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Injectable()
export class SupplierResolver implements Resolve<SupplierModel> {
    // prettier-ignore
    constructor(
        private store: Store<AppState>,
        private suppliersService: SuppliersEntityService,
    ) {}

    resolve(): Observable<SupplierModel> {
        return this.store.select(getUser).pipe(
            switchMap((user) => {
                return combineLatest([of(user), this.suppliersService.selectEntityById(user.companyId)]);
            }),
            switchMap(([user, supplier]) => {
                return !supplier.id ? this.suppliersService.loadById(user.companyId) : of(supplier);
            }),
            first(),
        );
    }
}
