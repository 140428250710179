// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// File saver
import { saveAs } from 'file-saver';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    constructor(private http: HttpClient) {}

    downloadFile(url: string, nameFic: string) {
        this.http
            .get(url, { responseType: 'blob' })
            .pipe(first())
            .subscribe((blob) => saveAs(blob, nameFic));
    }
}
