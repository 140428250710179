// Angular
import { Component } from '@angular/core';
// Material
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'email-input',
    templateUrl: './email-input.component.html',
})
export class EmailInputComponent {
    email: string = null;

    constructor(private dialogRef: MatDialogRef<EmailInputComponent>) {}

    /*************/
    /*  ACTIONS  */
    /*************/
    close(sendResult: boolean) {
        this.dialogRef.close(sendResult ? this.email : null);
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/

    /*************/
    /*    UI     */
    /*************/
}
