// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RXJS
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
// NGRX
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
// Core
import { FirestoreService } from '@core/services';
// Store
import { FS_PATH_FAVORITES, FS_TENANT_USERS } from '@store/firestore-collections';
import { FavoriteModel } from './favorites.model';

@Injectable({
    providedIn: 'root',
})
export class FavoritesDataService extends DefaultDataService<FavoriteModel> {
    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
    ) {
        super('Favorites', http, httpUrlGenerator);
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getAll(): Observable<FavoriteModel[]> {
        return this.firestoreService.collection$(FS_PATH_FAVORITES, FS_TENANT_USERS).pipe(map((favorites) => favorites.map((f) => new FavoriteModel(f))));
    }

    add(favorite: FavoriteModel): Observable<any> {
        const path = `${FS_PATH_FAVORITES}/${favorite.productId}`;
        return this.firestoreService.updateAt(path, favorite, FS_TENANT_USERS).pipe(map(() => favorite));
    }

    delete(id: string): Observable<number | string> {
        const path = `${FS_PATH_FAVORITES}/${id}`;
        return this.firestoreService.delete(path, FS_TENANT_USERS).pipe(map(() => id));
    }
}
