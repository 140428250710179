// Angular
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RXJS
import { filter, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
// NGRX
import { EntityAction, EntityOp } from '@ngrx/data';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
import { LayoutUtilsService, MessageType, TypesUtilsService } from '@core/_base/crud';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { ContactModel, SupplierModel, SuppliersEntityService } from '@store/index';
// Lodash
import { cloneDeep } from 'lodash';

@Component({
    selector: 'supplier-edit',
    templateUrl: './supplier-edit.component.html',
    styleUrls: ['./supplier-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SupplierEditComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    filesUpload: File[] = [];
    filteredSuppliers$: Observable<any[]>;
    form: FormGroup;
    loading: boolean;
    supplier: SupplierModel;
    //prettier-ignore
    constructor(
        private cdr: ChangeDetectorRef,
        private dialogRef: MatDialogRef<SupplierEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private layoutUtilsService: LayoutUtilsService,
        private suppliersService: SuppliersEntityService,
        private translateService: TranslateService,
        private utilsService: TypesUtilsService
        ) {
        super();

        this.supplier = new SupplierModel();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.getSupplier();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    close(): void {
        this.dialogRef.close();
    }

    removeLogo(): void {
        this.filesUpload = [];
        this.supplier.logo = null;
    }

    save(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.loading = true;

        // Prepare data
        const supplier = this.prepareData();

        // Add to server
        this.supplier.id ? this.suppliersService.update(supplier) : this.suppliersService.add(supplier);

        //Wait for response
        this.subs.sink = this.suppliersService.entityActions$
            .pipe(
                filter((ea: EntityAction) => ea.payload.entityOp === EntityOp.SAVE_ADD_ONE_SUCCESS || ea.payload.entityOp === EntityOp.SAVE_UPDATE_ONE_SUCCESS),
                first(),
            )
            .subscribe(() => {
                this.loading = false;
                this.close();
                const _message = this.translateService.instant('SUPPLIERS.SAVE_SUCCESS');
                this.layoutUtilsService.showActionNotification(_message, MessageType.Success, 5000, true, false);
            });
    }

    uploadLogo(event): void {
        this.filesUpload[0] = event.addedFiles[0];
        setTimeout(() => this.cdr.detectChanges(), 150);
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    getSupplier(): void {
        this.suppliersService.selectEntityById(this.data.supplierId).subscribe((supplier) => {
            if (supplier) {
                this.supplier = cloneDeep(supplier);
            }
            this.initForm();
        });
    }

    initForm(): void {
        this.form = this.fb.group({
            name: [this.supplier.name, Validators.required],
            address: [this.supplier.address],
            firstname: [this.supplier.contact?.firstname],
            lastname: [this.supplier.contact?.lastname],
            email: [this.supplier.contact?.email, Validators.email],
            phone: [this.supplier.contact?.phone, Validators.minLength(10)],
            logo: [this.supplier.logo],
        });
    }

    prepareData(): SupplierModel {
        const controls = this.form.controls;
        const _supplier = new SupplierModel({ id: this.supplier.id });
        _supplier.name = controls.name.value.trim();
        _supplier.address = controls.address.value?.trim() || null;
        _supplier.logo = this.filesUpload.length > 0 ? this.filesUpload[0] : this.supplier.logo || null;

        const _contact = new ContactModel();
        _contact.firstname = this.utilsService.capitalizeWords(controls.firstname.value?.trim()) || null;
        _contact.lastname = this.utilsService.capitalizeWords(controls.lastname.value?.trim()) || null;
        _contact.email = controls.email.value?.trim() || null;
        _contact.phone = controls.phone.value?.trim() || null;
        _contact.displayName = `${_supplier.contact?.firstname || ''} ${_supplier.contact?.lastname || ''}`?.trim() || null;

        _supplier.contact = _contact;

        return _supplier;
    }
}
