// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// Layout
import { TranslationService } from '../_base/layout';
// Auth
import { AuthNoticeService } from '.';
// Environment
import { environment } from '@env/environment';

@Component({
    selector: 'kt-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
    // Public properties
    headerLogo: string;
    today: number = Date.now();
    version: string;

    constructor(public authNoticeService: AuthNoticeService, private translationService: TranslationService) {}

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        //Get version
        this.version = environment.version;

        this.translationService.setLanguage(this.translationService.getSelectedLanguage());
    }
}
