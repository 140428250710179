// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
// RxJS
import { Observable, combineLatest, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
// NGRX
import { Store } from '@ngrx/store';
// Auth store
import { checkHasUserPermission, isUser } from '../store/_selectors/user-permissions.selectors';
import { AuthState } from '../store/_selectors/auth.selectors';
// Store
import { RolesEntityService } from '@store/roles/roles-entity.service';

@Injectable()
export class ModuleGuard implements CanActivate {
    constructor(private rolesService: RolesEntityService, private store: Store<AuthState>, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const modulePermissionName = route.data.modulePermissionName as string;

        if (!modulePermissionName) {
            return of(false);
        }

        return this.rolesService.loaded$.pipe(
            filter((loaded) => loaded),
            switchMap(() => {
                const hasAccess$ = this.store.select(checkHasUserPermission(modulePermissionName));
                const isUser$ = this.store.select(isUser);

                return combineLatest([hasAccess$, isUser$]);
            }),
            switchMap(([hasAccess, isUser]) => {
                if (!hasAccess) {
                    if (isUser) {
                        this.router.navigateByUrl('/mp/stores');
                    } else {
                        this.router.navigateByUrl('/error/403');
                    }
                    return of(false);
                } else {
                    return of(true);
                }
            }),
        );
    }

    public canDeactivate(): boolean {
        return true;
    }
}
