// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable, EMPTY, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth store
import { AuthState, getAuthStatus } from '../store/_selectors/auth.selectors';
// Core
import { UrlService } from '@core/services';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private store: Store<AuthState>, private router: Router, private urlService: UrlService) {}

    // eslint-disable-next-line
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        // "mp" or name of white label
        const baseUrl = this.urlService.getBaseUrl(state.url);

        return this.store.pipe(
            select(getAuthStatus),
            switchMap((authStatus) => {
                if (authStatus.loading) return EMPTY;
                else {
                    // Uses account is not yet completed
                    if (authStatus.loggedIn && !authStatus.user.profileCompleted) {
                        this.router.navigateByUrl(`${baseUrl}/auth/welcome`);
                        return of(false);
                    }

                    // Uses account is not yet active
                    if (authStatus.loggedIn && authStatus.user.roles.includes('inactive')) {
                        this.router.navigateByUrl(`${baseUrl}/auth/inactive`);
                        return of(false);
                    }

                    // User is not logged
                    if (!authStatus.loggedIn) {
                        this.router.navigateByUrl(`${baseUrl}/auth/login`);
                        return of(false);
                    }

                    // User is a white label
                    if (authStatus.user.roles.includes('customerWhiteLabel')) {
                        this.router.navigateByUrl(`${baseUrl}/auth/login`);
                        return of(false);
                    }

                    return of(true);
                }
            }),
        );
    }
}

@Injectable()
export class CanActivateInactive implements CanActivate {
    constructor(private store: Store<AuthState>, private router: Router, private urlService: UrlService) {}

    // eslint-disable-next-line
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        // "mp" or name of white label
        const baseUrl = this.urlService.getBaseUrl(state.url);

        return this.store.pipe(
            select(getAuthStatus),
            switchMap((authStatus) => {
                if (authStatus.loading) return EMPTY;
                else {
                    if (!authStatus.loggedIn) {
                        this.router.navigateByUrl(`${baseUrl}/auth/login`);
                        return of(false);
                    }

                    if (authStatus.loggedIn && !authStatus.user.roles.includes('inactive')) {
                        this.router.navigateByUrl(`${baseUrl}`);
                        return of(false);
                    }

                    return of(true);
                }
            }),
        );
    }
}

@Injectable()
export class CanActivateWelcome implements CanActivate {
    constructor(private store: Store<AuthState>, private router: Router, private urlService: UrlService) {}
    // eslint-disable-next-line
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // "mp" or name of white label
        const baseUrl = this.urlService.getBaseUrl(state.url);

        return this.store.pipe(
            select(getAuthStatus),
            switchMap((authStatus) => {
                if (authStatus.loading) return EMPTY;
                else {
                    if (!authStatus.loggedIn) {
                        this.router.navigateByUrl(`${baseUrl}/auth/login`);
                    }

                    if (authStatus.loggedIn && authStatus.user.profileCompleted) {
                        this.router.navigateByUrl(`${baseUrl}`);
                    }

                    return of(true);
                }
            }),
        );
    }
}
