// Angular
import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatDialog } from '@angular/material/dialog';
//Store
import { NotificationModel, NotificationsEntityService, NotificationType } from '@store/index';
// Angular Notifier
import { NotifierService } from 'angular-notifier';
// Lodash
import { differenceBy } from 'lodash';
// RXJS
import { map } from 'rxjs/operators';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
// Shared
import { AcceptInvitationComponent } from '@shared/components/business/accept-invitation/accept-invitation.component';

@Component({
    selector: 'notifications-alert',
    templateUrl: './notifications-alert.component.html',
    styleUrls: ['./notifications-alert.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NotificationsAlertComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @ViewChild('customNotification', { static: true }) customNotificationTmpl;

    notifications: NotificationModel[];

    //prettier-ignore
    constructor(
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private notificationsService: NotificationsEntityService,
        private notifierService: NotifierService,
        private router: Router,
    ) {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.listenNewNotifications();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /***************/
    /*   ACTIONS  */
    /***************/
    close(event: MouseEvent, notification: NotificationModel) {
        event.stopPropagation();
        this.markAsSeen(notification);
        this.notifierService.hide(notification.id);
        this.notifierService.hide;
    }

    redirect(notification: NotificationModel): void {
        if (notification.type === NotificationType.Catalog) {
            this.acceptInvitation(notification);
            return;
        }

        const link = notification.getLink();
        this.router.navigate(link.url, { relativeTo: this.activatedRoute, queryParams: link.queryParams });

        setTimeout(() => this.markAsSeen(notification), 500);
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    acceptInvitation(notification: NotificationModel) {
        const dialogRef = this.dialog.open(AcceptInvitationComponent, {
            data: { notification: notification },
            width: '600px',
        });

        this.subs.sink = dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.notificationsService.delete(notification.id);
            }
        });
    }

    listenNewNotifications(): void {
        this.subs.sink = this.notificationsService
            .selectNotSeenNotifications()
            .pipe(
                map((notifications: NotificationModel[]) => {
                    const newNotifications = differenceBy(notifications, this.notifications, 'id');
                    newNotifications.forEach((notification) => this.showNotifications(notification));
                    this.notifications = notifications;
                }),
            )
            .subscribe();
    }

    markAsSeen(notification: NotificationModel): void {
        this.notificationsService.update({ id: notification.id, seen: true });
    }

    showNotifications(notification: NotificationModel): void {
        const notificationOptions = {
            id: notification.id,
            message: notification.message,
            type: null,
            template: this.customNotificationTmpl,
            notification: notification,
        };
        this.notifierService.show(notificationOptions);
    }

    /***************/
    /*     UI      */
    /***************/
    getIcon(notification: NotificationModel): string {
        return notification.getIcon();
    }
}
