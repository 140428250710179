// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// NGRX
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity';
// RXJS
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
// Core
import { FirestoreService } from '@core/services';
// Store
import { FS_PATH_CONTACTS, FS_PATH_CUSTOMERS, FS_TENANT_SUPPLIERS } from '@store/firestore-collections';
import { CustomerModel } from './customer.model';
import { CatalogModel } from '@store/catalogs/catalog.model';
import { ContactModel } from '@store/_models/contact.model';
// Environment
import { environment } from '@env/environment';

// API_PATH
const API_PATH = `${environment.apiBaseUrl}/users`;
// const API_PATH = 'http://localhost:5001/stoick-dev/europe-west1/usersApi-users';

@Injectable({
    providedIn: 'root',
})
export class CustomersDataService extends DefaultDataService<CustomerModel> {
    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator
    ) {
        super('Customers', http, httpUrlGenerator);
    }

    /*********************/
    /*        API        */
    /*********************/
    searchCustomer(email: string, phone: string): Observable<CustomerModel> {
        let params = new HttpParams();
        if (email) params = params.append('email', email);
        else if (phone) params = params.append('phone', phone);

        //prettier-ignore
        return this.http.get<any[]>(`${API_PATH}/customer`, { params: params }).pipe(map((c) => new CustomerModel(c)));
    }

    sendInvitation(customer: CustomerModel, catalog: CatalogModel, tenantName?: string): Observable<any> {
        const path = `${API_PATH}/sendInvitation`;
        return this.http.post(path, { customer: customer, catalog: catalog, tenantName: tenantName });
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getAll(): Observable<CustomerModel[]> {
        return this.firestoreService.collection$(FS_PATH_CUSTOMERS, FS_TENANT_SUPPLIERS).pipe(
            switchMap((customers: CustomerModel[]) => {
                if (customers.length === 0) return of([]);

                // Query contacts
                return combineLatest(customers.map((customer) => this._getContact(customer)));
            }),
        );
    }

    add(customer: CustomerModel): Observable<CustomerModel> {
        const _company = {
            id: customer.company.id,
            catalogId: customer.catalogId,
            name: customer.company.name,
            address: customer.company.address,
            siret: customer.company.siret,
            ...(customer.company.logo && { logo: customer.company.logo }),
            _createdDate: this.firestoreService.getFirestoreTimestamp(),
            _createdUserId: localStorage.getItem('userId'),
        };

        const createCompany$ = this.firestoreService.updateAt(`${FS_PATH_CUSTOMERS}/${customer.company.id}`, _company, FS_TENANT_SUPPLIERS);

        const _customer = {
            id: customer.contact.id,
            displayName: customer.contact.displayName,
            email: customer.contact.email,
            ...(customer.contact.phone && { phone: customer.contact.phone }),
            _createdDate: this.firestoreService.getFirestoreTimestamp(),
            _createdUserId: localStorage.getItem('userId'),
        };

        const createCustomerContact$ = this.firestoreService.updateAt(`${FS_PATH_CUSTOMERS}/${customer.company.id}/${FS_PATH_CONTACTS}/${customer.contact.id}`, _customer, FS_TENANT_SUPPLIERS);

        return combineLatest([createCompany$, createCustomerContact$]).pipe(map(() => customer));
    }

    update(customer: Update<CustomerModel>): Observable<CustomerModel> {
        return this.firestoreService.updateAt(`${FS_PATH_CUSTOMERS}/${customer.id}`, customer.changes, FS_TENANT_SUPPLIERS).pipe(map(() => new CustomerModel(customer.changes)));
    }

    delete(id: string): Observable<string> {
        const path = `${FS_PATH_CUSTOMERS}/${id}`;
        return this.firestoreService.delete(path, FS_TENANT_SUPPLIERS).pipe(map(() => id));
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    _getContact(customer: CustomerModel): Observable<CustomerModel> {
        const pathContacts = `${FS_PATH_CUSTOMERS}/${customer.id}/${FS_PATH_CONTACTS}`;
        // It's works for just one contact for the moment
        return this.firestoreService.collection$(pathContacts, FS_TENANT_SUPPLIERS).pipe(
            map((contacts) => {
                const _customer = new CustomerModel({ ...customer });
                _customer.contact = new ContactModel({
                    id: contacts[0]?.id,
                    displayName: contacts[0]?.displayName,
                    email: contacts[0]?.email,
                    phone: contacts[0]?.phone,
                });
                return _customer;
            }),
        );
    }
}
