// Core
import { BaseModel } from '@core/_base/crud';
import { pickBy } from 'lodash';
import moment from 'moment';

export enum NotificationType {
    Account = 'account',
    Catalog = 'catalog',
    Message = 'message',
    Order = 'order',
    Quote = 'quote',
    Request = 'request',
    Supplier = 'supplier',
    Users = 'users',
}

export type Link = {
    url: string[];
    queryParams: any;
};

export class NotificationModel extends BaseModel {
    id: string;
    actionId: string;
    day: string;
    message: string;
    time: string;
    type: NotificationType;
    seen: boolean;

    constructor(obj?: any) {
        super();
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }

        if (this._createdDate || this._updatedDate) {
            this.day = moment(this._createdDate || this._updatedDate).format('Do MMMM YYYY');
            this.time = moment(this._createdDate || this._updatedDate).format('LT');
        }
    }

    getIcon(): string {
        const pathIcons = './assets/media/svg/icons';
        switch (this.type) {
            case NotificationType.Account:
                return `${pathIcons}/Communication/Contact1.svg`;

            case NotificationType.Catalog:
                return `${pathIcons}/Home/Book-open.svg`;

            case NotificationType.Message:
                return `${pathIcons}/Communication/Group-chat.svg`;

            case NotificationType.Request:
            case NotificationType.Order:
                return `${pathIcons}/Communication/Clipboard-check.svg`;

            case NotificationType.Quote:
                return `${pathIcons}/Shopping/Calculator.svg`;

            case NotificationType.Supplier:
                return `${pathIcons}/Communication/Clipboard-check.svg`;

            case NotificationType.Users:
                return `${pathIcons}/Communication/Group.svg`;

            default:
                return `${pathIcons}/Communication/Clipboard-check.svg`;
        }
    }

    getLink(): any {
        switch (this.type) {
            case NotificationType.Account:
                return { url: ['./../profile'], queryParams: {} };

            case NotificationType.Message:
                return { url: ['./../messages'], queryParams: { chatRoomId: this.actionId } };

            case NotificationType.Request:
                return { url: [`./../requests/${this.actionId}`] };

            case NotificationType.Order:
                return { url: [`./../orders/${this.actionId}`] };

            case NotificationType.Quote:
                return { url: [`./../quotes/${this.actionId}`] };

            case NotificationType.Users:
                return { url: [`./../users`] };

            default:
                return null;
        }
    }
}
