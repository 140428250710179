// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// Core
import { AppState } from '@core/reducers';
import { getUser, AuthUserModel, AuthActions } from '@core/auth';
import { UnsubscribeOnDestroy } from '@core/services';
import { ChangePasswordComponent } from '@core/auth/components/change-password/change-password.component';
import { LayoutUtilsService } from '@core/_base/crud';
// Store
import { CompanyEntityService, CompanyModel } from '@store/index';
// Environment
import { environment } from '@env/environment';
import { first } from 'rxjs/operators';

@Component({
    selector: 'user-profile',
    templateUrl: './user-profile.component.html',
})
export class UserProfileComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    // Public properties
    company$: Observable<CompanyModel>;
    user$: Observable<AuthUserModel>;
    version: string;

    //prettier-ignore
    constructor(
        private companyModel: CompanyEntityService,
        private layoutUtilsService: LayoutUtilsService,
        private store: Store<AppState>,
    ) {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        // Get user
        this.user$ = this.store.pipe(select(getUser));

        // Get company
        this.company$ = this.companyModel.getCompany();

        //Get version
        this.version = environment.version;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    logout(): void {
        this.store.dispatch(new AuthActions.Logout());
    }

    resetPassword(): void {
        const dialogRef = this.layoutUtilsService.confirm('AUTH.CHANGE_PASSWORD.TITLE', null, null, false, ChangePasswordComponent);
        this.subs.sink = dialogRef.componentInstance.confirm.pipe(first()).subscribe(() => dialogRef.close());
    }

    /*************/
    /*    UI     */
    /*************/
    getBadge(_user: AuthUserModel): string {
        return _user.displayName ? _user.displayName : _user.email;
    }
}
