// Angular
import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// Shared
import { RangeSelectorComponent } from '@shared/components';
//Store
import { ParamsService } from '@store/index';
import { ParamsModel } from '@store/_models/params.model';
import { SizeType, SizeTypeEnum } from '@store/_services/params.service';

@Component({
    selector: 'size-management',
    templateUrl: './size-management.component.html',
})
export class SizeManagementComponent implements AfterViewInit, OnInit {
    @ViewChild('euRangeSelector') euRangeSelector: RangeSelectorComponent;
    @ViewChild('glovesRangeSelector') glovesRangeSelector: RangeSelectorComponent;
    @ViewChild('pointRangeSelector') pointRangeSelector: RangeSelectorComponent;
    @ViewChild('universalRangeSelector') universalRangeSelector: RangeSelectorComponent;
    @ViewChild('shoesRangeSelector') shoesRangeSelector: RangeSelectorComponent;

    @Output() sizes = new EventEmitter();

    sizeTypeEnum = SizeTypeEnum;

    params: ParamsModel;
    selectors: RangeSelectorComponent[];
    sizesSelected: string[];
    sizeType: SizeType;
    productSizes: string[];

    //prettier-ignore
    constructor(
        private paramsService: ParamsService,
        private dialogRef: MatDialogRef<SizeManagementComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.sizesSelected = this.productSizes = this.data.sizesSelected;
        this.sizeType = this.data.sizeType;
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/

    ngOnInit() {
        this.params = this.paramsService.getParams();
    }

    ngAfterViewInit() {
        this.selectors = [this.euRangeSelector, this.pointRangeSelector, this.universalRangeSelector, this.glovesRangeSelector, this.shoesRangeSelector];
    }

    /***************/
    /*   ACTIONS   */
    /***************/

    close() {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close({ sizes: this.sizesSelected, sizeType: this.sizeType });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    getSizes(event: string[], sizeType: SizeType): void {
        this.sizesSelected = event;
        this.sizeType = sizeType;
        this.resetOtherSelectors(sizeType);
    }

    getSavedRangeSizes(selectorName: SizeType): string[] | null {
        if (this.productSizes) {
            const productSizesMinMax = new Set([this.productSizes[0], this.productSizes[this.productSizes.length - 1]]);

            if (selectorName === this.sizeType) {
                return Array.from(productSizesMinMax);
            }
        }

        return null;
    }

    /***************/
    /*     UI      */
    /***************/

    resetOtherSelectors(id: string) {
        this.selectors.forEach((selector: RangeSelectorComponent) => {
            if (selector.name != id) {
                selector.reset();
            }
        });
    }
}
