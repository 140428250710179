// Angular
import { Component, OnInit, Inject, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
// Angular material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// Store
import { ProductModel } from '@store/index';

@Component({
    selector: 'select-quantity',
    templateUrl: './select-quantity.component.html',
    styleUrls: ['./select-quantity.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SelectQuantityComponent implements OnInit {
    @Input() product: ProductModel;
    @Input() showInLine = false;

    @Output() handleAddToCart = new EventEmitter();

    quantity: number;
    size: string = null;

    // prettier-ignore
    constructor(
        private dialogRef: MatDialogRef<SelectQuantityComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.product = new ProductModel(this.data?.product || null);

        this.quantity = this.product.minimumPurchase || 1;
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    addToCartOrQuotesCart(): void {
        this.dialogRef.close({ quantity: this.quantity, size: this.size });
    }

    close(): void {
        this.dialogRef.close();
    }

    /***************/
    /*     UI      */
    /***************/
    add(quantity: number): void {
        this.quantity += quantity;
    }

    getTotal(): number {
        return this.product.getPrice() * this.quantity;
    }

    isDelay() {
        return this.product.delay > 0;
    }

    isQuotation(): boolean {
        return !!this.product.quotation;
    }

    quantityChange(event: any): void {
        this.quantity = Number(event.detail.value);
    }

    removeOne(): void {
        if (this.quantity > (this.product.minimumPurchase || 1)) this.quantity--;
    }
}
