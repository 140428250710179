// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, MergeStrategy } from '@ngrx/data';
// RXJS
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';
// Store
import { OrdersDataService } from './orders-data.service';
import { OrderLineModel, OrderModel, OrderStatus } from './order.model';
import { ProductModel } from '@store/products/product.model';
import { ErrorStoreService } from '@store/error-store.service';
import { CompanyEntityService } from '@store/company/company-entity.service';
import { SuppliersEntityService } from '@store/suppliers/suppliers-entity.service';
// Core
import { getUser } from '@core/auth';
// Lodash
import { cloneDeep, orderBy } from 'lodash';
import { RequestModel } from '..';

const CRID_LOAD = 'CRID_ORDERS';
@Injectable({
    providedIn: 'root',
})
export class OrdersEntityService extends EntityCollectionServiceBase<any> {
    private customer: any;
    private _correlationIndex = 0;

    // prettier-ignore
    constructor(
        private companyService: CompanyEntityService,
        private errorStoreService: ErrorStoreService,
        private ordersDataService: OrdersDataService,
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private suppliersService: SuppliersEntityService,
    ) {
        super('Orders', serviceElementsFactory);

        this.getUserInformations();

        this.loadAll();

        // Listen for errors
        this.errors$.subscribe(e => this.errorStoreService.showErrorNotifications(e));
    }

    /******************/
    /**    Getter     */
    /******************/
    /** Use to define entities as array of Order Model */
    getEntities(): Observable<OrderModel[]> {
        return this.entities$.pipe(map((orders) => orders.map((o) => new OrderModel(o))));
    }

    /******************/
    /**   Selectors   */
    /******************/
    selectCartNbItems(): Observable<number> {
        return this.getEntities().pipe(
            map((orders) => orders.filter((o) => o.status === OrderStatus.Cart || o.status === OrderStatus.ConsultationCart)),
            map((x) => x.reduce((p, c) => (p += c.orderLines?.length), 0)),
        );
    }

    selectCart(): Observable<OrderModel[]> {
        return this.getEntities().pipe(
            map((orders) => orders.filter((o) => o.status === OrderStatus.Cart || o.status === OrderStatus.ConsultationCart)),
            map((orders) => orderBy(orders, 'supplier.name')),
        );
    }

    selectCartByProduct(product: ProductModel): Observable<OrderModel> {
        return this.getEntities().pipe(map((orders) => orders.find((o) => o.status === OrderStatus.Cart && o.supplier.id === product.supplierCompanyId)));
    }

    selectCartBySupplier(supplierId: string): Observable<OrderModel> {
        return this.getEntities().pipe(map((orders) => orders.find((o) => o.status === OrderStatus.Cart && o.supplier.id === supplierId)));
    }

    selectOrCreateCart(product: ProductModel): Observable<OrderModel> {
        return this.getEntities().pipe(
            map((orders) => orders.find((o) => o.status === OrderStatus.Cart && o.supplier.id === product.supplierCompanyId)),
            switchMap((order) => (order ? of(order) : this.createCart(product))),
        );
    }

    selectEntityById(id: string): Observable<OrderModel> {
        return this.entityMap$.pipe(map((entities) => entities[id]));
    }

    selectIsOrders(): Observable<boolean> {
        return this.getEntities().pipe(map((orders) => orders.length > 0));
    }

    selectNewOrders(): Observable<OrderModel[]> {
        return this.getEntities().pipe(
            map((orders) => orders.filter((o) => o.status === OrderStatus.New)),
            map((orders) => orderBy(orders, '_createdDate', 'desc')),
        );
    }

    selectInProgressOrders(): Observable<OrderModel[]> {
        return this.getEntities().pipe(
            map((orders) => orders.filter((o) => o.status > OrderStatus.Cart && (o.status < OrderStatus.Received || o.status === OrderStatus.Available))),
            map((orders) => orderBy(orders, '_createdDate', 'desc')),
        );
    }

    selectIsFirstOrder(): Observable<boolean> {
        return this.getEntities().pipe(map((entities) => entities.length === 1));
    }

    selectNewOrdersNumber(): Observable<number> {
        return this.entities$.pipe(
            map((orders: OrderModel[]) => orders.filter((o) => o.status === OrderStatus.New)),
            map((orders: OrderModel[]) => orders.length),
        );
    }

    selectOrders(): Observable<OrderModel[]> {
        return this.getEntities().pipe(
            map((orders) => orders.filter((o) => o.status > OrderStatus.Cart && o.status !== OrderStatus.ConsultationCart)),
            map((orders) => orderBy(orders, '_createdDate', 'desc')),
        );
    }

    /******************/
    /**    Actions    */
    /******************/
    addToCart(product: ProductModel, quantity: number): Observable<any> {
        return this.selectOrCreateCart(product).pipe(
            take(1),
            switchMap((order) => {
                // // Check if the product is already in the cart
                let _orderLine: OrderLineModel = order.orderLines?.find((o) => o.product.id === product.id);

                if (_orderLine) {
                    _orderLine.quantity += +quantity;
                    _orderLine.total = _orderLine.quantity * _orderLine.product.getPrice();
                } else {
                    _orderLine = new OrderLineModel({ product: product, quantity: quantity });
                    order.orderLines.push(_orderLine);
                }

                this.ordersDataService.addOrUpdateOrderLine(order.id, _orderLine);
                return this.update(order);
            }),
        );
    }

    async addUserDisplayNameToComment(request: RequestModel): Promise<void> {
        const carts: OrderModel[] = [];
        // Get carts where to update comment
        for await (const rl of request.requestLines) {
            const cart = (await this.selectCartByProduct(rl.product).pipe(first()).toPromise()) as OrderModel;
            carts.push(cart);
        }

        for await (const cart of carts) {
            await this.ordersDataService.addUserDisplayNameToComment(cart.id, request.user.displayName);
        }
    }

    cancelLoad() {
        this.cancel(CRID_LOAD);
    }

    deleteOrder(order: OrderModel): Observable<any> {
        const orderLines = order.orderLines;
        return combineLatest([
            this.delete(order.id),
            orderLines.map((orderLine: any) => {
                return this.ordersDataService.deleteOrderLine(order.id, orderLine.id);
            }),
        ]);
    }

    getOrderLines(orderId: string): Observable<boolean> {
        return this.ordersDataService.getOrderLines(orderId).pipe(
            map((orderLines) => {
                // Add order lines in order in store
                const _order = new OrderModel();
                _order.id = orderId;
                _order.orderLines = orderLines || [];
                this.updateOneInCache(_order);
                return true;
            }),
        );
    }

    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    loadById(id: string): Observable<any> {
        return this.getByKey(id, { correlationId: this.getCorrelationId('loadById') });
    }

    loadCart(): Observable<any[]> {
        const query: any = { query1: { field: 'status', operator: '==', value: OrderStatus.Cart } };
        return this.getWithQuery(query, { correlationId: this.getCorrelationId('loadCart') });
    }

    loadNew(): Observable<any[]> {
        const query: any = { query1: { field: 'status', operator: '==', value: OrderStatus.New } };
        return this.getWithQuery(query, { correlationId: this.getCorrelationId('loadNew') });
    }

    loadInProgress(): Observable<any[]> {
        const query: any = { query1: { field: 'status', operator: 'not-in', value: [OrderStatus.Received, OrderStatus.Canceled] } };
        return this.getWithQuery(query, { correlationId: this.getCorrelationId('loadInProgress') });
    }

    removeFromCart(order: OrderModel, product: ProductModel): void {
        this.selectCartBySupplier(order.supplier.id)
            .pipe(
                take(1),
                switchMap((order) => {
                    return combineLatest([of(order), this.ordersDataService.deleteOrderLine(order.id, product.id)]);
                }),
                map((res) => {
                    const _order = cloneDeep(res[0]);
                    // If the product is the only one in the cart => delete the order
                    if (_order.orderLines.length === 1) {
                        return this.delete(_order.id);
                    } else {
                        //If there is many products, only remove the product from the order
                        _order.orderLines = _order.orderLines.filter((o) => o.product.id !== product.id);
                        return this.update(_order);
                    }
                }),
            )
            .subscribe();
    }

    updateOrderLineQuantity(order: OrderModel, orderLine: OrderLineModel): void {
        this.ordersDataService
            .updateOrderLineQuantity(order.id, orderLine)
            .pipe(
                tap(() => this.updateOneInCache(order, { mergeStrategy: MergeStrategy.IgnoreChanges })),
                first(),
            )
            .subscribe();
    }

    validate(order: OrderModel): void {
        combineLatest([order.orderLines.map((orderLine) => this.ordersDataService.addOrUpdateOrderLine(order.id, orderLine))])
            .pipe(
                switchMap(() => this.update(order)),
                first(),
            )
            .subscribe();
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    createCart(product: ProductModel): Observable<OrderModel> {
        const supplier$ = this.suppliersService.loadSupplierById(product.supplierCompanyId);

        return supplier$.pipe(
            switchMap((supplier) => {
                const _order = new OrderModel();

                _order.customer = this.customer;
                _order.status = OrderStatus.Cart;
                _order.supplier = supplier.getObjectToAddInCart();

                return this.add(_order);
            }),
        );
    }

    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }

    getUserInformations() {
        const company$ = this.companyService.getCompany().pipe(filter((company) => company.id != null));
        const user$ = this.store.select(getUser).pipe(filter((user) => user != null));

        combineLatest([company$, user$])
            .pipe(first())
            .subscribe(([company, user]) => {
                if (user.roles?.includes('customer') || user.roles?.includes('customerWhiteLabel') || user.roles?.includes('customerWithoutMP') || user.roles?.includes('user')) {
                    this.customer = {
                        id: user.id,
                        displayName: user.displayName,
                        email: user.email,
                        ...(user.phone && { phone: user.phone }),
                        companyId: user.companyId,
                        companyName: user.companyName,
                        companyAddress: company.address.toString(),
                        siret: company.siret,
                        ...(user.tenantName && { tenantName: user.tenantName }),
                    };
                }
            });
    }
}
