// Angular
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
// Animation
import { fadeIn, fadeOut } from './carousel-image.animation';

//prettier-ignore
@Component({
    selector: 'carousel-image',
    templateUrl: './carousel-image.component.html',
    styleUrls: ['./carousel-image.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger("carouselAnimation", [
          transition("void => *", [useAnimation(fadeIn)]),
          transition("* => void", [useAnimation(fadeOut)]),
        ])
      ]
})
export class CarouselImageComponent implements OnInit {
    // TODO remplacer any par string[]
    @Input() images: any = [];

    currentSlide = 0;
    displayButtons = false;
    displayFullScreen = false;

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.preloadImages();

    }

    /*************/
    /*  ACTIONS  */
    /*************/
    onNextClick() {
        const next = this.currentSlide + 1;
        this.currentSlide = next === this.images.length ? 0 : next;
    }

    onPreviousClick() {
        const previous = this.currentSlide - 1;
        this.currentSlide = previous < 0 ? this.images.length - 1 : previous;
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    preloadImages() {
        this.images.forEach(image => {
            new Image().src = image;
        })
    }
}
