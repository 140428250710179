// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
// RXJS
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
// Store
import { CategoriesDataService } from './categories-data.service';
import { CategoryModel } from './category.model';
// Lodash
import { sortBy } from 'lodash';

const CRID_LOAD = 'CRID_CATEGORIES';

@Injectable({
    providedIn: 'root',
})
export class CategoriesEntityService extends EntityCollectionServiceBase<any> {
    private _correlationIndex = 0;

    //prettier-ignore
    constructor(
        private categoriesDataService: CategoriesDataService,
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('Categories', serviceElementsFactory);

        // this.loadAll();
    }

    /******************/
    /**    Getter     */
    /******************/
    /** Use to return array of CategoryModel */
    getEntities(): Observable<CategoryModel[]> {
        return this.entities$.pipe(map((categories) => categories.map((c) => new CategoryModel(c))));
    }

    /******************/
    /**    Actions    */
    /******************/
    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    isCategories(): Observable<boolean> {
        return this.categoriesDataService.categoriesExist();
    }

    /******************/
    /**   Selectors   */
    /******************/
    selectCategoryFlatNameById(categoryId: string): Observable<CategoryModel> {
        return this.selectFlatCategories().pipe(
            map((categories) => categories.filter((c) => c.id === categoryId)),
            map((categories) => categories[0]),
        );
    }

    /** Return child categories with flatname => '<category> - <subcategory>' */
    selectChildCategories(): Observable<CategoryModel[]> {
        return this.getEntities().pipe(
            map((categories) => {
                return this.convertToFlat(categories).filter((c) => c.isChild);
            }),
        );
    }

    /** Select category by id in store collection */
    selectEntityById(categoryId: string): Observable<CategoryModel> {
        return this.entityMap$.pipe(
            map((entities) => new CategoryModel(entities[categoryId])),
            first(),
        );
    }

    /** Return flat category / sub category label => '<category> - <subcategory>' */
    selectFlatCategories(): Observable<CategoryModel[]> {
        return this.getEntities().pipe(map((categories) => this.convertToFlat(categories)));
    }

    /** Return flat category / sub category label => '<category> - <subcategory>' */
    selectParentCategories(): Observable<CategoryModel[]> {
        return this.getEntities().pipe(
            map((categories) => categories.filter((c) => !c.parent)),
            map((categories) => sortBy(categories, 'name')),
        );
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    convertToFlat(categories: CategoryModel[]): CategoryModel[] {
        const items: CategoryModel[] = [];
        categories.forEach((cat) => {
            let item;
            if (cat.parent) {
                const parent = categories.find((c) => c.id === cat.parent);
                item = {
                    id: cat.id,
                    nameSubcategory: cat.name,
                    parent: parent.id,
                    nameCategory: parent.name,
                    flatName: parent.name + ' - ' + cat.name,
                    isChild: true,
                };
            } else {
                item = {
                    id: cat.id,
                    nameCategory: cat.name,
                    flatName: cat.name,
                    isChild: categories.findIndex((c) => c.parent === cat.id) === -1,
                };
            }
            items.push(new CategoryModel(item));
        });
        return sortBy(items, 'flatName');
    }

    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }
}
