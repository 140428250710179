import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'pdf-dropzone',
    templateUrl: './pdf-dropzone.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PdfDropzoneComponent {
    @Output() uploadedFile = new EventEmitter<File>();

    @Input() set existingFile(value: string | File) {
        this._existingFile = value;
    }
    @Input() previewName: string;

    _existingFile: string | File;
    filesUpload: File[] = [];

    // prettier-ignore
    constructor(
        private cdr: ChangeDetectorRef,
        private layoutUtilsService: LayoutUtilsService,
        private translateService: TranslateService,
    ) {}

    removeFile(): void {
        this.filesUpload = [];
        this._existingFile = null;
        this.uploadedFile.emit(null);
        setTimeout(() => this.cdr.detectChanges(), 150);
    }

    fileMaxSizeAlert(): void {
        const _message = this.translateService.instant('CHAT.MAX_FILE_SIZE');
        this.layoutUtilsService.showActionNotification(_message, MessageType.Error, 5000, true, false);
    }

    rejectedFilesAlert(event: any): void {
        if (event.reason === 'size') {
            this.fileMaxSizeAlert();
        } else if (event.reason === 'type') {
            this.wrongFileTypeAlert();
        }
    }

    uploadFile(event: any): void {
        if (event.addedFiles[0]) {
            this.filesUpload[0] = event.addedFiles[0];
            this.uploadedFile.emit(this.filesUpload[0]);
        } else if (event.rejectedFiles[0]) {
            this.rejectedFilesAlert(event.rejectedFiles[0]);
        }
        setTimeout(() => this.cdr.detectChanges(), 150);
    }

    wrongFileTypeAlert(): void {
        const _message = this.translateService.instant('CHAT.WRONG_TYPE');
        this.layoutUtilsService.showActionNotification(_message, MessageType.Error, 5000, true, false);
    }
}
