// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Moment
import * as moment from 'moment';

/**
 * Returns date with custom format
 */
@Pipe({
    name: 'datePipe',
})
export class DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value) {
            const date = value instanceof Date ? value : value.toDate();

            switch (args) {
                case 'day':
                    return moment(date).format('DD MMM YYYY');
                case 'dayAndTime':
                    return moment(date).format('DD MMM YYYY à HH:mm');
                default:
                    return moment(date).format('DD MMM YYYY');
            }
        } else return null;
    }
}
