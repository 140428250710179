// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// Angular Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RXJS
import { Observable } from 'rxjs';
import { debounceTime, filter, first, map } from 'rxjs/operators';
// NGRX
import { EntityAction, EntityOp } from '@ngrx/data';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { ProductModel, ProductsEntityService, ManagerModel } from '@store/index';
import { UsersEntityService } from '@store/index';

@Component({
    selector: 'add-stock-informations',
    templateUrl: './add-stock-informations.component.html',
})
export class AddStockInformationsComponent extends UnsubscribeOnDestroy implements OnInit {
    loading: boolean;
    managers$: Observable<ManagerModel[]>;
    product: ProductModel;
    productForm: FormGroup;

    //prettier-ignore
    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<AddStockInformationsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private layoutUtilsService: LayoutUtilsService,
        private productsService: ProductsEntityService,
        private translateService: TranslateService,
        private usersService: UsersEntityService,
    ) {
        super();

        this.getManagers();

        this.createForm();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.product = this.data.product;

        this.setFormValues();

        // Listen for error
        this.subs.sink = this.productsService.errors$.pipe(debounceTime(500)).subscribe(() => (this.loading = false));
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    close() {
        this.dialogRef.close({ res: true });
    }

    save() {
        this.loading = true;

        const product = this.prepareData();

        this.productsService.update(product);

        // Wait for response
        this.subs.sink = this.productsService.entityActions$
            .pipe(
                filter((ea: EntityAction) => ea.payload.entityOp === EntityOp.SAVE_UPDATE_ONE_SUCCESS),
                debounceTime(500),
                first(),
            )
            .subscribe(() => {
                this.loading = false;
                this.close();

                const message = this.translateService.instant('ORDERS.NEW.ADD_TO_REPOSITORY_SUCCESS');
                this.layoutUtilsService.showActionNotification(message, MessageType.Success, 3000, true, false);
            });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    compareManager(m1: ManagerModel, m2: ManagerModel): boolean {
        return m1 && m2 ? m1.id === m2.id : m1 === m2;
    }

    createForm() {
        this.productForm = this.fb.group({
            managementMode: [1],
            reference: [''],
            manager: [''],
            step: [''],
            stock: [''],
            ean: [''],
        });
    }

    getManagers() {
        this.managers$ = this.usersService.selectManagers().pipe(
            map((users) =>
                users.map((u) => {
                    return <ManagerModel>{ id: u.id, displayName: u.displayName };
                }),
            ),
        );
    }

    prepareData() {
        // Prepare data
        const controls = this.productForm.controls;
        const _product = new ProductModel();

        _product.id = this.product.id;
        _product.ean = controls.ean.value?.trim();
        _product.linked = true;
        _product.maker = this.product.maker;
        _product.managementMode = Number(controls.managementMode.value);
        _product.manager = controls.manager.value;
        _product.name = this.product.name;
        _product.packaging = this.product.packaging;
        _product.photo = this.product.photo;
        _product.reference = controls.reference.value.trim();
        _product.referenceMaker = this.product.referenceMaker;
        _product.referenceSupplier = this.product.referenceSupplier;
        // _product.step = this.getControlMaskValue(controls.step.value);
        // _product.stock = this.getControlMaskValue(controls.stock.value);
        _product.supplierCompanyId = this.product.supplierCompanyId;
        _product.supplierName = this.product.supplierName;

        return _product;
    }

    setFormValues() {
        const controls = this.productForm.controls;
        controls.reference.setValue(this.product.reference || '');
        controls.manager.setValue(this.product.manager);
        controls.step.setValue(this.product.step || '');
        controls.stock.setValue(this.product.stock || '');
        controls.ean.setValue(this.product.ean || '');
    }

    getControlMaskValue(value: any) {
        if (value === null || value === '') return null;

        return Number(value);
    }

    /***************/
    /*     UI      */
    /***************/
    hasError(control: string) {
        const _control = this.productForm.controls[control];
        return _control.invalid && (_control.touched || _control.dirty);
    }
}
