// Angular
import { ChangeDetectionStrategy, Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// NGRX
import { Store } from '@ngrx/store';
// RXJS
import { filter } from 'rxjs/operators';
// Translate
import { locale as frLang } from '@core/_config/i18n/fr';
// Core
import { LayoutConfigService, TranslationService } from '@core/_base/layout';
import { AuthState } from '@core/auth/store/_selectors/auth.selectors';
import { AuthActions } from '@core/auth';
import { UnsubscribeOnDestroy, FirestoreService } from '@core/services';
// Moment
import * as moment from 'moment';
// Theme
import { SplashScreenComponent } from '@theme/partials';
// Store
import { ParamsService } from '@store/index';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body[kt-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @ViewChild(SplashScreenComponent) splashScreen: SplashScreenComponent;

    favIcon: HTMLLinkElement = document.querySelector('#appIcon');

    // Public properties
    loader: boolean;

    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        private layoutConfigService: LayoutConfigService,
        private paramsServices: ParamsService,
        private router: Router,
        private store: Store<AuthState>,
        private translationService: TranslationService,
    ) {
        super();

        this.getTitleApp();

        //Moment
        moment.locale('fr');

        // register translations
        this.translationService.loadTranslations(frLang);
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        // If screen size is less than 770px app is redirect to pwa (cf index.html)
        if (window.innerWidth > 770) {
            // Check if user is already logged
            this.store.dispatch(new AuthActions.GetUser());

            // enable/disable loader
            this.loader = this.layoutConfigService.getConfig('page-loader.type');

            this.subs.sink = this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.splashScreen.hide(500);

                    // scroll to top on every route change
                    window.scrollTo(0, 0);

                    // to display back the body content
                    setTimeout(() => document.body.classList.add('kt-page--loaded'), 500);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        this.firestoreService.close();
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(): void {
        this.subs.unsubscribe();
        this.firestoreService.close();
    }

    /****************/
    /*      UI      */
    /****************/
    getTitleApp(): void {
        const url = window.location.href;
        let title = '';

        this.subs.sink = this.paramsServices
            .getSupplierConfig()
            .pipe(filter((config) => config !== undefined))
            .subscribe((config) => {
                this.favIcon.href = config ? '' : './assets/media/logos/favicon.svg';
                title = config ? `Boutique en ligne ${config.supplierName}` : 'Stoick - Alternative locale et solidaire pour vos achats en entreprise';

                if (url.includes('localhost')) title = `LOCALHOST - ${title}`;

                if (url.includes('dev')) title = `DEV - ${title}`;

                if (url.includes('demo')) title = `DEMO - ${title}`;

                document.title = title;
            });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
}
