// Angular
import { NgModule } from '@angular/core';
// Angular Material
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
// Core module
import { CoreModule } from '@core/core.module';
// Theme
import { CartTopBarComponent, ChatTopbarComponent, LogoComponent, LanguageSelectorComponent, ScrollTopComponent, SplashScreenComponent, UserProfileComponent } from './';
import { MaterialModule } from '@theme/material.module';
import { BootstrapModule } from '@theme/bootstrap.module';
import { NgxModule } from '@theme/ngx.module';
import { QuotesTopBarComponent } from './topbar/quotes-topbar/quotes-topbar.component';

//prettier-ignore
@NgModule({
    declarations: [
        CartTopBarComponent,
        ChatTopbarComponent,
        LanguageSelectorComponent,
        LogoComponent,
        QuotesTopBarComponent,
        ScrollTopComponent,
        SplashScreenComponent,
        UserProfileComponent
    ],
    exports: [
        CartTopBarComponent,
        ChatTopbarComponent,
        LanguageSelectorComponent,
        LogoComponent,
        QuotesTopBarComponent,
        ScrollTopComponent,
        SplashScreenComponent,
        UserProfileComponent
    ],
    imports: [
        CoreModule,
        MaterialModule,
        BootstrapModule,
        NgxModule
    ],
    providers: [
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px',
            },
        },
    ],
})
export class PartialsModule {}
