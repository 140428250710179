// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, MergeStrategy } from '@ngrx/data';
// RXJS
import { Observable, combineLatest, of } from 'rxjs';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';
// Store
import { RequestsDataService } from './requests-data.service';
// Model
import { RequestLineModel, RequestModel, RequestStatus } from './requests.model';
// Lodash
import { cloneDeep, orderBy } from 'lodash';
import { AuthUserModel, getUser } from '@core/auth';
import { ProductModel } from '..';

const CRID_LOAD = 'CRID_REQUESTS';
@Injectable({
    providedIn: 'root',
})
export class RequestsEntityService extends EntityCollectionServiceBase<any> {
    private _correlationIndex = 0;
    user: any;

    //prettier-ignore
    constructor(
        private requestsDataService: RequestsDataService,
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('Requests', serviceElementsFactory);

        this.getUserConnected();

        this.loadAll();
    }

    /******************/
    /**    Getter     */
    /******************/
    getEntities(): Observable<RequestModel[]> {
        return this.entities$.pipe(map((requests) => requests.map((r) => new RequestModel(r))));
    }

    /******************/
    /**    Actions    */
    /******************/
    addToCart(product: ProductModel, quantity: number): Observable<any> {
        return this.selectCartByUser().pipe(
            take(1),
            switchMap((request) => {
                // If an request not already exist for this manager and for this management mode => create it
                const _request: RequestModel = request ? cloneDeep(request) : new RequestModel();

                _request.user = this.user;
                _request.status = RequestStatus.Cart;

                // Check if the product is already in the cart
                let _requestLine: RequestLineModel = _request.requestLines?.find((o) => o.product.id === product.id);

                if (_requestLine) {
                    _requestLine.quantity += +quantity;
                    _requestLine.total = _requestLine.quantity * _requestLine.product.getPrice();
                } else {
                    _requestLine = new RequestLineModel({ product: product, quantity: quantity });
                    _request.requestLines.push(_requestLine);
                }

                // If request already exist => update requestline and request if not => add it
                if (request) {
                    this.requestsDataService.addOrUpdateRequestLine(_request.id, _requestLine);
                    return this.update(_request);
                } else {
                    return this.add(_request);
                }
            }),
        );
    }

    deleteRequest(request: RequestModel): Observable<any> {
        const requestLines = request.requestLines;
        return combineLatest([
            this.delete(request.id),
            requestLines.map((requestLine: any) => {
                return this.requestsDataService.deleteRequestLine(request.id, requestLine.id);
            }),
        ]);
    }

    getRequestLines(requestId: string): Observable<boolean> {
        return this.requestsDataService.getRequestLines(requestId).pipe(
            map((requestLines) => {
                // Add request lines in request in store
                const _request = new RequestModel();
                _request.id = requestId;
                _request.requestLines = requestLines || [];
                this.updateOneInCache(_request);
                return true;
            }),
        );
    }

    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    loadCart(): Observable<any[]> {
        const query: any = { query1: { field: 'status', operator: '==', value: RequestStatus.Cart } };
        return this.getWithQuery(query, { correlationId: this.getCorrelationId('loadCart') });
    }

    removeFromCart(request: RequestModel, product: ProductModel): void {
        combineLatest([of(request), this.requestsDataService.deleteRequestLine(request.id, product.id)])
            .pipe(
                map((res) => {
                    const _request = cloneDeep(res[0]);
                    // If the product is the only one in the cart => delete the order
                    if (_request.requestLines.length === 1) {
                        return this.delete(_request.id);
                    } else {
                        //If there is many products, only remove the product from the order
                        _request.requestLines = _request.requestLines.filter((o) => o.product.id !== product.id);
                        return this.update(_request);
                    }
                }),
            )
            .subscribe();
    }

    updateRequestLineQuantity(request: RequestModel, requestLine: RequestLineModel): void {
        this.requestsDataService
            .updateRequestLineQuantity(request.id, requestLine)
            .pipe(
                tap(() => this.updateOneInCache(request, { mergeStrategy: MergeStrategy.IgnoreChanges })),
                first(),
            )
            .subscribe();
    }

    validate(request: RequestModel) {
        this.update(request);
    }

    /******************/
    /**   Selectors   */
    /******************/
    selectCartNbItems(): Observable<number> {
        return this.getEntities().pipe(
            map((requests) => requests.filter((r) => r.status === RequestStatus.Cart)),
            map((x) => x.reduce((p, c) => (p += c.requestLines?.length), 0)),
        );
    }

    selectCart(): Observable<RequestModel[]> {
        return this.getEntities().pipe(map((requests) => requests.filter((r) => r.status === RequestStatus.Cart)));
    }

    selectCartByUser(): Observable<RequestModel> {
        return this.getEntities().pipe(map((requests) => requests.find((r) => r.status === RequestStatus.Cart && r._createdUserId === this.user.id)));
    }

    selectCartByManagerAndManagementMode(managerId: string): Observable<RequestModel> {
        return this.getEntities().pipe(map((requests) => requests.find((r) => r.status === RequestStatus.Cart && r.user.id === managerId)));
    }

    selectEntityById(id: string): Observable<RequestModel> {
        return this.entityMap$.pipe(map((entities) => entities[id]));
    }

    selectNewRequests(): Observable<RequestModel[]> {
        return this.getEntities().pipe(
            map((requests) => requests.filter((o) => o.status === RequestStatus.New)),
            map((requests) => orderBy(requests, '_createdDate', 'desc')),
        );
    }

    selectRequests(): Observable<RequestModel[]> {
        return this.getEntities().pipe(
            map((requests) => requests.filter((r) => r.status > RequestStatus.Cart)),
            map((requests) => orderBy(requests, '_createdDate', 'desc')),
        );
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }

    getUserConnected() {
        this.store
            .select(getUser)
            .pipe(
                filter((user) => user != null),
                first(),
            )
            .subscribe((user: AuthUserModel) => {
                this.user = {
                    id: user.id,
                    displayName: user.displayName,
                };
            });
    }
}
