// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
// RXJS
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';
// Store
import { SaleTerms } from '@store/index';
import { SupplierModel } from './supplier.model';
import { SuppliersDataService } from './suppliers-data.service';
import { CategoryModel } from '@store/categories/category.model';
import { ProductModel } from '@store/products/product.model';
// Lodash
import { orderBy } from 'lodash';
// Core
import { AuthUserModel, getUser } from '@core/auth';

const CRID_LOAD = 'CRID_CUSTOMERS';
@Injectable({
    providedIn: 'root',
})
export class SuppliersEntityService extends EntityCollectionServiceBase<any> {
    private _correlationIndex = 0;
    customer: any;

    //prettier-ignore
    constructor(
        private suppliersDataService: SuppliersDataService,
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('Suppliers', serviceElementsFactory);

        this.getCustomerInformation();
    }

    /******************/
    /**    Getter     */
    /******************/
    getEntities(): Observable<SupplierModel[]> {
        return this.entities$.pipe(map((suppliers) => suppliers.map((s) => new SupplierModel(s))));
    }

    /******************/
    /**   Selectors   */
    /******************/
    selectEntityById(id: string): Observable<SupplierModel> {
        return this.entityMap$.pipe(map((entities) => new SupplierModel(entities[id])));
    }

    /** Return flat category / sub category label => '<category> - <subcategory>' */
    selectFlatCategories(supplierId: string): Observable<any> {
        return this.selectEntityById(supplierId).pipe(
            filter((supplier) => supplier?.categories !== null && supplier?.categories !== undefined),
            map((supplier) => this.convertToFlat(supplier.categories)),
        );
    }

    selectIsSuppliersPremium(): Observable<boolean> {
        return this.selectSuppliersPremium().pipe(map((suppliers) => suppliers.length > 0));
    }

    selectIsSupplierFavorite(supplierId: string): Observable<boolean> {
        return this.selectEntityById(supplierId).pipe(map((supplier) => supplier.id !== undefined));
    }

    selectProductsBySupplier(supplierId: string): Observable<ProductModel[]> {
        return this.selectEntityById(supplierId).pipe(
            map((supplier) => {
                if (!supplier?.products) return [];

                return supplier.products.map((p) => {
                    const _product = new ProductModel({ ...p });
                    _product.supplier = new SupplierModel({ id: supplier.id, name: supplier.name, logo: supplier.logo, contact: supplier.contact, saleTerms: supplier.saleTerms });
                    return _product;
                });
            }),
        );
    }

    selectProductsWithCategoriesFlatName(supplierId: string): Observable<any> {
        const categories$: Observable<any[]> = this.selectFlatCategories(supplierId);

        const products$: Observable<ProductModel[]> = this.selectProductsBySupplier(supplierId);

        return combineLatest([categories$, products$]).pipe(
            map(([categories, products]) => {
                // Join categories and products
                return products.map((product) => {
                    return {
                        ...product,
                        categoryName: categories.find((c) => c.id === product.categoryId)?.flatName || null,
                    };
                });
            }),
        );
    }

    selectSalesTerms(supplierId: string): Observable<SaleTerms> {
        return this.selectEntityById(supplierId).pipe(map((supplier) => supplier.saleTerms));
    }

    selectSuppliersActive(): Observable<SupplierModel[]> {
        return this.getEntities().pipe(map((suppliers) => suppliers.filter((s) => s.inactive !== true)));
    }

    selectSuppliersWithCustomCatalog(): Observable<SupplierModel[]> {
        return this.getEntities().pipe(map((suppliers) => suppliers.filter((s) => s.catalogId)));
    }

    selectSuppliersPremium(): Observable<SupplierModel[]> {
        return this.getEntities().pipe(map((suppliers) => suppliers.filter((s) => s.isPremium)));
    }

    selectSupplierByUrl(url: string): Observable<SupplierModel> {
        return this.getEntities().pipe(map((suppliers) => suppliers.find((s) => s.getUrl() === url)));
    }

    selectSuppliersPremiumOrderByName(): Observable<SupplierModel[]> {
        return this.selectSuppliersPremium().pipe(
            map((suppliers) => suppliers.map((s) => new SupplierModel({ id: s.id, name: s.name, logo: s.logo }))),
            map((suppliers) => orderBy(suppliers, 'name')),
        );
    }

    selectSuppliersOrderByName(): Observable<SupplierModel[]> {
        return this.getEntities().pipe(
            map((suppliers) => suppliers.map((s) => new SupplierModel({ id: s.id, name: s.name, logo: s.logo }))),
            map((suppliers) => orderBy(suppliers, 'name')),
        );
    }

    /******************/
    /**    Actions    */
    /******************/
    active(supplierId: string) {
        const supplier = new SupplierModel({ id: supplierId, inactive: false });
        this.update(supplier);
    }

    addCustomer(supplier: SupplierModel): Observable<any> {
        return this.suppliersDataService.addCustomer(supplier.id);
    }

    inquiry(supplier: SupplierModel, email?: string): Observable<any> {
        return this.suppliersDataService.inquiry(supplier, this.customer || { email: email });
    }

    deleteFileInStorage(url: string): Promise<void> {
        return this.suppliersDataService.deleteLogo(url);
    }

    inactive(supplierId: string) {
        const supplier = new SupplierModel({ id: supplierId, inactive: true });
        this.update(supplier);
    }

    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    loadAllSuppliers(): Observable<SupplierModel[]> {
        return this.suppliersDataService.getAllSuppliersTenant().pipe(map((suppliers) => orderBy(suppliers, 'name')));
    }

    loadById(id: string): Observable<SupplierModel> {
        return this.getByKey(id, { correlationId: this.getCorrelationId('loadById') });
    }

    loadSupplierById(supplierId: string): Observable<SupplierModel> {
        return this.suppliersDataService.getByIdSuppliersTenant(supplierId).pipe(map((supplier) => new SupplierModel(supplier)));
    }

    loadSupplierByUrl(supplierUrl: string): Observable<SupplierModel> {
        return this.suppliersDataService.getByNameSuppliersTenant(supplierUrl);
    }

    loadCatalog(supplierId: string): Observable<void> {
        this.setLoading(true);
        return this.selectEntityById(supplierId).pipe(
            filter((supplier) => supplier !== undefined && !supplier.catalogLoaded),
            switchMap((supplier: SupplierModel) => this.suppliersDataService.getSupplierCatalog(supplier.id)),
            map(([categories, products]) => {
                const _supplier = new SupplierModel();
                _supplier.id = supplierId;
                _supplier.categories = [...(categories || [])];
                _supplier.products = [...(products || [])];
                _supplier.catalogLoaded = true;
                this.updateOneInCache(_supplier);
            }),
            catchError(() => of(null)),
        );
    }

    saveLogoSupplier(file: File): Promise<string> {
        return this.suppliersDataService.saveLogo(file).pipe(first()).toPromise();
    }

    updateProduct(supplierId: string, catalogId: string, product: ProductModel): void {
        this.suppliersDataService.updateProduct(supplierId, catalogId, product).pipe(first()).subscribe();
    }

    viewCatalog(supplierId: string): Observable<[CategoryModel[], ProductModel[]]> {
        return this.suppliersDataService.getSupplierCatalog(supplierId);
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    convertToFlat(categories: CategoryModel[]): any[] {
        const items: any[] = [];
        categories.forEach((cat) => {
            let item;
            if (cat.parent) {
                const parent = categories.find((c) => c.id === cat.parent);
                item = {
                    id: cat.id,
                    nameSubcategory: cat.name,
                    parent: parent.id,
                    nameCategory: parent.name,
                    flatName: parent.name + ' - ' + cat.name,
                    isChild: true,
                };
            } else {
                item = {
                    id: cat.id,
                    nameCategory: cat.name,
                    flatName: cat.name,
                    isChild: categories.findIndex((c) => c.parent === cat.id) === -1,
                };
            }
            items.push(item);
        });
        return orderBy(items, 'flatName');
    }

    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }

    getCustomerInformation() {
        this.store
            .select(getUser)
            .pipe(first())
            .subscribe((user: AuthUserModel) => {
                if (user?.roles?.includes('customer')) {
                    this.customer = {
                        id: user.id,
                        name: user.displayName,
                        companyId: user.companyId,
                        companyName: user.companyName,
                        email: user.email,
                        ...(user.phone && { phone: user.phone }),
                    };
                }
            });
    }
}
