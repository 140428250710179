// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
// RXJS
import { combineLatest, Observable, zip } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
// Store
import { CatalogModel } from '@store/catalogs/catalog.model';
import { CatalogsEntityService } from '@store/catalogs/catalogs-entity.service';
import { CustomerModel } from './customer.model';
import { CustomersDataService } from './customers-data.service';
import { ErrorStoreService } from './../error-store.service';

const CRID_LOAD = 'CRID_CUSTOMERS';
@Injectable({
    providedIn: 'root',
})
export class CustomersEntityService extends EntityCollectionServiceBase<CustomerModel> {
    private _correlationIndex = 0;

    //prettier-ignore
    constructor(
        private catalogsService: CatalogsEntityService,
        private customersDataService: CustomersDataService,
        private errorStoreService: ErrorStoreService,
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('Customers', serviceElementsFactory);

        this.loadAll();
    }

    /******************/
    /**    Getter     */
    /******************/
    getEntities(): Observable<CustomerModel[]> {
        return this.entities$.pipe(map((customers) => customers.map((c) => new CustomerModel(c))));
    }

    /******************/
    /**    Actions    */
    /******************/
    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    searchCustomer(email: string, phone: string): Observable<any> {
        return this.customersDataService.searchCustomer(email, phone);
    }

    sendInvitation(customer: CustomerModel, catalog: CatalogModel, tenantName?: string): Observable<any> {
        return this.customersDataService.sendInvitation(customer, catalog, tenantName);
    }

    /******************/
    /**   Selectors   */
    /******************/
    dataIsLoaded(): Observable<[boolean, boolean]> {
        const customersLoaded$ = this.loaded$.pipe(filter((loaded) => loaded));
        const catalogsLoaded$ = this.catalogsService.loaded$.pipe(filter((loaded) => loaded));
        return zip(customersLoaded$, catalogsLoaded$);
    }

    selectEntityById(id: string): Observable<CustomerModel> {
        return this.entityMap$.pipe(map((entities) => new CustomerModel(entities[id])));
    }

    selectCustomersWithCatalog(onlyCustom?: boolean): Observable<CustomerModel[]> {
        const catalogs$: Observable<CatalogModel[]> = onlyCustom ? this.catalogsService.selectCustomCatalogs() : this.catalogsService.getEntities();
        const customers$: Observable<CustomerModel[]> = this.entities$;
        return this.dataIsLoaded().pipe(
            switchMap(() => {
                return combineLatest([catalogs$, customers$]).pipe(
                    map(([catalogs, customers]) => {
                        return (
                            // Join catalogs and customers
                            customers.map((customer) => {
                                const catalog = catalogs.find((c) => c.id === customer.catalogId) || null;
                                return new CustomerModel({ ...customer, catalog: catalog });
                            })
                        );
                    }),
                );
            }),
        );
    }

    selectCustomerByIdWithCatalog(customerId: string): Observable<CustomerModel> {
        const catalogs$: Observable<CatalogModel[]> = this.catalogsService.getEntities();
        const customer$: Observable<CustomerModel> = this.selectEntityById(customerId);
        return this.dataIsLoaded().pipe(
            switchMap(() => {
                return combineLatest([catalogs$, customer$]).pipe(
                    map(([catalogs, customer]) => {
                        const catalog = catalogs.find((c) => c.id === customer.catalogId) || null;
                        return new CustomerModel({ ...customer, catalog: catalog });
                    }),
                );
            }),
        );
    }

    selectCustomersByCatalogId(catalogId: string): Observable<CustomerModel[]> {
        return this.getEntities().pipe(map((customers) => customers.filter((c) => c.catalogId === catalogId)));
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }
}
