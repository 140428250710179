import { BaseModel } from '@core/_base/crud/models/_base.model';
import { pickBy } from 'lodash';

export class CatalogModel extends BaseModel {
    customUser?: string; // Fill with customer id if it's a custom catalog
    description?: string;
    discount?: number;
    id?: string;
    name?: string;
    nbProducts?: number;
    products?: ProductDiscount[];

    constructor(obj?: any) {
        super();
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }
    }

    /** Return object with only available search properties */
    getObjectToSearch(): any {
        const obj = {
            name: this.name,
            description: this.description,
        };
        return obj;
    }

    isCustom(): boolean {
        return this.customUser !== (null || undefined);
    }
}

export class ProductDiscount extends BaseModel {
    canEditDiscount = true;
    canEditPrice = true;
    discount?: number = 0;
    id?: string;
    priceDiscount?: number = 0;

    constructor(obj?: any) {
        super();

        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }
    }

    calculateDiscount(price: number): number {
        const discount = price - this.priceDiscount === 0 ? 0 : 100 * Math.abs((this.priceDiscount - price) / price);
        return Number(discount.toFixed(2));
    }

    calculatePriceDiscount(price: number, catalogDiscount: number): number {
        let newPrice = price;
        if (catalogDiscount) newPrice = newPrice * (1 - catalogDiscount / 100);

        if (this.canEditDiscount && this.discount) newPrice = newPrice * (1 - this.discount / 100);

        return Number(newPrice.toFixed(2));
    }

    updateProductDiscount(price: number, catalogDiscount: number) {
        if (this.canEditDiscount) {
            this.priceDiscount = this.calculatePriceDiscount(price, catalogDiscount);
        } else {
            this.discount = this.calculateDiscount(price);
        }
    }
}
