export class MenuConfig {
    public defaults: any = {
        aside: {
            self: {},
            items: [
                {
                    title: 'Admin',
                    translate: 'MENU.ADMIN',
                    icon: 'Communication/Shield-user',
                    page: 'admin',
                    permission: 'adminStoick',
                    root: true,
                    bullet: 'dot',
                    submenu: [
                        {
                            title: 'Commandes',
                            translate: 'Commandes',
                            page: '/admin/commandes',
                            permission: 'adminStoick',
                        },
                        {
                            title: 'Devis',
                            translate: 'Devis',
                            page: '/admin/devis',
                            permission: 'adminStoick',
                        },
                        {
                            title: 'Univers',
                            translate: 'Univers',
                            page: '/admin/univers',
                            permission: 'adminStoick',
                        },

                        {
                            title: 'Utilisateurs',
                            translate: 'Utilisateurs',
                            page: '/admin/utilisateurs',
                            permission: 'adminStoick',
                        },
                    ],
                },
                {
                    title: 'Accueil',
                    translate: 'MENU.HOME',
                    icon: 'Home/Home',
                    page: 'home',
                    permission: 'accessToHome',
                },
                {
                    section: 'STOICK',
                    permission: 'isStoick',
                },
                // Role : isSupplier
                {
                    title: 'Référentiel articles',
                    translate: 'MENU.ITEMS',
                    icon: 'Home/Commode2',
                    page: 'products',
                    permission: 'isSupplier',
                },
                {
                    title: 'Catalogues',
                    translate: 'MENU.CATALOGS',
                    icon: 'Home/Book-open',
                    page: 'catalogs',
                    permission: 'isSupplier',
                },
                {
                    title: 'Suivi commandes',
                    translate: 'MENU.TRACKING_ORDERS',
                    icon: 'Communication/Clipboard-check',
                    page: 'orders',
                    permission: 'isSupplier',
                },
                {
                    title: 'Suivi devis',
                    translate: 'MENU.TRACKING_QUOTES',
                    icon: 'Shopping/Calculator',
                    page: 'quotes',
                    permission: 'isSupplier',
                },
                {
                    title: 'Clients',
                    translate: 'MENU.CUSTOMERS',
                    icon: 'Communication/Adress-book2',
                    page: 'customers',
                    permission: 'isSupplier',
                },
                // Role : isCustomer
                {
                    title: 'Acheter',
                    translate: 'MENU.BUY',
                    icon: 'Shopping/Cart1',
                    page: 'stores',
                    permission: 'isCustomer',
                },
                {
                    title: 'Suivi commandes',
                    translate: 'MENU.TRACKING_ORDERS',
                    icon: 'Communication/Clipboard-check',
                    page: 'orders',
                    permission: 'isCustomer',
                },
                {
                    title: 'Suivi devis',
                    translate: 'MENU.TRACKING_QUOTES',
                    icon: 'Shopping/Calculator',
                    page: 'quotes',
                    permission: 'isCustomer',
                },
                {
                    title: 'Fournisseurs',
                    translate: 'MENU.SUPPLIERS',
                    icon: 'Communication/Adress-book2',
                    page: 'suppliers',
                    permission: 'isCustomer',
                },
                // Role : isCustomerWhiteLabel
                {
                    title: 'Acheter',
                    translate: 'MENU.BUY',
                    icon: 'Shopping/Cart1',
                    page: 'store',
                    permission: 'isCustomerWhiteLabel',
                },
                {
                    title: 'Suivi commandes',
                    translate: 'MENU.TRACKING_ORDERS',
                    icon: 'Communication/Clipboard-check',
                    page: 'orders',
                    permission: 'isCustomerWhiteLabel',
                },
                {
                    title: 'Suivi devis',
                    translate: 'MENU.TRACKING_QUOTES',
                    icon: 'Shopping/Calculator',
                    page: 'quotes',
                    permission: 'isCustomerWhiteLabel',
                },
                // Role : isAdmin
                {
                    title: 'Utilisateurs',
                    translate: 'MENU.USERS_MANAGEMENT',
                    icon: 'Communication/Group',
                    page: 'users',
                    permission: 'isAdmin',
                    permissionExcept: 'isStoick',
                },
                // Role : isStoick
                {
                    section: 'STOICK Inside',
                    permission: 'isStoick',
                },
                // {
                //     title: 'Référentiel articles',
                //     translate: 'MENU.ITEMS',
                //     icon: 'Home/Commode2',
                //     page: '/products',
                //     permission: 'isStoick',
                // },
                // {
                //     title: 'Suivi stock',
                //     translate: 'MENU.FOLLOW_STOCK',
                //     icon: 'Shopping/Box3',
                //     page: 'stock',
                //     permission: 'isStoick',
                // },
                {
                    title: 'Catalogue interne',
                    translate: 'MENU.CATALOGS',
                    icon: 'Home/Book-open',
                    page: 'catalog',
                    permission: 'accessToInternalCatalog',
                },
                {
                    title: 'Suivi demandes',
                    translate: 'MENU.TRACKING_REQUESTS',
                    icon: 'Communication/Clipboard-check',
                    page: 'requests',
                    permission: 'isStoick',
                },
                {
                    title: 'Utilisateurs',
                    translate: 'MENU.USERS_MANAGEMENT',
                    icon: 'Communication/Group',
                    page: 'users',
                    permission: 'isStoick',
                },
                // Role : isUser
                {
                    title: 'Acheter',
                    translate: 'MENU.BUY',
                    icon: 'Shopping/Cart1',
                    page: 'stores',
                    permission: 'isUser',
                },
                {
                    title: 'Suivi demandes',
                    translate: 'MENU.TRACKING_REQUESTS',
                    icon: 'Communication/Clipboard-check',
                    page: 'requests',
                    permission: 'isUser',
                },
                // Not display only to set title page
                {
                    title: 'Messages',
                    translate: 'MENU.CHAT',
                    page: 'messages',
                },
                {
                    title: 'Panier',
                    translate: 'MENU.CART',
                    page: 'cart',
                },
                {
                    title: 'Demandes de devis',
                    translate: 'MENU.QUOTES_CART',
                    page: 'quote-cart',
                },
            ],
        },
    };

    public get configs(): any {
        return this.defaults;
    }
}
