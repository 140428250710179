// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NGRX
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
// RXJS
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
// Store
import { UserModel } from './user.model';
import { FS_PATH_USERS } from '@store/firestore-collections';
// Core
import { FirestoreService } from '@core/services';
import { AuthUserModel, getUser } from '@core/auth';
import { AppState } from '@core/reducers';
// Environment
import { environment } from '@env/environment';

// API PATH
const API_PATH = `${environment.apiBaseUrl}/users`;
// const API_PATH = `http://localhost:5001/stoick-dev/europe-west1/usersApi-users`;

@Injectable({
    providedIn: 'root',
})
export class UsersDataService extends DefaultDataService<any> {
    user: AuthUserModel;

    // prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private store: Store<AppState>
    ) {
        super('Users', http, httpUrlGenerator);

       this._getUserConnected();
    }

    /*********************/
    /*        API        */
    /*********************/
    getAll(): Observable<UserModel[]> {
        const path = `${API_PATH}/${this.user.companyId}`;
        return this.http.get(path).pipe(map((users: any) => users.map((u) => new UserModel(u))));
    }

    add(user: UserModel): Observable<any> {
        return this.http.post(API_PATH, JSON.stringify(user));
    }

    delete(userId: string): Observable<any> {
        const path = `${API_PATH}/${userId}`;
        return this.http.delete(path);
    }

    validateAccount(userId: string, validate: boolean): Observable<any> {
        const path = `${API_PATH}/validateAccount`;
        return this.http.post(path, { userId: userId, validate: validate });
    }

    /***********************/
    /*      FIRESTORE      */
    /***********************/
    /** Users collections */
    getById(id: string): Observable<UserModel> {
        return this.firestoreService.doc$(`${FS_PATH_USERS}/${id}`).pipe(filter((user) => user.lastSignin != null));
    }

    update(user: Update<UserModel>): Observable<any> {
        const _user = user.changes;
        return this.firestoreService.updateAt(`${FS_PATH_USERS}/${_user.id}`, _user);
    }

    /************************/
    /*  PRIVATE FUNCTIONS   */
    /************************/
    _getUserConnected(): void {
        this.store
            .select(getUser)
            .pipe(filter((user) => user != undefined))
            .subscribe((user) => (this.user = user));
    }
}
