// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class KtDialogService {
    private ktDialog: any;
    private currentState: BehaviorSubject<boolean> = new BehaviorSubject(false);

    // Public properties
    constructor(private translateService: TranslateService) {
        const message = this.translateService.instant('GENERAL.WAIT_LOADING');
        this.ktDialog = new KTDialog({ type: 'loader', placement: 'top center', message: message });
    }

    show() {
        this.currentState.next(true);
        this.ktDialog.show();
    }

    hide() {
        this.currentState.next(false);
        this.ktDialog.hide();
    }

    checkIsShown() {
        return this.currentState.value;
    }
}
