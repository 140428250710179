// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NGRX
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
// RXJS
import { Observable } from 'rxjs';
// Store
import { PermissionModel } from './permission.model';
import { FS_PATH_PERMISSIONS } from '@store/firestore-collections';
// Core
import { FirestoreService } from '@core/services';

@Injectable({
    providedIn: 'root',
})
export class PermissionsDataService extends DefaultDataService<any> {
    constructor(private firestore: FirestoreService, http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
        super('Permissions', http, httpUrlGenerator);
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getAll(): Observable<PermissionModel[]> {
        return this.firestore.collectionSnapShot(FS_PATH_PERMISSIONS);
    }
}
