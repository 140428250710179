import { AddressModel } from '@store/_models/address.model';
import { pickBy } from 'lodash';
import moment from 'moment';

export class CompanyModel {
    address: AddressModel;
    id: string;
    logo?: string | File;
    name: string;
    siret?: string;
    tags?: string[];
    tenantName?: string; // For supplier who have a white label website => value equal record id in collection "suppliersConfig" of params

    constructor(obj?: any) {
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);

            if (obj.address) {
                this.address = new AddressModel(obj.address);
            }
        }
    }
}
export class CompanyScoringModel {
    creationDate: string;
    currentScoring: string;
    currentScoringColor: string;
    currentScoringComment: string;
    currentScoringLetter: string;
    latePaymentCount: number;
    latePaymentTotalDays: number;
    openProcedure: number;
    procedureCollective: 'Oui' | 'Non';
    procedureCollectiveType: string;

    constructor(obj?: any) {
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }
    }

    getColor(): string {
        return this.currentScoringColor;
    }

    getDateRCS(): string {
        return moment(this.creationDate).format('DD MMM YYYY');
    }

    getScoring(): number {
        return +this.currentScoring;
    }

    isScoring(): boolean {
        return this.hasOwnProperty('currentScoring');
    }
}
