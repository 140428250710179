// Angular
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// Typesense
import { COLLECTION_REPOSITORY, TypesenseClient } from '@shared/components/typesense';
// Store
import { CatalogModel, ProductModel } from '@store/index';
// RXJS
import { first } from 'rxjs/operators';
// Feature
import { CatalogAddProductComponent } from '../catalog-add-product/catalog-add-product.component';

@Component({
    selector: 'catalog-search-product',
    templateUrl: './catalog-search-product.component.html',
    styleUrls: ['./catalog-search-product.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CatalogSearchProductComponent implements OnInit {
    catalog: CatalogModel;
    isEdit = false;
    typesenseClient: TypesenseClient;

    //prettier-ignore
    constructor(
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<CatalogSearchProductComponent>,
    ) {}

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.catalog = new CatalogModel(this.data.catalog);
        this.initTypesense();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    close() {
        this.dialogRef.close({ catalog: this.catalog, isEdit: this.isEdit });
    }

    selectProduct(product: ProductModel) {
        const dialogRef = this.dialog.open(CatalogAddProductComponent, {
            data: {
                product: product,
                catalog: this.catalog,
            },
            width: '800px',
            disableClose: true,
        });

        dialogRef
            .afterClosed()
            .pipe(first())
            .subscribe((res) => {
                if (res) {
                    this.catalog = new CatalogModel({ id: res.catalogId, ...this.catalog });
                    this.isEdit = true;
                }
            });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    initTypesense(): void {
        this.typesenseClient = new TypesenseClient(COLLECTION_REPOSITORY, this.data.typesenseRepositoryApiKey);
    }
}
