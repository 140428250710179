import { pickBy } from 'lodash';

export const DaysArray = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

export type Day = typeof DaysArray[number];

export class OpeningTime {
    day: Day;
    hours: string[];

    constructor(obj?: any) {
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }
    }

    isOpen(): boolean {
        return this.hours.filter((h) => h !== null).length > 0;
    }

    getDayLabel(): string {
        return `GENERAL.WEEKDAYS.${this.day.toUpperCase()}`;
    }

    getOpenTime(): string {
        let openTime;
        const hours = this.hours.filter((h) => h !== null);
        openTime = `${this.getTimeLabel(hours[0])} - ${this.getTimeLabel(hours[1])}`;

        if (hours.length === 4) {
            openTime = `${openTime} \n ${this.getTimeLabel(hours[2])} - ${this.getTimeLabel(hours[3])}`;
        }

        return openTime;
    }

    getTimeLabel(hour: string): string {
        return hour?.substring(0, 2) + ':' + hour?.substring(2, 4);
    }

    isTwoTimeSlots(): boolean {
        return this.hours.filter((h) => h !== null).length === 4;
    }
}
