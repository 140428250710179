// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
// RXJS
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
// Model
import { UserModel } from './user.model';
import { UsersDataService } from './users-data.service';

const CRID_LOAD = 'CRID_USERS';
@Injectable({
    providedIn: 'root',
})
export class UsersEntityService extends EntityCollectionServiceBase<any> {
    private _correlationIndex = 0;

    //prettier-ignore
    constructor(
        private usersDataService: UsersDataService,
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('Users', serviceElementsFactory);
    }

    /******************/
    /**    Getter     */
    /******************/
    getEntities(): Observable<UserModel[]> {
        return this.entities$.pipe(map((users) => users.map((u) => new UserModel(u))));
    }

    /******************/
    /**    Actions    */
    /******************/
    delete(userId: string): Observable<any> {
        return this.usersDataService.delete(userId);
    }

    loadById(id: string): Observable<any> {
        return this.getByKey(id, { correlationId: this.getCorrelationId('loadById') });
    }

    validateAccount(userId: string, validate: boolean): Observable<any> {
        return this.usersDataService.validateAccount(userId, validate);
    }

    /******************/
    /**   Selectors   */
    /******************/
    selectEntityById(userId: string): Observable<UserModel> {
        return this.entityMap$.pipe(
            map((entities) => entities[userId]),
            first(),
        );
    }

    selectEntityByEmail(email: string): Observable<UserModel> {
        return this.getEntities().pipe(
            map((users) => users.find((u) => u.email === email)),
            first(),
        );
    }

    selectManagers(): Observable<UserModel[]> {
        return this.getEntities().pipe(map((users) => users.filter((u) => u.roles.includes('customer'))));
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }
}
