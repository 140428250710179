// Angular
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Core
import { isCustomer } from '@core/auth/store/_selectors/user-permissions.selectors';
import { AppState } from '@core/reducers';
import { AuthService, AuthActions } from '@core/auth';
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
// NGRX
import { Store } from '@ngrx/store';
// RXJS
import { Observable, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { ParamsService } from '@store/index';
import { MixpanelService } from '@core/services';

@Component({
    selector: 'account-form',
    templateUrl: './account-form.component.html',
})
export class AccountFormComponent {
    @Input() form: FormGroup;

    displayAccountChoice = true;
    displaySelectTypeAccount$: Observable<boolean>;
    isPasswordUpdated = false;
    passwordType = 'password';

    //prettier-ignore
    constructor(
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private layoutUtilsService: LayoutUtilsService,
        private mixpanelService: MixpanelService,
        private paramsService: ParamsService,
        private store: Store<AppState>,
        private translateService: TranslateService) {
        // Select isCustomer if the role have been already set
        this.checkIsCustomer();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    async updatePassword(): Promise<void> {
        const controls = this.form.controls;
        if (controls.password.valid) {
            try {
                // Update Firebase Auth profil
                const password = controls.password.value.trim();
                await this.authService.updateAuthProfile(password);
                this.isPasswordUpdated = true;
                this.cdr.markForCheck();

                setTimeout(() => {
                    this.isPasswordUpdated = false;
                    this.cdr.markForCheck();
                }, 3000);
            } catch (error) {
                if (error.code == 'auth/requires-recent-login') {
                    const message = this.translateService.instant('AUTH.REGISTER.ERROR_PASSWORD_UPDATE');
                    this.layoutUtilsService.showActionNotification(message, MessageType.Error, 5000, true, false);
                    setTimeout(() => this.renewRegister(), 3000);
                }
            }
        }
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    checkIsCustomer(): void {
        const isCustomer$ = this.store.select(isCustomer);
        const isWhiteLabel$ = this.paramsService.isWhiteLabelExist().pipe(filter((value) => value !== undefined));

        this.displaySelectTypeAccount$ = combineLatest([isCustomer$, isWhiteLabel$]).pipe(
            tap(([isCustomer, isWhiteLabel]) => {
                this.form?.controls?.isCustomer.setValue(isCustomer || isWhiteLabel || true);
            }),
            map(([isCustomer, isWhiteLabel]) => {
                if (isCustomer === undefined && isWhiteLabel === false) {
                    return true;
                }
                return false;
            }),
        );
    }

    renewRegister(): void {
        this.store.dispatch(new AuthActions.SendRegisterLink({ email: this.form.controls.email.value, tenantName: this.paramsService.getTenantName() }));
        this.store.dispatch(new AuthActions.Logout());
        this.mixpanelService.track('Register - New link', { email: this.form.controls.email.value });
    }
}
