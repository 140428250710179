// Angular
import { Component, Input, Inject, forwardRef, Optional, OnInit } from '@angular/core';
// Core
import { MixpanelService } from '@core/services';
// Angular instantsearch
import { NgAisIndex, TypedBaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
// Instantsearch
import { connectToggleRefinement } from 'instantsearch.js/es/connectors';
import { ToggleRefinementConnectorParams, ToggleRefinementWidgetDescription, ToggleRefinementRenderState } from 'instantsearch.js/es/connectors/toggle-refinement/connectToggleRefinement';
//Typesense
import { noop } from '../utils';

@Component({
    selector: 'typesense-toggle-refinement',
    templateUrl: 'toggle-refinement.component.html',
})
export class TypesenseToggleRefinementComponent extends TypedBaseWidget<ToggleRefinementWidgetDescription, ToggleRefinementConnectorParams> implements OnInit {
    // instance options
    @Input() public label: string;
    @Input() public attribute: ToggleRefinementConnectorParams['attribute'];
    @Input() public on?: ToggleRefinementConnectorParams['on'];
    @Input() public off?: ToggleRefinementConnectorParams['off'];

    public state: ToggleRefinementRenderState = {
        canRefine: false,
        sendEvent: undefined,
        value: {
            count: undefined,
            isRefined: false,
            name: '',
            offFacetValue: undefined,
            onFacetValue: undefined,
        },
        createURL: () => '#',
        refine: noop,
    };

    //prettier-ignore
    constructor(
        @Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
        private mixpanelService: MixpanelService,
    ) {
        super('ToggleRefinement');
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.createWidget(connectToggleRefinement, {
            attribute: this.attribute,
            on: this.on,
            off: this.off,
        });

        super.ngOnInit();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    handleChange(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.state.refine(this.state.value);
        this.mixpanelService.track('Typesense - Toggle', { value: this.state.value.name });
    }

    /**********************/
    /*          UI        */
    /**********************/
}
