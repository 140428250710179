import { style, animate, animation } from '@angular/animations';

// =========================
// Fade
// =========================
//prettier-ignore
export const fadeIn = animation([
    style({ opacity: 0 }),
    animate('300ms', style({ opacity: 1 })),
]);

//prettier-ignore
export const fadeOut = animation([animate('300ms', style({ opacity: 0 }))]);
