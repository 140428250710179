import { LatLng } from 'leaflet';
import { pickBy } from 'lodash';

export class AddressModel {
    address1: string;
    address2: string;
    postalCode: number;
    city: string;
    location?: LatLng;

    constructor(obj?: any) {
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }
    }

    toString(): string {
        let _address = `${this.address1}\n`;
        if (this.address2) _address = `${_address}${this.address2}\n`;
        _address = `${_address}${this.postalCode} ${this.city}`;

        return _address;
    }
}
