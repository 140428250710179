import firebase from 'firebase/app';
import 'firebase/firestore';

export interface QueryConfig {
    path: string; //  path to collection
    queryArray: QueryFSModel[]; // field to orderBy
    limit: number; // limit per query
    reverse: boolean; // reverse order?
    prepend: boolean; // prepend to source?
}

export class QueryFSModel {
    field: string;
    operator: firebase.firestore.WhereFilterOp;
    value: any;

    constructor(field: string, operator: firebase.firestore.WhereFilterOp, value: any) {
        this.field = field;
        this.operator = operator;
        this.value = value;
    }
}

export class OrderFSModel {
    field: string | firebase.firestore.FieldPath;
    direction: firebase.firestore.OrderByDirection;

    constructor(field: string | firebase.firestore.FieldPath, direction?: firebase.firestore.OrderByDirection) {
        this.field = field;
        this.direction = direction || 'asc';
    }
}
