// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
// RXJS
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
// Store
import { CompanyEntityService, ParamsService, SupplierModel, SuppliersEntityService } from '@store/index';
// Core
import { UnsubscribeOnDestroy, ToolsService, MixpanelService } from '@core/services';
// Theme
import { TitlePageService } from '@theme/layout/header/title-page/title-page.service';
// Leaflet
import * as L from 'leaflet';

const LOCATION_BORDEAUX = new L.LatLng(44.837789, -0.57918);

@Component({
    selector: 'suppliers-search',
    templateUrl: './suppliers-search.component.html',
    styleUrls: ['./suppliers-search.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SuppliersSearchComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    // LeafletMap
    markers = new Map<string, L.Marker>();
    suppliersMap: L.Map;
    supplierIcon: L.Icon<L.IconOptions> = L.icon({
        iconUrl: 'assets/media/img/pin-supplier.svg',
        iconSize: [24, 36],
        iconAnchor: [12, 36],
    });
    supplierActiveIcon: L.Icon<L.IconOptions> = L.icon({
        iconUrl: 'assets/media/img/pin-supplier-active.svg',
        iconSize: [36, 54],
        iconAnchor: [18, 54],
    });

    // Others
    activeIcon = false;
    displaySupplierInformations = false;
    loading = true;
    searchValue$: BehaviorSubject<string> = new BehaviorSubject('');
    supplierSelected: SupplierModel;
    suppliers: SupplierModel[];
    suppliers$: Observable<SupplierModel[]>;
    userCoordonates: L.LatLng;

    //prettier-ignore
    constructor(
        private cdr: ChangeDetectorRef,
        private companyEntityService: CompanyEntityService,
        private mixpanelService: MixpanelService,
        private paramsService: ParamsService,
        private suppliersService: SuppliersEntityService,
        private titlePageService: TitlePageService,
        private toolsService: ToolsService,
    ) {
        super();

        this.titlePageService.showBackButton(true);

        this.loadSuppliers();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.getUserCoordonates();
        this.getSuppliers();
        this.initMap();
        this.generateMapIcon();
    }

    ngOnDestroy(): void {
        this.titlePageService.showBackButton(false);
        this.subs.unsubscribe();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    closeSupplierInformations(): void {
        this.displaySupplierInformations = !this.displaySupplierInformations;
        this.toggleSupplierIcon();
        localStorage.removeItem('supplierSelected');
    }

    viewSupplierInformations(id: string, event: any): void {
        event?.stopPropagation();
        this.supplierSelected = this.suppliers.find((supplier) => supplier.id === id);

        if (this.supplierSelected.standBy) return;

        this.displaySupplierInformations = true;
        this.suppliersMap.setView([this.supplierSelected.address.location.lat, this.supplierSelected.address.location.lng]);
        this.toggleSupplierIcon(id);
        this.cdr.markForCheck();
        this.mixpanelService.track('Suppliers - View informations', { value: this.supplierSelected.name });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    generateMapIcon(): void {
        this.subs.sink = this.suppliers$.subscribe((suppliers) => {
            this.markers.forEach((marker) => this.suppliersMap.removeLayer(marker));

            this.generateMarkers(suppliers);
        });
    }

    generateMarkers(suppliers: SupplierModel[]): void {
        suppliers.forEach((supplier) => {
            if (supplier.address.location) {
                this.markers.set(supplier.id, L.marker([supplier.address.location.lat, supplier.address.location.lng], { icon: this.supplierIcon }).addTo(this.suppliersMap));
                this.cdr.markForCheck();
            }
        });

        this.markers.forEach((marker, i) => {
            marker.on('click', (e: any) => {
                this.viewSupplierInformations(i, e.originalEvent);
                this.toggleSupplierIcon(i);
            });
        });
    }

    getSuppliers(): void {
        this.suppliers$ = this.searchValue$.pipe(
            tap(() => {
                this.loading = true;
                this.cdr.markForCheck();
            }),
            filter(() => this.suppliers !== undefined),
            map((searchValue) => {
                return searchValue === '' ? this.suppliers : this.suppliers.filter((obj: SupplierModel) => this.searchSuppliers(obj, searchValue));
            }),
            tap(() => {
                this.getSupplierSelected();
                this.loading = false;
                setTimeout(() => this.cdr.markForCheck(), 100);
            }),
        );
    }

    getSupplierSelected() {
        const supplierId = localStorage.getItem('supplierSelected');
        if (supplierId) {
            localStorage.removeItem('supplierSelected');
            this.viewSupplierInformations(supplierId, null);
        }
    }

    getUserCoordonates(): void {
        this.subs.sink = this.companyEntityService.getCompany().subscribe((company) => (this.userCoordonates = company?.address?.location ? company.address.location : LOCATION_BORDEAUX));
    }

    initMap(): void {
        const myIcon = L.icon({
            iconUrl: 'assets/media/img/pin-customer.svg',
            iconSize: [36, 54],
            iconAnchor: [24, 54],
        });

        // user company location
        const { lat, lng } = this.userCoordonates;

        // generate the map and set view on user company location
        this.suppliersMap = L.map('suppliers-map')
            .setView([lat, lng], 12)
            .on('zoom', () => this.displayTooltips());

        // user icon location
        L.marker([lat, lng], { icon: myIcon }).addTo(this.suppliersMap);

        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: 'Carte des fournisseurs',
        }).addTo(this.suppliersMap);
    }

    loadSuppliers(): void {
        this.suppliersService
            .loadAllSuppliers()
            .pipe(
                filter((suppliers) => suppliers.length > 0),
                first(),
            )
            .subscribe((suppliers: any) => {
                this.suppliers = suppliers;
                this.searchValue$.next('');
                this.cdr.markForCheck();
            });
    }

    search(searchValue: any): void {
        this.searchValue$.next(searchValue);
        this.mixpanelService.track('Suppliers - Search', { value: searchValue });
    }

    searchSuppliers(supplier: SupplierModel, value: string): any {
        const _supplier = new SupplierModel(supplier).getObjectToSearch();
        return Object.values(_supplier).some((val) => val?.toString().toLowerCase().includes(value.toLowerCase()));
    }

    /*******************/
    /*       UI        */
    /*******************/
    displayTooltips(): void {
        const zoom = this.suppliersMap.getZoom();
        this.markers.forEach((marker) => {
            if (zoom > 11) {
                marker.openTooltip();
            } else {
                marker.closeTooltip();
            }
        });
    }

    getDistance(supplier: SupplierModel): number {
        const hitLocation = new L.LatLng(supplier.address.location.lat, supplier.address.location.lng);
        return this.toolsService.calculateDistance(this.userCoordonates, hitLocation);
    }

    getLabelColor(supplier: SupplierModel): string {
        const params = this.paramsService.getParams();
        if (!params) return '';

        const distance = this.getDistance(supplier);

        if (distance <= params.distanceMin) {
            return 'label-success';
        }
        if (distance > params.distanceMin && distance <= params.distanceMax) {
            return 'label-warning';
        }
        if (distance > params.distanceMax) {
            return 'label-danger';
        }
        return '';
    }

    isLoading(supplierId: string): boolean {
        const el = document.getElementById(supplierId);
        return el.classList.contains('spinner');
    }

    toggleSpinner(supplierId: string): any {
        const el = document.getElementById(supplierId);

        if (this.isLoading(supplierId)) {
            el.classList.remove('spinner', 'spinner-primary');
        } else {
            el.classList.add('spinner', 'spinner-primary');
        }
    }

    toggleSupplierIcon(id?: string): void {
        this.markers.forEach((m, ind) => {
            ind === id ? m.setIcon(this.supplierActiveIcon) : m.setIcon(this.supplierIcon);
        });
    }

    viewSupplierPin(supplier: SupplierModel) {
        this.suppliersMap.setView([supplier.address.location.lat, supplier.address.location.lng]);
        this.toggleSupplierIcon(supplier.id);
    }
}
