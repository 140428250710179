import { BaseModel } from '../../../_base/crud/models/_base.model';
import { pickBy } from 'lodash';

export class AuthUserModel extends BaseModel {
    id: string;
    companyId: string;
    companyName: string;
    dataAccess: string[];
    displayName: string;
    email: string;
    emailVerified: boolean;
    firstname: string;
    isAdmin?: string;
    lastname: string;
    lastSignin: Date;
    phone: string;
    profileCompleted: boolean;
    roles: string[];
    tenantName?: string;

    constructor(obj?: any) {
        super();

        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);

            if (obj.lastSignin) {
                this.lastSignin = obj.lastSignin instanceof Date ? obj.lastSignin : obj.lastSignin.toDate();
            }
        }
    }
}
