// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NGRX
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity';
// RXJS
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
// Core
import { FirestoreService } from '@core/services';
import { AppState } from '@core/reducers';
import { isCustomer } from '@core/auth';
import { QueryFSModel } from '@core/_base/crud';
// Store
import { FS_PATH_CATEGORIES, FS_TENANT_USERS, FS_TENANT_SUPPLIERS, FS_PATH_PRODUCTS } from '@store/firestore-collections';
import { Store } from '@ngrx/store';
// Feature
import { CategoryModel } from './category.model';

@Injectable({
    providedIn: 'root',
})
export class CategoriesDataService extends DefaultDataService<any> {
    tenantPath: string;

    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService, 
        http: HttpClient, 
        httpUrlGenerator: HttpUrlGenerator, 
        private store: Store<AppState>
    ) {
        super('Categories', http, httpUrlGenerator);

        // Check if the connected user is customer
        this.store
            .select(isCustomer)
            .pipe(
                filter((res) => res !== undefined && res !== null),
                first(),
            )
            .subscribe((isCustomer) => {
                this.tenantPath = isCustomer ? FS_TENANT_USERS : FS_TENANT_SUPPLIERS;
            });
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    categoriesExist(): Observable<boolean> {
        return this.firestoreService.collectionExist(FS_PATH_CATEGORIES, this.tenantPath);
    }

    getAll(): Observable<CategoryModel[]> {
        return this.firestoreService.collection$(FS_PATH_CATEGORIES, this.tenantPath).pipe(
            switchMap((categories: CategoryModel[]) => {
                if (categories.length === 0) return of([]);

                // Query order lines
                return combineLatest(
                    categories.map((category) => {
                        return this.hasProducts(category.id).pipe(map((hasProducts) => new CategoryModel({ ...category, hasProducts: hasProducts })));
                    }),
                );
            }),
        );
    }

    add(category: CategoryModel): Observable<CategoryModel> {
        return this.firestoreService.updateAt(FS_PATH_CATEGORIES, category, this.tenantPath).pipe(map((doc) => new CategoryModel({ id: doc.id, ...category })));
    }

    update(category: Update<CategoryModel>): Observable<any> {
        const path = `${FS_PATH_CATEGORIES}/${category.id}`;
        return this.firestoreService.updateAt(path, category.changes, this.tenantPath);
    }

    delete(id: string): Observable<string | number> {
        const path = `${FS_PATH_CATEGORIES}/${id}`;
        return this.firestoreService.delete(path, this.tenantPath).pipe(map(() => id));
    }

    hasProducts(categoryId: string): Observable<boolean> {
        const query = [new QueryFSModel('categoryId', '==', categoryId)];
        return this.firestoreService.collectionSnapShot(FS_PATH_PRODUCTS, this.tenantPath, query, null, 1).pipe(map((res) => res.length > 0));
    }
}
