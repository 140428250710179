// Angular
import { Injectable } from '@angular/core';
// Tranlsation
import { TranslateService } from '@ngx-translate/core';

export interface Locale {
    lang: string;
    data: unknown;
}

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    // Private properties
    private langIds: string[] = [];

    /**
     * Service Constructor
     *
     * @param translate: TranslateService
     */
    constructor(private translateService: TranslateService) {
        // add new langIds to the list
        this.translateService.addLangs(['fr']);

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translateService.setDefaultLang('fr');
    }

    /**
     * Load Translation
     *
     * @param args: Locale[]
     */
    loadTranslations(...args: Locale[]): void {
        const locales = [...args];

        locales.forEach((locale) => {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            this.translateService.setTranslation(locale.lang, locale.data, true);

            this.langIds.push(locale.lang);
        });

        // add new languages to the list
        this.translateService.addLangs(this.langIds);
    }

    /**
     * Setup language
     *
     * @param lang: any
     */
    setLanguage(lang: string): void {
        if (lang) {
            this.translateService.use(this.translateService.getDefaultLang());
            this.translateService.use(lang);
            localStorage.setItem('language', lang);
        }
    }

    /**
     * Returns selected language
     */
    getSelectedLanguage(): string {
        return localStorage.getItem('language') || this.translateService.getDefaultLang();
    }
}
