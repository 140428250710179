// Angular
import { Component, Input, Inject, forwardRef, Optional, OnInit } from '@angular/core';
// Angular instantsearch
import { NgAisIndex, TypedBaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
// Instantsearch
import { connectHierarchicalMenu } from 'instantsearch.js/es/connectors';
import { HierarchicalMenuConnectorParams, HierarchicalMenuWidgetDescription, HierarchicalMenuRenderState, HierarchicalMenuItem } from 'instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu';
// Typesense
import { noop, parseNumberInput } from '../utils';

@Component({
    selector: 'typesense-hierarchical-menu',
    templateUrl: 'hierarchical-menu.component.html',
})
export class TypesenseHierarchicalMenuComponent extends TypedBaseWidget<HierarchicalMenuWidgetDescription, HierarchicalMenuConnectorParams> implements OnInit {
    @Input() attributes: HierarchicalMenuConnectorParams['attributes'];
    @Input() separator?: HierarchicalMenuConnectorParams['separator'];
    @Input() rootPath?: HierarchicalMenuConnectorParams['rootPath'];
    @Input() showParentLevel?: HierarchicalMenuConnectorParams['showParentLevel'];
    @Input() limit?: HierarchicalMenuConnectorParams['limit'];
    @Input() sortBy?: HierarchicalMenuConnectorParams['sortBy'];
    @Input() transformItems?: HierarchicalMenuConnectorParams['transformItems'];

    public state: HierarchicalMenuRenderState = {
        createURL: () => '#',
        items: [],
        refine: noop,
        canRefine: false,
        isShowingMore: false,
        toggleShowMore: noop,
        canToggleShowMore: false,
        sendEvent: noop,
    };

    //prettier-ignore
    constructor(
        @Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
    ) {
        super('HierarchicalMenu');

        this.transformItems = (items) => this.sortWithAccent(items);
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.createWidget(connectHierarchicalMenu, {
            limit: parseNumberInput(this.limit),
            attributes: this.attributes,
            rootPath: this.rootPath,
            separator: this.separator,
            showParentLevel: this.showParentLevel,
            sortBy: this.sortBy,
            transformItems: this.transformItems,
        });

        super.ngOnInit();
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    get isHidden(): boolean {
        return this.state.items.length === 0 && this.autoHideContainer;
    }

    sortWithAccent(items) {
        return items.sort((item1: HierarchicalMenuItem, item2: HierarchicalMenuItem) => {
            return item1.value.localeCompare(item2.value);
        });
    }
}
