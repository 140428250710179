// Angular
import { Component, Input, Inject, forwardRef, Optional, OnInit } from '@angular/core';
// Core
import { MixpanelService } from '@core/services';
// Angular instantsearch
import { NgAisIndex, TypedBaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
// Instantsearch
import { connectRefinementList } from 'instantsearch.js/es/connectors';
import { RefinementListConnectorParams, RefinementListWidgetDescription, RefinementListRenderState, RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
//Typesense
import { noop } from '../utils';

@Component({
    selector: 'typesense-refinement-list',
    templateUrl: 'refinement-list.component.html',
})
export class TypesenseRefinementListComponent extends TypedBaseWidget<RefinementListWidgetDescription, RefinementListConnectorParams> implements OnInit {
    // instance options
    @Input() attribute: RefinementListConnectorParams['attribute'];
    @Input() operator: RefinementListConnectorParams['operator'];
    @Input() sortBy: RefinementListConnectorParams['sortBy'];
    @Input() value: string;

    public state: RefinementListRenderState = {
        canRefine: false,
        canToggleShowMore: false,
        createURL: () => '',
        isShowingMore: false,
        items: [],
        refine: noop,
        toggleShowMore: noop,
        searchForItems: noop,
        isFromSearch: false,
        hasExhaustiveItems: false,
        sendEvent: noop,
    };

    //prettier-ignore
    constructor(
        @Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
        private mixpanelService: MixpanelService,
    ) {
        super('RefinementList');
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.createWidget(connectRefinementList, {
            attribute: this.attribute,
            operator: this.operator,
            limit: 50,
            sortBy: this.sortBy,
            escapeFacetValues: true,
        });

        super.ngOnInit();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    refine(event: MouseEvent, item: RefinementListItem): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.state.canRefine) {
            // update UI directly, it will update the checkbox state
            item.isRefined = !item.isRefined;

            // refine through Algolia API
            this.state.refine(item.value);

            this.mixpanelService.track('Typesense - Refinement list', { value: item.value });
        }
    }

    /**********************/
    /*          UI        */
    /**********************/
    get isHidden(): boolean {
        return this.state.items.length === 0 && this.autoHideContainer;
    }
}
