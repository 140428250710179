// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Theme
import { BaseComponent } from '@theme/layout/base/base.component';
// Auth
import { AuthGuard, AuthWhiteLabelGuard } from '@core/auth';
// Shared
import { WhiteLabelGuard } from '@shared/guard/white-label.guard';

const routes: Routes = [
    // Routes for marketplace
    {
        path: 'mp',
        component: BaseComponent,
        children: [
            // Public routes
            {
                path: '',
                loadChildren: () => import('@features/public/public.module').then((m) => m.PublicModule),
            },
            {
                path: 'auth',
                loadChildren: () => import('@core/auth/auth.module').then((m) => m.AuthModule),
            },
            // Private routes => need authentification
            {
                path: 'admin',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/admin/admin.module').then((m) => m.AdminModule),
            },
            {
                path: 'cart',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/cart/cart.module').then((m) => m.CartModule),
            },
            {
                path: 'catalog',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/internal-catalog/internal-catalog.module').then((m) => m.InternalCatalogModule),
            },
            {
                path: 'catalogs',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/catalogs/catalogs.module').then((m) => m.CatalogsModule),
            },
            {
                path: 'customers',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/customers/customers.module').then((m) => m.CustomersModule),
            },
            {
                path: 'home',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/home/home.module').then((m) => m.HomeModule),
            },
            {
                path: 'messages',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/chat/chat.module').then((m) => m.ChatModule),
            },
            {
                path: 'orders',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/orders/orders.module').then((m) => m.OrdersModule),
            },
            {
                path: 'products',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/products/products.module').then((m) => m.ProductsModule),
            },
            {
                path: 'profile',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/profile-edit/profile-edit.module').then((m) => m.ProfileEditModule),
            },
            {
                path: 'quotes',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/quotes/quotes.module').then((m) => m.QuotesModule),
            },
            {
                path: 'quote-cart',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/quotes-cart/quotes-cart.module').then((m) => m.QuotesCartModule),
            },
            {
                path: 'requests',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/requests/requests.module').then((m) => m.RequestsModule),
            },
            {
                path: 'stock',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/stock/stock.module').then((m) => m.StockModule),
            },
            {
                path: 'stores',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/stores/stores.module').then((m) => m.StoresModule),
            },
            {
                path: 'suppliers',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/suppliers/suppliers.module').then((m) => m.SuppliersModule),
            },
            {
                path: 'users',
                canActivate: [AuthGuard],
                loadChildren: () => import('@features/users-management/users-management.module').then((m) => m.UsersManagementModule),
            },
            { path: '', redirectTo: 'home', pathMatch: 'full' },
        ],
    },

    // Routes for whitelabel
    {
        path: ':id',
        component: BaseComponent,
        canActivate: [WhiteLabelGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('@features/public/public.module').then((m) => m.PublicModule),
            },
            {
                path: 'auth',
                loadChildren: () => import('@core/auth/auth.module').then((m) => m.AuthModule),
            },
            // Private routes => need authentification
            {
                path: 'cart',
                canActivate: [AuthWhiteLabelGuard],
                loadChildren: () => import('@features/cart/cart.module').then((m) => m.CartModule),
            },
            {
                path: 'home',
                canActivate: [AuthWhiteLabelGuard],
                loadChildren: () => import('@features/home/home.module').then((m) => m.HomeModule),
            },
            {
                path: 'profile',
                canActivate: [AuthWhiteLabelGuard],
                loadChildren: () => import('@features/profile-edit/profile-edit.module').then((m) => m.ProfileEditModule),
            },
            {
                path: 'messages',
                canActivate: [AuthWhiteLabelGuard],
                loadChildren: () => import('@features/chat/chat.module').then((m) => m.ChatModule),
            },
            {
                path: 'orders',
                canActivate: [AuthWhiteLabelGuard],
                loadChildren: () => import('@features/orders/orders.module').then((m) => m.OrdersModule),
            },
            {
                path: 'quotes',
                canActivate: [AuthWhiteLabelGuard],
                loadChildren: () => import('@features/quotes/quotes.module').then((m) => m.QuotesModule),
            },
            {
                path: 'quote-cart',
                canActivate: [AuthWhiteLabelGuard],
                loadChildren: () => import('@features/quotes-cart/quotes-cart.module').then((m) => m.QuotesCartModule),
            },
            {
                path: 'store',
                canActivate: [AuthWhiteLabelGuard],
                loadChildren: () => import('@features/stores/stores.module').then((m) => m.StoresModule),
            },
            {
                path: 'users',
                canActivate: [AuthWhiteLabelGuard],
                loadChildren: () => import('@features/users-management/users-management.module').then((m) => m.UsersManagementModule),
            },
        ],
    },

    { path: '', redirectTo: 'mp', pathMatch: 'full' },
    { path: '**', redirectTo: 'mp', pathMatch: 'full' },
];

//prettier-ignore
@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
})
export class AppRoutingModule { }
