import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

@Injectable({
    providedIn: 'root',
})
export class PdfService {
    constructor() {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        pdfMake.fonts = {
            Poppins: {
                normal: 'https://fonts.cdnfonts.com/s/16009/Poppins-Regular.ttf',
                bold: 'https://fonts.cdnfonts.com/s/16009/Poppins-Bold.ttf',
            },
        };
    }

    savePdf(docDefinition: any, name): void {
        pdfMake.createPdf(docDefinition).download(`${name}.pdf`);
    }

    openPdf(docDefinition: any): void {
        pdfMake.createPdf(docDefinition).open();
    }
}
