// Angular
import { NgModule } from '@angular/core';
// NgBootstrap
import { NgbDropdownModule, NgbTooltipModule, NgbCollapseModule, NgbNavModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

//prettier-ignore
@NgModule({
    exports: [
        // Bootstrap modules
        NgbCollapseModule,
        NgbDropdownModule,
        NgbNavModule,
        NgbProgressbarModule,
        NgbTooltipModule,
    ],
})
export class BootstrapModule {}
