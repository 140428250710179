import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[replaceDotByComma]',
})
export class InputNumberDirective {
    constructor(private el: ElementRef) {}
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key === '.') {
            this.el.nativeElement.value += ',';
            event.preventDefault();
        }
    }
}
