// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NGRX
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// RXJS
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
// Core
import { FirestoreService, StorageService } from '@core/services';
import { OrderFSModel, QueryFSModel } from '@core/_base/crud';
import { AuthUserModel, getUser } from '@core/auth';
import { AppState } from '@core/reducers';
// Store
import { ChatRoomModel, MessageModel } from './chat-room.model';
import { FS_PATH_CHAT, FS_PATH_MESSAGES } from '@store/firestore-collections';
// Firebase
import 'firebase/firestore';
// Lodash
import { orderBy, union } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ChatRoomsDataService extends DefaultDataService<any> {
    user: AuthUserModel;

    // prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private storageService: StorageService,
        private store: Store<AppState>
        ) {
        super('ChatRooms', http, httpUrlGenerator);

        this.getUserConnected();
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getAll(): Observable<any> {
        const querySupplier = new QueryFSModel('supplierId', '==', this.user.id);
        const res1$ = this.firestoreService.collection$(FS_PATH_CHAT, null, [querySupplier]);

        const queryCustomer = new QueryFSModel('customerId', '==', this.user.id);
        const res2$ = this.firestoreService.collection$(FS_PATH_CHAT, null, [queryCustomer]);

        return combineLatest([res1$, res2$]).pipe(map(([res1, res2]) => union(res1, res2)));
    }

    /** Messages */
    addMessage(chatRoomId: string, message: MessageModel): Observable<any> {
        const path = `${FS_PATH_CHAT}/${chatRoomId}/${FS_PATH_MESSAGES}`;
        return this.firestoreService.updateAt(path, message);
    }

    getNewMessages(chatRoomId: string, date: Date): Observable<any[]> {
        const path = `${FS_PATH_CHAT}/${chatRoomId}/${FS_PATH_MESSAGES}`;
        const query = new QueryFSModel('_createdDate', '>=', date);

        return this.firestoreService.collection$(path, null, [query]).pipe(
            map((messages) => messages.map((message) => new MessageModel(message))),
            map((messages) => orderBy(messages, '_createdDate', 'asc')),
        );
    }

    getMoreMessages(chatRoomId: string, date: Date, lastItem?: MessageModel): Observable<MessageModel[]> {
        const path = `${FS_PATH_CHAT}/${chatRoomId}/${FS_PATH_MESSAGES}`;
        const order = new OrderFSModel('_createdDate', 'desc');
        const limit = 10;
        const index = lastItem?._createdDate;

        return this.firestoreService.collectionSnapShot(path, null, null, order, limit, index).pipe(
            map((messages) => messages.map((message) => new MessageModel(message))),
            map((messages) => orderBy(messages, '_createdDate', 'asc')),
        );
    }

    update(chatRoom: Update<ChatRoomModel>): Observable<any> {
        return this.firestoreService.updateAt(`${FS_PATH_CHAT}/${chatRoom.id}`, chatRoom.changes);
    }

    uploadFile(chatRoomId: string, file: File): Observable<any> {
        this.storageService.setFolderPath(FS_PATH_CHAT, chatRoomId);
        return this.storageService.uploadFile(file, file.name).pipe(
            switchMap((urlFile) => {
                const message = new MessageModel();
                message.message = file.name;
                message.url = urlFile;
                message.type = file.type;
                return this.addMessage(chatRoomId, message);
            }),
        );
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    getUserConnected(): void {
        this.store
            .select(getUser)
            .pipe(
                filter((user) => user != null && user != undefined),
                first(),
            )
            .subscribe((user) => (this.user = user));
    }
}
