// Angular
import { Component, ChangeDetectionStrategy, Input, EventEmitter } from '@angular/core';

type PageIndexAction = 'first' | 'previous' | 'next' | 'last';

@Component({
    selector: 'table-paginator',
    templateUrl: './table-paginator.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class TablePaginatorComponent {
    @Input() set nbItems(value: number) {
        this._totalItems = value;
    }

    pageChange$ = new EventEmitter();

    private _pageIndex: number;
    private _pageSize = 50;
    private _totalItems = 0;

    //prettier-ignore
    constructor() {
        this._pageIndex = Number(localStorage.getItem('pageIndex')) || 0;
        this.setPageChange();
    }

    changePageIndex(action: PageIndexAction): void {
        switch (action) {
            case 'first':
                this._pageIndex = 0;
                break;
            case 'previous':
                this._pageIndex--;
                break;
            case 'next':
                this._pageIndex++;
                break;
            case 'last':
                this._pageIndex = Math.ceil(this._totalItems / this._pageSize) - 1;
                break;
        }
        this.setPageChange();
    }

    get pageIndex(): number {
        return this._pageIndex;
    }

    get pageSize(): number {
        return this._pageSize;
    }

    get totalItems(): number {
        return this._totalItems;
    }

    getEndRange(): number {
        const endRange = this._pageSize * (this._pageIndex + 1);
        return endRange > this._totalItems ? this._totalItems : endRange;
    }

    getStartRange(): number {
        const startRange = this._totalItems === 0 ? 0 : this._pageSize * this._pageIndex + 1;
        if (startRange > this._totalItems) {
            this.resetPageIndex();
        }
        return startRange;
    }

    isPageIndexBtnDisabled(action: PageIndexAction): boolean {
        if (this._totalItems === 0) return true;

        switch (action) {
            case 'first':
            case 'previous':
                return this._pageIndex === 0;
            case 'next':
            case 'last':
                return this._pageIndex === Math.ceil(this._totalItems / this._pageSize) - 1;
        }
    }

    resetPageIndex(): void {
        this._pageIndex = 0;
        this.setPageChange();
    }

    setPageChange(): void {
        this.pageChange$.next(this._pageIndex);
    }

    setItemsPerPage(value: number): void {
        this._pageSize = value;
        this.resetPageIndex();
    }
}
