import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnsubscribeOnDestroy } from '@core/services';
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
import { EntityAction, EntityOp } from '@ngrx/data';
import { TranslateService } from '@ngx-translate/core';
import { OrderModel, OrdersEntityService, OrderStatus } from '@store/index';
import { filter, first, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'order-add-tracking',
    templateUrl: './order-add-tracking.component.html',
})
export class OrderAddTrackingComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    loading = false;
    order: OrderModel;
    tracking: string;

    // prettier-ignore
    constructor(
        private dialogRef: MatDialogRef<OrderAddTrackingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private ordersService: OrdersEntityService,
        private layoutUtilsService: LayoutUtilsService,
        private translateService: TranslateService,
    )
    {
        super()
    }

    ngOnInit(): void {
        this.order = this.data.order;
    }

    ngOnDestroy(): void {
        this.loading = false;
    }

    close(): void {
        this.dialogRef.close();
    }

    prepareData(): OrderModel {
        const _order = new OrderModel();
        _order.id = this.order.id;
        _order.trackingLink = this.tracking?.trim();
        _order.status = OrderStatus.DeliveryInProgress;

        return _order;
    }

    async validate(): Promise<void> {
        this.loading = true;

        // Prepare data
        const _order = this.prepareData();

        // Update order
        this.ordersService.update(_order);

        // Wait for response
        this.subs.sink = this.ordersService.entityActions$
            .pipe(
                filter((ea: EntityAction) => ea.payload.entityOp === EntityOp.SAVE_UPDATE_ONE_SUCCESS),
                first(),
                debounceTime(1000),
            )
            .subscribe(() => {
                this.loading = false;
                this.close();

                const message = this.translateService.instant('ORDERS.EDIT.VALIDATE_SUCCESS');
                this.layoutUtilsService.showActionNotification(message, MessageType.Success, 3000, true, false);
            });
    }
}
