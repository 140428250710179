// Angular
import { Component, OnInit } from '@angular/core';
// RXJS
import { Observable } from 'rxjs';
// Store
import { QuotesEntityService } from '@store/index';

@Component({
    selector: 'quotes-topbar',
    templateUrl: './quotes-topbar.component.html',
    styleUrls: ['./quotes-topbar.component.scss'],
})
export class QuotesTopBarComponent implements OnInit {
    quotes$: Observable<number>;

    constructor(private quotesService: QuotesEntityService) {}

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.quotes$ = this.quotesService.selectQuoteNbItems();
    }

    /*************/
    /*    UI     */
    /*************/
    isActive() {
        return window.location.pathname.includes('/quote-cart');
    }
}
