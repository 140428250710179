// Angular
import { Component, OnInit } from '@angular/core';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
// Store
import { NotificationsEntityService } from '@store/notifications/notifications-entity.service';
// RXJS
import { Observable } from 'rxjs';

@Component({
    selector: 'chat-topbar',
    templateUrl: './chat-topbar.component.html',
    styleUrls: ['./chat-topbar.component.scss'],
})
export class ChatTopbarComponent extends UnsubscribeOnDestroy implements OnInit {
    nbNewMessages$: Observable<number>;

    constructor(private notificationsService: NotificationsEntityService) {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.nbNewMessages$ = this.notificationsService.selectNbNewMessages();
    }

    /*************/
    /*    UI     */
    /*************/
    isActive() {
        return window.location.pathname.includes('/messages');
    }
}
