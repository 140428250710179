// Angular
import { Component, OnInit } from '@angular/core';
// Core
import { LayoutConfigService, ToggleOptions } from '@core/_base/layout';
// Store
import { ParamsService } from '@store/index';
// RXJS
import { Observable } from 'rxjs';
// Theme
import { HtmlClassService } from '../html-class.service';

@Component({
    selector: 'kt-brand',
    templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit {
    // Public properties
    asideSelfMinimizeToggle = true;
    brandClasses = '';
    headerLogo = '';
    isMinimize = false;
    supplierConfig: any;

    toggleOptions: ToggleOptions = {
        target: 'kt_body',
        targetState: 'aside-minimize',
        toggleState: 'active',
    };

    //prettier-ignore
    constructor(
        private layoutConfigService: LayoutConfigService,
        public htmlClassService: HtmlClassService,
        private paramsService: ParamsService,
    ) {
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        // this.loadLogoFormUserRole();

        this.brandClasses = this.htmlClassService.getClasses('brand', true).toString();
        this.asideSelfMinimizeToggle = this.layoutConfigService.getConfig('aside.self.minimize.toggle');

        if (window.innerWidth < 1550) {
            this.toggleAsideClick();
        }
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/

    /*************/
    /*    UI     */
    /*************/
    getLogo(): Observable<string> {
        return this.paramsService.getLogo();
        // return 'https://firebasestorage.googleapis.com/v0/b/stoick-demo.appspot.com/o/companies%2FVq9VW3BgBfi9oLGLciz4%2Flogo.png?alt=media&token=d728f5db-6de2-47d9-ad57-142ffe24b542';
        // return `./assets/media/logos/logo.svg`;
    }

    toggleAsideClick(): void {
        if (!this.isMinimize) {
            document.body.classList.add('aside-minimize');
        } else {
            document.body.classList.remove('aside-minimize-hover');
        }
        this.isMinimize = !this.isMinimize;
    }
}
