// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// NGRX
import { Store } from '@ngrx/store';
// RXJS
import { filter } from 'rxjs/operators';
// Core
import { getUser } from '@core/auth/store/_selectors/auth.selectors';
import { AppState } from '@core/reducers';
// Environment
import { environment } from '@env/environment';
// MixPanel
import * as mixpanel from 'mixpanel-browser';

@Injectable({
    providedIn: 'root',
})
export class MixpanelService {
    constructor(private router: Router, private store: Store<AppState>) {
        this._init();
        this._identify();
        this._trackActionByUrl();
    }

    /****************/
    /*   PRIVATE    */
    /****************/
    private _init(): void {
        mixpanel.init(environment.mixPanelToken);
    }

    private _identify(): void {
        this.store.select(getUser).subscribe((user) => {
            if (user != null) {
                mixpanel.identify(user.email);
            }
        });
    }

    private _trackActionByUrl(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            switch (event.urlAfterRedirects) {
                case '/mp/auth/login':
                    this.track('Login page');
                    break;
                case '/mp/auth/register':
                    this.track('Register page');
                    break;
                case '/mp/cart':
                    this.track('Cart page');
                    break;
                case '/mp/home':
                    this.track('Home page');
                    break;
                case '/mp/messages':
                    this.track('Chat page');
                    break;
                case '/mp/orders':
                    this.track('Orders page');
                    break;
                case '/mp/profile"':
                    this.track('Profile page');
                    break;
                case '/mp/public':
                    this.track('Welcome page');
                    break;
                case '/mp/public/items':
                    this.track('Welcome items page');
                    break;
                case '/mp/public/suppliers':
                    this.track('Welcome suppliers page');
                    break;
                case '/mp/quotes':
                    this.track('Quotes page');
                    break;
                case '/mp/quotes-cart':
                    this.track('Quotes cart page');
                    break;
                case '/mp/stores':
                    this.track('Store page');
                    break;
                case '/mp/stores/all':
                    this.track('Marketplace page');
                    break;
                case '/mp/suppliers':
                    this.track('Suppliers page');
                    break;
                case '/mp/users':
                    this.track('Users page');
                    break;
                default:
                    break;
            }
        });
    }

    /****************/
    /*    PUBLIC    */
    /****************/
    track(id: string, action: any = {}): void {
        mixpanel.track(id, action);
    }
}
