import { OnDestroy, Injectable } from '@angular/core';
import { SubSink } from 'subsink';

@Injectable({
    providedIn: 'root',
})
export class UnsubscribeOnDestroy implements OnDestroy {
    subs: SubSink;

    constructor() {
        this.subs = new SubSink();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
