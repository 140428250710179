// Angular
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
// Angular Material
import { MatSort } from '@angular/material/sort';
// RXJS
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
import { CustomDataTable, TypesUtilsService } from '@core/_base/crud';
// Store
import { ProductModel, ProductsEntityService, ProductDiscount } from '@store/index';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Shared
import { SearchInputComponentService } from '@shared/components/general/search-input/search-input.service';
import { TablePaginatorComponent } from '@shared/components/general/table-paginator/table-paginator.component';
// Lodash
import { intersection, intersectionWith } from 'lodash';

@Component({
    selector: 'products-edit-list',
    templateUrl: './products-edit-list.component.html',
    styleUrls: ['products-edit-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductsEditListComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy, AfterViewInit {
    @Input() hidePrice = false;

    @Input()
    set canEditDiscounts(value: boolean) {
        this._canEditDiscounts = value;
    }

    /** Update price when catalog discount is updated */
    @Input()
    set catalogDiscount(value: number) {
        this._catalogDiscount = value || 0;
        this.inputChange$.next(true);
    }

    /** Listen for hideSelection property change */
    @Input()
    set hideSelection(value: boolean) {
        this._hideSelection = value;
        this.inputChange$.next(true);
        this.loadColumns();
    }

    /** Update price when catalog discount is updated */
    @Input()
    set productsDiscounts(value: ProductDiscount[]) {
        this._productsDiscounts = value || [];
        this.initSelection();
        this.inputChange$.next(true);
    }

    // Datatable
    @ViewChild(MatSort) set matSort(ms: MatSort) {
        if (!this.dataTable.sort) this.dataTable.setSort(ms);
    }
    @ViewChild(TablePaginatorComponent) set paginator(paginator: TablePaginatorComponent) {
        if (!this.dataTable.paginator) this.dataTable.setPaginator(paginator);
    }
    @ViewChild('matTableWrapper') set matTableWrapper(matTableWrapper: ElementRef) {
        this.dataTable.matTableWrapper = matTableWrapper;
    }
    @ViewChild('tableLoader') set tableLoader(tableLoader: ElementRef) {
        this.dataTable.tableLoader = tableLoader;
    }
    public columnDefinitions: any[] = [];
    public dataTable: CustomDataTable;

    // Selection
    selection = new SelectionModel<any>(true /* multiple */);
    _hideSelection = false;
    selectionChange$: Subject<boolean> = new Subject<false>();

    // Other
    _canEditDiscounts = false;
    _catalogDiscount: number;
    _productsDiscounts: ProductDiscount[] = [];
    allProducts: ProductModel[] = [];
    allSelected = false;
    data$: Observable<any>;
    inputChange$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    loading = false;

    //prettier-ignore
    constructor(
        private productsService: ProductsEntityService,
        private searchInputComponentService: SearchInputComponentService,
        private translateService: TranslateService,
        private typesUtilsService: TypesUtilsService,
        )
    {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.loadColumns();

        this.getAndSaveProducts();

        this.getProductsAndCategories();

        this.initDataTable();
    }

    ngAfterViewInit(): void {
        // When datatable data is updated check to select or deselect checkbox "All"
        this.data$.subscribe(() => {
            this.checkAllGroup();
            this.checkAll();
        });
    }

    ngOnDestroy(): void {
        this.dataTable.disconnect();
        this.searchInputComponentService.reset();
        this.subs.unsubscribe();
    }

    /***************/
    /*  DATATABLE  */
    /***************/
    getDisplayedColumns(): string[] {
        return this.columnDefinitions.filter((cd) => !cd.hide).map((cd) => cd.def);
    }

    initDataTable(): void {
        this.dataTable = new CustomDataTable(this.data$, this.searchInputComponentService.getSearchValue$(), this.productsService.loading$, this.productsService.errors$);

        // Display data grouped by category
        this.dataTable.groupByColumns.push({ id: 'categoryId', label: 'categoryName' });
    }

    loadColumns(): void {
        // prettier-ignore
        this.columnDefinitions = [
            { def: 'photo' },
            { def: 'reference' },
            { def: 'name' },
            { def: 'price' },
            { def: 'discount', hide: this.hidePrice },
            { def: 'priceDiscount' },
            { def: 'display', hide: this._hideSelection }
        ];
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    onDiscountChange(item: any) {
        item.discount = this.typesUtilsService.getNumber(item.discount);
        item.canEditDiscount = true;
        item.canEditPrice = item.discount === 0;

        const productDiscount = this.saveDiscount(item);
        item.priceDiscount = productDiscount.priceDiscount;
    }

    onDiscountPriceChange(item: any) {
        item.priceDiscount = this.typesUtilsService.getNumber(item.priceDiscount);
        item.canEditDiscount = item.priceDiscount === 0;
        item.canEditPrice = true;

        const productDiscount = this.saveDiscount(item);
        item.discount = productDiscount.discount;
    }

    saveDiscount(item: any): ProductDiscount {
        // Delete item if already exist
        this._productsDiscounts = this._productsDiscounts?.filter((el) => el.id !== item.id);

        const _productDiscount = this.prepareProductDiscount(item, item.price);
        this._productsDiscounts?.push(_productDiscount);

        return _productDiscount;
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    calculateDiscount(price: number, priceDiscount: number | string): number {
        if (priceDiscount === '') return 0;

        priceDiscount = Number(priceDiscount);
        let newPrice = price;
        if (this._catalogDiscount) newPrice = newPrice * (1 - this._catalogDiscount / 100);

        const discount = newPrice - priceDiscount === 0 ? 0 : 100 * Math.abs((priceDiscount - newPrice) / newPrice);
        return Number(discount.toFixed(2));
    }

    calculatePriceDiscount(price: number, discount: number): number {
        let newPrice = price;
        if (this._catalogDiscount) newPrice = newPrice * (1 - this._catalogDiscount / 100);

        if (discount) newPrice = newPrice * (1 - discount / 100);

        return Number(newPrice.toFixed(2));
    }

    getAndSaveProducts(): void {
        // Save products to edit them (display and discount);
        this.subs.sink = this.productsService.getEntities().subscribe((products) => (this.allProducts = products));
    }

    getProductsAndCategories(): void {
        this.data$ = combineLatest([this.productsService.selectProductsWithCategoriesFlatName(), this.inputChange$]).pipe(
            map(([products]) => {
                if (this._hideSelection) {
                    products = products.filter((product) => this.selection.selected.includes(product.id));
                }

                return this.joinProductsAndDiscounts(products);
            }),
        );
    }

    getProductsDiscounts() {
        return this.getSelectedProducts().map((p) => {
            const productDiscount = this._productsDiscounts.find((el) => el.id === p.id);
            return this.prepareProductDiscount(productDiscount || p, p.price);
        });
    }

    joinProductsAndDiscounts(products: ProductModel[]): any[] {
        return products.map((p) => {
            const productDiscount = this._productsDiscounts.find((el) => el.id === p.id);
            const _productDiscount = this.prepareProductDiscount(productDiscount || p, p.price);

            return { ...p, ..._productDiscount };
        });
    }

    prepareProductDiscount(item: any, price: number): ProductDiscount {
        const productDiscount = new ProductDiscount();
        productDiscount.canEditDiscount = item.canEditDiscount === undefined ? true : item.canEditDiscount;
        productDiscount.canEditPrice = item.canEditPrice === undefined ? true : item.canEditPrice;
        productDiscount.discount = item.discount || 0;
        productDiscount.id = item.id;
        productDiscount.priceDiscount = item.priceDiscount || 0;

        productDiscount.updateProductDiscount(price, this._catalogDiscount);

        return productDiscount;
    }

    /***************/
    /*  SELECTION  */
    /***************/
    /** Select or unselect all items */
    checkAll(): void {
        const nbItems = this.dataTable.getDisplayedGroupedData().length;
        if (nbItems > 0) {
            const nbItemsSelected = this.selection.selected.length;
            this.allSelected = nbItems === nbItemsSelected;
        }
    }

    /** Select or unselect group only if all items are slected or unselected */
    checkAllGroup(): void {
        this.dataTable.getGroup().forEach((group) => this.checkGroup(group));
    }

    /** Select or unselect group only if all items are slected or unselected */
    checkGroup(group: any): void {
        const nbGroupItems = group.totalItems;
        const itemsId = this.dataTable
            .getDisplayedData()
            .filter((el) => el.categoryId === group.id)
            .map((item) => item.id);

        const nbItemsSelected = intersection(this.selection.selected, itemsId).length;
        nbItemsSelected === nbGroupItems ? this.selection.select(group.id) : this.selection.deselect(group.id);
    }

    getNbProductsSelected(): number {
        return this.getSelectedProducts().length;
    }

    getSelectedProducts(): any[] {
        return intersectionWith(this.allProducts, this.selection.selected, (a, b) => a.id === b);
    }

    initSelection(): void {
        this.selection.clear();
        const ids = this._productsDiscounts.map((el) => el.id);
        this.selection.select(...ids);
    }

    isSelected(item: any): boolean {
        return this.selection.isSelected(item.id);
    }

    onSelectAll(): void {
        this.allSelected = !this.allSelected;
        const ids = this.dataTable.getDisplayedData().map((el) => el.id);
        this.allSelected ? this.selection.select(...ids) : this.selection.deselect(...ids);
        this.checkAllGroup();
        this.selectionChange$.next(true);
    }

    /** Select or deselect group and its items */
    onSelectGroup(group: any): void {
        // Get items of the group
        const itemsId = this.dataTable
            .getDisplayedData()
            .filter((el) => el.categoryId === group.id)
            .map((item) => item.id);

        if (this.isSelected(group)) {
            this.selection.deselect(group.id);
            this.selection.deselect(...itemsId);
        } else {
            this.selection.select(group.id);
            this.selection.select(...itemsId);
        }

        this.checkAll();
        this.selectionChange$.next(true);
    }

    /** Display or hide item and check to select or unselect the group */
    onSelectItem(item: any): void {
        this.isSelected(item) ? this.selection.deselect(item.id) : this.selection.select(item.id);

        const group = this.dataTable.getGroup().find((el) => el.id === item.categoryId);
        this.checkGroup(group);
        this.checkAll();
        this.selectionChange$.next(true);
    }

    /***************/
    /*     UI      */
    /***************/
    getCategoryName(category: string): string {
        return category !== '' ? category : this.translateService.instant('PRODUCTS.CATEGORY.UNCLASSIFIED');
    }
}
