export const TypesenseUtils = {
    _adaptHighlightTag(value, highlightPreTag, highlightPostTag) {
        return value.replace(new RegExp('<mark>', 'g'), highlightPreTag).replace(new RegExp('</mark>', 'g'), highlightPostTag);
    },
    _adaptNumberOfPages(typesenseResponse) {
        const result = typesenseResponse.found / typesenseResponse.request_params.per_page;
        if (Number.isFinite(result)) {
            return Math.ceil(result);
        } else {
            return 1;
        }
    },
    _flat(array: any[]) {
        return array.reduce((acc, val) => acc.concat(Array.isArray(val) ? this._flat(val) : val), []);
    },
};
