// Angular
import { Directive, ElementRef, Input } from '@angular/core';
// RXJS
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise } from 'rxjs/operators';

interface ScrollPosition {
    sH: number;
    sT: number;
    cH: number;
}

@Directive({
    selector: '[scrollable]',
})
export class ScrollableDirective {
    private nearTopPosition = 70;
    private scrollEvent$: Observable<any>;
    private _scrollPosition = 0;

    @Input() scrollPercent = 90;

    constructor(public el: ElementRef) {
        this.registerScrollEvent();
    }

    private isNearBottom(position) {
        return (position.sT + position.cH) / position.sH > this.scrollPercent / 100;
    }

    private isNearTop(position) {
        return position.sT < this.nearTopPosition;
    }

    private isUserScrollingBottom = (positions) => {
        return positions[0].sT < positions[1].sT;
    };

    private isUserScrollingUp = (positions) => {
        return positions[0].sT > positions[1].sT;
    };

    getScrollPosition(): number {
        return this.el.nativeElement.scrollTop;
    }

    listenToScrollBottom(): Observable<boolean> {
        return this.scrollEvent$.pipe(
            map(
                (e: any): ScrollPosition => ({
                    sH: e.target.scrollHeight,
                    sT: e.target.scrollTop,
                    cH: e.target.clientHeight,
                }),
            ),
            pairwise(),
            map((positions) => this.isUserScrollingBottom(positions) && this.isNearBottom(positions[1])),
            distinctUntilChanged(),
            filter((userScrollToTop) => userScrollToTop),
        );
    }

    listenToScrollUp(): Observable<boolean> {
        return this.scrollEvent$.pipe(
            map(
                (e: any): ScrollPosition => ({
                    sH: e.target.scrollHeight,
                    sT: e.target.scrollTop,
                    cH: e.target.clientHeight,
                }),
            ),
            pairwise(),
            map((positions) => this.isUserScrollingUp(positions) && this.isNearTop(positions[1])),
            distinctUntilChanged(),
            filter((userScrollToTop) => userScrollToTop),
        );
    }

    private registerScrollEvent() {
        this.scrollEvent$ = fromEvent(this.el.nativeElement, 'scroll');
    }

    public setScrollBottom(): void {
        this.el.nativeElement.scrollTop = this.el.nativeElement.scrollHeight;
    }

    saveScrollPosition(): void {
        this._scrollPosition = this.el.nativeElement.scrollTop;
    }

    restoreScrollPosition(): void {
        this.el.nativeElement.scrollTop = this._scrollPosition;
    }

    setScrollPosition(position: string): void {
        this.el.nativeElement.scrollTop = position;
    }
}
