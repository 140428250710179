export class FavoriteModel {
    id: string;
    productId: string;
    supplierId: string;

    constructor(obj?: any) {
        if (obj) {
            this.id = obj.productId;
            this.productId = obj.productId;
            this.supplierId = obj.supplierId;
        }
    }
}
