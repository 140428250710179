import { pickBy } from 'lodash';
import { BaseModel } from '@core/_base/crud';
import moment from 'moment';

export class ChatRoomModel {
    id: string;
    customerId: string;
    customerName: string;
    customerCompany: string;
    customerLastSignIn: string | Date;
    lastMessageTime: Date;
    supplierId: string;
    supplierName: string;
    supplierCompany: string;
    supplierLastSignIn: string | Date;

    constructor(obj?: any) {
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }
    }

    /** Return object with only available search properties */
    getObjectToSearch(suppliers: boolean): any {
        return suppliers ? { supplierName: this.supplierName, supplierCompany: this.supplierCompany } : { customerName: this.customerName, customerCompany: this.customerCompany };
    }
}

export class MessageModel extends BaseModel {
    id: string;
    userDisplayName: string;
    message: string;
    day: string;
    time: string;
    type?: string;
    url?: string;

    constructor(obj?: any) {
        super();
        if (obj) {
            const cleanedObj = pickBy(obj, (v) => v !== undefined);
            Object.assign(this, cleanedObj);
        }

        if (this._createdDate) {
            this.day = moment(this._createdDate).format('Do MMMM YYYY');
            this.time = moment(this._createdDate).format('LT');
        }
    }
}
