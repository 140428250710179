// Angular
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// Translate
import { TranslateService } from '@ngx-translate/core';
import { StockStatus } from '@store/index';
// Store

@Component({
    selector: 'stock-status-filter',
    templateUrl: './stock-status-filter.component.html',
    styleUrls: ['./stock-status-filter.component.scss'],
})
export class StockStatusFilterComponent implements OnInit {
    @Input() status: number;

    filters: {
        ok: boolean;
        alert: boolean;
        outOfStock: boolean;
    };

    @Output() selectStatus = new EventEmitter();

    constructor(private translateService: TranslateService) {}

    ngOnInit(): void {
        this.filters = {
            ok: this.status === StockStatus.OK,
            alert: this.status === StockStatus.Alert,
            outOfStock: this.status === StockStatus.OutOfStock,
        };
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    // TODO : Refactor
    changeFilters(filter: string) {
        this.filters[filter] = !this.filters[filter];

        const _filtersArray = [];
        if (this.filters.ok === true) _filtersArray.push(StockStatus.OK);
        if (this.filters.alert === true) _filtersArray.push(StockStatus.Alert);
        if (this.filters.outOfStock === true) _filtersArray.push(StockStatus.OutOfStock);

        this.selectStatus.emit(_filtersArray);
    }

    /***********/
    /**   UI   */
    /***********/
}
