// Angular
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
@Component({
    selector: 'splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
    // Private properties
    private _show = false;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.show();
    }

    isVisible(): boolean {
        return this._show;
    }

    hide(duration = 0): void {
        setTimeout(() => {
            this._show = false;
            this.cdr.markForCheck();
        }, duration);
    }

    show(): void {
        this._show = true;
        this.cdr.markForCheck();
    }
}
