// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
// RXJS
import { Observable } from 'rxjs';
import { map, first, filter } from 'rxjs/operators';
// Model
import { InvitationModel } from './invitation.model';

const CRID_LOAD = 'CRID_INVITATIONS';
@Injectable({
    providedIn: 'root',
})
export class InvitationsEntityService extends EntityCollectionServiceBase<InvitationModel> {
    private _correlationIndex = 0;

    //prettier-ignore
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('Invitations', serviceElementsFactory);

        this.loadAll();
    }

    /******************/
    /**    Getter     */
    /******************/
    getEntities(): Observable<InvitationModel[]> {
        return this.entities$.pipe(map((invitations) => invitations.map((i) => new InvitationModel(i))));
    }

    /******************/
    /**    Actions    */
    /******************/
    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    /******************/
    /**   Selectors   */
    /******************/
    selectEntityById(categoryId: string): Observable<InvitationModel> {
        return this.entityMap$.pipe(map((entities) => new InvitationModel(entities[categoryId])));
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }
}
