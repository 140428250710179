import { Injectable } from '@angular/core';
// RXJS
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
// Firebase
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth';
// Fire-Auth
import * as userActions from '../_actions/auth.actions';

@Injectable()
export class ReAuthenticationEffects {
    @Effect()
    reAuthenticateWithCredentials: Observable<Action> = this.actions$.pipe(
        ofType(userActions.AuthActionTypes.CredentialsReAuthentication),
        map((action: userActions.CredentialsReAuthentication) => {
            return {
                email: action.email,
                password: action.password,
            };
        }),
        exhaustMap((credentials) => {
            return from(this.doReAuthenticationWithCredentials(credentials)).pipe(
                map(() => {
                    // successful login
                    return new userActions.ReAuthenticationSuccess();
                }),
                catchError((error) => of(new userActions.ReAuthenticationError(error))),
            );
        }),
    );

    private async doReAuthenticationWithCredentials(credentials: { email: string; password: string }): Promise<any> {
        const credential = firebase.auth.EmailAuthProvider.credential(credentials.email, credentials.password);
        return (await this.afAuth.currentUser).reauthenticateAndRetrieveDataWithCredential(credential);
    }

    constructor(private actions$: Actions, private afAuth: AngularFireAuth) {}
}
