// Angular
import { Component } from '@angular/core';
import { Router } from '@angular/router';
// RXJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Store
import { ParamsService } from '@store/index';
// Core
import { UrlService } from '@core/services';

const STOICK_LOGO = './assets/media/logos/logo.svg';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
})
export class LogoComponent {
    //prettier-ignore
    constructor(
        private paramsService: ParamsService,
        private router: Router,
        private urlService: UrlService,
        ) {}

    /***************/
    /*  LIFECYCLE  */
    /***************/

    /*************/
    /*  ACTIONS  */
    /*************/
    backToHome() {
        this.router.navigateByUrl(this.urlService.getBaseUrl(this.router.url));
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/

    /*************/
    /*    UI     */
    /*************/
    getLogo(): Observable<string> {
        return this.paramsService.getLogo().pipe(map((logo) => (logo === null ? STOICK_LOGO : logo)));
    }
}
