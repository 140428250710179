// Angular
import { Component, OnInit, ChangeDetectorRef, OnDestroy, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Angular Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RXJS
import { delay } from 'rxjs/operators';
// Core
import { LogService, UnsubscribeOnDestroy } from '@core/services';
// Shared
import { ContactFormComponent } from '@shared/components/general/contact-form/contact-form.component';
import { OpeningTimeComponent } from '@shared/components/general/opening-time/opening-time.component';
import { UserFeedbackComponent } from '@shared/components/general/user-feedback/user-feedback.component';
// Store
import { BillingMethod, CompanyEntityService, ContactModel, CustomerTenantModel, OpeningTime, PaymentChoice } from '@store/index';

@Component({
    templateUrl: './billing-informations.component.html',
})
export class BillingInformationsComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @ViewChild(ContactFormComponent, { static: false }) public contactFormComponent: ContactFormComponent;
    @ViewChild(OpeningTimeComponent, { static: false }) public openingTimeComponent: OpeningTimeComponent;
    @ViewChild(UserFeedbackComponent, { static: false }) public userFeedbackComponent: UserFeedbackComponent;

    billingMethod: BillingMethod[] = ['order', 'monthly'];
    billingContact: ContactModel;
    companyId: string;
    form: FormGroup;
    loading = false;
    openingTime: OpeningTime[] = [];
    paymentChoice: PaymentChoice[] = ['transfer', 'check', 'exchange'];
    step = 1;

    //prettier-ignore
    constructor(
        private cdr: ChangeDetectorRef,
        private companyService: CompanyEntityService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<BillingInformationsComponent>,
        private fb: FormBuilder,
        private logService: LogService,
    )
    {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.getCompany();

        this.initForm();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    next() {
        this.step = this.step + 1;
    }

    previous() {
        this.step = this.step - 1;
    }

    save(): void {
        this.loading = true;

        const customerTenant = this.prepareData();

        this.userFeedbackComponent.save();

        this.subs.sink = this.companyService
            .updateCustomersTenantInformations(customerTenant)
            .pipe(delay(800))
            .subscribe(
                () => {
                    this.loading = false;
                    this.cdr.markForCheck();
                    this.dialogRef.close();
                },
                (error) => {
                    this.logService.logErrorsInFS(error);
                    this.loading = false;
                },
            );
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    getCompany() {
        this.subs.sink = this.companyService.getCompany().subscribe((company) => (this.companyId = company.id));
    }

    initForm(): void {
        this.form = this.fb.group({
            billingMethod: [null, Validators.required],
            paymentChoice: [null, Validators.required],
        });
    }

    prepareData(): CustomerTenantModel {
        const controls = this.form.controls;
        const _customerTenant = new CustomerTenantModel();

        _customerTenant.id = this.companyId;
        _customerTenant.billingAddress = [];
        _customerTenant.billingContact = this.contactFormComponent.prepareData();
        _customerTenant.billingMethod = controls.billingMethod.value;
        _customerTenant.deliveryAddress = [];
        _customerTenant.openingTime = this.openingTimeComponent.prepareData();
        _customerTenant.paymentChoice = controls.paymentChoice.value;

        return _customerTenant;
    }

    /*************/
    /*    UI     */
    /*************/
    getTitle(): string {
        switch (this.step) {
            case 2:
                return 'CUSTOMERS.PAYMENT_TITLE';
            case 3:
                return 'CUSTOMERS.BILLING_CONTACT_TITLE';
            case 4:
                return 'CUSTOMERS.OPEN_HOURS';
            case 4:
                return 'BUSINESS.OPINION_TITLE';
            default:
                return '';
        }
    }

    isInvalid(): boolean {
        if (this.step === 2) {
            return this.form.invalid;
        }
        return false;
    }

    showStep(step: number): string {
        return this.step === step ? 'd-block' : 'd-none';
    }
}
