// Angular
import { Component, Input } from '@angular/core';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { RequestStatus } from '@store/index';

@Component({
    selector: 'request-status',
    templateUrl: './request-status.component.html',
})
export class RequestStatusComponent {
    @Input() status: number;

    constructor(private translateService: TranslateService) {}

    /***********/
    /**   UI   */
    /***********/
    getStatusCSS(): string {
        switch (this.status) {
            case RequestStatus.New:
                return 'label-outline-primary';

            case RequestStatus.AddToCart:
                return 'label-light-primary';

            case RequestStatus.Validate:
                return 'label-outline-success';

            case RequestStatus.Ready:
                return 'label-light-success';

            case RequestStatus.Delivered:
            case RequestStatus.Done:
                return 'label-success';

            case RequestStatus.Canceled:
                return 'label-light-danger';

            default:
                return null;
        }
    }

    getStatusLabel(status?: number): string {
        return this.translateService.instant('REQUESTS.STATUT_' + (status || this.status));
    }
}
