// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, MergeStrategy } from '@ngrx/data';
// RXJS
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';
// Store
import { QuotesDataService } from './quotes-data.service';
import { QuoteLineModel, QuoteModel, QuoteStatus } from './quote.model';
import { ProductModel } from '@store/products/product.model';
import { ErrorStoreService } from '@store/error-store.service';
import { CompanyEntityService } from '@store/company/company-entity.service';
import { SuppliersEntityService } from '@store/suppliers/suppliers-entity.service';
import { OrdersEntityService } from '@store/orders/orders-entity.service';
// Core
import { getUser } from '@core/auth';
// Lodash
import { cloneDeep, isNil, omitBy, orderBy } from 'lodash';

const CRID_LOAD = 'CRID_QUOTES';
@Injectable({
    providedIn: 'root',
})
export class QuotesEntityService extends EntityCollectionServiceBase<any> {
    private customer: any;
    private _correlationIndex = 0;

    // prettier-ignore
    constructor(
        private companyService: CompanyEntityService,
        private errorStoreService: ErrorStoreService,
        private ordersService: OrdersEntityService,
        private quotesDataService: QuotesDataService,
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private suppliersService: SuppliersEntityService,
    ) {
        super('Quotes', serviceElementsFactory);

        this.getUserInformations();

        this.loadAll();

        // Listen for errors
        this.errors$.subscribe(e => this.errorStoreService.showErrorNotifications(e));
    }

    /******************/
    /**    Getter     */
    /******************/
    /** Use to define entities as array of Quote Model */
    getEntities(): Observable<QuoteModel[]> {
        return this.entities$.pipe(map((quotes) => quotes.map((o) => new QuoteModel(o))));
    }

    /******************/
    /**   Selectors   */
    /******************/
    selectQuoteNbItems(): Observable<number> {
        return this.getEntities().pipe(
            map((quotes) => quotes.filter((o) => o.status === QuoteStatus.QuoteCart)),
            map((x) => x.reduce((p, c) => (p += c.quoteLines?.length), 0)),
        );
    }

    selectQuotesCart(): Observable<QuoteModel[]> {
        return this.getEntities().pipe(
            map((quotes) => quotes.filter((o) => o.status === QuoteStatus.QuoteCart)),
            map((quotes) => orderBy(quotes, 'supplier.name')),
        );
    }

    selectOrCreateQuote(product: ProductModel): Observable<QuoteModel> {
        return this.getEntities().pipe(
            map((quotes) => quotes.find((o) => o.status === QuoteStatus.QuoteCart && o.supplier.id === product.supplierCompanyId)),
            switchMap((quote) => (quote ? of(quote) : this.createQuote(product))),
        );
    }

    selectQuoteBySupplier(supplierId: string): Observable<QuoteModel> {
        return this.getEntities().pipe(map((quotes) => quotes.find((o) => o.status === QuoteStatus.QuoteCart && o.supplier.id === supplierId)));
    }

    selectEntityById(id: string): Observable<QuoteModel> {
        return this.entityMap$.pipe(map((entities) => entities[id]));
    }

    selectIsFirstQuote(): Observable<boolean> {
        return this.getEntities().pipe(map((entities) => entities.length === 1));
    }

    selectQuotes(): Observable<QuoteModel[]> {
        return this.getEntities().pipe(
            map((quotes) => quotes.filter((o) => o.status > QuoteStatus.QuoteCart)),
            map((quotes) => orderBy(quotes, '_createdDate', 'desc')),
        );
    }

    /******************/
    /**    Actions    */
    /******************/
    addToQuote(product: ProductModel, quantity: number): Observable<any> {
        return this.selectOrCreateQuote(product).pipe(
            take(1),
            switchMap((quote) => {
                // // Check if the product is already in the quote
                let _quoteLine: QuoteLineModel = quote.quoteLines?.find((o) => o.product.id === product.id);

                if (_quoteLine) {
                    _quoteLine.quantity += +quantity;
                    _quoteLine.total = _quoteLine.quantity * _quoteLine.product.getPrice();
                } else {
                    _quoteLine = new QuoteLineModel({ product: product, quantity: quantity });
                    quote.quoteLines.push(_quoteLine);
                }

                this.quotesDataService.addOrUpdateQuoteLine(quote.id, _quoteLine);
                return this.update(quote);
            }),
        );
    }

    async addToCart(quote: QuoteModel): Promise<any> {
        const supplierCompanyId = quote.supplier.id;

        for await (const quoteLine of quote.quoteLines) {
            const _product = this.prepareProductToCart(quoteLine.product, quoteLine.priceQuote, supplierCompanyId);
            await this.ordersService.addToCart(_product, quoteLine.quantity).pipe(first()).toPromise();
        }
        const _quote = new QuoteModel();
        _quote.id = quote.id;
        _quote.quoteLines = quote.quoteLines;
        _quote.status = QuoteStatus.AcceptedAndCart;
        return this.update(_quote).pipe(first()).toPromise();
    }

    cancelLoad() {
        this.cancel(CRID_LOAD);
    }

    deleteQuote(quote: QuoteModel): Observable<any> {
        const quoteLines = quote.quoteLines;
        return combineLatest([
            this.delete(quote.id),
            quoteLines.map((quoteLine: any) => {
                return this.quotesDataService.deleteQuoteLine(quote.id, quoteLine.id);
            }),
        ]);
    }

    getQuoteLines(quoteId: string): Observable<boolean> {
        return this.quotesDataService.getQuoteLines(quoteId).pipe(
            map((quoteLines) => {
                // Add quote lines in quote in store
                const _quote = new QuoteModel();
                _quote.id = quoteId;
                _quote.quoteLines = quoteLines || [];
                this.updateOneInCache(_quote);
                return true;
            }),
        );
    }

    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    loadById(id: string): Observable<any> {
        return this.getByKey(id, { correlationId: this.getCorrelationId('loadById') });
    }

    removeFromQuote(product: ProductModel, supplierId: string): void {
        this.selectQuoteBySupplier(supplierId)
            .pipe(
                take(1),
                switchMap((quote) => {
                    return combineLatest([of(quote), this.quotesDataService.deleteQuoteLine(quote.id, product.id)]);
                }),
                map((res) => {
                    const _quote = cloneDeep(res[0]);
                    // If the product is the only one in the quote => delete the quote
                    if (_quote.quoteLines.length === 1) {
                        return this.delete(_quote.id);
                    } else {
                        //If there is many products, only remove the product from the quote
                        _quote.quoteLines = _quote.quoteLines.filter((o) => o.product.id !== product.id);
                        return this.update(_quote);
                    }
                }),
            )
            .subscribe();
    }

    updateQuoteLineQuantity(quote: QuoteModel, quoteLine: QuoteLineModel): void {
        this.quotesDataService
            .updateQuoteLineQuantity(quote.id, quoteLine)
            .pipe(first())
            .subscribe(() => this.updateOneInCache(quote, { mergeStrategy: MergeStrategy.IgnoreChanges }));
    }

    validate(quote: QuoteModel): void {
        combineLatest([quote.quoteLines.map((quoteLine) => this.quotesDataService.addOrUpdateQuoteLine(quote.id, quoteLine))])
            .pipe(
                switchMap(() => this.update(quote)),
                first(),
            )
            .subscribe();
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    createQuote(product: ProductModel): Observable<QuoteModel> {
        const supplier$ = this.suppliersService.loadSupplierById(product.supplierCompanyId);

        return supplier$.pipe(
            switchMap((supplier) => {
                const _quote = new QuoteModel();

                _quote.customer = this.customer;
                _quote.status = QuoteStatus.QuoteCart;
                _quote.supplier = supplier.getObjectToAddInQuotesRequests();

                return this.add(_quote);
            }),
        );
    }

    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }

    getUserInformations() {
        const company$ = this.companyService.getCompany().pipe(filter((company) => company.id != null));
        const user$ = this.store.select(getUser).pipe(filter((user) => user != null));

        combineLatest([company$, user$])
            .pipe(first())
            .subscribe(([company, user]) => {
                if (user.roles?.includes('customer') || user.roles?.includes('customerWhiteLabel') || user.roles?.includes('user')) {
                    this.customer = {
                        id: user.id,
                        displayName: user.displayName,
                        email: user.email,
                        ...(user.phone && { phone: user.phone }),
                        companyId: user.companyId,
                        companyName: user.companyName,
                        companyAddress: company.address.toString(),
                        siret: company.siret,
                        ...(user.tenantName && { tenantName: user.tenantName }),
                    };
                }
            });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    prepareProductToCart(product: ProductModel, priceQuote: number, supplierCompanyId: string): ProductModel {
        const _product = new ProductModel();
        _product.ecotax = product.ecotax;
        _product.id = product.id;
        _product.name = product.name;
        _product.maker = product.maker;
        _product.minimumPurchase = product.minimumPurchase;
        _product.photo = product.photo;
        _product.price = priceQuote;
        _product.provider = product.provider;
        _product.referenceMaker = product.referenceMaker;
        _product.referenceProvider = product.referenceProvider;
        _product.referenceSupplier = product.referenceSupplier;
        _product.size = product.size;
        _product.taxes = product.taxes;
        _product.supplierCompanyId = supplierCompanyId;

        return <ProductModel>omitBy(_product, isNil);
    }
}
