// Angular
import { EventEmitter, Inject, Input, Component, forwardRef, Optional, Output, ChangeDetectorRef, OnInit } from '@angular/core';
// Angular Instantsearch
import { NgAisIndex, TypedBaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
// Instantsearch
import { connectHitsWithInsights } from 'instantsearch.js/es/connectors';
import { HitsConnectorParams, HitsWidgetDescription, HitsRenderState } from 'instantsearch.js/es/connectors/hits/connectHits';
// RXJS
import { Observable } from 'rxjs';
// Store
import { FavoritesEntityService, FavoriteModel } from '@store/index';

@Component({
    selector: 'typesense-hits-products',
    templateUrl: 'hits-products.component.html',
    styleUrls: ['hits-products.component.scss'],
})
export class TypesenseHitsProductsComponent extends TypedBaseWidget<HitsWidgetDescription, HitsConnectorParams> implements OnInit {
    @Input() displaySupplierName = true;
    @Input() canAddToInternalCatalog = false;
    @Input() canBuy = true;
    @Input() escapeHTML?: HitsConnectorParams['escapeHTML'];
    @Input() favoritesProducts: string[] = [];
    @Input() selectedProductsId: string[] = [];
    @Input() transformItems?: HitsConnectorParams['transformItems'];

    @Output() addToCartOrQuotesCart = new EventEmitter();
    @Output() addToInternalCatalog = new EventEmitter();
    @Output() viewProduct = new EventEmitter();
    @Output() viewSupplierInfo = new EventEmitter();

    public state: HitsRenderState = {
        hits: [],
        results: undefined,
        bindEvent: undefined,
        sendEvent: undefined,
    };

    isStoick$: Observable<boolean>;

    //prettier-ignore
    constructor(
        @Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
        private cdr: ChangeDetectorRef,
        private favoritesService: FavoritesEntityService,
    )
    {
        super('Hits');
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.createWidget(connectHitsWithInsights, {
            escapeHTML: this.escapeHTML,
            transformItems: this.transformItems,
        });

        super.ngOnInit();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    addFavorite(event: MouseEvent, item: any): void {
        event.stopPropagation();
        const favorite = new FavoriteModel({ productId: item.productId, supplierId: item.supplierCompanyId });
        this.favoritesService.add(favorite);
        setTimeout(() => this.cdr.markForCheck(), 500);
    }

    deleteFavorite(event: MouseEvent, item: any): void {
        event.stopPropagation();
        this.favoritesService.delete(item.productId);
    }

    onAddToCartOrQuoteRequest(event: MouseEvent, item: any): void {
        event.stopPropagation();
        this.addToCartOrQuotesCart.emit(item);
    }

    onAddToInternalCatalog(item: any): void {
        this.addToInternalCatalog.emit(item);
    }

    onSelectSupplier(event: MouseEvent, item: any): void {
        event.stopPropagation();
        this.viewSupplierInfo.emit(item);
    }

    onViewProduct(item: any): void {
        this.viewProduct.emit(item);
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    updateState = (state: HitsRenderState, isFirstRendering: boolean): void => {
        this.cdr.markForCheck();
        if (isFirstRendering) return;
        this.state = state;
    };

    /*******************/
    /*       UI        */
    /*******************/
    getPrice(hit: any): number {
        return (hit.priceDiscount || hit.price) + hit.ecotax;
    }

    isFavorite(hit: any): boolean {
        return this.favoritesProducts.includes(hit.productId);
    }

    isSelected(hit: any): boolean {
        return this.selectedProductsId?.includes(hit.productId);
    }

    isQuotation(hit: any): boolean {
        return !!hit.quotation;
    }
}
