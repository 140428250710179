// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { HtmlClassService } from '../html-class.service';
// Environment
import { environment } from '@env/environment';

@Component({
    selector: 'kt-footer',
    templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
    // Public properties
    footerClasses = '';
    footerContainerClasses = '';
    today: number = Date.now();
    version: string;

    constructor(private uiClasses: HtmlClassService) {}

    ngOnInit(): void {
        this.footerClasses = this.uiClasses.getClasses('footer', true).toString();
        this.footerContainerClasses = this.uiClasses.getClasses('footer_container', true).toString();
        this.version = environment.version;
    }
}
