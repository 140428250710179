// Angular
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
import { LayoutUtilsService, MessageType, TypesUtilsService } from '@core/_base/crud';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { CatalogModel, CatalogsEntityService, ProductDiscount, ProductModel } from '@store/index';
// RXJS
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'catalog-add-product',
    templateUrl: './catalog-add-product.component.html',
})
export class CatalogAddProductComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    catalog: CatalogModel;
    loading = false;
    product: ProductModel;
    productDiscount = new ProductDiscount();

    //prettier-ignore
    constructor(
        private catalogsService: CatalogsEntityService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<CatalogAddProductComponent>,
        private layoutUtilsService: LayoutUtilsService,
        private translateService: TranslateService,
        private typesUtilsService: TypesUtilsService,
    ) {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.product = this.data.product;
        this.catalog = this.data.catalog;
        this.productDiscount.id = this.product.id;
        this.productDiscount.updateProductDiscount(this.product.price, this.catalog.discount);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    addProduct(): void {
        this.loading = true;

        this.catalogsService
            .addProduct(this.catalog, this.productDiscount)
            .pipe(debounceTime(500))
            .subscribe(
                (res) => {
                    this.loading = false;
                    this.dialogRef.close({ catalogId: res.id || this.catalog.id });
                    const _message = this.translateService.instant('CATALOGS.EDIT.PRODUCT_ADDED');
                    this.layoutUtilsService.showActionNotification(_message, MessageType.Success, 3000, true, false);
                },
                () => (this.loading = false),
            );
    }

    onDiscountChange() {
        this.productDiscount.discount = this.typesUtilsService.getNumber(this.productDiscount.discount);
        this.productDiscount.canEditDiscount = true;
        this.productDiscount.canEditPrice = this.productDiscount.discount === 0;
        this.productDiscount.updateProductDiscount(this.product.price, this.catalog.discount);
    }

    onDiscountPriceChange() {
        this.productDiscount.priceDiscount = this.typesUtilsService.getNumber(this.productDiscount.priceDiscount);
        this.productDiscount.canEditDiscount = this.productDiscount.priceDiscount === 0;
        this.productDiscount.canEditPrice = true;
        this.productDiscount.updateProductDiscount(this.product.price, this.catalog.discount);
    }

    close() {
        this.dialogRef.close();
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
}
