// Angular
import { Injectable } from '@angular/core';
// AngularFire
import { AngularFireStorage } from '@angular/fire/storage';
// RXJS
import { switchMap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    // Define the path to the tenant of the user
    private folderPath: string;

    constructor(private afs: AngularFireStorage) {}

    /**
     * @param {companyId} companyId id of the user's company
     *
     * Init folder path
     **/
    setFolderPath(folder: string, id: string): void {
        this.folderPath = `/${folder}/${id}`;
    }

    uploadFile(file: File, fileName?: string, folder?: string, inCompanyFolder?: boolean, addTimestampToFilename?: boolean): Observable<string> {
        const _fileName = addTimestampToFilename ? `${Date.now()}_${fileName}` : fileName;

        // The storage path
        let path = folder ? `${folder}/${_fileName}` : `${this.folderPath}/${_fileName}`;
        if (inCompanyFolder) {
            path = `${this.folderPath}/${path}`;
        }

        // Reference to storage bucket
        const ref = this.afs.ref(path);

        // The main task
        const task = this.afs.upload(path, file);

        return from(task).pipe(switchMap(() => ref.getDownloadURL()));
    }

    deleteFile(url: string): Promise<any> {
        try {
            return this.afs.storage.refFromURL(url).delete();
        } catch (e) {
            return Promise.resolve(null);
        }
    }
}
