import * as fromAuth from '../_reducers/auth.reducer';
import * as fromChangePassword from '../_reducers/password-management.reducer';
import * as fromCustomEmailHandler from '../_reducers/custom-email-handler.reducer';

import { ActionReducerMap, createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

export interface AuthState {
    auth: fromAuth.State;
    changePassword: fromChangePassword.State;
    customEmailHandler: fromCustomEmailHandler.State;
}

export const reducers: ActionReducerMap<AuthState> = {
    auth: fromAuth.authReducer,
    changePassword: fromChangePassword.passwordManagementReducer,
    customEmailHandler: fromCustomEmailHandler.customEmailHandlerReducer,
};

export const getAuthState: MemoizedSelector<unknown, AuthState> = createFeatureSelector<AuthState>('auth');

export const getAuthStatus = createSelector(getAuthState, (state) => state?.auth);

export const isAuthLoading = createSelector(getAuthState, (state) => state?.auth?.loading);

export const getUser = createSelector(getAuthState, (state) => state?.auth?.user);

export const getAuthError = createSelector(getAuthState, (state) => state?.auth?.error);

export const isAuthSuccess = createSelector(getAuthState, (state) => state?.auth?.loggedIn);

export const getChangePasswordStatus = createSelector(getAuthState, (state) => state?.changePassword);

export const getCustomEmailHandlerRequestStatus = createSelector(getAuthState, (state) => {
    return {
        emailVerified: {
            loading: state.customEmailHandler.emailVerified.loading,
            error: state.customEmailHandler.emailVerified.error,
            success: state.customEmailHandler.emailVerified.success,
        },
    };
});

export const getEmailVerifiedStatus = createSelector(getAuthState, (state) => state.customEmailHandler.emailVerified);

export const getPasswordResetStatus = createSelector(getAuthState, (state) => state.customEmailHandler.resetPassword);

export const getVerifyResetPasswordProcedure = createSelector(getAuthState, (state) => state.customEmailHandler.passwordResetCode);

export const getEmailVerifiedError = createSelector(getAuthState, (state) => state.customEmailHandler.emailVerified.error);

export const isEmailVerifiedLoading = createSelector(getAuthState, (state) => state.customEmailHandler.emailVerified.loading);

export const getPasswordResetCodeError = createSelector(getAuthState, (state) => state.customEmailHandler.passwordResetCode.error);

export const isPasswordResetCodeLoading = createSelector(getAuthState, (state) => state.customEmailHandler.passwordResetCode.loading);

export const getPasswordResetCodeEmail = createSelector(getAuthState, (state) => state.customEmailHandler.passwordResetCode.email);

export const getResetPasswordError = createSelector(getAuthState, (state) => state.customEmailHandler.resetPassword.error);

export const isResetPasswordLoading = createSelector(getAuthState, (state) => state.customEmailHandler.resetPassword.loading);

export const getCheckCodeError = createSelector(getAuthState, (state) => state.customEmailHandler.checkCode.error);

export const isCheckCodeLoading = createSelector(getAuthState, (state) => state.customEmailHandler.checkCode.loading);

export const getRecoverEmailError = createSelector(getAuthState, (state) => state.customEmailHandler.recoverEmail.error);

export const isRecoverEmailLoading = createSelector(getAuthState, (state) => state.customEmailHandler.recoverEmail.loading);

export const getResetPasswordOrCodeError = createSelector(getAuthState, (state) => state.customEmailHandler.resetPassword.error || state.customEmailHandler.passwordResetCode.error);

export const getResetPasswordSuccess = createSelector(getAuthState, (state) => state.customEmailHandler.resetPassword.success);

export const isResetPasswordOrCodeLoading = createSelector(getAuthState, (state) => state.customEmailHandler.resetPassword.loading || state.customEmailHandler.passwordResetCode.loading);

export const getRecoverEmailOrCheckCodeError = createSelector(getAuthState, (state) => state.customEmailHandler.recoverEmail.error || state.customEmailHandler.checkCode.error);

export const isRecoverEmailOrCheckCodeLoading = createSelector(getAuthState, (state) => state.customEmailHandler.recoverEmail.loading || state.customEmailHandler.checkCode.loading);
