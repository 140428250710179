// Angular
import { NgModule } from '@angular/core';
// Others
import { BootstrapModule } from './bootstrap.module';
import { MaterialModule } from './material.module';
import { NgxModule } from './ngx.module';
import { PartialsModule } from './partials/partials.module';

//prettier-ignore
@NgModule({
    exports: [
        BootstrapModule,
        MaterialModule,
        NgxModule,
        PartialsModule,
    ],
})
export class ThemeModule {}
