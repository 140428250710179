export class QueryParamsModel {
    filter: any;
    pageIndex: number;
    pageSize: number; // 25 | 50 | 100
    searchValue: string;
    sortField: string;
    sortDirection: 'asc' | 'desc';

    constructor(_filter: any = null, _sortDirection: 'asc' | 'desc' = 'asc', _searchValue = '', _sortField = '', _pageIndex = 0, _pageSize = 50) {
        this.filter = _filter;
        this.pageIndex = Number(localStorage.getItem('pageIndex')) || _pageIndex;
        this.pageSize = _pageSize;
        this.searchValue = _searchValue;
        this.sortDirection = _sortDirection;
        this.sortField = _sortField;
    }
}
