// Angular
import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
// Angular Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// Store
import { CatalogsEntityService } from '@store/index';
// Core
import { ExcelService, LogService } from '@core/services';
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Lodash
import { groupBy } from 'lodash';
// RXJS
import { first } from 'rxjs/operators';

@Component({
    selector: 'catalog-import',
    templateUrl: './catalog-import-dialog.component.html',
    providers: [ExcelService],
})
export class CatalogImportDialogComponent implements OnDestroy {
    // Public properties
    checkFileOK: boolean = null;
    createItemsOK: boolean = null;
    errors: any = [];
    headerSize = 4; // Size of header in Excel template
    loading = false;

    //prettier-ignore
    constructor(
        private catalogsService: CatalogsEntityService,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CatalogImportDialogComponent>,
        private excelService: ExcelService,
        private layoutUtilsService: LayoutUtilsService,
        private logService: LogService,
        private translateService: TranslateService,
        )
    {}

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnDestroy(): void {
        this.clear();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    clear(): void {
        this.errors = [];
        this.checkFileOK = null;
        this.createItemsOK = null;
    }

    async onFileChange(event): Promise<void> {
        try {
            this.loading = true;
            this.cdr.markForCheck();
            this.clear();

            const lineHeader = ['reference', 'name', 'price', 'discount', 'priceDiscount'];
            const file = event.addedFiles[0];
            const excelData = await this.excelService.importExcelFile(file, lineHeader, this.headerSize);

            // Check if file format is valid
            this.checkFileOK = await this.checkFile(excelData);

            if (!this.checkFileOK) {
                this.loading = false;
                return;
            }

            this.catalogsService
                .import(this.data.catalog, excelData)
                .pipe(first())
                .subscribe(
                    (catalog) => {
                        this.loading = false;
                        const message = this.translateService.instant('PRODUCTS.IMPORT.SUCCESS');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Success, 5000, true, false);
                        this.dialogRef.close(catalog);
                    },
                    (error) => {
                        console.error(error);
                        const _error = { status: error.status, message: error.error?.message || error.message };
                        this.logService.logErrorsInFS(_error);
                        this.loading = false;
                        const message = this.translateService.instant('PRODUCTS.IMPORT.ERROR');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 5000, true, false);
                    },
                );
        } catch (error) {
            this.loading = false;
            console.error(error);
            const _error = { status: error.status, message: error.error?.message || error.message };
            this.logService.logErrorsInFS(_error);
            const message = this.translateService.instant('PRODUCTS.IMPORT.ERROR_FILE');
            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 7000, true, false);
        }
    }

    checkFile(items: any[]): Promise<boolean> {
        items.forEach((item) => {
            const rowNum = item.__rowNum__ + 1;
            /** Check for required field */
            // Reference
            if (!item.reference) this.errors.push({ line: rowNum, message: this.translateService.instant('PRODUCTS.IMPORT.REQUIRED_MISSING_REFERENCE') });
        });

        if (this.errors.length > 0) {
            this.errors = groupBy(this.errors, 'line');
            return new Promise((resolve) => setTimeout(() => resolve(false), 500));
        }

        return new Promise((resolve) => setTimeout(() => resolve(true), 500));
    }

    close(): void {
        this.dialogRef.close();
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/

    /***************/
    /*     UI      */
    /***************/
    hasErrors(): boolean {
        return Object.keys(this.errors).length > 0;
    }
}
