// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCacheAction, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityOp } from '@ngrx/data';
// RXJ
import { combineLatest, Observable, throwError } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
// Store
import { CategoriesEntityService } from '@store/categories/categories-entity.service';
import { ProductsDataService } from './products-data.service';
import { ErrorStoreService } from '@store/error-store.service';
// Model
import { ManagementMode, ProductModel, StockStatus } from './product.model';
// Lodash
import { orderBy, sortBy, uniqBy } from 'lodash';

const CRID_LOAD = 'CRID_PRODUCTS';
@Injectable({
    providedIn: 'root',
})
export class ProductsEntityService extends EntityCollectionServiceBase<any> {
    private _correlationIndex = 0;

    // prettier-ignore
    constructor(
        private categoriesService: CategoriesEntityService,
        private errorStoreService: ErrorStoreService,
        private productsDataService: ProductsDataService,
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('Products', serviceElementsFactory);
    }

    /******************/
    /**    Getter     */
    /******************/
    getEntities(): Observable<ProductModel[]> {
        return this.entities$.pipe(map((products) => products.map((p) => new ProductModel(p))));
    }

    /******************/
    /**    Actions    */
    /******************/
    deleteFileInStorage(url: string): Promise<any> {
        return this.productsDataService.deleteFileInStorage(url);
    }

    deleteMany(productsId: string[]): Observable<any> {
        return this.productsDataService.deleteMany(productsId);
    }

    deleteProductsInInternalCatalog(productsId: string[]): Observable<any> {
        return this.productsDataService.deleteMany(productsId, true);
    }

    import(data: any): Observable<any> {
        return this.productsDataService.import(data);
    }

    isProducts(): Observable<boolean> {
        return this.productsDataService.productsExist();
    }

    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    loadById(id: string): Observable<any> {
        return this.getByKey(id, { correlationId: this.getCorrelationId('loadById') });
    }

    saveProductsFiles(file: File, filename: string): Observable<string> {
        return this.productsDataService.saveProductsFiles(file, filename);
    }

    saveProductInInternalCatalog(products: ProductModel[]): void {
        const _products = products.map((p) => new ProductModel(p).getObjectToSaveInInternalCatalog());
        this.productsDataService
            .updateMany(_products, true)
            .pipe(
                tap(() => {
                    this.setLoading(false);
                    this.createAndDispatch(EntityOp.SAVE_UPDATE_MANY_SUCCESS, []);
                }),
                first(),
            )
            .subscribe();
    }

    updateMany(products: ProductModel[]): void {
        this.setLoading(true);
        this.productsDataService
            .updateMany(products)
            .pipe(
                tap(() => {
                    this.setLoading(false);
                    this.createAndDispatch(EntityOp.SAVE_UPDATE_MANY_SUCCESS, []);
                }),
                first(),
            )
            .subscribe();
    }

    updatePhotoInProductsList(file: File, product: ProductModel) {
        return this.productsDataService.updatePhotoInProductsList(file, product);
    }

    /******************/
    /**   Selectors   */
    /******************/
    selectAlerts(): Observable<ProductModel[]> {
        return this.getEntities().pipe(
            map((products) => products.filter((p) => p.statusFutureStock === StockStatus.Alert)),
            map((products) => orderBy(products, 'stock')),
        );
    }

    selectOutOfStock(): Observable<ProductModel[]> {
        return this.getEntities().pipe(
            map((products) => products.filter((p) => p.statusFutureStock === StockStatus.OutOfStock)),
            map((products) => orderBy(products, 'stock')),
        );
    }

    /** Select product by id in store collection */
    selectEntityById(productId: string): Observable<ProductModel> {
        return this.entityMap$.pipe(
            map((entities) => entities[productId]),
            first(),
        );
    }

    /** Products "Click & Collect and D-STock only" */
    selectProductsWithStock(): Observable<ProductModel[]> {
        return this.getEntities().pipe(map((products) => products.filter((p) => p.managementMode === ManagementMode['Click & Collect'] || p.managementMode === ManagementMode['D-Stock'])));
    }

    selectProductsWithCategories(productId?: string): Observable<Partial<ProductModel>[]> {
        const categories$: Observable<any[]> = this.categoriesService.selectFlatCategories();
        const products$: Observable<ProductModel[]> = productId ? this.selectEntityById(productId).pipe(map((product) => [product])) : this.productsDataService.getAllSnapshot();

        return combineLatest([categories$, products$]).pipe(
            map(([categories, products]) => {
                return (
                    products
                        // Join categories and products
                        .map((product) => {
                            const category = categories.find((c) => c.id === product?.categoryId) || null;
                            return { ...product, category };
                        })
                );
            }),
        );
    }

    selectProductsWithCategoriesFlatName(onlyProductsWithStock?: boolean): Observable<any> {
        const categories$: Observable<any[]> = this.categoriesService.selectFlatCategories();
        const products$: Observable<ProductModel[]> = onlyProductsWithStock ? this.selectProductsWithStock() : this.getEntities();

        return combineLatest([categories$, products$]).pipe(
            map(([categories, products]) => {
                // Join categories and products
                return products.map((product) => {
                    return {
                        ...product,
                        categoryName: categories.find((c) => c.id === product.categoryId)?.flatName || null,
                    };
                });
            }),
        );
    }

    selectSupplierOfProducts(): Observable<any> {
        return this.getEntities().pipe(
            map((products) =>
                products.map((p) => {
                    return {
                        id: p.supplier?.id || null,
                        name: p.supplier?.name || null,
                        logo: p.supplier?.logo || null,
                    };
                }),
            ),
            map((suppliers) => sortBy(uniqBy(suppliers, 'id'), 'name')),
        );
    }

    /******************/
    /**    Errors     */
    /******************/
    /**
     *
     * Delete one or many case : as delete is optimistic operation => undo data to store are consistent with server
     *
     */
    errorHandler(error: any): any {
        switch (error.payload?.entityOp || error.originalAction?.type) {
            case EntityOp.SAVE_DELETE_ONE_ERROR: {
                this.createAndDispatch(EntityOp.UNDO_ONE, error.payload.data.originalAction.payload.data);
                break;
            }
            case EntityCacheAction.SAVE_ENTITIES: {
                this.createAndDispatch(EntityOp.UNDO_MANY, error.originalAction.payload.changeSet.changes[0].entities);
                break;
            }
            default:
                break;
        }

        this.errorStoreService.showErrorNotifications(error);
        return throwError(error);
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }
}
