import { AuthActions, CustomEmailHandlerActions } from '../_actions';
import { AuthUserModel } from '../_models/auth.model';

export type Action = AuthActions.AuthActionsUnion | CustomEmailHandlerActions.CustomEmailHandlerActionsUnion;

export interface State {
    error: {
        code: string;
        message: string;
    };
    loading: boolean;
    loggedIn: boolean;
    success: boolean;
    user: AuthUserModel | null;
}

const defaultState = {
    error: null,
    loading: true,
    loggedIn: false,
    success: false,
    user: null,
};

/// Reducer function
export function authReducer(state: State = defaultState, action: Action): State {
    switch (action.type) {
        case AuthActions.AuthActionTypes.GetUser: {
            return { ...state, loading: true, success: false };
        }

        case AuthActions.AuthActionTypes.Authenticated: {
            return { ...state, user: action.payload.user, loading: false, loggedIn: true, success: false };
        }

        case AuthActions.AuthActionTypes.NotAuthenticated:
            return { ...state, ...defaultState, loading: false, loggedIn: false, success: false };

        case AuthActions.AuthActionTypes.CredentialsLogin:
        case AuthActions.AuthActionTypes.CredentialsReAuthentication:
        case AuthActions.AuthActionTypes.SendRegisterLink:
            return { ...state, loading: true, success: false };

        case AuthActions.AuthActionTypes.ResetAuthState:
            return { ...state, loading: false, success: false, error: null };

        case AuthActions.AuthActionTypes.AuthError:
        case AuthActions.AuthActionTypes.DeleteAccountError:
        case AuthActions.AuthActionTypes.ReAuthenticationError:
        case AuthActions.AuthActionTypes.RegisterLinkError:
            return { ...state, loading: false, success: false, error: action.payload };

        case AuthActions.AuthActionTypes.ReAuthenticationSuccess:
        case AuthActions.AuthActionTypes.RegisterLinkSuccess:
            return { ...state, loading: false, error: null, success: true };

        case AuthActions.AuthActionTypes.Logout:
            return { ...state, loading: true };
        case AuthActions.AuthActionTypes.DeleteAccount:
            return { ...state, loading: true, success: true, error: null };
        case AuthActions.AuthActionTypes.DeleteAccountSuccess:
            return { ...state, loading: false, loggedIn: false, success: true, error: null, user: null };

        case CustomEmailHandlerActions.CustomEmailHandlerActionTypes.VerifyEmailAddressSuccess:
            return { ...state, user: { ...state.user, emailVerified: true } };

        default:
            return state;
    }
}
