// Angular
import { EventEmitter, Inject, Input, Component, forwardRef, Optional, Output, ChangeDetectorRef, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy } from '@angular/core';
// Material
import { MatSort } from '@angular/material/sort';
// Angular Instantsearch
import { NgAisIndex, TypedBaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
// Instantsearch
import { connectHitsWithInsights } from 'instantsearch.js/es/connectors';
import { HitsConnectorParams, HitsWidgetDescription, HitsRenderState } from 'instantsearch.js/es/connectors/hits/connectHits';
// Store
import { ProductDiscount, ProductModel } from '@store/index';
// Core
import { CustomDataTable, TypesUtilsService } from '@core/_base/crud';
// RXJS
import { BehaviorSubject, Subscription } from 'rxjs';
// Typesense
import { COLLECTION_PRODUCTS } from '../typesense-client';
import { TypesenseSortService } from '../sort/sort.service';
// Lodash
import { debounce } from 'lodash';

@Component({
    selector: 'typesense-hits-products-list-edit',
    templateUrl: 'hits-products-list-edit.component.html',
    styleUrls: ['hits-products-list-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TypesenseHitsProductsListEditComponent extends TypedBaseWidget<HitsWidgetDescription, HitsConnectorParams> implements OnInit, OnDestroy {
    @Input() displaySelection = true;
    @Input() escapeHTML?: HitsConnectorParams['escapeHTML'];
    @Input() transformItems?: HitsConnectorParams['transformItems'];

    /** Update price when catalog discount is updated */
    @Input()
    set catalogDiscount(value: number) {
        this._catalogDiscount = value || 0;
    }

    @Input() set productsDiscounts(value: ProductDiscount[]) {
        if (this.state.hits.length > 0 && value?.length > 0) {
            this._productsDiscounts = value;
            this.data$.next(this.joinProductsAndDiscounts());
        }
    }

    @Input() set loading(value: boolean) {
        this.loading$.next(value);
    }

    @Output() deleteProducts = new EventEmitter();
    @Output() hitsChange = new EventEmitter();
    @Output() productDiscountChange = new EventEmitter<ProductDiscount>();
    @Output() selectProduct = new EventEmitter<ProductModel>();

    // Datatable
    @ViewChild('tableLoader') set tableLoader(tableLoader: ElementRef) {
        this.dataTable.tableLoader = tableLoader;
    }
    public columnDefinitions: any[] = [];
    public dataTable: CustomDataTable;
    data$ = new BehaviorSubject([]);

    // Others
    _catalogDiscount: number;
    _productsDiscounts: ProductDiscount[];
    _matSort: MatSort;
    isEdit = false;
    itemsSort = [];
    hasItems = false;
    loading$ = new BehaviorSubject(false);
    productsToSave: ProductDiscount[];
    subs: Subscription;

    // Typessense
    public state: HitsRenderState = {
        hits: [],
        results: undefined,
        bindEvent: undefined,
        sendEvent: undefined,
    };

    //prettier-ignore
    constructor(
        @Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
        private cdr: ChangeDetectorRef,
        private sortService: TypesenseSortService,
        private typesUtilsService: TypesUtilsService,
    )
    {
        super('Hits');

        this.initSort();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.createWidget(connectHitsWithInsights, {
            escapeHTML: this.escapeHTML,
            transformItems: this.transformItems,
        });

        super.ngOnInit();

        this.loadColumns();

        this.initDataTable();

        this.productsToSave = [];
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }

    /***************/
    /*  DATATABLE  */
    /***************/
    getDisplayedColumns(): string[] {
        return this.columnDefinitions.filter((cd) => !cd.hide).map((cd) => cd.def);
    }

    initDataTable(): void {
        this.dataTable = new CustomDataTable(this.data$, null, this.loading$, null);
        this.dataTable.setPageSize(100);
    }

    initSort() {
        this.itemsSort = [
            { label: 'name_asc', value: `${COLLECTION_PRODUCTS}/sort/name:asc` },
            { label: 'name_desc', value: `${COLLECTION_PRODUCTS}/sort/name:desc` },
            { label: 'price_asc', value: `${COLLECTION_PRODUCTS}/sort/price:asc` },
            { label: 'price_desc', value: `${COLLECTION_PRODUCTS}/sort/price:desc` },
            { label: 'reference_asc', value: `${COLLECTION_PRODUCTS}/sort/referenceSupplier:asc` },
            { label: 'reference_desc', value: `${COLLECTION_PRODUCTS}/sort/referenceSupplier:desc` },
        ];
    }

    loadColumns(): void {
        // prettier-ignore
        this.columnDefinitions = [
            { def: 'select' },
            { def: 'photo' },
            { def: 'reference' },
            { def: 'name' },
            { def: 'price' },
            { def: 'discount' },
            { def: 'priceDiscount' },
        ];
    }

    listenOnSortChange(matSort: MatSort) {
        const defaultSort = 'reference_asc';
        if (matSort) {
            this.subs = matSort.sortChange.subscribe((sort) => {
                const _sort = sort ? `${sort.active}_${sort.direction}` : defaultSort;
                this.sortService.setsortValue$(_sort);
            });
        } else {
            this.sortService.setsortValue$(defaultSort);
        }
    }
    /***************/
    /*   ACTIONS   */
    /***************/
    handlerDeleteSelectedProducts(event: any): void {
        event.stopPropagation();
        this.deleteProducts.emit(this.dataTable.getSelectedProducts());
    }

    debouceDiscountChange = debounce((item: any) => this.onDiscountChange(item), 1000);

    onDiscountChange(item: any) {
        item.discount = this.typesUtilsService.getNumber(item.discount);
        item.canEditDiscount = true;
        item.canEditPrice = item.discount === 0;

        this.saveDiscount(item);
    }

    debouceDiscountPriceChange = debounce((item: any) => this.onDiscountPriceChange(item), 1000);

    onDiscountPriceChange(item: any) {
        item.priceDiscount = this.typesUtilsService.getNumber(item.priceDiscount);
        item.canEditDiscount = item.priceDiscount === 0;
        item.canEditPrice = true;

        this.saveDiscount(item);
    }
    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    joinProductsAndDiscounts(): any[] {
        if (!this._productsDiscounts) return this.state.hits;

        return this.state.hits.map((p) => {
            const productDiscount = this._productsDiscounts.find((el) => el.id === p.productId);
            const _productDiscount = this.prepareProductDiscount(productDiscount || p, p.price);

            return { ...p, ..._productDiscount };
        });
    }

    prepareProductDiscount(item: any, price: number): ProductDiscount {
        const productDiscount = new ProductDiscount();
        productDiscount.canEditDiscount = item.canEditDiscount === undefined ? true : item.canEditDiscount;
        productDiscount.canEditPrice = item.canEditPrice === undefined ? true : item.canEditPrice;
        productDiscount.discount = item.discount || 0;
        productDiscount.id = item.id;
        productDiscount.priceDiscount = item.priceDiscount || 0;

        productDiscount.updateProductDiscount(price, this._catalogDiscount);

        return productDiscount;
    }

    saveDiscount(item: any) {
        this._productsDiscounts = this._productsDiscounts?.filter((el) => el.id !== item.id);
        const productDiscount = this.prepareProductDiscount(item, item.price);

        item.discount = productDiscount.discount;
        item.priceDiscount = productDiscount.priceDiscount;

        this._productsDiscounts.push(productDiscount);
        this.isEdit;
        this.cdr.markForCheck();

        this.productDiscountChange.emit(productDiscount);
    }

    updateState = (state: HitsRenderState, isFirstRendering: boolean): void => {
        if (isFirstRendering) return;
        this.state = state;
        this.hasItems = state.hits.length > 0;
        this.dataTable.checkAllSelected();
        this.hitsChange.emit(this.state.hits.map((hit) => hit.productId));
        this.cdr.markForCheck();
    };

    /*******************/
    /*    SELECTION    */
    /*******************/
    isAllSelected(): boolean {
        return this.dataTable.isAllSelected;
    }

    isItemsSelected(): boolean {
        return this.dataTable?.nbItemsSelected() > 0;
    }

    isSelected(item: ProductModel) {
        return this.dataTable.selection.isSelected(item);
    }

    selectRow(item: ProductModel) {
        this.dataTable.selectItem(item);
        this.selectProduct.emit(item);
    }

    selectAllDisplayed() {
        this.dataTable.masterToggle();
    }

    /*******************/
    /*       UI        */
    /*******************/
}
