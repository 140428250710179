import { Component, Input } from '@angular/core';
import { bem, getPropertyByPath } from '../utils';

@Component({
    selector: 'typesense-highlight',
    template: `<span [class]="cx()" [innerHtml]="content"></span>`,
})
export class TypesenseHighlightComponent {
    @Input() attribute: string;
    @Input() hit: { _highlightResult?: unknown; label?: string; highlighted?: string };
    @Input() tagName = 'em';

    cx = bem('Highlight');

    get content(): any {
        if (this.attribute === 'highlighted') {
            return this.hit.highlighted ? this.replaceWithTagName(this.hit.highlighted) : this.hit.label;
        }

        if (this.hit.hasOwnProperty('_highlightResult')) {
            const attributeHighlighted = getPropertyByPath(this.hit._highlightResult, this.attribute);

            // check that the attributeHighlighted is a string
            if (attributeHighlighted !== undefined && typeof attributeHighlighted.value === 'string') {
                return this.replaceWithTagName(attributeHighlighted.value);
            }
        }

        const fallback = getPropertyByPath(this.hit, this.attribute);
        if (!fallback) {
            console.warn(`Could not find attribute [${this.attribute}] into hit object, will display an empty string.`);

            return '';
        }

        return fallback;
    }

    replaceWithTagName(value: string): string {
        return value.replace(new RegExp('<em>', 'g'), `<${this.tagName} class="${this.cx('highlighted')}">`).replace(new RegExp('</em>', 'g'), `</${this.tagName}>`);
    }
}
