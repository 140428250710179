// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NGRX
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
// RXJS
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';
// Core
import { FirestoreService, StorageService } from '@core/services';
import { AppState } from '@core/reducers';
import { isCustomer } from '@core/auth';
// Feature
import { SupplierModel } from './supplier.model';
// Store
import { FS_PATH_CATEGORIES, FS_PATH_CATALOGS, FS_PATH_PRODUCTS, FS_PATH_SUPPLIERS, FS_TENANT_CUSTOMERS, FS_PATH_CONTACTS, FS_PATH_SUPPLIERS_TENANT, FS_PATH_INQUIERIES } from '@store/firestore-collections';
import { CategoryModel } from '@store/categories/category.model';
import { ProductModel } from '@store/products/product.model';
import { ContactModel } from '@store/_models/contact.model';
// Environment
import { environment } from '@env/environment';

// API PATH
const API_PATH = `${environment.apiBaseUrl}/suppliers`;
// const API_PATH = 'http://localhost:5001/stoick-dev/europe-west1/suppliersTenantApi-suppliers';

@Injectable({
    providedIn: 'root',
})
export class SuppliersDataService extends DefaultDataService<any> {
    pathSuppliers: string;
    tenantPath: string;

    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        private storageService: StorageService,
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private store: Store<AppState>,
    ) {
        super('Suppliers', http, httpUrlGenerator);

        this.getTenantPath();
    }

    /*********************/
    /*        API        */
    /*********************/
    addCustomer(supplierId: string): Observable<any> {
        const path = `${API_PATH}/${supplierId}/addCustomer`;
        return this.http.post(path, {});
    }

    getSupplierCatalog(supplierId: string): Observable<[CategoryModel[], ProductModel[]]> {
        const path = `${API_PATH}/${supplierId}/catalog`;
        return this.http.get(path).pipe(
            map((res: any) => {
                const categories = res.categories.map((el) => new CategoryModel(el));
                const products = res.products.map((el) => new ProductModel(el));
                return [categories, products];
            }),
        );
    }

    getAllSuppliersTenant(): Observable<SupplierModel[]> {
        return this.http.get(API_PATH).pipe(map((suppliers: any[]) => suppliers.map((s) => new SupplierModel(s))));
    }

    getByIdSuppliersTenant(supplierId: string): Observable<SupplierModel> {
        return this.http.get(`${API_PATH}/${supplierId}`).pipe(map((supplier) => new SupplierModel(supplier)));
    }

    getByNameSuppliersTenant(supplierUrl: string): Observable<SupplierModel> {
        return this.http.get(`${API_PATH}/name/${supplierUrl}`).pipe(
            map((supplier) => new SupplierModel(supplier)),
            catchError(() => of(null)),
        );
    }

    /*********************/
    /*     FIRESTORE     */
    /*********************/
    getAll(): Observable<SupplierModel[]> {
        return this.firestoreService.collection$(FS_PATH_SUPPLIERS, FS_TENANT_CUSTOMERS).pipe(
            switchMap((suppliers: SupplierModel[]) => {
                if (suppliers.length === 0) return of([]);

                // Query contacts
                return combineLatest(suppliers.map((supplier) => this.getContact(supplier)));
            }),
        );
    }

    getById(supplierId: string): Observable<SupplierModel> {
        const path = `${this.pathSuppliers}/${supplierId}`;
        return this.firestoreService.doc$(path, this.tenantPath);
    }

    // TODO : refacto comme update ci-dessous
    add(supplier: SupplierModel): Observable<any> {
        if (supplier.logo instanceof File) {
            return this.saveLogo(supplier.logo).pipe(
                switchMap((urlLogo) => {
                    const _supplier = { ...supplier, logo: urlLogo };
                    return this.firestoreService.updateAt(FS_PATH_SUPPLIERS, _supplier, FS_TENANT_CUSTOMERS).pipe(
                        map((doc) => {
                            return { id: doc.id, ...supplier };
                        }),
                    );
                }),
            );
        } else {
            return this.firestoreService.updateAt(FS_PATH_SUPPLIERS, supplier, FS_TENANT_CUSTOMERS).pipe(
                map((doc) => {
                    return { id: doc.id, ...supplier };
                }),
            );
        }
    }

    update(supplier: Update<SupplierModel>): Observable<SupplierModel> {
        const path = `${this.pathSuppliers}/${supplier.id}`;
        const _supplier = new SupplierModel(supplier.changes);

        let logo$ = of(undefined);
        if (_supplier.logo instanceof File) {
            logo$ = this.saveLogo(_supplier.logo);
        }

        let saleTermFile$ = of(undefined);
        if (_supplier.saleTerms?.saleTermsFile instanceof File) {
            saleTermFile$ = this.saveSaleTermsFile(_supplier.saleTerms.saleTermsFile);
        }

        return combineLatest([logo$, saleTermFile$]).pipe(
            switchMap(([logo, saleTermFile]) => {
                if (logo !== undefined) _supplier.logo = logo;
                if (saleTermFile !== undefined) _supplier.saleTerms.saleTermsFile = saleTermFile;

                return this.firestoreService.updateAt(path, _supplier, this.tenantPath).pipe(map(() => _supplier));
            }),
        );
    }

    delete(supplierId: string): Observable<string | number> {
        const path = `${FS_PATH_SUPPLIERS}/${supplierId}`;
        return this.firestoreService.updateAt(path, { active: false }, this.tenantPath);
    }

    /** Load catalog of a supplier */
    loadCatalogs(supplier: SupplierModel): Observable<[CategoryModel[], ProductModel[]]> {
        const pathCatalogs = `${FS_PATH_SUPPLIERS}/${supplier.id}/${FS_PATH_CATALOGS}`;
        return this.firestoreService.collection$(pathCatalogs, FS_TENANT_CUSTOMERS).pipe(
            switchMap((catalogs: any[]) => {
                if (catalogs.length === 0) return of([[], []]);

                return combineLatest(
                    catalogs.map((catalog) => {
                        const categories$ = this.loadCategories(supplier, catalog.id);
                        const products$ = this.loadProducts(supplier, catalog.id, catalog.discount);

                        return combineLatest([categories$, products$]);
                    }),
                );
            }),
            map(([res]) => [res[0], res[1]]),
        );
    }

    loadCategories(supplier: SupplierModel, catalogId: string): Observable<CategoryModel[]> {
        const pathCategories = `${FS_PATH_SUPPLIERS}/${supplier.id}/${FS_PATH_CATALOGS}/${catalogId}/${FS_PATH_CATEGORIES}`;
        return this.firestoreService.collection$(pathCategories, FS_TENANT_CUSTOMERS).pipe(map((categories) => categories.map((c) => new CategoryModel(c))));
    }

    loadProducts(supplier: SupplierModel, catalogId: string, catalogDiscount: number): Observable<ProductModel[]> {
        const pathProducts = `${FS_PATH_SUPPLIERS}/${supplier.id}/${FS_PATH_CATALOGS}/${catalogId}/${FS_PATH_PRODUCTS}`;
        return this.firestoreService.collection$(pathProducts, FS_TENANT_CUSTOMERS).pipe(
            map((products) =>
                products
                    .filter((p: ProductModel) => p.name)
                    .map((p: ProductModel) => {
                        const _product = new ProductModel(p);
                        _product.calculateDiscount(catalogDiscount, p.discount);
                        _product.catalogId = catalogId;
                        return _product;
                    }),
            ),
        );
    }

    updateProduct(supplierId: string, catalogId: string, product: ProductModel): Observable<any> {
        const pathProduct = `${FS_PATH_SUPPLIERS}/${supplierId}/${FS_PATH_CATALOGS}/${catalogId}/${FS_PATH_PRODUCTS}/${product.id}`;
        return this.firestoreService.updateAt(pathProduct, product, FS_TENANT_CUSTOMERS);
    }

    /*********************/
    /*      QUOTE        */
    /*********************/
    inquiry(supplier: SupplierModel, customer: any): Observable<any> {
        const data = {
            customer: customer,
            supplierId: supplier.id,
            supplier: {
                name: supplier.commercialContact?.firstname || null,
                email: supplier.commercialContact?.email || null,
            },
        };
        return this.firestoreService.updateAt(FS_PATH_INQUIERIES, data);
    }

    /*********************/
    /*      STORAGE      */
    /*********************/
    deleteLogo(url: string): Promise<any> {
        return this.storageService.deleteFile(url);
    }

    saveLogo(file: File): Observable<string> {
        return this.storageService.uploadFile(file, 'logo');
    }

    saveSaleTermsFile(file: File): Observable<string> {
        return this.storageService.uploadFile(file, 'saleTerms');
    }

    /*************************/
    /*   SERVICE FUNCTIONS   */
    /*************************/
    getContact(supplier: SupplierModel): Observable<SupplierModel> {
        const pathContacts = `${FS_PATH_SUPPLIERS}/${supplier.id}/${FS_PATH_CONTACTS}`;
        // It's works for just one contact for the moment
        return this.firestoreService.collection$(pathContacts, FS_TENANT_CUSTOMERS).pipe(
            map((contacts) => {
                const _supplier = new SupplierModel({ ...supplier });
                _supplier.contact = new ContactModel({
                    id: contacts[0]?.id,
                    displayName: contacts[0]?.displayName,
                    email: contacts[0]?.email,
                    phone: contacts[0]?.phone,
                });
                return _supplier;
            }),
        );
    }

    getTenantPath(): void {
        this.store
            .select(isCustomer)
            .pipe(
                filter((res) => res !== undefined && res !== null),
                first(),
            )
            .subscribe((isCustomer) => {
                this.pathSuppliers = isCustomer ? FS_PATH_SUPPLIERS : FS_PATH_SUPPLIERS_TENANT;
                this.tenantPath = isCustomer ? FS_TENANT_CUSTOMERS : null;
            });
    }
}
