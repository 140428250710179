// Angular
import { Component, Input } from '@angular/core';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { OrderStatus } from '@store/index';

@Component({
    selector: 'order-status',
    templateUrl: './order-status.component.html',
})
export class OrderStatusComponent {
    @Input() status: number;

    constructor(private translateService: TranslateService) {}

    /***********/
    /**   UI   */
    /***********/
    getStatusCSS() {
        switch (this.status) {
            case OrderStatus.New:
                return 'label-outline-primary';

            case OrderStatus.View:
                return 'label-outline-success';

            case OrderStatus.Validate:
                return 'label-light-primary';

            case OrderStatus.DeliveryInProgress:
            case OrderStatus.Available:
                return 'label-light-success';

            case OrderStatus.Received:
                return 'label-success';

            case OrderStatus.Canceled:
                return 'label-light-danger';

            case OrderStatus.Consultation:
                return 'label-secondary';

            default:
                return null;
        }
    }

    getStatusLabel(status?: number) {
        return this.translateService.instant('ORDERS.STATUT_' + (status || this.status));
    }
}
