// Angular
import { Injectable } from '@angular/core';
// RXJS
import { first, map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
// Store
import { CategoryModel } from '../categories/category.model';
import { FS_PATH_CATEGORIES, FS_PATH_SUPPLIERS_TENANT, FS_PATH_UNIVERSES } from '../firestore-collections';
// Core
import { FirestoreService, StorageService } from '@core/services';
// Lodash
import { orderBy } from 'lodash';

export interface Universe {
    active: boolean;
    categoriesId?: string[];
    id: string;
    label: string;
    order: number;
    photo: File | string;
}

@Injectable({
    providedIn: 'root',
})
export class UniversesService {
    private _universes: Universe[];

    //prettier-ignore
    constructor(
        private firestoreService: FirestoreService,
        private storageService: StorageService,
    ) {
        this.loadUniverses();
    }

    getUniverseLabel(universeId): string {
        return this._universes.find((el) => el.id === universeId).label;
    }

    getUniversesActive(): Universe[] {
        return this._universes.filter((el) => el.active);
    }

    getUniversesWithoutAll(): Universe[] {
        return this._universes.filter((el) => el.id !== 'all');
    }

    isUniversesActive() {
        return this.getUniversesActive().length > 0;
    }

    loadCategoriesParentBySupplierId(supplierId: string): Observable<CategoryModel[]> {
        const path = `${FS_PATH_SUPPLIERS_TENANT}/${supplierId}/${FS_PATH_CATEGORIES}`;
        return this.firestoreService.collection$(path).pipe(
            map((categories) => categories.filter((c) => c.parent === undefined || c.parent === null)),
            map((categories) => orderBy(categories, 'name', 'asc')),
        );
    }

    loadUniverses() {
        return this.firestoreService
            .collection$(FS_PATH_UNIVERSES)
            .pipe(first())
            .subscribe((res) => (this._universes = orderBy(res, [(el) => el.order, (el) => el.label], ['asc', 'asc'])));
    }

    saveCategories(supplierId: string, universes: Universe[]) {
        const path = `${FS_PATH_SUPPLIERS_TENANT}/${supplierId}/${FS_PATH_CATEGORIES}`;
        return combineLatest(
            universes.map((u) => {
                return combineLatest(
                    u.categoriesId.map((c) => {
                        return this.firestoreService.updateAt(`${path}/${c}`, { universe: u.id });
                    }),
                );
            }),
        );
    }

    saveCategoriesWithoutUniverse(supplierId: string, categoriesId: string[]) {
        const path = `${FS_PATH_SUPPLIERS_TENANT}/${supplierId}/${FS_PATH_CATEGORIES}`;
        return combineLatest(
            categoriesId.map((id) => {
                return this.firestoreService.updateAt(`${path}/${id}`, { universe: null });
            }),
        );
    }

    savePhoto(file: File, filename: string): Observable<string> {
        return this.storageService.uploadFile(file, filename, 'universes');
    }

    saveUniverse(universe: Universe): Observable<void> {
        const path = `${FS_PATH_UNIVERSES}/${universe.id}`;

        let photo$ = of(universe.photo);
        if (universe.photo instanceof File) {
            photo$ = this.savePhoto(universe.photo, universe.id);
        }

        return photo$.pipe(
            switchMap((photo) => {
                if (photo !== undefined) universe.photo = photo;
                return this.firestoreService.updateAt(path, universe);
            }),
        );
    }
}
