// Angular
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Core
import { LayoutUtilsService } from '@core/_base/crud';
import { UnsubscribeOnDestroy } from '@core/services';
// Store
import { CompanyEntityService, CompanyModel } from '@store/index';
// Translate
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'terms-sale-form',
    templateUrl: './terms-sales-form.component.html',
})
export class TermsSaleFormComponent extends UnsubscribeOnDestroy {
    @Input() form: FormGroup;

    company: CompanyModel = new CompanyModel();

    // prettier-ignore
    constructor(
        private cdr: ChangeDetectorRef,
        private companyService: CompanyEntityService,
        private layoutUtilsService: LayoutUtilsService,
        private translateService: TranslateService)
    {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/

    /***************/
    /*   ACTIONS   */
    /***************/

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/

    /*************/
    /*    UI     */
    /*************/
}
