// Angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatDialog } from '@angular/material/dialog';
// NGRX
import { Store } from '@ngrx/store';
// RXJS
import { Observable } from 'rxjs';
import { first, filter, map } from 'rxjs/operators';
// Core
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
import { MixpanelService, ToolsService, UnsubscribeOnDestroy } from '@core/services';
import { AppState } from '@core/reducers';
import { isAuthSuccess, isDemo, isStoick } from '@core/auth';
// Store
import { CartService, FavoritesEntityService, ProductModel, ProductsEntityService, QuotesEntityService, SupplierModel, SuppliersEntityService, UniversesService } from '@store/index';
// Shared
import { ProductDetailComponent } from '@shared/components/business/product-detail/product-detail.component';
import { SelectQuantityComponent } from '@shared/components/business/select-quantity/select-quantity.component';
import { SupplierPresentationComponent } from '@shared/components/business/supplier-presentation/supplier-presentation.component';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Lodash
import { isNil, omitBy } from 'lodash';
//Typesense
import { TypesenseClient } from '@shared/components/typesense/typesense-client';
// Theme
import { TitlePageService } from '@theme/layout/header/title-page/title-page.service';
import { HeaderActionButtonsService } from '@theme/index';
import { EntityAction, EntityOp } from '@ngrx/data';

@Component({
    selector: 'store-mp',
    templateUrl: './store-mp.component.html',
    styleUrls: ['./store-mp.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreMPComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    // Typesense
    typesenseClient: TypesenseClient;

    // Services
    private cartService: CartService;
    private favoritesService: FavoritesEntityService;
    private quotesService: QuotesEntityService;

    // Other
    canAddToInternalCatalog = false;
    catalogId: string;
    displayAllProducts = true;
    displayOnlyFavorites = false;
    displayUniverses = false;
    favorites = [];
    isDemoUser = false;
    isUserLogged = false;
    loading$: Observable<boolean>;
    searchValue: string;
    selectedProducts: ProductModel[] = [];
    supplier: SupplierModel;

    //prettier-ignore
    constructor(
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog,
        private inject: Injector,
        private headerActionButtonsService: HeaderActionButtonsService,
        private layoutUtilsService: LayoutUtilsService,
        private mixpanelService: MixpanelService,
        private productsService: ProductsEntityService,
        private router: Router,
        private suppliersService: SuppliersEntityService,
        private store: Store<AppState>,
        private titlePageService: TitlePageService,
        private toolsService: ToolsService,
        private translateService: TranslateService,
        private universesService: UniversesService,
    ) {
        super();

        this.checkIsUserLogged();
        this.checkIsDemoUser();
        this.checkIsStoickUser();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.loadServices();

        this.getProducts();

        this.listenRouteParamsChange();
    }

    ngOnDestroy(): void {
        this.resetTitlePage();
        this.subs.unsubscribe();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    addToInternalCatalog(product: ProductModel) {
        this.selectedProducts = this.toolsService.toggleObjectInArray(this.selectedProducts, product, 'id');
        this.headerActionButtonsService.setTitleButton(`Enregistrer : ${this.selectedProducts.length} sélectionné(s)`);
        this.cdr.markForCheck();
    }

    filterFavorites() {
        this.displayOnlyFavorites = !this.displayOnlyFavorites;
        this.displayOnlyFavorites ? this.setFavoritesFilter() : this.resetFavoritesFilter();
        this.mixpanelService.track('Typesense - Filter on favorites');
    }

    onSearch(event: string): void {
        this.router.navigate(['.'], { queryParams: { search: event }, relativeTo: this.activatedRoute });
        this.displayUniverses = false;
    }

    onSelectUniverse(universeId: string): void {
        this.router.navigate(['.'], { queryParams: { universe: universeId }, relativeTo: this.activatedRoute });
        this.displayUniverses = false;
    }

    selectQuantity(product: ProductModel): void {
        const dialogRef = this.dialog.open(SelectQuantityComponent, {
            data: { product: product },
            width: '500px',
        });

        this.subs.sink = dialogRef
            .afterClosed()
            .pipe(first())
            .subscribe((res) => {
                if (!res) return;
                product.quotation ? this.addItemToQuoteRequest(product, res.quantity, res.size) : this.addItemToCart(product, res.quantity, res.size);
            });
    }

    viewProductDetail(product: ProductModel): void {
        const dialogRef = this.dialog.open(ProductDetailComponent, {
            data: {
                product: product,
                canBuy: this.canBuy(),
            },
            width: '90%',
            maxWidth: '1100px',
            height: '80%',
        });

        this.subs.sink = dialogRef
            .afterClosed()
            .pipe(first())
            .subscribe((res) => {
                if (!res) return;
                product.quotation ? this.addItemToQuoteRequest(product, res.quantity, res.size) : this.addItemToCart(product, res.quantity, res.size);
            });
    }

    viewSupplierInfo(product: ProductModel) {
        this.dialog.open(SupplierPresentationComponent, {
            data: { supplierId: product.supplierCompanyId },
            width: '600px',
            height: '80%',
        });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    addItemToCart(product: ProductModel, quantity: number, size: string): void {
        const _product = this.prepareData(product, size);
        this.subs.sink = this.cartService
            .addToCart(_product, quantity)
            .pipe(first())
            .subscribe(() => {
                const _message = this.translateService.instant('CART.ITEM_ADDED_TO_CART');
                this.layoutUtilsService.showActionNotification(_message, null, 2000, true, false);
            });
    }

    addItemToQuoteRequest(product: ProductModel, quantity: number, size: string): void {
        const _product = this.prepareData(product, size);
        this.subs.sink = this.quotesService
            .addToQuote(_product, quantity)
            .pipe(first())
            .subscribe(() => {
                const _message = this.translateService.instant('QUOTES.ADDED_TO_QUOTES_REQUEST');
                this.layoutUtilsService.showActionNotification(_message, null, 2000, true, false);
            });
    }

    checkIsDemoUser(): void {
        this.subs.sink = this.store.select(isDemo).subscribe((isDemo) => (this.isDemoUser = isDemo));
    }

    checkIsStoickUser() {
        this.subs.sink = this.store
            .select(isStoick)
            .pipe(filter((isStoick) => isStoick === true))
            .subscribe(() => {
                this.listenHeaderActionButtonsClick();
            });
    }

    checkIsUniversesActive(): void {
        this.displayUniverses = this.universesService.isUniversesActive();
    }

    checkIsUserLogged(): void {
        this.subs.sink = this.store.select(isAuthSuccess).subscribe((isAuthSuccess) => (this.isUserLogged = isAuthSuccess));
    }

    getAndInitFavoritesFilter(supplierId?: string): void {
        const favorites$ = supplierId ? this.favoritesService.selectFavoritesBySupplierId(supplierId) : this.favoritesService.selectFavorites();
        this.subs.sink = favorites$.subscribe((favorites) => {
            this.favorites = [...favorites];
            if (favorites.length > 0 && this.displayOnlyFavorites) {
                this.setFavoritesFilter();
            } else {
                this.displayOnlyFavorites = false;
                this.resetFavoritesFilter();
            }
        });
    }

    getProducts(): void {
        this.initTypesense();

        if (this.isUserLogged) {
            this.getAndInitFavoritesFilter(this.supplier?.id || null);
            this.removeSuppliersPremium();
        }
    }

    initTypesense(): void {
        this.typesenseClient = new TypesenseClient('products');

        this.typesenseClient.addFilterBy('catalogId', '=', ['mp']);

        const sort = [
            { label: this.translateService.instant('TYPESENSE.SORT_RELEVANCE'), value: 'products' },
            { label: this.translateService.instant('TYPESENSE.SORT_PRICE_ASC'), value: 'products/sort/price:asc' },
            { label: this.translateService.instant('TYPESENSE.SORT_PRICE_DESC'), value: 'products/sort/price:desc' },
        ];

        this.typesenseClient.setSort(sort);
    }

    listenHeaderActionButtonsClick() {
        this.subs.sink = this.headerActionButtonsService.onClick.subscribe(() => {
            if (this.selectedProducts?.length == 0) {
                this.selectedProducts = [];
                this.canAddToInternalCatalog = true;
                this.headerActionButtonsService.setTitleButton(`Enregistrer : ${this.selectedProducts.length} sélectionné(s)`);
                this.headerActionButtonsService.setShowCancelButton(true);
            } else {
                this.saveToInternalCatalog();
            }
            this.cdr.markForCheck();
        });

        this.subs.sink = this.headerActionButtonsService.onCancel.subscribe(() => this.resetActionButtons());
    }

    listenRouteParamsChange(): void {
        this.subs.sink = this.activatedRoute.queryParams.subscribe((queryParams) => {
            const universeId = queryParams.universe;
            const search = queryParams.search;

            if (universeId) {
                if (universeId != 'all') {
                    this.typesenseClient.addFilterBy('universe', '=', universeId);
                }
                this.displayUniverses = false;
                setTimeout(() => {
                    this.titlePageService.setTitlePage(this.universesService.getUniverseLabel(universeId));
                    if (this.isUserLogged) this.titlePageService.showBackButton(true);
                }, 0);
            }

            if (search) {
                this.searchValue = search;
                this.displayUniverses = false;
                setTimeout(() => {
                    this.titlePageService.setTitlePage(this.universesService.getUniverseLabel('all'));
                    if (this.isUserLogged) this.titlePageService.showBackButton(true);
                }, 0);
            }

            if (!search && !universeId) {
                this.typesenseClient.removeFilterBy('universe');
                this.checkIsUniversesActive();
                if (!this.isUserLogged) this.titlePageService.setTitlePage(this.translateService.instant('BUSINESS.ITEMS'));
                this.titlePageService.showBackButton(this.isUserLogged);
            }

            this.cdr.markForCheck();
        });
    }

    loadServices() {
        if (this.isUserLogged) {
            this.cartService = this.inject.get(CartService);
            this.favoritesService = this.inject.get(FavoritesEntityService);
            this.quotesService = this.inject.get(QuotesEntityService);
        }
    }

    prepareData(product: ProductModel, size: string): ProductModel {
        const _product = new ProductModel();
        _product.ecotax = product.ecotax;
        _product.id = !size ? product.productId : `${product.productId}-${size}`;
        _product.name = product.name;
        _product.maker = product.maker;
        _product.minimumPurchase = product.minimumPurchase;
        _product.photo = product.photo;
        _product.price = product.price;
        _product.priceDiscount = product.priceDiscount;
        _product.provider = product.provider;
        _product.referenceMaker = product.referenceMaker;
        _product.referenceProvider = product.referenceProvider;
        _product.referenceSupplier = product.referenceSupplier;
        _product.size = size;
        _product.taxes = product.taxes;
        _product.supplierCompanyId = product.supplierCompanyId;

        if (product.packaging > 1) {
            _product.name = `${this.translateService.instant('BUSINESS.PACKAGING')} ${product.packaging} - ${_product.name}`;
        }

        return <ProductModel>omitBy(_product, isNil);
    }

    removeSuppliersPremium(): void {
        this.subs.sink = this.suppliersService
            .selectSuppliersPremium()
            .pipe(
                filter((suppliers) => suppliers?.length > 0),
                map((suppliers) => suppliers.map((s) => s.id)),
            )
            .subscribe((suppliers) => {
                this.typesenseClient.addFilterBy('supplierCompanyId', '!=', suppliers);
            });
    }

    resetActionButtons() {
        this.canAddToInternalCatalog = false;
        this.selectedProducts = [];
        this.headerActionButtonsService.setLoading(false);
        this.headerActionButtonsService.setTitleButton('INTERNAL_CATALOG.ADD');
        this.headerActionButtonsService.setShowCancelButton(false);
        this.cdr.markForCheck();
    }

    resetFavoritesFilter() {
        this.typesenseClient.removeFilterBy('productId');
    }

    resetTitlePage() {
        this.titlePageService.showBackButton(false);
        this.titlePageService.setImage(null);
        this.titlePageService.setTitlePage(null);
    }

    saveToInternalCatalog() {
        this.headerActionButtonsService.setLoading(true);

        this.productsService.saveProductInInternalCatalog(this.selectedProducts);

        this.subs.sink = this.productsService.entityActions$
            .pipe(
                filter((ea: EntityAction) => ea.payload.entityOp === EntityOp.SAVE_UPDATE_MANY_SUCCESS),
                first(),
            )
            .subscribe(() => {
                const message = this.translateService.instant('INTERNAL_CATALOG.SAVE_SUCCESS');
                this.layoutUtilsService.showActionNotification(message, MessageType.Success, 3000, true, false);
                this.resetActionButtons();
            });

        // this.productsService
        //     .saveProductInInternalCatalog(this.selectedProducts)
        //     .pipe(first())
        //     .subscribe(() => {
        //         const message = this.translateService.instant('INTERNAL_CATALOG.SAVE_SUCCESS');
        //         this.layoutUtilsService.showActionNotification(message, MessageType.Success, 3000, true, false);
        //         this.resetActionButtons();
        //     });
    }

    setFavoritesFilter() {
        this.typesenseClient.addFilterBy('productId', '=', this.favorites);
    }

    /*******************/
    /*       UI        */
    /*******************/
    canBuy(): boolean {
        return !this.isDemoUser && this.isUserLogged;
    }

    getSelectedProductsId(): string[] {
        return this.selectedProducts?.map((p) => p.id);
    }
}
