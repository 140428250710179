// Angular
import { NgModule } from '@angular/core';
// NGRX
import { EntityDataService, EntityDefinitionService } from '@ngrx/data';
// Store
import { entityMetadata } from '@store/entity-metadata';
// Store services
import { CatalogsDataService } from './catalogs/catalogs-data.service';
import { CatalogsEntityService } from './catalogs/catalogs-entity.service';
import { CategoriesDataService } from './categories/categories-data.service';
import { CategoriesEntityService } from './categories/categories-entity.service';
import { ChatRoomsDataService } from './chat-rooms/chat-rooms-data.service';
import { ChatRoomsEntityService } from './chat-rooms/chat-rooms-entity.service';
import { CompanyDataService } from './company/company-data.service';
import { CompanyEntityService } from './company/company-entity.service';
import { CustomersDataService } from './customers/customers-data.service';
import { CustomersEntityService } from './customers/customers-entity.service';
import { ErrorStoreService } from './error-store.service';
import { FavoritesDataService } from './favorites/favorites-data.service';
import { FavoritesEntityService } from './favorites/favorites-entity.service';
import { InvitationsDataService } from './invitations/invitations-data.service';
import { InvitationsEntityService } from './invitations/invitations-entity.service';
import { NotificationsDataService } from './notifications/notifications-data.service';
import { NotificationsEntityService } from './notifications/notifications-entity.service';
import { OrdersDataService } from './orders/orders-data.service';
import { OrdersEntityService } from './orders/orders-entity.service';
import { PermissionsDataService } from './permissions/permissions-data.service';
import { PermissionsEntityService } from './permissions/permissions-entity.service';
import { ProductsDataService } from './products/products-data.service';
import { ProductsEntityService } from './products/products-entity.service';
import { RequestsDataService } from './requests/requests-data.service';
import { RequestsEntityService } from './requests/requests-entity.service';
import { RolesEntityService } from './roles/roles-entity.service';
import { RolesDataService } from './roles/roles-data.service';
import { SuppliersDataService } from './suppliers/suppliers-data.service';
import { SuppliersEntityService } from './suppliers/suppliers-entity.service';
import { UsersDataService } from './users/users-data.service';
import { UsersEntityService } from './users/users-entity.service';
import { QuotesDataService } from './quotes/quotes-data.service';
import { QuotesEntityService } from './quotes/quotes-entity.service';

//prettier-ignore
@NgModule({
    providers: [
        ErrorStoreService,

        // Collections
        CatalogsDataService,
        CatalogsEntityService,
        CategoriesDataService,
        CategoriesEntityService,
        ChatRoomsDataService,
        ChatRoomsEntityService,
        CompanyDataService,
        CompanyEntityService,
        CustomersDataService,
        CustomersEntityService,
        FavoritesEntityService,
        InvitationsDataService,
        InvitationsEntityService,
        NotificationsDataService,
        NotificationsEntityService,
        OrdersDataService,
        OrdersEntityService,
        PermissionsDataService,
        PermissionsEntityService,
        ProductsDataService,
        ProductsEntityService,
        QuotesDataService,
        QuotesEntityService,
        RequestsDataService,
        RequestsEntityService,
        RolesDataService,
        RolesEntityService,
        SuppliersDataService,
        SuppliersEntityService,
        UsersDataService,
        UsersEntityService,
    ],
})
export class AppStoreModule {
    constructor(
        entityDefinitionService: EntityDefinitionService,
        entityDataService: EntityDataService,
        errorsStoreService: ErrorStoreService,
        // Entity Service
        catalogsService: CatalogsDataService,
        categoriesService: CategoriesDataService,
        chatService: ChatRoomsDataService,
        companyService: CompanyDataService,
        customersService: CustomersDataService,
        favoritesService: FavoritesDataService,
        invitationsService: InvitationsDataService,
        notificationsService: NotificationsDataService,
        ordersService: OrdersDataService,
        permissionsService: PermissionsDataService,
        productsService: ProductsDataService,
        quotesService: QuotesDataService,
        requestsService: RequestsDataService,
        rolesService: RolesDataService,
        suppliersService: SuppliersDataService,
        usersService: UsersDataService,
    ) {
        entityDefinitionService.registerMetadataMap(entityMetadata);
        entityDataService.registerService('Catalogs', catalogsService);
        entityDataService.registerService('Categories', categoriesService);
        entityDataService.registerService('ChatRooms', chatService);
        entityDataService.registerService('Company', companyService);
        entityDataService.registerService('Customers', customersService);
        entityDataService.registerService('Favorites', favoritesService);
        entityDataService.registerService('Invitations', invitationsService);
        entityDataService.registerService('Notifications', notificationsService);
        entityDataService.registerService('Orders', ordersService);
        entityDataService.registerService('Permissions', permissionsService);
        entityDataService.registerService('Products', productsService);
        entityDataService.registerService('Quotes', quotesService);
        entityDataService.registerService('Requests', requestsService);
        entityDataService.registerService('Roles', rolesService);
        entityDataService.registerService('Suppliers', suppliersService);
        entityDataService.registerService('Users', usersService);
    }
}
