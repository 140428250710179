// Angular
import { NgModule } from '@angular/core';
// Core module
import { CoreModule } from '@core/core.module';
// Theme
import { ThemeModule } from '@theme/theme.module';
// Typesense module
import { TypesenseModule } from './components/typesense/typesense.module';
// Business
import { AddStockInformationsComponent } from './components/business/add-stock-informations/add-stock-informations.component';
import { BillingInformationsComponent } from './components/business/billing-informations/billing-informations.component';
import { CatalogAddProductComponent } from './components/business/catalog-add-product/catalog-add-product.component';
import { CatalogEditComponent } from './components/business/catalog-edit/catalog-edit.component';
import { CatalogImportDialogComponent } from './components/business/catalog-import/catalog-import-dialog.component';
import { CatalogSearchProductComponent } from './components/business/catalog-search-product/catalog-search-product.component';
import { CategoriesComponent } from './components/business/categories/categories.component';
import { CategoriesComponentService } from './components/business/categories/categories.service';
import { CategoryAddComponent } from './components/business/category-add/category-add.component';
import { EditAllSuppliersComponent } from './components/business/edit-all-suppliers/edit-all-suppliers.component';
import { EditCustomSupplierComponent } from './components/business/edit-custom-supplier/edit-custom-supplier.component';
import { ManagementModeComponent } from './components/business/management-mode/management-mode.component';
import { OrderAddTrackingComponent } from './components/business/order-add-tracking/order-add-tracking.component';
import { OrderStatusComponent } from './components/business/order-status/order-status.component';
import { OrderStatusFilterComponent } from './components/business/order-status-filter/order-status-filter.component';
import { OrderValidateComponent } from './components/business/order-validate/order-validate.component';
import { ProductDetailComponent } from './components/business/product-detail/product-detail.component';
import { QuoteStatusComponent } from './components/business/quote-status/quote-status.component';
import { RequestStatusComponent } from './components/business/request-status/request-status.component';
import { RequestValidateComponent } from './components/business/request-validate/request-validate.component';
import { SizeManagementComponent } from './components/business/size-management/size-management.component';
import { SuppliersFilterComponent } from './components/business/suppliers-filter/suppliers-filter.component';
import { StockStatusFilterComponent } from './components/business/stock-status-filter/stock-status-filter.component';
import { SelectSuppliersComponent } from './components/business/select-suppliers/select-suppliers.component';
import { SuppliersFilterComponentService } from './components/business/suppliers-filter/suppliers-filter.service';
import { StoreMPComponent } from './components/business/store-mp/store-mp.component';
import { StoreSupplierComponent } from './components/business/store-supplier/store-supplier.component';
import { StoreUniversesComponent } from './components/business/store-universes/store-universes.component';
import { SupplierPresentationComponent } from './components/business/supplier-presentation/supplier-presentation.component';
import { SuppliersSearchComponent } from './components/business/suppliers-search/suppliers-search.component';
import { WelcomeCustomerComponent } from './components/business/welcome-customer/welcome-customer.component';
// General
import { ChipsMultiSelectComponent } from './components/general/chips-multi-select/chips-multi-select.component';
import { ConfirmDialogComponent } from './components/general/confirm-dialog/confirm-dialog.component';
import { ContactFormComponent } from './components/general/contact-form/contact-form.component';
import { EmptyContentComponent } from './components/general/empty-content/empty-content.component';
import { EmailInputComponent } from './components/general/email-input/email-input.component';
import { customNotifierOptions } from './components/general/notifications-alert/notifier-options';
import { PdfDropzoneComponent } from './components/general/pdf-dropzone/pdf-dropzone.component';
import { RangeSelectorComponent } from './components/general/range-selector/range-selector.component';
import { SearchInputComponent } from './components/general/search-input/search-input.component';
import { UserFeedbackComponent } from './components/general/user-feedback/user-feedback.component';
import { WaitDialogComponent } from './components/general/wait-dialog/wait-dialog.component';

// prettier-ignore
import {
    AcceptInvitationComponent,
    ActionNotificationComponent,
    CarouselImageComponent,
    DeleteDialogComponent,
    ErrorComponent,
    FilterInactiveComponent,
    NotificationsAlertComponent,
    ProductsEditListComponent,
    OpeningTimeComponent,
    SearchInputComponentService,
    SelectQuantityComponent,
    TablePaginatorComponent,
    QuoteValidateComponent,
} from './components';
// Notifier
import { NotifierModule } from 'angular-notifier';
// Resolver
import { SupplierResolver } from './resolver/shared.resolver';

//prettier-ignore
@NgModule({
    declarations: [
        // Business
        AcceptInvitationComponent,
        AddStockInformationsComponent,
        BillingInformationsComponent,
        CatalogAddProductComponent,
        CatalogEditComponent,
        CatalogImportDialogComponent,
        CatalogSearchProductComponent,
        CategoriesComponent,
        CategoryAddComponent,
        EditAllSuppliersComponent,
        EditCustomSupplierComponent,
        ManagementModeComponent,
        OrderAddTrackingComponent,
        OrderStatusComponent,
        OrderStatusFilterComponent,
        OrderValidateComponent,
        ProductDetailComponent,
        ProductsEditListComponent,
        QuoteStatusComponent,
        QuoteValidateComponent,
        RequestStatusComponent,
        RequestValidateComponent,
        SelectQuantityComponent,
        SelectSuppliersComponent,
        SizeManagementComponent,
        StockStatusFilterComponent,
        StoreMPComponent,
        StoreSupplierComponent,
        StoreUniversesComponent,
        SupplierPresentationComponent,
        SuppliersFilterComponent,
        SuppliersSearchComponent,
        WelcomeCustomerComponent,

        // General
        ActionNotificationComponent,
        CarouselImageComponent,
        ChipsMultiSelectComponent,
        ConfirmDialogComponent,
        ContactFormComponent,
        DeleteDialogComponent,
        EmailInputComponent,
        EmptyContentComponent,
        ErrorComponent,
        FilterInactiveComponent,
        NotificationsAlertComponent,
        OpeningTimeComponent,
        PdfDropzoneComponent,
        RangeSelectorComponent,
        SearchInputComponent,
        TablePaginatorComponent,
        UserFeedbackComponent,
        WaitDialogComponent,
    ],
    exports: [
        // Business
        AcceptInvitationComponent,
        AddStockInformationsComponent,
        BillingInformationsComponent,
        CatalogAddProductComponent,
        CatalogEditComponent,
        CatalogImportDialogComponent,
        CatalogSearchProductComponent,
        CategoriesComponent,
        CategoryAddComponent,
        EditAllSuppliersComponent,
        EditCustomSupplierComponent,
        ManagementModeComponent,
        OrderAddTrackingComponent,
        OrderStatusComponent,
        OrderStatusFilterComponent,
        OrderValidateComponent,
        ProductDetailComponent,
        ProductsEditListComponent,
        QuoteStatusComponent,
        QuoteValidateComponent,
        RequestStatusComponent,
        SelectQuantityComponent,
        SelectSuppliersComponent,
        SizeManagementComponent,
        StockStatusFilterComponent,
        StoreMPComponent,
        StoreSupplierComponent,
        StoreUniversesComponent,
        SupplierPresentationComponent,
        SuppliersFilterComponent,
        SuppliersSearchComponent,
        WelcomeCustomerComponent,

        // General
        ActionNotificationComponent,
        CarouselImageComponent,
        ChipsMultiSelectComponent,
        ConfirmDialogComponent,
        ContactFormComponent,
        DeleteDialogComponent,
        EmailInputComponent,
        EmptyContentComponent,
        ErrorComponent,
        FilterInactiveComponent,
        NotificationsAlertComponent,
        PdfDropzoneComponent,
        OpeningTimeComponent,
        RangeSelectorComponent,
        SearchInputComponent,
        TablePaginatorComponent,
        UserFeedbackComponent,
        WaitDialogComponent,
    ],
    imports: [
        CoreModule,
        NotifierModule.withConfig(customNotifierOptions),
        ThemeModule,
        TypesenseModule
    ],
    providers: [
        CategoriesComponentService,
        SearchInputComponentService,
        SuppliersFilterComponentService,
        SupplierResolver
    ],
})
export class SharedModule {}
