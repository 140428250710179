// Angular
import { Component, ViewEncapsulation, ChangeDetectorRef, Input, OnInit, Inject, Optional, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// NGRX
import { Store } from '@ngrx/store';
// RXJS
import { throwError } from 'rxjs';
import { catchError, first, delay } from 'rxjs/operators';
// Core
import { MixpanelService, UnsubscribeOnDestroy } from '@core/services';
import { AppState } from '@core/reducers';
import { isDemo, isAuthSuccess } from '@core/auth';
// Store
import { SuppliersEntityService, SupplierModel } from '@store/index';
// Shared
import { EmailInputComponent } from '@shared/components/general/email-input/email-input.component';

@Component({
    selector: 'supplier-presentation',
    templateUrl: './supplier-presentation.component.html',
    styleUrls: ['./supplier-presentation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SupplierPresentationComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @Input() supplier: SupplierModel;

    @Output() handleClose = new EventEmitter();

    actionSuccess = false;
    isDemoUser = false;
    isModal = false;
    isUserLogged = false;
    loading = false;

    //prettier-ignore
    constructor(
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        @Optional() private dialogRef: MatDialogRef<SupplierPresentationComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private mixpanelService: MixpanelService,
        private router: Router,
        private store: Store<AppState>,
        private suppliersService: SuppliersEntityService,
    ) {
        super();

        if (this.data?.supplierId) {
            this.loadSupplier();
            this.isModal = true;
        }
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.checkIsUserLogged();

        this.checkIsDemoUser();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    async inquiry() {
        // If no auth user => ask for email
        let email = null;
        if (!this.isUserLogged) {
            email = await this.getUserEmail();
            if (!email) return;
        }

        this.loading = true;
        this.cdr.markForCheck();

        this.subs.sink = this.suppliersService
            .inquiry(this.supplier, email)
            .pipe(
                catchError((error) => throwError(error)),
                first(),
                delay(1000),
            )
            .subscribe(() => {
                this.actionSuccess = true;
                this.loading = false;
                this.cdr.markForCheck();
                this.mixpanelService.track('Suppliers - Inquiry', { value: this.supplier.name });
            });
    }

    close() {
        this.dialogRef?.close();
        this.handleClose.emit();
    }

    viewCatalog() {
        this.router.navigate(['../', this.supplier.getUrl()], { relativeTo: this.activatedRoute, state: { supplier: this.supplier } });
        localStorage.setItem('supplierSelected', this.supplier.id);
        this.mixpanelService.track('Suppliers - View catalog', { value: this.supplier.name });
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    checkIsDemoUser(): void {
        this.subs.sink = this.store.select(isDemo).subscribe((isDemo) => (this.isDemoUser = isDemo));
    }

    checkIsUserLogged(): void {
        this.subs.sink = this.store.select(isAuthSuccess).subscribe((isAuthSuccess) => (this.isUserLogged = isAuthSuccess));
    }

    getUserEmail(): Promise<string> {
        const dialogRef = this.dialog.open(EmailInputComponent, { width: 'w-auto' });
        return dialogRef.afterClosed().pipe(first()).toPromise();
    }

    loadSupplier(): void {
        this.subs.sink = this.suppliersService.loadSupplierById(this.data.supplierId).subscribe((supplier) => {
            this.supplier = new SupplierModel(supplier);
        });
    }

    /*******************/
    /*       UI        */
    /*******************/
}
