import { Observable } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RXJS
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TypesenseSortService {
    private sort$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor() {
        this.reset();
    }

    getSortValue$(): Observable<string> {
        return this.sort$.asObservable();
    }

    setsortValue$(sortValue: string): void {
        this.sort$.next(sortValue);
    }

    reset(): void {
        this.sort$.next('');
    }
}
