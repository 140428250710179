// Angular
import { Component, Inject, OnInit, ViewChild, ComponentFactoryResolver, Output, EventEmitter } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// Core
import { AdDirective } from '@core/_base/layout';

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements OnInit {
    @ViewChild(AdDirective, { static: true }) adHost: AdDirective;
    @Output() cancel = new EventEmitter();
    @Output() confirm = new EventEmitter();

    labelNoButton: string;
    labelYesButton: string;
    loading = false;
    title: string;
    waitDescription: string;

    // prettier-ignore
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
        this.labelNoButton = this.data.labelNoButton ||  "GENERAL.NO";
        this.labelYesButton = this.data.labelYesButton ||  "GENERAL.YES";
        this.title = this.data.title || '';
        this.waitDescription = this.data.waitDescription ||  "GENERAL.WAIT_IN_PROGRESS";
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        if (this.data.component) {
            this.loadComponent();
        }
    }

    /*************/
    /*  ACTIONS  */
    /*************/
    onNoClick(): void {
        this.dialogRef.close();
        this.cancel.emit();
    }

    onYesClick(): void {
        this.loading = true;
        this.confirm.emit();
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    loadComponent(): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.component);
        const viewContainerRef = this.adHost.viewContainerRef;
        viewContainerRef.clear();
        viewContainerRef.createComponent(componentFactory);
    }
}
