// Angular
import { Component, ViewEncapsulation, Input } from '@angular/core';
// Core
import { FirestoreService } from '@core/services';
// Store
import { FS_PATH_FEEDBACK } from '@store/firestore-collections';

@Component({
    selector: 'user-feedback',
    templateUrl: './user-feedback.component.html',
    styleUrls: ['./user-feedback.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UserFeedbackComponent {
    @Input() title: string;

    comment: string = null;
    note: number = null;

    constructor(private firestoreService: FirestoreService) {}

    /*************/
    /*  ACTIONS  */
    /*************/
    setNote(note: number) {
        this.note = note;
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    save() {
        this.firestoreService.updateAt(FS_PATH_FEEDBACK, { note: this.note, comment: this.comment });
    }

    /*************/
    /*    UI     */
    /*************/
}
