// Angular
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// Core
import { LayoutConfigService, LayoutRefService } from '@core/_base/layout';
// HTML Class Service
import { HtmlClassService } from '../html-class.service';

@Component({
    selector: 'kt-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, AfterViewInit {
    // Public properties
    headerClasses = '';
    headerContainerClasses = '';
    headerMenuClasses = '';
    headerAttributes = {};
    headerMenuSelfDisplay = false;

    @ViewChild('ktHeader', { static: true }) ktHeader: ElementRef;

    constructor(public htmlClassService: HtmlClassService, private layoutRefService: LayoutRefService, private layoutConfigService: LayoutConfigService) {}

    ngOnInit(): void {
        this.headerClasses = this.htmlClassService.getClasses('header', true).toString();
        this.headerAttributes = this.htmlClassService.getAttributes('header');
        this.headerMenuSelfDisplay = this.layoutConfigService.getConfig('header.menu.self.display');
        this.headerContainerClasses = this.htmlClassService.getClasses('header_container', true).toString();
        this.headerMenuClasses = this.htmlClassService.getClasses('header_menu', true).toString();
    }

    ngAfterViewInit(): void {
        // keep header element in the service
        this.layoutRefService.addElement('header', this.ktHeader.nativeElement);
    }
}
