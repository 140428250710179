// Angular
import { Component, Inject, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
// NGRX
import { EntityAction, EntityOp } from '@ngrx/data';
import { Store } from '@ngrx/store';
// RXJS
import { timer } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';
// Core
import { isCustomer } from '@core/auth';
import { AppState } from '@core/reducers';
import { UnsubscribeOnDestroy } from '@core/services';
import { AuthNoticeService } from '@core/auth/store/auth-notice/auth-notice.service';
import { LayoutUtilsService, MessageType } from '@core/_base/crud';
// Store
import { QuoteModel, QuotesEntityService, QuoteStatus } from '@store/index';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Theme
import { MY_FORMATS } from '@theme/material.module';
// Moment
import moment from 'moment';
@Component({
    selector: 'quote-validate',
    templateUrl: './quote-validate.component.html',
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class QuoteValidateComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @ViewChild('dpFooter', { static: false }) datepickerFooter: ElementRef;
    @ViewChild('dp', { static: false }) datepicker: MatDatepicker<any>;

    comment: string;
    dateSelected: any;
    deliveryAsap: boolean;
    isCustomer = false;
    isAcceptProgress = false;
    isRefused = false;
    loading = false;
    minDateEnd = new Date();
    quote: QuoteModel;

    // prettier-ignore
    constructor(
        public authNoticeService: AuthNoticeService,
        private dialogRef: MatDialogRef<QuoteValidateComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private layoutUtilsService: LayoutUtilsService,
        private quotesService: QuotesEntityService,
        private store: Store<AppState>,
        private translateService: TranslateService) 
    {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnInit(): void {
        this.checkIfUserIsCustomer();

        this.getModalData();
    }

    ngOnDestroy(): void {
        this.loading = false;
        this.subs.unsubscribe();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    close(): void {
        this.dialogRef.close();
    }

    openDP(): void {
        this.datepicker.open();
        if (this.isCustomer) {
            this.appendFooter();
        }
    }

    refuseQuote() {
        const _quote = this.prepareDataToRefuse();

        this.quotesService.update(_quote);

        // Wait for response
        this.subs.sink = this.quotesService.entityActions$
            .pipe(
                filter((ea: EntityAction) => ea.payload.entityOp === EntityOp.SAVE_UPDATE_ONE_SUCCESS),
                first(),
            )
            .subscribe(() => {
                this.loading = false;
                this.close();
            });
    }

    resetAsap(): void {
        this.quote.deliveryAsap = null;
        this.quote.deliveryDate = null;
        this.openDP();
    }

    selectAsap(): void {
        this.quote.deliveryAsap = true;
        this.quote.deliveryDate = null;
        this.datepicker.close();
    }

    async validateQuote(): Promise<void> {
        // Open automatically the datepicker if delivery date or is not selected
        if (this.checkIfNeedToSelectDate()) return;

        this.loading = true;

        // Pause to have a smooth change for the user
        await timer(1000).pipe(take(1)).toPromise();

        const _quote = this.prepareData();

        this.quotesService.validate(_quote);

        // Wait for response
        this.subs.sink = this.quotesService.entityActions$
            .pipe(
                filter((ea: EntityAction) => ea.payload.entityOp === EntityOp.SAVE_UPDATE_ONE_SUCCESS),
                first(),
            )
            .subscribe(() => {
                this.loading = false;
                this.close();

                const message = this.translateService.instant(this.getValidationMessage());
                this.layoutUtilsService.showActionNotification(message, MessageType.Success, 3000, true, false);
            });
    }
    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/
    checkIfUserIsCustomer() {
        this.subs.sink = this.store.select(isCustomer).subscribe((isCustomer) => (this.isCustomer = isCustomer));
    }

    checkIfNeedToSelectDate(): boolean {
        if (this.isQuoteProposition() && this.quote.deliveryAsap == null && this.quote.deliveryDate == null) {
            this.openDP();
            this.isAcceptProgress = true;
            return true;
        }
        return false;
    }

    getCommentWithDate(previousComment?: string) {
        let commentToReturn = previousComment || '';

        if (!this.comment) return commentToReturn;

        if (previousComment) {
            commentToReturn = `${previousComment}\n\n${moment().format('DD/MM/YY')} : ${this.comment?.trim()}`;
        } else {
            commentToReturn = `${moment().format('DD/MM/YY')} : ${this.comment?.trim()}`;
        }
        return commentToReturn;
    }

    getModalData() {
        this.quote = new QuoteModel(this.data.quote);

        this.isRefused = this.data.isRefused || false;
    }

    isQuoteProposition(): boolean {
        return this.quote.status === QuoteStatus.Proposal || this.quote.status === QuoteStatus.NewProposal;
    }

    onDPClosed(): void {
        // When delivery date is selected before user click on accept quote
        if (this.isAcceptProgress) {
            this.validateQuote();
            this.isAcceptProgress = false;
        }
    }

    prepareDataToRefuse(): QuoteModel {
        const _quote = new QuoteModel();
        _quote.id = this.quote.id;
        _quote.status = QuoteStatus.Refused;
        _quote.commentCustomer = this.getCommentWithDate(this.quote.commentCustomer);

        return _quote;
    }

    prepareData(): QuoteModel {
        const _quote = new QuoteModel();
        _quote.id = this.quote.id;
        _quote.quoteLines = this.quote.quoteLines;

        // Validate quote
        if (this.quote.status === QuoteStatus.QuoteCart) {
            _quote.clickAndCollect = this.quote.clickAndCollect;
            _quote.deliveryDate = this.quote.deliveryDate;
            _quote.commentCustomer = this.getCommentWithDate();
            _quote.status = QuoteStatus.New;
            _quote.nbProducts = this.quote.quoteLines.length;
            _quote.validateDate = new Date();
        }
        // Accept quote
        else if (this.isQuoteProposition()) {
            _quote.clickAndCollect = this.quote.clickAndCollect;
            _quote.deliveryDate = this.quote.deliveryDate;
            _quote.commentCustomer = this.getCommentWithDate(this.quote.commentCustomer);
            _quote.status = QuoteStatus.Accepted;
        }
        // Do a proposal or a new proposal
        else {
            _quote.supplier = this.quote.supplier;
            _quote.deliveryPlanned = this.quote.deliveryPlanned;
            _quote.commentSupplier = this.getCommentWithDate(this.quote.commentSupplier);
            _quote.status = this.quote.status === QuoteStatus.Refused ? QuoteStatus.NewProposal : QuoteStatus.Proposal;
            _quote.calculateAndSetAmountTotal();
            _quote.setShippingCost();
        }

        return _quote;
    }

    /***************/
    /*     UI      */
    /***************/
    appendFooter(): void {
        const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
        matCalendar.appendChild(this.datepickerFooter.nativeElement);
    }

    getTitleLabel(): string {
        if (this.isRefused) return 'QUOTES.EDIT.REFUSE_TITLE';

        switch (this.quote.status) {
            case QuoteStatus.QuoteCart:
                return 'QUOTES.EDIT.VALIDATE_TITLE';
            case QuoteStatus.New:
            case QuoteStatus.Refused:
            case QuoteStatus.View:
                return 'QUOTES.EDIT.PROPOSAL_TITLE';
            case QuoteStatus.NewProposal:
            case QuoteStatus.Proposal:
                return 'ORDERS.EDIT.VALIDATE_TITLE';
            default:
                return 'QUOTES.EDIT.VALIDATE_TITLE';
        }
    }

    getValidateLabel(): string {
        switch (this.quote.status) {
            case QuoteStatus.QuoteCart:
                return 'QUOTES.EDIT.SEND_QUOTE_REQUEST';
            case QuoteStatus.New:
            case QuoteStatus.Refused:
            case QuoteStatus.View:
                return 'QUOTES.EDIT.SEND_QUOTE_PROPOSAL';
            case QuoteStatus.NewProposal:
            case QuoteStatus.Proposal:
                return 'ORDERS.EDIT.SEND_ORDER';
            default:
                return 'GENERAL.VALIDATE';
        }
    }

    getValidationMessage(): string | any {
        switch (this.quote.status) {
            case QuoteStatus.Accepted:
                return 'QUOTES.EDIT.ACCEPTED_SUCCESS';
            case QuoteStatus.QuoteCart:
                return 'QUOTES.EDIT.SEND_QUOTE_PROPOSAL_SUCCESS';
            case QuoteStatus.New:
            case QuoteStatus.View:
                return 'QUOTES.EDIT.SEND_QUOTE_PROPOSAL_SUCCESS';
            default:
                return 'GENERAL.SAVE_SUCCESS';
        }
    }
}
