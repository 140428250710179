import { Injectable } from '@angular/core';

// RXJS
import { catchError, exhaustMap, map, mapTo } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
// Fire-Auth
import * as PasswordActions from '../_actions/password-management.actions';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class PasswordManagementEffects {
    @Effect()
    changePassword: Observable<Action> = this.actions$.pipe(
        ofType<PasswordActions.ChangePasswordRequest>(PasswordActions.PasswordManagementActionTypes.ChangePasswordRequest),
        map((action) => action.payload),
        exhaustMap((payload) => {
            return this.authService.updatePassword(payload.oldPassword, payload.newPassword).pipe(
                mapTo(new PasswordActions.ChangePasswordSuccess()),
                catchError((err) => of(new PasswordActions.ChangePasswordError(err))),
            );
        }),
    );

    @Effect()
    passwordForgotten$ = this.actions$.pipe(
        ofType<PasswordActions.ResetPasswordRequest>(PasswordActions.PasswordManagementActionTypes.ResetPasswordRequest),
        map((action) => action.payload),
        exhaustMap((payload) => {
            return this.authService.sendPasswordResetEmail(payload.email).pipe(
                mapTo(new PasswordActions.ResetPasswordRequestSuccess()),
                catchError((err) => {
                    console.error(err);
                    return of(new PasswordActions.ResetPasswordRequestError(err));
                }),
            );
        }),
    );

    constructor(private actions$: Actions, private authService: AuthService) {}
}
