// Angular
import { Injectable } from '@angular/core';
// AngularFire
import { AngularFirestore } from '@angular/fire/firestore';
import firebase, { firestore } from 'firebase/app';
import 'firebase/firestore';
// RXJS
import { map, catchError, takeUntil } from 'rxjs/operators';
import { Observable, from, Subject } from 'rxjs';
// NGRX
import { QueryParams } from '@ngrx/data';
// Core
import { OrderFSModel, QueryFSModel } from '@core/_base/crud/models/query-models/query-firestore.model';
import { TypesUtilsService } from '../_base/crud/utils/types-utils.service';
// Moment
import * as moment from 'moment';

// Firestore collection
// Customers tenant
const FS_PATH_CUSTOMERS_TENANT = 'customersTenant';
const FS_PATH_USERS = 'users';

// Suppliers tenant
const FS_PATH_SUPPLIERS_TENANT = 'suppliersTenant';

@Injectable({
    providedIn: 'root',
})
export class FirestoreService {
    // Define the path to the tenant of the user
    // Customers tenant
    private customersTenantPath: string;
    private usersTenantPath: string;

    // Suppliers tenant
    private suppliersTenantPath: string;

    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private afs: AngularFirestore, private typesUtilsService: TypesUtilsService) {}

    /**
     * @param {companyId} companyId id of the company's user
     * @param {userId} userId
     *
     * Add tenantId to tenantPath
     **/
    initAllTenantsPath(companyId: string, userId: string, isSupplier = false): void {
        if (isSupplier) {
            this.setSuppliersTenantPath(companyId);
        } else {
            this.setCustomersTenantPath(companyId);
            this.setUsersTenantPath(userId);
        }
    }

    // Customers tenant
    setCustomersTenantPath(companyId: string): void {
        this.customersTenantPath = `/${FS_PATH_CUSTOMERS_TENANT}/${companyId}/`;
    }
    setUsersTenantPath(userId: string): void {
        this.usersTenantPath = this.customersTenantPath + `${FS_PATH_USERS}/${userId}/`;
    }

    // Suppliers tenant
    setSuppliersTenantPath(companyId: string): void {
        this.suppliersTenantPath = `/${FS_PATH_SUPPLIERS_TENANT}/${companyId}/`;
    }

    getTenantPath(tenantName: string): string {
        switch (tenantName) {
            case 'customers':
                return this.customersTenantPath;
            case 'users':
                return this.usersTenantPath;
            case 'suppliers':
                return this.suppliersTenantPath;
            default:
                return null;
        }
    }

    /**
     * @param {string} path 'collection/docID'
     * @param {string} tenantName define if document is in a tenant collection
     * @param {QueryFSModel[]} queryArray array of query object
     * @param {OrderFSModel} orderBy orderBy object
     * @param {number} limit limit the number of result
     * @param {number} start index to start query (use to paginate)
     *
     *
     * Return a collection of documents in observable
     **/
    collection$(path: string, tenantName?: string, queryArray?: QueryFSModel[], orderBy?: OrderFSModel, limit?: number): Observable<any> {
        // Add the tenantPath if required
        if (tenantName) {
            path = this.getTenantPath(tenantName) + path;
        }

        return this.afs
            .collection(path, (ref) => {
                let collection: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                queryArray?.forEach((query) => {
                    collection = collection.where(this.getFieldPath(query.field), query.operator, query.value);
                });

                // Order result
                if (orderBy) collection = collection.orderBy(orderBy.field, orderBy.direction);

                // Limit the number of result
                if (limit) collection = collection.limit(limit);

                return collection;
            })
            .snapshotChanges()
            .pipe(
                takeUntil(this.destroy$),
                map((actions) => {
                    return actions.map((a) => {
                        const data: any = a.payload.doc.data();
                        const id = a.payload.doc.id;

                        // Convert Firestore timestamp date to date
                        if (data?._createdDate) data._createdDate = data?._createdDate?.toDate();
                        if (data?._updatedDate) data._updatedDate = data?._updatedDate?.toDate();

                        return { id, ...data };
                    });
                }),
                catchError((error) => {
                    throw error;
                }),
            );
    }

    // Check if at least one document exist in collection
    collectionExist(path: string, tenantName?: string): Observable<boolean> {
        return this.collectionSnapShot(path, tenantName, null, null, 1).pipe(
            map((docs: any[]) => {
                return docs.length > 0;
            }),
        );
    }

    /**
     * @param {string} path 'collection/docID'
     * @param {string} tenantName define if document is in a tenant collection
     * @param {any} queryArray array of query
     *
     * Return a collection of documents in promise
     **/
    collectionSnapShot(path: string, tenantName?: string, queryArray?: QueryFSModel[], orderBy?: OrderFSModel, limit?: number, index?: any): Observable<any> {
        // Add the tenantPath if required
        if (tenantName) {
            path = this.getTenantPath(tenantName) + path;
        }

        return this.afs
            .collection(path, (ref) => {
                let collection: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                queryArray?.forEach((query) => {
                    collection = collection.where(this.getFieldPath(query.field), query.operator, query.value);
                });

                // Order result
                if (orderBy) collection = collection.orderBy(orderBy.field, orderBy.direction);

                // Paginate
                if (index) collection = collection.startAfter(index);

                // Limit the number of result
                if (limit) collection = collection.limit(limit);

                return collection;
            })
            .get()
            .pipe(
                takeUntil(this.destroy$),
                map((querySnapshot) =>
                    querySnapshot.docs.map((documentSnapshot: firebase.firestore.QueryDocumentSnapshot) => {
                        return { id: documentSnapshot.id, ...documentSnapshot.data() };
                    }),
                ),
                map((datas) =>
                    datas.map((data: any) => {
                        // Convert Firestore timestamp date to date
                        if (data?._createdDate) data._createdDate = data?._createdDate?.toDate();
                        if (data?._updatedDate) data._updatedDate = data?._updatedDate?.toDate();

                        return data;
                    }),
                ),
                catchError((error) => {
                    throw error;
                }),
            );
    }

    /**
     * @param {string} path 'collection/docID'
     * @param {string} tenantName define if document is in a tenant collection
     *
     * Return a document in observable
     **/
    doc$(path: string, tenantName?: string): Observable<any> {
        // Add the tenantPath if required
        if (tenantName) {
            path = this.getTenantPath(tenantName) + path;
        }

        return this.afs
            .doc(path)
            .snapshotChanges()
            .pipe(
                takeUntil(this.destroy$),
                map((doc) => {
                    const data: any = doc.payload.data();
                    const id = doc.payload.id;

                    // Convert Firestore timestamp date to date
                    if (data?._createdDate) data._createdDate = data?._createdDate?.toDate();
                    if (data?._updatedDate) data._updatedDate = data?._updatedDate?.toDate();

                    return { id, ...data };
                }),
                catchError((error) => {
                    throw error;
                }),
            );
    }

    /**
     * @param {string} path 'collection/docID'
     * @param {string} tenantName define if document is in a tenant collection
     *
     * Return a document in promise
     **/
    docSnapshot(path: string, tenantName?: string): Observable<any> {
        // Add the tenantPath if required
        if (tenantName) {
            path = this.getTenantPath(tenantName) + path;
        }

        return this.afs
            .doc(path)
            .get()
            .pipe(
                map((documentSnapshot) => {
                    const data: any = documentSnapshot.data();

                    // Convert Firestore timestamp date to date
                    if (data?._createdDate) data._createdDate = data?._createdDate?.toDate();
                    if (data?._updatedDate) data._updatedDate = data?._updatedDate?.toDate();

                    return { id: documentSnapshot.id, ...data };
                }),
                catchError((error) => {
                    throw error;
                }),
            );
    }

    /**
     * @param {string} path 'collection' or 'collection/docID'
     * @param {object} data new data or updated data
     * @param {string} tenantName define if document is in a tenant collection
     *
     * Creates or updates data on a collection or a document
     **/
    updateAt(path: string, data: any, tenantName?: string): Observable<any> {
        const segments = path.split('/').filter((v) => v);
        // Clone object without class model to avoid error in firestore
        let _data = this.typesUtilsService.nestedObjectAssign({}, data);
        // Add the tenantPath if required
        if (tenantName) {
            path = this.getTenantPath(tenantName) + path;
        }

        if (segments.length % 2) {
            _data = this.setUserInfo(_data);
            return from(this.afs.collection(path).add(_data)).pipe(
                map((doc) => {
                    return { id: doc.id, ...data };
                }),
            );
        } else {
            _data = this.setUserInfo(_data, true);
            const docReference = this.afs.doc(path);
            return from(docReference.set(_data, { merge: true })).pipe(
                map(() => {
                    return { id: docReference.ref.id, ...data };
                }),
            );
        }
    }

    /**
     * @param {string} path 'collection/docID'
     * @param {string} tenantName define if document is in a tenant collection
     *
     * Delete a document
     **/
    delete(path: string, tenantName?: string): Observable<void> {
        // Add the tenantPath if required
        if (tenantName) {
            path = this.getTenantPath(tenantName) + path;
        }
        return from(this.afs.doc(path).delete());
    }

    // https://stackoverflow.com/questions/54671543/what-counts-towards-the-500-item-limit-in-firestore-batch-writes
    checkIfNeedToChangeBatch(nbRecords: number): firebase.firestore.WriteBatch | null {
        return nbRecords === 249 ? this.getBatch() : null;
    }

    getBatch(): firestore.WriteBatch {
        return firebase.firestore().batch();
    }

    /** Get document reference */
    getDocRef(path: string, tenantName?: string): firebase.firestore.DocumentReference {
        const segments = path.split('/').filter((v) => v);

        // Add the tenantPath if required
        if (tenantName) {
            path = this.getTenantPath(tenantName) + path;
        }

        if (segments.length % 2) return firebase.firestore().collection(path).doc();
        else return firebase.firestore().doc(path);
    }

    /** Transform the date to a Firestore format date */
    getFirestoreDate(date: any): any {
        return moment.isDate(date) ? date : firebase.firestore.Timestamp.fromDate(date?.toDate());
    }

    /** Get a field path type if the query key is a sub object */
    getFieldPath(key: string): firestore.FieldPath {
        const fieldPath = key.split('.');
        return new firebase.firestore.FieldPath(...fieldPath);
    }

    /** Transform QueryParams to Query Firestore model */
    getQueryFSModel(queryParams: QueryParams | string): QueryFSModel[] {
        const queryArray = [];
        Object.keys(queryParams).forEach((key) => {
            const query = queryParams[key];
            queryArray.push(new QueryFSModel(query.field, query.operator, query.value));
        });
        return queryArray;
    }

    getFirestoreTimestamp(): firestore.FieldValue {
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    setUserInfo(data: any, update?: boolean): any {
        const userId = localStorage.getItem('userId');
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        if (update) {
            return { ...data, _updatedDate: timestamp, _updatedUserId: userId };
        } else {
            return { ...data, _createdDate: timestamp, _createdUserId: userId };
        }
    }

    /* Close Firestore connection */
    close(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
