// Angular
import { NgModule } from '@angular/core';
// Typesense
import { ProductUploadPhotoComponent } from './hits-products-list/product-upload-photo/product-upload-photo.component';
import { TypesenseClearRefinementsComponent } from './clear-refinements/clear-refinements.component';
import { TypesenseClearRefinementsService } from './clear-refinements/clear-refinements.service';
import { TypesenseHierarchicalMenuComponent } from './hierarchical-menu/hierarchical-menu.component';
import { TypesenseHierarchicalMenuItemComponent } from './hierarchical-menu/hierarchical-menu-item.component';
import { TypesenseHighlightComponent } from './highlights/highlights.component';
import { TypesenseHitsPerPageComponent } from './hits-per-page/hits-per-page.component';
import { TypesenseHitsProductsComponent } from './hits-products/hits-products.component';
import { TypesenseHitsProductsListComponent } from './hits-products-list/hits-products-list.component';
import { TypesenseHitsProductsListEditComponent } from './hits-products-list-edit/hits-products-list-edit.component';
import { TypesensePaginationComponent } from './pagination/pagination.component';
import { TypesensePaginationService } from './pagination/pagination.service';
import { TypesenseRefinementListComponent } from './refinement-list/refinement-list.component';
import { TypesenseRefreshComponent } from './refresh/refresh.component';
import { TypesenseSearchComponent } from './search/search.component';
import { TypesenseSearchService } from './search/search.service';
import { TypesenseSortComponent } from './sort/sort.component';
import { TypesenseStatsComponent } from './stats/stats.component';
import { TypesenseToggleRefinementComponent } from './toggle-refinement/toggle-refinement.component';
// Theme
import { ThemeModule } from '@theme/theme.module';
// Core
import { CoreModule } from '@core/core.module';
// Angular Instantsearch
import { NgAisModule } from 'angular-instantsearch';
import { TypesenseSortService } from './sort/sort.service';

//prettier-ignore
@NgModule({
    imports: [
        CoreModule,
        ThemeModule,
    ],
    declarations: [
        ProductUploadPhotoComponent,
        TypesenseClearRefinementsComponent,
        TypesenseHierarchicalMenuComponent,
        TypesenseHierarchicalMenuItemComponent,
        TypesenseHighlightComponent,
        TypesenseHitsProductsComponent,
        TypesenseHitsProductsListComponent,
        TypesenseHitsProductsListEditComponent,
        TypesensePaginationComponent,
        TypesenseHitsPerPageComponent,
        TypesenseRefinementListComponent,
        TypesenseRefreshComponent,
        TypesenseSearchComponent,
        TypesenseSortComponent,
        TypesenseStatsComponent,
        TypesenseToggleRefinementComponent
    ],
    exports: [
        NgAisModule,
        ProductUploadPhotoComponent,
        TypesenseClearRefinementsComponent,
        TypesenseHierarchicalMenuComponent,
        TypesenseHierarchicalMenuItemComponent,
        TypesenseHighlightComponent,
        TypesenseHitsProductsComponent,
        TypesenseHitsProductsListComponent,
        TypesenseHitsProductsListEditComponent,
        TypesensePaginationComponent,
        TypesenseHitsPerPageComponent,
        TypesenseRefinementListComponent,
        TypesenseRefreshComponent,
        TypesenseSearchComponent,
        TypesenseSortComponent,
        TypesenseStatsComponent,
        TypesenseToggleRefinementComponent
    ],
    providers: [
        TypesenseClearRefinementsService,
        TypesensePaginationService,
        TypesenseSearchService,
        TypesenseSortService,
    ]
})
export class TypesenseModule {}
