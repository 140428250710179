// Angular
import { Injectable } from '@angular/core';
// NGRX
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
// RXJS
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
// Store
import { ChatRoomModel, MessageModel } from './chat-room.model';
import { ChatRoomsDataService } from './chat-rooms-data.service';
// Core
import { AuthUserModel, getUser } from '@core/auth';
// Lodash
import { isEmpty, orderBy } from 'lodash';

const CRID_LOAD = 'CRID_CHAT';
@Injectable({
    providedIn: 'root',
})
export class ChatRoomsEntityService extends EntityCollectionServiceBase<any> {
    private _chatRoom$: BehaviorSubject<ChatRoomModel> = new BehaviorSubject<any>(null);
    private _correlationIndex = 0;
    private _user: AuthUserModel;

    //prettier-ignore
    constructor(
        private chatRoomDataService: ChatRoomsDataService,
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('ChatRooms', serviceElementsFactory);

        this.getUserConnected();

        this.loadAll();
    }

    /******************/
    /**    Getter     */
    /******************/
    getEntities(): Observable<ChatRoomModel[]> {
        return this.entities$.pipe(map((chatRooms) => chatRooms.map((c) => new ChatRoomModel(c))));
    }

    /******************/
    /**    Actions    */
    /******************/
    addMessage(chatRoomId: string, message: MessageModel): void {
        this.chatRoomDataService.addMessage(chatRoomId, message);
    }

    getNewMessages(chatRoomId: string, date: Date): Observable<MessageModel[]> {
        return this.chatRoomDataService.getNewMessages(chatRoomId, date);
    }

    getMoreMessages(chatRoomId: string, date: Date, index: any): Observable<MessageModel[]> {
        return this.chatRoomDataService.getMoreMessages(chatRoomId, date, index);
    }

    getChatRoom(): Observable<ChatRoomModel> {
        return this._chatRoom$.asObservable();
    }

    loadAll(): void {
        this.loaded$
            .pipe(
                filter((loaded) => !loaded),
                first(),
            )
            .subscribe(() => this.load({ correlationId: this.getCorrelationId('loadAll') }));
    }

    setChatRoom(chatRoom: ChatRoomModel): void {
        this._chatRoom$.next(null);
        this._chatRoom$.next(chatRoom);
    }

    setUserStatus(isOnline: boolean): void {
        this.entities$
            .pipe(
                map((chatRooms: ChatRoomModel[]) =>
                    chatRooms.map((chatRoom) => {
                        const _chatRoom = new ChatRoomModel();
                        _chatRoom.id = chatRoom.id;

                        const status = isOnline ? 'online' : new Date();

                        chatRoom.customerId === this._user.id ? (_chatRoom.customerLastSignIn = status) : (_chatRoom.supplierLastSignIn = status);

                        this.update(_chatRoom);
                    }),
                ),
                first(),
            )
            .subscribe();
    }

    uploadFile(chatRoomId: string, file: File): Observable<any> {
        return this.chatRoomDataService.uploadFile(chatRoomId, file);
    }

    /******************/
    /**   Selectors   */
    /******************/
    isChatRooms(): Observable<boolean> {
        return this.selectActiveChatRooms().pipe(map((chatRooms) => chatRooms.length > 0));
    }

    isSupplierAndCustomer(): Observable<boolean> {
        return this.entities$.pipe(
            map((chatRooms: ChatRoomModel[]) => {
                return chatRooms.findIndex((c) => c.customerId === this._user.id) > -1 && chatRooms.findIndex((c) => c.supplierId === this._user.id) > -1;
            }),
        );
    }

    // Don't select chat rooms when customer don't have yet created his account
    selectActiveChatRooms(): Observable<ChatRoomModel[]> {
        return this.getEntities().pipe(
            map((chatRooms) => chatRooms.filter((chatRoom) => chatRoom.customerCompany)),
            map((chatRooms) => orderBy(chatRooms, ({ lastMessageTime }) => lastMessageTime || '', 'desc')),
        );
    }

    selectEntityById(id: string): Observable<ChatRoomModel> {
        return this.entityMap$.pipe(
            filter((entities) => !isEmpty(entities)),
            map((entities) => new ChatRoomModel(entities[id])),
        );
    }

    /*************************/
    /*   Service functions   */
    /*************************/
    getCorrelationId(action: string) {
        this._correlationIndex++;
        return `${CRID_LOAD}_${action.toUpperCase()}_${this._correlationIndex}`;
    }

    getUserConnected(): void {
        this.store
            .select(getUser)
            .pipe(first())
            .subscribe((user) => (this._user = user));
    }
}
