import { BaseModel } from '@core/_base/crud';

export class RoleModel extends BaseModel {
    id: string;
    name: string;
    permissions: string[];

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.permissions = [];
    }
}
