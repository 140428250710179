// Angular
import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
// Core
import { UnsubscribeOnDestroy } from '@core/services';
// Store
import { ProductModel, ProductsEntityService } from '@store/index';
// RXJS
import { first } from 'rxjs/operators';

@Component({
    selector: 'product-upload-photo',
    templateUrl: './product-upload-photo.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductUploadPhotoComponent extends UnsubscribeOnDestroy implements OnDestroy {
    @Input() product: ProductModel;

    loading = false;

    // prettier-ignore
    constructor(
        private cdr: ChangeDetectorRef,
        private productsService: ProductsEntityService
    ) {
        super();
    }

    /***************/
    /*  LIFECYCLE  */
    /***************/
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /***************/
    /*   ACTIONS   */
    /***************/
    uploadPhoto(event: any): void {
        this.loading = true;
        const photoFile = event.addedFiles[0];

        if (photoFile) {
            this.subs.sink = this.productsService
                .updatePhotoInProductsList(photoFile, this.product)
                .pipe(first())
                .subscribe((res) => {
                    this.loading = false;
                    this.product.photo = res.photo;
                    this.cdr.markForCheck();
                });
        }
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/

    /*******************/
    /*       UI        */
    /*******************/
    isPhoto(): boolean {
        return this.product.photo?.length > 0;
    }
}
