// Angular
import { Injectable } from '@angular/core';
// RXJS
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    public isLoading$ = new BehaviorSubject(false);
}
