// Angular
import { Component, Input, ViewEncapsulation } from '@angular/core';
// Translate
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'empty-content',
    templateUrl: './empty-content.component.html',
    styleUrls: ['./empty-content.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EmptyContentComponent {
    @Input() content: string = null;
    @Input() imageFile: string = null;
    @Input() image = true;

    constructor(private translateService: TranslateService) {}

    getImage(): string {
        const file = this.imageFile || this.content;
        return `/assets/media/img/empty/${file}.svg`;
    }

    getLabel(): string {
        return this.translateService.instant(`EMPTY.${this.content?.toLocaleUpperCase()}`);
    }
}
