// Angular
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'order-status-filter',
    templateUrl: './order-status-filter.component.html',
})
export class OrderStatusFilterComponent {
    statusSelected = -1;
    @Output() handlerFilter = new EventEmitter();

    /***************/
    /*  LIFECYCLE  */
    /***************/

    /***************/
    /*   ACTIONS   */
    /***************/
    onStatusSelected() {
        this.handlerFilter.emit(Number(this.statusSelected));
    }

    /*************************/
    /*  COMPONENT FUNCTIONS  */
    /*************************/

    /***********/
    /**   UI   */
    /***********/
}
